// src/components/modules/chatInterface/subcomponents/navigationTabs/QuestionsAnswersList.jsx

import React from 'react';
import { Box, Grid, Typography, Chip, Paper, Divider } from '@mui/material';
import { campaignQuestionsConfig } from '../../../../../utils/configs/campaignQuestionsConfig';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

const QuestionsAnswersList = ({ campaignType, formData }) => {
	const questions = campaignQuestionsConfig[campaignType] || [];

	const formatDate = (dateString) => {
		const [year, month, day] = dateString.split('-');
		const date = new Date(year, month - 1, day); // Months are 0-based in JavaScript Date

		const options = { month: 'long', day: 'numeric', year: 'numeric' };
		return date.toLocaleDateString('en-US', options);
	};

	const getAnswer = (key) => {
		const answer = formData[key];
		const detailsKey = `${key}Details`; // Construct the details key name

		// Helper function to format numbers with commas
		const formatNumber = (num, addDollarSign) => {
			return `${addDollarSign ? '$' : ''}${Number(num).toLocaleString('en-US')}`;
		};

		// Check if the key or detailsKey should have a dollar sign
		const needsDollarSign =
			key.toLowerCase().includes('budget') || key.toLowerCase().includes('cost');

		if (formData[detailsKey] && typeof formData[detailsKey] === 'object') {
			// If there's a details field, get the first key-value pair from it
			const [detailKey, detailValue] = Object.entries(formData[detailsKey])[0] || [];
			if (detailKey && detailValue) {
				// Determine if a dollar sign is needed based on detailKey
				const formattedValue = !isNaN(detailValue)
					? formatNumber(detailValue, needsDollarSign)
					: detailValue;
				return (
					<Typography variant="body2">
						{`${detailKey} - ${formattedValue}`}
					</Typography>
				);
			}
		}

		// Handle non-array and non-object values or fallback if no details field is found
		if (Array.isArray(answer)) {
			return answer.map((item, index) => (
				<Chip
					key={index}
					label={item}
					sx={{ mr: 1, mb: 1 }}
					color="primary"
					variant="outlined"
				/>
			));
		} else if (typeof answer === 'object' && answer !== null) {
			return Object.entries(answer).map(([subKey, subValue], index) => (
				<Typography key={index} variant="body2">
					<strong>{subKey}:</strong> {subValue}
				</Typography>
			));
		} else if (typeof answer === 'string' && answer.match(/^\d{4}-\d{2}-\d{2}$/)) {
			// Format date string if it matches the YYYY-MM-DD format
			return <Typography variant="body2">{formatDate(answer)}</Typography>;
		}

		// Fallback for standard strings or numbers without details
		return <Typography variant="body2">{answer}</Typography>;
	};

	return (
		<Box>
			<Grid container spacing={3}>
				{questions.map((questionItem) => (
					<Grid item xs={12} md={6} key={questionItem.key}>
						<Paper sx={{ p: 2, height: '100%', borderRadius: 2, boxShadow: 3 }}>
							<Box display="flex" alignItems="center" mb={1}>
								<QuestionAnswerIcon color="secondary" />
								<Typography variant="subtitle1" ml={1} sx={{ fontWeight: 'bold' }}>
									{questionItem.question}
								</Typography>
							</Box>
							<Divider />
							<Box mt={2}>{getAnswer(questionItem.key)}</Box>
						</Paper>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default QuestionsAnswersList;
