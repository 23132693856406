// RecentCampaignsCarousel.jsx
import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import RecentCampaignCard from './RecentCampaignCard';
import { fetchActiveCampaigns } from '../../../../utils/apis/marketingAssistant/campaignUtils';
import {
	Box,
	Card,
	CardContent,
	Typography,
	Alert,
	IconButton,
} from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import ArtistContext from '../../../../context/ArtistContext';
import LoadingSpinner from '../../../common/LoadingSpinner';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Install Swiper modules
SwiperCore.use([Navigation]);

const RecentCampaignsCarousel = ({
	onSelectConversation,
	refreshTrigger,
	onUpdate,
}) => {
	const { artistData } = useContext(ArtistContext);
	const [loading, setLoading] = useState(true);
	const [activeCampaigns, setActiveCampaigns] = useState([]);
	const [error, setError] = useState(null);
	const [showNavigation, setShowNavigation] = useState(false);

	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);

	const prevRef = useRef(null);
	const nextRef = useRef(null);

	const fetchActiveCampaignsFromAPI = async () => {
		setLoading(true);
		setError(null);
		try {
			const campaignsData = await fetchActiveCampaigns(artistData.artistId);
			setActiveCampaigns(campaignsData);
		} catch (err) {
			setError(err.message || 'Error fetching active campaigns.');
		} finally {
			setLoading(false);
		}
	};

	const updateNavigationVisibility = () => {
		const windowWidth = window.innerWidth;
		let visibleSlides = 1;

		if (windowWidth >= 1920) visibleSlides = 5;
		else if (windowWidth >= 1400) visibleSlides = 4;
		else if (windowWidth >= 1000) visibleSlides = 3;
		else if (windowWidth >= 750) visibleSlides = 2;

		setShowNavigation(activeCampaigns.length > visibleSlides);
	};

	useEffect(() => {
		fetchActiveCampaignsFromAPI();
	}, [artistData.artistId, refreshTrigger]);

	useEffect(() => {
		updateNavigationVisibility();
		window.addEventListener('resize', updateNavigationVisibility);
		return () => window.removeEventListener('resize', updateNavigationVisibility);
	}, [activeCampaigns]);

	return (
		<Box sx={{ p: 2 }}>
			<Typography variant="h4" sx={{ textAlign: 'left' }} gutterBottom>
				Recent Campaigns
			</Typography>
			{loading && <LoadingSpinner isLoading={true} />}
			{error && !loading && (
				<Alert severity="error" sx={{ mt: 2 }}>
					{error}
				</Alert>
			)}
			{!loading && !error && activeCampaigns.length > 0 && (
				<>
					<Swiper
						modules={[Navigation]}
						navigation={{
							prevEl: prevRef.current,
							nextEl: nextRef.current,
						}}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl = prevRef.current;
							swiper.params.navigation.nextEl = nextRef.current;
						}}
						onSlideChange={(swiper) => {
							setIsBeginning(swiper.isBeginning);
							setIsEnd(swiper.isEnd);
						}}
						spaceBetween={16}
						breakpoints={{
							0: {
								slidesPerView: 1,
							},
							750: {
								slidesPerView: 2,
							},
							1000: {
								slidesPerView: 3,
							},
							1400: {
								slidesPerView: 4,
							},
							1920: {
								slidesPerView: 5,
							},
						}}
						style={{ padding: '8px 0' }}
					>
						{activeCampaigns.map((campaign) => (
							<SwiperSlide key={campaign.id}>
								<Box sx={{ p: 2 }}>
									<RecentCampaignCard
										campaign={campaign}
										onSelect={onSelectConversation}
										onUpdate={onUpdate}
									/>
								</Box>
							</SwiperSlide>
						))}
					</Swiper>
					{showNavigation && (
						<Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
							<IconButton ref={prevRef} disabled={isBeginning}>
								<ArrowBackIosIcon />
							</IconButton>
							<IconButton ref={nextRef} disabled={isEnd}>
								<ArrowForwardIosIcon />
							</IconButton>
						</Box>
					)}
				</>
			)}
			{!loading && !error && activeCampaigns.length === 0 && (
				<Card
					sx={{
						height: '100%',
						mt: 2,
					}}
				>
					<CardContent>
						<Typography variant="body2" sx={{ mt: 2 }}>
							No recent campaigns.
						</Typography>
					</CardContent>
				</Card>
			)}
		</Box>
	);
};

RecentCampaignsCarousel.propTypes = {
	onSelectConversation: PropTypes.func.isRequired,
	refreshTrigger: PropTypes.number,
	onUpdate: PropTypes.func.isRequired,
};

export default RecentCampaignsCarousel;
