// src/components/modules/chatInterface/subcomponents/navigationTabs/ChatOverview.jsx

import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import OverviewSummary from './OverviewSummary';
import QuestionsAnswersList from './QuestionsAnswersList';
import InfoIcon from '@mui/icons-material/Info';

const ChatOverview = ({ conversationContext }) => {
	const { marketing_goal, form_data } = conversationContext;

	return (
		<Box p={3} sx={{ backgroundColor: 'background.default' }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<OverviewSummary campaignType={marketing_goal} />
				</Grid>
				<Grid item xs={12}>
					<Divider />
				</Grid>
				<Grid item xs={12}>
					<Box display="flex" alignItems="center" mb={3}>
						<InfoIcon color="primary" />
						<Typography variant="h4" ml={1}>
							Campaign Details
						</Typography>
					</Box>
					<QuestionsAnswersList campaignType={marketing_goal} formData={form_data} />
				</Grid>
			</Grid>
		</Box>
	);
};

export default ChatOverview;
