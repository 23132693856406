// components/modules/chatInterface/subcomponents/messageTypes/Visualization.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import {
	Line,
	Bar,
	Pie,
	Doughnut,
	Radar,
	PolarArea,
	Bubble,
	Scatter,
} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const Visualization = ({ data }) => {
	const defaultOptions = {
		responsive: true,
		maintainAspectRatio: false,
	};
	if (!data || !data.chartData || !data.type) {
		return (
			<Typography variant="body2" color="error">
				Visualization data is incomplete or malformed.
			</Typography>
		);
	}

	const { type, chartData, options } = data;

	// Merge context default options with user-provided options
	const mergedOptions = {
		...defaultOptions,
		...options,
	};

	const renderChart = () => {
		const commonProps = { data: chartData, options: mergedOptions };

		switch (type) {
			case 'line':
				return <Line {...commonProps} />;
			case 'bar':
				return <Bar {...commonProps} />;
			case 'pie':
				return <Pie {...commonProps} />;
			case 'doughnut':
				return <Doughnut {...commonProps} />;
			case 'radar':
				return <Radar {...commonProps} />;
			case 'polarArea':
				return <PolarArea {...commonProps} />;
			case 'bubble':
				return <Bubble {...commonProps} />;
			case 'scatter':
				return <Scatter {...commonProps} />;
			default:
				return (
					<Typography variant="body2" color="error">
						Unsupported chart type: {type}
					</Typography>
				);
		}
	};

	return (
		<Box
			sx={{
				mt: 2,
				p: 2,
				backgroundColor: 'background.paper',
				borderRadius: 2,
				boxShadow: 3,
				width: '100%',
				minHeight: 300,
				maxHeight: 400,
				overflow: 'auto',
				'@media (max-width:600px)': {
					maxWidth: '100%',
					maxHeight: 300,
				},
			}}
		>
			{renderChart()}
		</Box>
	);
};

Visualization.propTypes = {
	data: PropTypes.shape({
		type: PropTypes.oneOf([
			'line',
			'bar',
			'pie',
			'doughnut',
			'radar',
			'polarArea',
			'bubble',
			'scatter',
		]).isRequired,
		chartData: PropTypes.object.isRequired,
		options: PropTypes.object,
	}).isRequired,
};

Visualization.defaultProps = {
	options: {},
};

export default React.memo(Visualization);
