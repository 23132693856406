// src/components/homePage/WelcomeMessage.jsx
import React from 'react';
import { Typography, Box } from '@mui/material';

const WelcomeMessage = ({ userName, artistName }) => {
	return (
		<Box
			sx={{
				textAlign: 'center',
			}}
		>
			<Typography variant="h2" component="h1" gutterBottom>
				Welcome back, {userName}!
			</Typography>
			<Typography variant="subtitle1" color="textSecondary">
				Here’s an overview of {artistName}'s performance.
			</Typography>
		</Box>
	);
};

export default WelcomeMessage;
