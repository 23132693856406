import React, { useEffect } from 'react';
import {
	Button,
	DialogActions,
	DialogContent,
	Typography,
	TextField,
	Grid,
	MenuItem,
	Box,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import typography from '../../../../themes/typography';

const LongTermGoals = ({ artistData, onSave, onPopupNext }) => {
	const goalCategories = [
		'Career Development',
		'Music Production',
		'Fan Engagement',
		'Revenue Growth',
		'Brand Partnerships',
		'Touring & Live Performances',
		'Social Media Growth',
	];

	// Formik setup
	const formik = useFormik({
		initialValues: {
			goal1Category: artistData.longTermGoal1Category || '',
			goal1Description: artistData.longTermGoal1Description || '',
			goal2Category: artistData.longTermGoal2Category || '',
			goal2Description: artistData.longTermGoal2Description || '',
			goal3Category: artistData.longTermGoal3Category || '',
			goal3Description: artistData.longTermGoal3Description || '',
		},
		// Remove enableReinitialize if it's causing issues
		validationSchema: Yup.object({
			goal1Category: Yup.string().required('Goal 1 category is required'),
			goal1Description: Yup.string()
				.min(50, 'Goal 1 description must be at least 50 characters long')
				.required('Goal 1 description is required'),
			goal2Category: Yup.string().required('Goal 2 category is required'),
			goal2Description: Yup.string()
				.min(50, 'Goal 2 description must be at least 50 characters long')
				.required('Goal 2 description is required'),
			goal3Category: Yup.string().required('Goal 3 category is required'),
			goal3Description: Yup.string()
				.min(50, 'Goal 3 description must be at least 50 characters long')
				.required('Goal 3 description is required'),
		}),
		onSubmit: (values) => {
			const updatedData = {
				longTermGoal1Category: values.goal1Category,
				longTermGoal2Category: values.goal2Category,
				longTermGoal3Category: values.goal3Category,
				longTermGoal1Description: values.goal1Description,
				longTermGoal2Description: values.goal2Description,
				longTermGoal3Description: values.goal3Description,
			};
			onSave(updatedData);
			onPopupNext();
		},
	});

	// Custom handleChange to add logging
	const handleChange = (e) => {
		formik.handleChange(e);
	};

	return (
		<Box p={3}>
			<DialogContent>
				<Box
					sx={{
						textAlign: 'center',
					}}
				>
					<Typography variant="h4" sx={{ mb: 3 }}>
						What are your long-term goals?
					</Typography>
				</Box>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={2} textAlign={'center'}>
						{/* Goal 1 */}
						<Grid item xs={12} md={4}>
							<Typography variant="h4" sx={{ mb: 2 }}>
								Goal 1
							</Typography>
							<TextField
								select
								fullWidth
								label="Select Goal Category"
								name="goal1Category"
								{...formik.getFieldProps('goal1Category')}
								onChange={handleChange}
								error={
									formik.touched.goal1Category &&
									Boolean(formik.errors.goal1Category)
								}
								helperText={
									formik.touched.goal1Category && formik.errors.goal1Category
								}
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{goalCategories.map((category) => (
									<MenuItem key={category} value={category}>
										{category}
									</MenuItem>
								))}
							</TextField>
							<TextField
								fullWidth
								label="Briefly Describe Your Goal"
								multiline
								minRows={5}
								name="goal1Description"
								{...formik.getFieldProps('goal1Description')}
								error={
									formik.touched.goal1Description &&
									Boolean(formik.errors.goal1Description)
								}
								helperText={
									formik.touched.goal1Description && formik.errors.goal1Description
								}
								sx={{ mt: 2 }}
							/>
						</Grid>

						{/* Goal 2 */}
						<Grid item xs={12} md={4}>
							<Typography variant="h4" sx={{ mb: 2 }}>
								Goal 2
							</Typography>
							<TextField
								select
								fullWidth
								label="Select Goal Category"
								name="goal2Category"
								{...formik.getFieldProps('goal2Category')}
								onChange={handleChange}
								error={
									formik.touched.goal2Category &&
									Boolean(formik.errors.goal2Category)
								}
								helperText={
									formik.touched.goal2Category && formik.errors.goal2Category
								}
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{goalCategories.map((category) => (
									<MenuItem key={category} value={category}>
										{category}
									</MenuItem>
								))}
							</TextField>
							<TextField
								fullWidth
								label="Briefly Describe Your Goal"
								multiline
								minRows={5}
								name="goal2Description"
								{...formik.getFieldProps('goal2Description')}
								error={
									formik.touched.goal2Description &&
									Boolean(formik.errors.goal2Description)
								}
								helperText={
									formik.touched.goal2Description && formik.errors.goal2Description
								}
								sx={{ mt: 2 }}
							/>
						</Grid>

						{/* Goal 3 */}
						<Grid item xs={12} md={4}>
							<Typography variant="h4" sx={{ mb: 2 }}>
								Goal 3
							</Typography>
							<TextField
								select
								fullWidth
								label="Select Goal Category"
								name="goal3Category"
								{...formik.getFieldProps('goal3Category')}
								onChange={handleChange}
								error={
									formik.touched.goal3Category &&
									Boolean(formik.errors.goal3Category)
								}
								helperText={
									formik.touched.goal3Category && formik.errors.goal3Category
								}
							>
								<MenuItem value="">
									<em>None</em>
								</MenuItem>
								{goalCategories.map((category) => (
									<MenuItem key={category} value={category}>
										{category}
									</MenuItem>
								))}
							</TextField>
							<TextField
								fullWidth
								label="Briefly Describe Your Goal"
								multiline
								minRows={5}
								name="goal3Description"
								{...formik.getFieldProps('goal3Description')}
								error={
									formik.touched.goal3Description &&
									Boolean(formik.errors.goal3Description)
								}
								helperText={
									formik.touched.goal3Description && formik.errors.goal3Description
								}
								sx={{ mt: 2 }}
							/>
						</Grid>
					</Grid>
					<Grid container sx={{ justifyContent: 'center', mt: 2 }}>
						<Grid item xs={12} sm={12}>
							<Box display="flex" justifyContent="center" alignItems="center">
								<DialogActions>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										sx={{ ...typography.button }}
									>
										Next
									</Button>
								</DialogActions>
							</Box>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Box>
	);
};

export default LongTermGoals;
