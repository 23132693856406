// src/context/ArtistContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import {
	fetchArtists,
	fetchArtistData,
} from '../utils/apis/globalData/artistDataUtils';
import SessionContext from './SessionContext';
import { createNewArtist } from '../utils/apis/onboardingData/artistOnboardingUtils';
import { debounce } from 'lodash';

const ArtistContext = createContext();

const SELECTED_ARTIST_KEY = 'selectedArtistId';

export const ArtistProvider = ({ children }) => {
	const { session } = useContext(SessionContext);
	const [artists, setArtists] = useState([]);
	const [selectedArtist, setSelectedArtist] = useState(null);
	const [artistData, setArtistData] = useState({
		artistId: '',
		stageName: '',
		bio: '',
		genre: '',
		location: '',
		yearsActive: '',
		profilePhoto: '',
	});
	const [showModal, setShowModal] = useState(false);
	const [loading, setLoading] = useState(true);

	const [totalFollowers, setTotalFollowers] = useState(0);
	const [totalMonthlyListeners, setTotalMonthlyListeners] = useState(0);

	const debouncedCreateNewArtist = debounce(async () => {
		const newArtist = await createNewArtist();
		setSelectedArtist(newArtist);
	}, 300);

	const loadArtists = async () => {
		setLoading(true);
		try {
			const artistList = await fetchArtists();

			if (artistList && artistList.length > 0) {
				const mappedArtists = artistList.map((artist) => ({
					id: artist.id,
					name: artist.stage_name,
					profilePhoto: artist.profile_photo,
					onboardingComplete: artist.onboarding_complete,
				}));

				setArtists(mappedArtists);

				// Retrieve stored artist ID from localStorage
				const storedArtistId = localStorage.getItem(SELECTED_ARTIST_KEY);

				let initialSelectedArtist = null;

				if (storedArtistId) {
					initialSelectedArtist = mappedArtists.find(
						(artist) => artist.id === storedArtistId
					);
				}

				// Select the first artist with onboardingComplete or fallback to the first artist
				if (!initialSelectedArtist) {
					initialSelectedArtist =
						mappedArtists.find((artist) => artist.onboardingComplete) ||
						mappedArtists[0];
				}

				if (initialSelectedArtist) {
					await fetchAndSetArtistData(initialSelectedArtist.id);
					setSelectedArtist(initialSelectedArtist);

					// Store the selected artist ID in localStorage
					localStorage.setItem(SELECTED_ARTIST_KEY, initialSelectedArtist.id);
				}
			} else if (!artistList || artistList.length === 0) {
				debouncedCreateNewArtist();
			}
		} catch (error) {
			console.error('Error fetching artists:', error);
		} finally {
			setLoading(false);
		}
	};

	const fetchAndSetArtistData = async (artistId) => {
		setLoading(true);
		try {
			const fetchedArtistData = await fetchArtistData(artistId);

			if (fetchedArtistData) {
				// Calculate the total social following
				const spotifyFollowers =
					parseInt(artistData.spotify?.selectedProfile?.followers, 10) || 1;
				const youtubeSubscribers =
					parseInt(artistData.youtube?.selectedProfile?.subscribers, 10) || 1;
				const instagramFollowers =
					parseInt(artistData.instagram?.selectedProfile?.followers, 10) || 1;
				const tiktokFollowers =
					parseInt(artistData.tiktok?.selectedProfile?.followers, 10) || 1;

				// Calculate the total followers and format with commas
				const totalFollowers =
					spotifyFollowers +
					youtubeSubscribers +
					instagramFollowers +
					tiktokFollowers;

				// Calculate the average monthly listeners
				const spotifyListeners =
					parseInt(artistData.spotify?.analyticsData?.listeners, 10) || 1;
				const appleMusicAvgDailyListeners =
					parseInt(
						artistData.appleMusic?.analyticsData?.averageDailyListeners,
						10
					) || 1;
				const youtubeUniqueViewers =
					parseInt(artistData.youtube?.analyticsData?.audience?.uniqueViewers, 10) ||
					1;

				// Convert Apple Music average daily listeners to monthly listeners (assuming 30 days in a month)
				const appleMusicMonthlyListeners = appleMusicAvgDailyListeners * 30;

				// Calculate the total monthly listeners and format with commas
				const totalListeners =
					spotifyListeners + appleMusicMonthlyListeners + youtubeUniqueViewers;

				setArtistData({
					artistId: fetchedArtistData.id,
					stageName: fetchedArtistData.stageName,
					bio: fetchedArtistData.bio,
					genre: Array.isArray(fetchedArtistData.genre)
						? fetchedArtistData.genre.join(', ')
						: fetchedArtistData.genre || '',
					location: fetchedArtistData.location,
					yearsActive: fetchedArtistData.yearsActive,
					profilePhoto: fetchedArtistData.profilePhoto,
					onboardingComplete: fetchedArtistData.onboardingComplete,
					totalListeners: totalListeners,
					totalFollowers: totalFollowers,
				});
			}
		} catch (error) {
			console.error('Error fetching artist data:', error);
		} finally {
			setLoading(false);
		}
	};

	const handleArtistSelect = async (artist) => {
		setSelectedArtist(artist);
		setShowModal(false);
		await fetchAndSetArtistData(artist.id);

		// Store the selected artist ID in localStorage
		localStorage.setItem(SELECTED_ARTIST_KEY, artist.id);
	};

	// Optionally, provide a method to clear the selected artist from storage
	const clearSelectedArtist = () => {
		localStorage.removeItem(SELECTED_ARTIST_KEY);
	};

	// Fetch artists when the provider mounts
	useEffect(() => {
		if (session.isLoggedIn) {
			loadArtists();
		}
	}, [session.isLoggedIn]);

	useEffect(() => {
		if (artistData) {
			// Calculate the total social following
			const spotifyFollowers =
				parseInt(artistData.spotify?.selectedProfile?.followers, 10) || 0;
			const youtubeSubscribers =
				parseInt(artistData.youtube?.selectedProfile?.subscribers, 10) || 0;
			const instagramFollowers =
				parseInt(artistData.instagram?.selectedProfile?.followers, 10) || 0;
			const tiktokFollowers =
				parseInt(artistData.tiktok?.selectedProfile?.followers, 10) || 0;

			// Calculate the total followers and format with commas
			const totalFollowers =
				spotifyFollowers + youtubeSubscribers + instagramFollowers + tiktokFollowers;
			setTotalFollowers(totalFollowers);

			// Calculate the average monthly listeners
			const spotifyListeners =
				parseInt(artistData.spotify?.analyticsData?.listeners, 10) || 0;
			const appleMusicAvgDailyListeners =
				parseInt(artistData.appleMusic?.analyticsData?.averageDailyListeners, 10) ||
				0;
			const youtubeUniqueViewers =
				parseInt(artistData.youtube?.analyticsData?.audience?.uniqueViewers, 10) ||
				0;

			// Convert Apple Music average daily listeners to monthly listeners (assuming 30 days in a month)
			const appleMusicMonthlyListeners = appleMusicAvgDailyListeners * 30;

			// Calculate the total monthly listeners and format with commas
			const totalListeners =
				spotifyListeners + appleMusicMonthlyListeners + youtubeUniqueViewers;
			setTotalMonthlyListeners(totalListeners);
		}
	}, [artistData]);

	return (
		<ArtistContext.Provider
			value={{
				artists,
				selectedArtist,
				artistData,
				showModal,
				setShowModal,
				loading,
				setLoading,
				handleArtistSelect,
				loadArtists,
				fetchAndSetArtistData,
				clearSelectedArtist,
			}}
		>
			{children}
		</ArtistContext.Provider>
	);
};

export default ArtistContext;
