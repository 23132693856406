// components/common/ErrorBoundary.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	static getDerivedStateFromError(error) {
		// Update state to trigger fallback UI
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		// Optionally log error details
		this.setState({ errorInfo });
		console.error('ErrorBoundary caught an error:', error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			const { fallback } = this.props;
			if (fallback) {
				return fallback(this.state.error, this.state.errorInfo);
			}
			// Default fallback UI
			return (
				<Box sx={{ p: 2, backgroundColor: 'error.light', borderRadius: 2 }}>
					<Typography variant="h6" color="error.main">
						Something went wrong.
					</Typography>
					<Typography variant="body2" color="error.dark">
						{this.state.error && this.state.error.toString()}
					</Typography>
					<Typography variant="body2" color="error.dark">
						{this.state.errorInfo && this.state.errorInfo.componentStack}
					</Typography>
				</Box>
			);
		}

		return this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.node.isRequired,
	fallback: PropTypes.func, // New prop for custom fallback
};

export default ErrorBoundary;
