// src/contexts/SessionContext.js
import React, { createContext, useState } from 'react';
import { getUserSession } from '../utils/apis/auth/sessionUtils';

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
	const { token, isFirstLogin, companyId } = getUserSession();
	const [session, setSession] = useState({
		isLoggedIn: !!token,
		isFirstLogin,
		companyId,
	});

	return (
		<SessionContext.Provider value={{ session, setSession }}>
			{children}
		</SessionContext.Provider>
	);
};

export default SessionContext;
