// MilestoneCard.jsx
import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	TextField,
	MenuItem,
	Box,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormikContext } from 'formik';
import { useTheme } from '@mui/material/styles';

const MilestoneCard = ({
	index,
	milestone,
	milestoneCategories,
	secondaryOptions,
}) => {
	const theme = useTheme();
	const formik = useFormikContext();

	const handleCategoryChange = (e) => {
		const { value } = e.target;
		formik.setFieldValue(`milestones[${index}].category`, value);
		formik.setFieldValue(`milestones[${index}].secondary`, '');
	};

	const handleSecondaryChange = (e) => {
		const { value } = e.target;
		formik.setFieldValue(`milestones[${index}].secondary`, value);
	};

	const handleDescriptionChange = (e) => {
		const { value } = e.target;
		formik.setFieldValue(`milestones[${index}].description`, value);
	};

	const handleDateChange = (newValue) => {
		formik.setFieldValue(`milestones[${index}].date`, newValue);
	};

	return (
		<Card
			sx={{
				border:
					formik.touched.milestones?.[index]?.category &&
					formik.errors.milestones?.[index]?.category
						? '1px solid #f44336'
						: '1px solid #e0e0e0',
				borderRadius: '8px',
				cursor: 'pointer',
				boxShadow: formik.values.milestones[index].category
					? '0 4px 12px rgba(0, 128, 0, 0.1)'
					: 'none',
				'&:hover': {
					boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
				},
				transition: 'none',
				':hover': {
					transform: 'none',
				},
				p: 1,
			}}
		>
			<CardContent>
				<Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
					Milestone {index + 1}
				</Typography>

				{/* Milestone Category */}
				<TextField
					select
					fullWidth
					label="Select Milestone Category"
					name={`milestones[${index}].category`}
					value={milestone.category}
					onChange={handleCategoryChange}
					onBlur={formik.handleBlur}
					error={
						formik.touched.milestones?.[index]?.category &&
						Boolean(formik.errors.milestones?.[index]?.category)
					}
					helperText={
						formik.touched.milestones?.[index]?.category &&
						formik.errors.milestones?.[index]?.category
					}
				>
					{milestoneCategories.map((category, idx) => (
						<MenuItem key={idx} value={category}>
							{category}
						</MenuItem>
					))}
				</TextField>

				{/* Secondary Milestone Option */}
				<TextField
					select
					fullWidth
					label="Select Secondary Milestone Option"
					name={`milestones[${index}].secondary`}
					value={milestone.secondary}
					onChange={handleSecondaryChange}
					onBlur={formik.handleBlur}
					error={
						formik.touched.milestones?.[index]?.secondary &&
						Boolean(formik.errors.milestones?.[index]?.secondary)
					}
					helperText={
						formik.touched.milestones?.[index]?.secondary &&
						formik.errors.milestones?.[index]?.secondary
					}
					disabled={!milestone.category}
					sx={{ mt: 2 }}
				>
					{(secondaryOptions[milestone.category] || []).map((option, idx) => (
						<MenuItem key={idx} value={option}>
							{option}
						</MenuItem>
					))}
				</TextField>

				{/* Description */}
				<TextField
					fullWidth
					label="Add Detail to Your Milestone"
					multiline
					minRows={3}
					name={`milestones[${index}].description`}
					value={milestone.description}
					onChange={handleDescriptionChange}
					onBlur={formik.handleBlur}
					error={
						formik.touched.milestones?.[index]?.description &&
						Boolean(formik.errors.milestones?.[index]?.description)
					}
					helperText={
						formik.touched.milestones?.[index]?.description &&
						formik.errors.milestones?.[index]?.description
					}
					sx={{ mt: 2 }}
				/>

				{/* Measurement Value */}
				{/*}
				<TextField
					fullWidth
					label="Specify Value (Numerical)"
					type="number"
					name={`milestones[${index}].value`}
					value={milestone.value}
					onChange={handleValueChange}
					onBlur={formik.handleBlur}
					error={
						formik.touched.milestones?.[index]?.value &&
						Boolean(formik.errors.milestones?.[index]?.value)
					}
					helperText={
						formik.touched.milestones?.[index]?.value &&
						formik.errors.milestones?.[index]?.value
					}
					sx={{ mt: 2 }}
				/>
				*/}

				{/* Target Date */}
				<Box sx={{ mt: 2 }}>
					<DesktopDatePicker
						label="Target Date"
						inputFormat="MM/DD/YYYY"
						value={milestone.date}
						onChange={handleDateChange}
						slotProps={{
							textField: {
								fullWidth: true,
								name: `milestones[${index}].date`,
								onBlur: formik.handleBlur,
								error:
									formik.touched.milestones?.[index]?.date &&
									Boolean(formik.errors.milestones?.[index]?.date),
								helperText:
									formik.touched.milestones?.[index]?.date &&
									formik.errors.milestones?.[index]?.date,
							},
						}}
					/>
				</Box>
			</CardContent>
		</Card>
	);
};

export default MilestoneCard;
