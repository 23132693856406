export const createNewArtist = async () => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');
		if (!accessToken) {
			console.error('Failed to get access token');
			return;
		}

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/onboarding/artist/create`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);

		if (!response.ok) {
			console.error(`Failed to create artist: ${response.statusText}`);
			return null;
		}

		return await response.json();
	} catch (error) {
		console.error('Error creating new artist:', error);
		throw new Error('Failed to create new artist');
	}
};

export const deleteArtist = async (artistId) => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return;
		}

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/onboarding/artist/${artistId}`,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.json();
	} catch (error) {
		console.error('Error deleting artist:', error);
	}
};

export const saveArtistOnboardingData = async (artistData, artistId) => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return;
		}

		// Append artistId as a query parameter if it exists
		const url =
			`${process.env.REACT_APP_API_URL}/api/onboarding/artist/save` +
			(artistId ? `?artistId=${artistId}` : '');

		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(artistData),
		});

		if (!response.ok) {
			throw new Error('Failed to save onboarding data');
		}
		return true;
	} catch (error) {
		console.error('Error saving onboarding data:', error);
		return false;
	}
};

export const fetchArtistOnboardingData = async (artistId) => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return null;
		}

		// Append artistId as a query parameter if it exists
		const url =
			`${process.env.REACT_APP_API_URL}/api/onboarding/artist/get` +
			(artistId ? `?artistId=${artistId}` : '');

		const response = await fetch(url, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});

		if (response.ok) {
			const data = await response.json();
			const fetchedData = {
				artist: data.artist || {},
				careerIdols: data.careerIdols || {},
				careerObjectives: data.careerObjectives || {},
				careerPath: data.careerPath || {},
				careerStage: data.careerStage || {},
				immediateNeeds: data.immediateNeeds || {},
				recentActivities: data.recentActivities || {},
				spotify: data.spotify || {},
				appleMusic: data.appleMusic || {},
				instagram: data.instagram || {},
				tiktok: data.tiktok || {},
				youtube: data.youtube || {},
			};
			return fetchedData;
		} else {
			return null;
		}
	} catch (error) {
		console.error('Error fetching onboarding data:', error);
		return null;
	}
};

export const completeOnboarding = async () => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return false;
		}

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/onboarding/complete-onboarding`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);

		if (!response.ok) {
			throw new Error('Failed to complete onboarding');
		}
		return true;
	} catch (error) {
		console.error('Error completing onboarding:', error);
		return false;
	}
};

export const completeArtistOnboarding = async (artistId) => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return false;
		}

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/onboarding/artist/complete/${artistId}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);

		if (!response.ok) {
			throw new Error('Failed to complete artist onboarding');
		}
		return true;
	} catch (error) {
		console.error('Error completing artist onboarding:', error);
		return false;
	}
};
