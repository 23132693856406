// src/components/marketingAssistant/QuestionComponent.js

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import InputQuestionComponent from './InputQuestion';
import SelectionQuestionComponent from './SelectionQuestion';

const QuestionComponent = ({ questionData, handleNext, handleBack, formData }) => {
	if (!questionData) return null;

	const commonProps = {
		question: questionData.question,
		keyName: questionData.key,
		dataType: questionData.dataType,
		handleNext,
		handleBack,
		formData,
		additionalDetailsAllowed: questionData.additionalDetailsAllowed,
		additionalDetailsRequired: questionData.additionalDetailsRequired,
	};

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				mt: 2,
			}}
		>
			{questionData.type === 'selection' ? (
				<SelectionQuestionComponent
					{...commonProps}
					options={questionData.options}
					allowMultiple={questionData.allowMultiple}
					customInputAllowed={questionData.customInputAllowed}
				/>
			) : questionData.type === 'input' ? (
				<InputQuestionComponent
					{...commonProps}
					inputType={questionData.inputType}
					allowMultiple={questionData.allowMultiple}
				/>
			) : null}
		</Box>
	);
};

QuestionComponent.propTypes = {
	questionData: PropTypes.shape({
		type: PropTypes.oneOf(['input', 'selection']).isRequired,
		question: PropTypes.string.isRequired,
		options: PropTypes.array,
		inputType: PropTypes.string,
		key: PropTypes.string.isRequired,
		dataType: PropTypes.string,
		allowMultiple: PropTypes.bool,
		customInputAllowed: PropTypes.bool,
		additionalDetailsAllowed: PropTypes.bool,
		additionalDetailsRequired: PropTypes.bool,
	}).isRequired,
	handleNext: PropTypes.func.isRequired,
	handleBack: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
};

export default QuestionComponent;
