// components/modules/chatInterface/subcomponents/messageTypes/DefaultMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';

/**
 * DefaultMessage Component
 *
 * Renders a fallback message for unrecognized message types.
 *
 * Props:
 * - text: The text content of the message.
 */
const DefaultMessage = ({ text }) => {
	if (!text) {
		return (
			<Typography variant="body2" color="error">
				Received an empty message.
			</Typography>
		);
	}
	return (
		<Box
			sx={{
				backgroundColor: 'grey.300',
				color: 'text.primary',
				borderRadius: 2,
				padding: 1.5,
				maxWidth: '70%',
				wordBreak: 'break-word',
			}}
		>
			<Typography variant="body1" color="text.secondary">
				{text}
			</Typography>
		</Box>
	);
};

DefaultMessage.propTypes = {
	text: PropTypes.string.isRequired,
};

export default React.memo(DefaultMessage);
