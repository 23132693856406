// src/utils/promptUtils.js
import { generateChatGPTPrompt } from './processing/promptGenerator';
import { joinConversation, sendMessage, createConversation } from './socket';
import { getUserSession } from './apis/auth/sessionUtils';

// Consolidate functions as they perform similar tasks
export const initiateConversation = async (formData, artistData, companyData) => {
	try {
		const projectName = formData.projectName;

		// Generate the prompt with all necessary data
		const prompt = generateChatGPTPrompt(
			formData,
			artistData,
			companyData,
			projectName
		);
		// Get the access token
		const { token: accessToken } = getUserSession();
		if (!accessToken) {
			throw new Error('Access token not found');
		}
		// Send the message to ChatGPT via the utility function
		const conversationId = await createConversation(
			formData.marketing_goal,
			formData,
			projectName,
			artistData?.artistId,
			artistData
		);

		// Now that we have conversationId, join the conversation
		joinConversation(conversationId);

		// Send the initial message via Socket.io
		sendMessage(conversationId, artistData?.artistId, prompt);

		return { conversation_id: conversationId };
	} catch (error) {
		console.error('Error initiating conversation with ChatGPT:', error.message);
		return null;
	}
};

export const sendChatMessage = async (conversation_id, message) => {
	try {
		sendMessage(conversation_id, null, message);
		return { success: true };
	} catch (error) {
		console.error('Error sending chat message to ChatGPT:', error.message);
		return null;
	}
};
