import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CareerPathCard = ({ path, selected, onSelect }) => {
	const theme = useTheme();
	return (
		<Card
			onClick={onSelect}
			sx={{
				border: selected
					? `2px solid ${theme.palette.primary.main}`
					: `2px solid ${theme.palette.divider}`,
				cursor: 'pointer',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				textAlign: 'center',
			}}
		>
			<CardContent>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						mb: theme.spacing(2),
						minHeight: '60px',
					}}
				>
					<Typography variant="h3" sx={{ fontSize: '2rem' }}>
						{path.icon}
					</Typography>
					<Typography
						variant="h5"
						sx={{
							ml: theme.spacing(1),
							fontWeight: theme.typography.h5.fontWeight,
							color: theme.palette.text.primary,
						}}
					>
						{path.name}
					</Typography>
				</Box>
				<Box sx={{ mb: theme.spacing(2) }}>
					<Typography
						variant="body1"
						sx={{
							fontWeight: 'bold',
							mb: theme.spacing(1),
							color: theme.palette.text.primary,
						}}
					>
						Career Path Overview:
					</Typography>
					<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
						{path.overview}
					</Typography>
				</Box>
				<Box sx={{ mb: theme.spacing(2) }}>
					<Typography
						variant="body1"
						sx={{
							fontWeight: 'bold',
							mb: theme.spacing(1),
							color: theme.palette.text.primary,
						}}
					>
						Potential Day-to-Day:
					</Typography>
					<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
						{path.dayToDay}
					</Typography>
				</Box>
				<Box sx={{ mb: theme.spacing(2) }}>
					<Typography
						variant="body1"
						sx={{
							fontWeight: 'bold',
							mb: theme.spacing(1),
							color: theme.palette.text.primary,
						}}
					>
						Types of Revenue Streams:
					</Typography>
					<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
						{path.revenueStreams}
					</Typography>
				</Box>
				<Box sx={{ mb: theme.spacing(2) }}>
					<Typography
						variant="body1"
						sx={{
							fontWeight: 'bold',
							mb: theme.spacing(1),
							color: theme.palette.text.primary,
						}}
					>
						Skills Required:
					</Typography>
					<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
						{path.skills}
					</Typography>
				</Box>
				<Box>
					<Typography
						variant="body1"
						sx={{
							fontWeight: 'bold',
							mb: theme.spacing(1),
							color: theme.palette.text.primary,
						}}
					>
						Typical Career Length:
					</Typography>
					<Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
						{path.careerLength}
					</Typography>
				</Box>
			</CardContent>
		</Card>
	);
};

export default CareerPathCard;
