// src/components/homePage/ArtistPerformanceCharts.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, Box } from '@mui/material';
import AgeDistributionChart from '../charts/AgeDistributionChart';
import GenderBreakdownChart from '../charts/GenderBreakdownChart';
import GeographicalLocationsChart from '../charts/GeographicalLocationsChart';
import GrowthTrendsChart from '../charts/GrowthTrendsChart';

const ArtistPerformanceCharts = ({ performanceData, geographicalData }) => {
	// State to track button rows from child charts
	const [ageButtonRows, setAgeButtonRows] = useState(0);
	const [genderButtonRows, setGenderButtonRows] = useState(0);

	// Callback functions to update button rows
	const handleAgePerPlatformToggle = (rows) => {
		setAgeButtonRows(rows);
	};

	const handleGenderPerPlatformToggle = (rows) => {
		setGenderButtonRows(rows);
	};

	// Calculate maximum extra button rows from child charts
	const maxExtraButtonRows = Math.max(ageButtonRows, genderButtonRows);

	const charts = [
		{
			component: (
				<AgeDistributionChart
					data={performanceData.ageDistribution}
					onPerPlatformToggle={handleAgePerPlatformToggle}
				/>
			),
			gridProps: { sm: 12, lg: 4 },
		},
		{
			component: (
				<GenderBreakdownChart
					data={performanceData.genderBreakdown}
					onPerPlatformToggle={handleGenderPerPlatformToggle}
				/>
			),
			gridProps: { sm: 12, lg: 4 },
		},
		{
			component: (
				<GrowthTrendsChart
					data={performanceData.growthTrends}
					totalExtraButtonRows={maxExtraButtonRows}
				/>
			),
			gridProps: { sm: 12, lg: 4 },
		},
		{
			component: (
				<GeographicalLocationsChart data={geographicalData.geographicalLocations} />
			),
			gridProps: { xs: 12 },
		},

		// You can include existing charts or add more as needed
	];

	return (
		<Box>
			<Typography variant="h4" sx={{ textAlign: 'left', mb: 4 }}>
				Artist Performance
			</Typography>
			<Grid container spacing={4}>
				{charts.map((chart, index) => (
					<Grid
						item
						key={index}
						{...chart.gridProps}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'stretch', // Ensures all grid items stretch to the same height
						}}
					>
						<Box
							sx={{
								width: '100%',
								height: '100%', // Allow the chart box to fill available space
							}}
						>
							{chart.component}
						</Box>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

ArtistPerformanceCharts.propTypes = {
	performanceData: PropTypes.shape({
		ageDistribution: PropTypes.shape({
			overall: PropTypes.arrayOf(
				PropTypes.shape({
					ageGroup: PropTypes.string.isRequired,
					percentage: PropTypes.number.isRequired,
				})
			).isRequired,
			perPlatform: PropTypes.objectOf(
				PropTypes.arrayOf(
					PropTypes.shape({
						ageGroup: PropTypes.string.isRequired,
						percentage: PropTypes.number.isRequired,
					})
				)
			).isRequired,
		}).isRequired,
		genderBreakdown: PropTypes.shape({
			overall: PropTypes.arrayOf(
				PropTypes.shape({
					gender: PropTypes.string.isRequired,
					percentage: PropTypes.number.isRequired,
				})
			).isRequired,
			perPlatform: PropTypes.objectOf(
				PropTypes.arrayOf(
					PropTypes.shape({
						gender: PropTypes.string.isRequired,
						percentage: PropTypes.number.isRequired,
					})
				)
			).isRequired,
		}).isRequired,
		geographicalLocations: PropTypes.shape({
			topCountries: PropTypes.arrayOf(
				PropTypes.shape({
					country: PropTypes.string.isRequired,
					listeners: PropTypes.number.isRequired,
				})
			).isRequired,
			topCities: PropTypes.arrayOf(
				PropTypes.shape({
					city: PropTypes.string.isRequired,
					listeners: PropTypes.number.isRequired,
				})
			).isRequired,
		}).isRequired,
		growthTrends: PropTypes.shape({
			followersOverTime: PropTypes.arrayOf(
				PropTypes.shape({
					month: PropTypes.string.isRequired,
					followers: PropTypes.number.isRequired,
				})
			).isRequired,
			listenersOverTime: PropTypes.arrayOf(
				PropTypes.shape({
					month: PropTypes.string.isRequired,
					listeners: PropTypes.number.isRequired,
				})
			).isRequired,
		}).isRequired,
		// Include other data types if added
	}).isRequired,
	geographicalData: PropTypes.shape({
		geographicalLocations: PropTypes.object.isRequired, // Define appropriately
	}).isRequired,
};

export default ArtistPerformanceCharts;
