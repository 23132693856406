// src/components/charts/ChartWrapper.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, useTheme } from '@mui/material';

const ChartWrapper = ({ title, children }) => {
	const theme = useTheme();

	return (
		<Card
			sx={{
				height: '100%',
				boxShadow: theme.shadows[2],
				padding: theme.spacing(2),
				':hover': {
					transform: 'none',
				},
			}}
		>
			<CardContent>
				<Typography variant="h4" align="center" sx={{ mb: 2 }} gutterBottom>
					{title}
				</Typography>
				{children}
			</CardContent>
		</Card>
	);
};

ChartWrapper.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
};

export default ChartWrapper;
