// AddNewArtistCard.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardActionArea, Box, Typography, useTheme } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { styled } from '@mui/system';

// Styled Components
const StyledCard = styled(Card)(({ theme }) => ({
	border: `2px dashed ${theme.palette.grey[400]}`,
	borderRadius: theme.shape.borderRadius,
	maxWidth: 200,
	margin: '0 auto',
	transition: 'border-color 0.3s, background-color 0.3s',
	'&:hover': {
		borderColor: theme.palette.primary.main,
		backgroundColor: theme.palette.action.hover,
	},
}));

const AddNewArtistCard = ({ onAdd }) => {
	const theme = useTheme();

	return (
		<StyledCard>
			<CardActionArea onClick={onAdd} aria-label="Add New Artist">
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						padding: theme.spacing(3),
					}}
				>
					<PersonAddIcon sx={{ fontSize: 40, color: 'primary.main' }} />
					<Typography
						variant="subtitle1"
						sx={{
							mt: 1,
							color: 'text.primary',
							fontWeight: 'medium',
						}}
					>
						Add New Artist
					</Typography>
				</Box>
			</CardActionArea>
		</StyledCard>
	);
};

AddNewArtistCard.propTypes = {
	onAdd: PropTypes.func.isRequired,
};

export default AddNewArtistCard;
