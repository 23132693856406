import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { Button, Typography, Box } from '@mui/material';
import InterfaceStepper from '../common/InterfaceStepper';
import ArtistOnboardingStep1 from './mainPages/ArtistOnboardingStep1';
import ArtistOnboardingStep2 from './mainPages/ArtistOnboardingStep2';
import ArtistOnboardingStep3 from './mainPages/ArtistOnboardingStep3';
import ArtistOnboardingStep4 from './mainPages/ArtistOnboardingStep4';
import ArtistOnboardingStep5 from './mainPages/ArtistOnboardingStep5';
import {
	fetchArtistOnboardingData,
	saveArtistOnboardingData,
	completeOnboarding,
	completeArtistOnboarding,
} from '../../utils/apis/onboardingData/artistOnboardingUtils';
import LoadingSpinner from '../common/LoadingSpinner';
import { useTheme } from '@mui/material/styles';

const initialArtistData = {
	artist: {
		stageName: '',
		genre: [],
		yearsActive: '',
		bio: '',
		location: '',
	},
	careerIdols: {
		idol1: {},
		idol2: {},
		idol3: {},
	},
	careerObjectives: {
		longTerm: {},
		shortTerm: {},
		milestones: {},
	},
	careerPath: {},
	careerStage: {},
	immediateNeeds: {},
	recentActivities: {},
	spotify: {},
	appleMusic: {},
	instagram: {},
	tiktok: {},
	youtube: {},
};

const steps = [
	'Artist Profile',
	'Career Detail',
	'Data Integration',
	'Current Status',
	'Data Review',
];

const ArtistOnboarding = ({ selectedArtist, isAddingNewArtist, onComplete }) => {
	const theme = useTheme();
	const [activeStep, setActiveStep] = useState(0);
	const [artistData, setArtistData] = useState(initialArtistData);
	const [isLoading, setLoading] = useState(false);
	const initialDataRef = useRef(initialArtistData);

	useEffect(() => {
		const initializeData = async () => {
			setLoading(true);
			try {
				if (isAddingNewArtist) {
					// If adding a new artist, initialize with initialArtistData
					setArtistData(initialArtistData);
					initialDataRef.current = _.cloneDeep(initialArtistData);
				} else if (selectedArtist) {
					// If updating an existing artist, fetch their data
					const artist = await fetchArtistOnboardingData(selectedArtist.id);
					if (artist) {
						setArtistData(artist);
						initialDataRef.current = _.cloneDeep(artist);
					} else {
						console.error('No artist data found.');
					}
				}
			} catch (error) {
				console.error('Error initializing onboarding data:', error);
			} finally {
				setLoading(false);
			}
		};
		initializeData();
	}, [isAddingNewArtist, selectedArtist]);

	const updateArtistData = (newData) => {
		setArtistData((prevData) => _.merge({}, prevData, newData));
	};

	const handleSave = async () => {
		const artistChanged = !_.isEqual(initialDataRef.current, artistData);
		if (artistChanged) {
			try {
				const success = await saveArtistOnboardingData(
					artistData,
					selectedArtist.id
				);
				if (success) {
					initialDataRef.current = _.cloneDeep(artistData);
				}
			} catch (error) {
				console.error('Error saving onboarding data:', error);
			}
		}
	};

	const handleNext = async () => {
		setActiveStep((prevStep) => prevStep + 1);
	};

	const handleBack = async () => {
		setActiveStep((prevStep) => prevStep - 1);
	};

	const handleFinish = async () => {
		setLoading(true);
		await handleSave();
		const onboardingSuccess = await completeOnboarding();
		const artistSuccess = await completeArtistOnboarding(selectedArtist.id);
		if (onboardingSuccess && artistSuccess) {
			onComplete();
			setLoading(false);
		} else {
			setLoading(false);
			console.error('Failed to complete onboarding.');
		}
	};

	useEffect(() => {
		handleSave();
	}, [artistData]);

	const getStepContent = (step) => {
		switch (step) {
			case 0:
				return (
					<ArtistOnboardingStep1
						artistData={artistData}
						updateArtistData={updateArtistData}
						onNext={handleNext}
					/>
				);
			case 1:
				return (
					<ArtistOnboardingStep2
						artistData={artistData}
						updateArtistData={updateArtistData}
						onNext={handleNext}
						onBack={handleBack}
					/>
				);
			case 2:
				return (
					<ArtistOnboardingStep3
						artistData={artistData}
						updateArtistData={updateArtistData}
						onNext={handleNext}
						onBack={handleBack}
					/>
				);
			case 3:
				return (
					<ArtistOnboardingStep4
						artistData={artistData}
						updateArtistData={updateArtistData}
						onNext={handleNext}
						onBack={handleBack}
					/>
				);
			case 4:
				return (
					<ArtistOnboardingStep5
						artistData={artistData}
						updateArtistData={updateArtistData}
						onFinish={handleFinish}
						onBack={handleBack}
					/>
				);
			default:
				return 'Unknown step';
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: '#f5f5f5',
				padding: '2rem',
				borderRadius: '8px',
				boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				maxWidth: '900px',
				margin: '0 auto',
			}}
		>
			<LoadingSpinner isLoading={isLoading} />
			<Typography
				variant="h2"
				sx={{
					...theme.typography.h4,
					ml: 2,
					marginBottom: '1.5rem',
					textAlign: 'center',
				}}
			>
				{selectedArtist?.name === null ||
				selectedArtist?.name === undefined ||
				isAddingNewArtist
					? `Let's Onboard One of Your Artists`
					: `Let's Continue ${selectedArtist?.name}'s Onboarding`}
			</Typography>
			<InterfaceStepper steps={steps} activeStep={activeStep} />

			<Box sx={{ mt: 3 }}>
				{activeStep === steps.length ? (
					<Box sx={{ textAlign: 'center' }}>
						<Button
							onClick={handleFinish}
							variant="contained"
							color="primary"
							sx={{ mt: 2 }}
						>
							Finish
						</Button>
					</Box>
				) : (
					<Box sx={{ mt: 2 }}>{getStepContent(activeStep)}</Box>
				)}
			</Box>
		</Box>
	);
};

export default ArtistOnboarding;
