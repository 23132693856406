import React, { useState, useEffect } from 'react';
import { Box, Button, Dialog, Typography, Grid, Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import HistoryIcon from '@mui/icons-material/History';
import BuildIcon from '@mui/icons-material/Build';
import CareerStage from '../dialogs/step4/CareerStage';
import ImmediateNeeds from '../dialogs/step4/ImmediateNeeds';
import RecentActivities from '../dialogs/step4/RecentActivities';
import { useTheme } from '@mui/material/styles';

const ArtistOnboardingStep4 = ({ artistData, updateArtistData, onNext, onBack }) => {
	const theme = useTheme();
	const sections = [
		{ key: 'careerStage', label: 'Career Stage', icon: <EmojiEventsIcon /> },
		{ key: 'recentActivities', label: 'Recent Activities', icon: <HistoryIcon /> },
		{ key: 'immediateNeeds', label: 'Immediate Needs', icon: <BuildIcon /> },
	];

	// 2. Helper function to check if a section is complete
	const isSectionComplete = (sectionKey, data) => {
		const sectionData = data?.[sectionKey];
		if (!sectionData) return false;

		switch (sectionKey) {
			case 'careerStage':
				return (
					sectionData.stageId &&
					sectionData.label &&
					sectionData.overview &&
					sectionData.metrics
				);
			case 'recentActivities':
				return (
					sectionData.activity1Category &&
					sectionData.activity1Secondary &&
					sectionData.activity1Description &&
					sectionData.activity2Category &&
					sectionData.activity2Secondary &&
					sectionData.activity2Description &&
					sectionData.activity3Category &&
					sectionData.activity3Secondary &&
					sectionData.activity3Description &&
					sectionData.activity4Category &&
					sectionData.activity4Secondary &&
					sectionData.activity4Description
				);
			case 'immediateNeeds':
				return (
					sectionData.selectedNeeds &&
					sectionData.selectedNeeds.length >= 3 &&
					sectionData.selectedNeeds.every(
						(need) => need.title && need.description && need.priority
					)
				);
			default:
				return false;
		}
	};

	// 3. Initialize state based on sections
	const initialProfiles = sections.reduce((acc, section) => {
		acc[section.key] =
			artistData?.[section.key] ||
			(section.key === 'recentActivities'
				? {
						activity1Category: '',
						activity1Secondary: '',
						activity1Description: '',
						activity2Category: '',
						activity2Secondary: '',
						activity2Description: '',
						activity3Category: '',
						activity3Secondary: '',
						activity3Description: '',
						activity4Category: '',
						activity4Secondary: '',
						activity4Description: '',
				  }
				: {});
		return acc;
	}, {});

	const initialCompletionStatuses = sections.reduce((acc, section) => {
		acc[section.key] = isSectionComplete(section.key, artistData);
		return acc;
	}, {});

	const initialOpenDialogs = sections.reduce((acc, section) => {
		acc[section.key] = false;
		return acc;
	}, {});

	const [profiles, setProfiles] = useState(initialProfiles);
	const [completionStatuses, setCompletionStatuses] = useState(
		initialCompletionStatuses
	);
	const [openDialogs, setOpenDialogs] = useState(initialOpenDialogs);

	// 4. useEffect to synchronize local state with artistData
	useEffect(() => {
		if (artistData) {
			// Update profiles
			const updatedProfiles = sections.reduce((acc, section) => {
				acc[section.key] =
					section.key === 'recentActivities'
						? artistData?.[section.key] || {
								activity1Category: '',
								activity1Secondary: '',
								activity1Description: '',
								activity2Category: '',
								activity2Secondary: '',
								activity2Description: '',
								activity3Category: '',
								activity3Secondary: '',
								activity3Description: '',
								activity4Category: '',
								activity4Secondary: '',
								activity4Description: '',
						  }
						: artistData?.[section.key] || {};
				return acc;
			}, {});
			setProfiles(updatedProfiles);

			// Update completion statuses
			const updatedCompletionStatuses = sections.reduce((acc, section) => {
				acc[section.key] = isSectionComplete(section.key, artistData);
				return acc;
			}, {});
			setCompletionStatuses(updatedCompletionStatuses);
		}
	}, [artistData]);

	// 5. Handler functions for dialog operations
	const handleOpenDialog = (sectionKey) => {
		setOpenDialogs((prev) => ({ ...prev, [sectionKey]: true }));
	};

	const handleCloseDialog = (sectionKey) => {
		setOpenDialogs((prev) => ({ ...prev, [sectionKey]: false }));
	};

	const handleSave = (sectionKey, updatedData) => {
		setProfiles((prev) => ({ ...prev, [sectionKey]: updatedData }));
		const updatedArtistData = {
			...artistData,
			[sectionKey]: updatedData,
		};
		updateArtistData(updatedArtistData);

		const isComplete = isSectionComplete(sectionKey, updatedArtistData);
		setCompletionStatuses((prev) => ({
			...prev,
			[sectionKey]: isComplete,
		}));

		handleCloseDialog(sectionKey);
	};

	const handleCancel = (sectionKey) => {
		handleCloseDialog(sectionKey);
	};

	const StatusBadge = ({ label, isComplete }) => (
		<Chip
			icon={
				isComplete ? (
					<CheckCircleIcon sx={{ color: 'green' }} />
				) : (
					<RadioButtonUncheckedIcon />
				)
			}
			label={label}
			variant="outlined"
			color={isComplete ? 'success' : 'default'}
			sx={{ width: '100%', textAlign: 'center' }}
		/>
	);

	// Helper functions for descriptions and button labels
	const getSectionDescription = (sectionKey) => {
		switch (sectionKey) {
			case 'careerStage':
				return "Let's get to know where your artist is in their career.";
			case 'recentActivities':
				return "Let's walk through what you've done in the last year.";
			case 'immediateNeeds':
				return "Let's determine what you need assistance with right now.";
			default:
				return '';
		}
	};

	const getButtonLabel = (sectionKey) => {
		switch (sectionKey) {
			case 'careerStage':
				return 'Select Stage';
			case 'recentActivities':
				return 'Provide Info';
			case 'immediateNeeds':
				return 'Provide Info';
			default:
				return 'Open';
		}
	};

	const isStepComplete = () => {
		return sections.every((section) => completionStatuses[section.key]);
	};

	const renderDialog = (section) => {
		switch (section.key) {
			case 'careerStage':
				return (
					<CareerStage
						artistData={profiles[section.key]}
						onSave={(updatedData) => handleSave(section.key, updatedData)}
						onCancel={() => handleCancel(section.key)}
					/>
				);
			case 'recentActivities':
				return (
					<RecentActivities
						artistData={profiles[section.key]}
						onSave={(updatedData) => handleSave(section.key, updatedData)}
						onCancel={() => handleCancel(section.key)}
					/>
				);
			case 'immediateNeeds':
				return (
					<ImmediateNeeds
						artistData={profiles[section.key]}
						onSave={(updatedData) => handleSave(section.key, updatedData)}
						onCancel={() => handleCancel(section.key)}
					/>
				);
			default:
				return null;
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: '#ffffff',
				padding: '2rem',
				borderRadius: '8px',
				boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				maxWidth: '800px',
				margin: '0 auto',
			}}
		>
			{/* Header Section */}
			<Box sx={{ textAlign: 'center', mb: 2 }}>
				<Typography
					variant="h3"
					sx={{
						textAlign: 'center',
					}}
				>
					Current Status Assessment
				</Typography>
			</Box>

			{/* Action Buttons Section */}
			<Box sx={{ mt: 3 }}>
				<Grid container spacing={3} sx={{ justifyContent: 'center' }}>
					{sections.map((section) => (
						<Grid item xs={12} sm={12} md={4} key={section.key}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									textAlign: 'center',
								}}
							>
								<Typography variant="h6" sx={{ ...theme.typography.h4, mb: 1 }}>
									{section.label}
								</Typography>
								<Typography
									variant="body1"
									sx={{ ...theme.typography.body2, mb: 1 }}
								>
									{getSectionDescription(section.key)}
								</Typography>
								<Button
									variant="contained"
									onClick={() => handleOpenDialog(section.key)}
									sx={{
										maxWidth: '100%',
										minWidth: '220px',
										justifyItems: 'center',
									}}
									startIcon={section.icon}
								>
									{getButtonLabel(section.key)}
								</Button>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>

			{/* Progress Section */}
			<Box sx={{ mt: 5 }}>
				<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
					Step Completion Status
				</Typography>
				<Grid container spacing={2} sx={{ justifyContent: 'center' }}>
					{sections.map((section) => (
						<Grid item xs={12} sm={6} md={6} key={section.key}>
							<StatusBadge
								label={section.label}
								isComplete={completionStatuses[section.key]}
							/>
						</Grid>
					))}
				</Grid>
			</Box>

			{/* Navigation Buttons */}
			<Box sx={{ mt: 4 }} textAlign="center">
				<Button
					variant="contained"
					color="secondary"
					onClick={() => onBack(artistData)}
					sx={{ mr: 2 }}
				>
					Back
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => onNext(artistData)}
					// disabled={!isStepComplete()}
				>
					Next
				</Button>
			</Box>

			{/* Dialogs for Each Section */}
			{sections.map((section) => (
				<Dialog
					key={section.key}
					open={openDialogs[section.key]}
					onClose={() => handleCloseDialog(section.key)}
					maxWidth="md"
					fullWidth
				>
					{renderDialog(section)}
				</Dialog>
			))}
		</Box>
	);
};

export default ArtistOnboardingStep4;
