import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditCampaignModal from './EditCampaignModal';
import {
	updateCampaign,
	deleteCampaign,
} from '../../../../utils/apis/marketingAssistant/campaignUtils';
import {
	Card,
	CardContent,
	Typography,
	Box,
	Grid,
	IconButton,
	Tooltip,
	Button,
	Checkbox,
} from '@mui/material';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { campaignTypes } from '../../../../utils/configs/campaignsConfig';

const CampaignItem = ({
	campaign,
	onSelect,
	onUpdate,
	selectable,
	onSelectForBulk,
}) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const getCampaignIcon = (goalName) => {
		const campaignType = campaignTypes.find((ct) => ct.goalName === goalName);
		if (campaignType && campaignType.icon) {
			const IconComponent = campaignType.icon;
			return <IconComponent fontSize="medium" color="primary" />;
		}
		return <FiberManualRecordIcon fontSize="medium" color="primary" />;
	};

	const getStatusColor = (status) => {
		switch (status.toLowerCase()) {
			case 'active':
				return 'success';
			case 'paused':
				return 'warning';
			case 'completed':
				return 'default';
			default:
				return 'default';
		}
	};

	const handleSelect = () => {
		onSelect(campaign.id);
	};

	const openEditModal = (e) => {
		e.stopPropagation();
		setIsEditModalOpen(true);
	};

	const closeEditModal = () => {
		setIsEditModalOpen(false);
	};

	const handleSave = async (updatedData) => {
		try {
			await updateCampaign(campaign.id, updatedData);
			onUpdate();
			setIsEditModalOpen(false);
		} catch (error) {
			console.error('Error updating campaign:', error);
			alert('Failed to update campaign. Please try again.');
		}
	};

	const handleDelete = async (e) => {
		e.stopPropagation();
		const confirmDelete = window.confirm(
			'Are you sure you want to delete this campaign?'
		);
		if (!confirmDelete) return;

		setIsDeleting(true);
		try {
			await deleteCampaign(campaign.id);
			onUpdate();
		} catch (error) {
			console.error('Error deleting campaign:', error);
			alert('Failed to delete campaign. Please try again.');
		} finally {
			setIsDeleting(false);
		}
	};

	const handleResume = async (e) => {
		e.stopPropagation();
		// Implement the resume functionality here
		// For example, set the campaign status to 'active'
		try {
			await updateCampaign(campaign.id, { status: 'active' });
			onUpdate();
		} catch (error) {
			console.error('Error resuming campaign:', error);
			alert('Failed to resume campaign. Please try again.');
		}
	};

	// Format the date as "MMM dd, yy" (e.g., "Jan 01, 23")
	const formattedDate = format(new Date(campaign.updated_at), 'MMM dd, yy');

	return (
		<>
			<Card
				variant="outlined"
				sx={{
					position: 'relative',
					cursor: 'pointer',
					height: '100%',
					width: '100%',
					maxWidth: 350,
					display: 'flex',
					flexDirection: 'column',
					'&:hover': { boxShadow: 6 },
					borderRadius: 2,
					mb: 2,
					backgroundColor: 'background.paper',
				}}
				onClick={handleSelect}
			>
				<CardContent sx={{ flexGrow: 1 }}>
					<Grid container spacing={1} alignItems="center">
						{/* Selection Checkbox */}
						{selectable && (
							<Grid item xs={1}>
								<Checkbox
									onChange={(e) => onSelectForBulk(campaign.id, e.target.checked)}
									onClick={(e) => e.stopPropagation()}
									color="primary"
								/>
							</Grid>
						)}

						{/* Campaign Details */}
						<Grid item xs={selectable ? 11 : 12}>
							<Typography
								variant="h4"
								component="div"
								sx={{
									fontWeight: 'bold',
									color: 'text.primary',
									textAlign: 'center',
									mb: 1,
								}}
							>
								{campaign.project_name}
							</Typography>
							<Box display="flex" alignItems="center" gap={2}>
								{getCampaignIcon(campaign.marketing_goal)}
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{ mt: 1, fontStyle: 'italic' }}
								>
									{campaign.marketing_goal}
								</Typography>
							</Box>
						</Grid>
						{/* Status */}
						<Grid item xs={12}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									backgroundColor: 'background.default',
									padding: '4px 8px',
									borderRadius: '8px',
									boxShadow: 1,
								}}
							>
								<FiberManualRecordIcon
									fontSize="small"
									color={getStatusColor(campaign.status)}
									sx={{ mr: 0.5 }}
								/>
								<Typography variant="caption" color="text.secondary">
									{campaign.status.charAt(0).toUpperCase() +
										campaign.status.slice(1)}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</CardContent>

				{/* Action Footer */}
				<Box
					sx={{
						borderTop: '1px solid',
						borderColor: 'divider',
						p: 2,
						display: 'flex',
						flexDirection: { xs: 'column', sm: 'row' },
						justifyContent: 'space-between',
						alignItems: 'center',
						backgroundColor: 'background.default',
					}}
				>
					<Typography variant="caption" color="text.secondary">
						Last Updated: {formattedDate}
					</Typography>
					<Box mt={{ xs: 1, sm: 0 }} display="flex" alignItems="center" gap={1}>
						{campaign.status.toLowerCase() === 'paused' && (
							<Tooltip title="Resume Campaign">
								<Button
									variant="outlined"
									color="primary"
									size="small"
									startIcon={<PlayArrowIcon />}
									onClick={handleResume}
								>
									Resume
								</Button>
							</Tooltip>
						)}
						<Tooltip title="Edit Campaign">
							<IconButton
								aria-label={`Edit campaign ${campaign.project_name}`}
								onClick={openEditModal}
								size="small"
								sx={{ color: 'info.main' }}
							>
								<EditIcon fontSize="small" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete Campaign">
							<IconButton
								aria-label={`Delete campaign ${campaign.project_name}`}
								onClick={handleDelete}
								sx={{ color: 'error.main' }}
								disabled={isDeleting}
								size="small"
							>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</Card>

			{/* Edit Campaign Modal */}
			<EditCampaignModal
				isOpen={isEditModalOpen}
				onClose={closeEditModal}
				campaign={campaign}
				onSave={handleSave}
			/>
		</>
	);
};

CampaignItem.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
		project_name: PropTypes.string.isRequired,
		marketing_goal: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		conversation_id: PropTypes.string.isRequired,
		updated_at: PropTypes.string.isRequired,
	}).isRequired,
	onSelect: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
	selectable: PropTypes.bool,
	onSelectForBulk: PropTypes.func,
};

CampaignItem.defaultProps = {
	selectable: false,
	onSelectForBulk: () => {},
	onUpdate: PropTypes.func.isRequired,
};

export default CampaignItem;
