import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

// Styled IconButton to match MAVA Design System
const StyledIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.text.primary,
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
	transition: 'background-color 0.3s',
}));

// Helper function to clear cookies
const clearCookies = () => {
	const cookies = document.cookie.split(';');
	cookies.forEach((cookie) => {
		const eqPos = cookie.indexOf('=');
		const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
		document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
	});
};

const LogoutButton = ({ onLogout }) => {
	const navigate = useNavigate();
	const theme = useTheme();

	const handleLogout = () => {
		// Clear session data
		localStorage.removeItem('accessToken');
		localStorage.removeItem('isFirstLogin');
		localStorage.removeItem('companyId');
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('isFirstLogin');
		sessionStorage.removeItem('companyId');

		// Clear cookies
		clearCookies();

		// Call the onLogout function to update session state in the App component
		onLogout();

		// Redirect to the login page
		navigate('/login', { replace: true });
	};

	return (
		<Tooltip title="Logout">
			<StyledIconButton onClick={handleLogout}>
				<LogoutIcon />
			</StyledIconButton>
		</Tooltip>
	);
};

export default LogoutButton;
