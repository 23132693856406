// src/themes/index.js
import { createTheme } from '@mui/material/styles';
import getPalette from './palette';
import typography from './typography';
import spacing from './spacing';
import components from './components';
import breakpoints from './breakpoints';

const baseTheme = createTheme({
	palette: getPalette('light'), // Default to light mode for initial theme setup
	spacing,
	breakpoints,
	components,
	shape: {
		borderRadius: 12, // Increased border radius for a more modern look
	},
});
const pxToRem = (size) => `${size / 16}rem`;
// Apply typography with the theme object
baseTheme.typography = {
	...typography(baseTheme),
	pxToRem,
};

export default baseTheme;
