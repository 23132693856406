import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Grid, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AppleMusicArtistCard from '../../subcomponents/AppleMusicArtistCard';
import { useTheme } from '@mui/material/styles';
import AppleMusicData from './AppleMusicData';

const AppleMusicProfile = ({
	artistData: appleMusic,
	onSave,
	onCancel,
	onComplete,
}) => {
	const theme = useTheme();
	const [searchResults, setSearchResults] = useState([]);
	const [appleMusicProfile, setAppleMusicProfile] = useState({
		selectedProfile: {},
		analyticsData: {},
	});
	const [step, setStep] = useState(1);

	const formik = useFormik({
		initialValues: {
			searchTerm: appleMusic?.artist?.stageName || '',
		},
		validationSchema: Yup.object({
			searchTerm: Yup.string().required('Search term is required'),
		}),
		onSubmit: (values) => {
			searchAppleMusicProfiles(values.searchTerm);
		},
	});

	useEffect(() => {
		if (appleMusic) {
			setAppleMusicProfile({
				selectedProfile: appleMusic.selectedProfile || {},
				analyticsData: appleMusic.analyticsData || {},
			});
		} else {
			setAppleMusicProfile({
				selectedProfile: {},
				analyticsData: {},
			});
		}
	}, [appleMusic]);

	const searchAppleMusicProfiles = async (term) => {
		try {
			const accessToken =
				sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

			if (!accessToken) {
				console.error('Failed to get access token');
				return;
			}

			const apiUrl = `${
				process.env.REACT_APP_API_URL
			}/api/applemusic/search?term=${encodeURIComponent(term)}`;
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			});

			if (!response.ok) {
				const errorText = await response.text();
				console.error('Error fetching Apple Music profiles:', errorText);
				throw new Error(errorText || 'Failed to search Apple Music profiles');
			}

			const data = await response.json();
			setSearchResults(data.results?.artists?.data || []);
		} catch (error) {
			console.error('Error fetching Apple Music profiles from backend:', error);
		}
	};

	const toggleSelectProfile = (profile) => {
		const isSelected =
			appleMusicProfile?.selectedProfile?.appleMusicId === profile.id;

		if (isSelected) {
			setAppleMusicProfile((prev) => ({
				...prev,
				selectedProfile: {},
			}));
		} else {
			setAppleMusicProfile((prev) => ({
				selectedProfile: {
					appleMusicId: profile.id,
					name: profile.attributes?.name || 'Unknown Artist',
					externalUrl: profile.attributes?.url || '',
					imageUrl: profile.attributes?.artwork?.url
						? profile.attributes.artwork.url.replace('{w}x{h}', '250x250')
						: '/placeholder.jpg',
					genres: profile.attributes?.genreNames || [],
				},
				...(prev.analyticsData || {}),
			}));
		}
	};

	const handleNext = () => {
		if (appleMusicProfile?.selectedProfile?.appleMusicId) {
			onSave(appleMusicProfile);
			setStep(2);
		} else {
			alert('Please select an Apple Music profile.');
		}
	};

	const handleSaveAndComplete = (updatedData) => {
		const updatedAppleMusicProfile = {
			...appleMusicProfile,
			analyticsData: updatedData,
		};
		setAppleMusicProfile(updatedAppleMusicProfile);
		onSave(updatedAppleMusicProfile);
		onComplete();
	};

	return (
		<Box sx={{ width: '100%', maxWidth: '900px', minWidth: '600px', p: 2 }}>
			{step === 1 && (
				<Box className="apple-music-profile-popup">
					<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
						Search Apple Music Artist Profile
					</Typography>

					<form onSubmit={formik.handleSubmit}>
						<Box display="flex" justifyContent="center" mb={2}>
							<TextField
								fullWidth
								name="searchTerm"
								placeholder="Search Apple Music Artist"
								value={formik.values.searchTerm}
								onChange={formik.handleChange}
								sx={{ maxWidth: '300px' }}
								error={
									formik.touched.searchTerm && Boolean(formik.errors.searchTerm)
								}
								helperText={formik.touched.searchTerm && formik.errors.searchTerm}
							/>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								sx={{ ml: 2 }}
							>
								Search
							</Button>
						</Box>
					</form>

					<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
						Search Results
					</Typography>

					<Box
						className="search-results-container"
						mb={3}
						sx={{ maxHeight: '400px', overflowY: 'auto' }}
					>
						<Grid container spacing={2} justifyContent="center">
							{searchResults.length === 0 ? (
								<Grid item xs={12}>
									<Typography
										variant="body2"
										sx={{ ...theme.typography.body2, textAlign: 'center' }}
									>
										No results found.
									</Typography>
								</Grid>
							) : (
								searchResults.map((profile) => (
									<Grid item key={profile.id}>
										<AppleMusicArtistCard
											artist={{
												id: profile.id,
												name: profile.attributes?.name || 'Unknown Artist',
												genres: profile.attributes?.genreNames || [],
												imageUrl: profile.attributes?.artwork?.url
													? profile.attributes.artwork.url.replace(
															'{w}x{h}',
															'250x250'
													  )
													: '/placeholder.jpg',
												externalUrl: profile.attributes?.url || '',
											}}
											selected={
												appleMusicProfile.selectedProfile.appleMusicId === profile.id
											}
											onSelect={() => toggleSelectProfile(profile)}
										/>
									</Grid>
								))
							)}
						</Grid>
					</Box>

					<Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
						Selected Apple Music Profile
					</Typography>
					<Grid container spacing={2} justifyContent="center">
						<Grid item>
							{appleMusicProfile.selectedProfile.appleMusicId ? (
								<AppleMusicArtistCard
									artist={{
										id: appleMusicProfile.selectedProfile.appleMusicId,
										name: appleMusicProfile.selectedProfile.name,
										genres: appleMusicProfile.selectedProfile.genres || [],
										imageUrl: appleMusicProfile.selectedProfile.imageUrl,
										externalUrl: appleMusicProfile.selectedProfile.externalUrl,
									}}
									selected={true}
									onSelect={() =>
										toggleSelectProfile({
											id: appleMusicProfile.selectedProfile.appleMusicId,
										})
									}
								/>
							) : (
								<Box
									sx={{
										width: '250px',
										height: '100%',
										minHeight: '300px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: '1px dashed #ccc',
										borderRadius: '8px',
										backgroundColor: '#f9f9f9',
									}}
								>
									<Typography variant="body2" color="textSecondary">
										No Profile Selected
									</Typography>
								</Box>
							)}
						</Grid>
					</Grid>

					<Box display="flex" justifyContent="center" mt={2} gap={2}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ...theme.typography.button }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleNext}
							disabled={!appleMusicProfile.selectedProfile.appleMusicId}
							sx={{ ...theme.typography.button }}
						>
							Save & Next
						</Button>
					</Box>
				</Box>
			)}

			{step === 2 && (
				<Box className="apple-music-profile-popup">
					<AppleMusicData
						artistData={appleMusicProfile.analyticsData}
						onSave={handleSaveAndComplete}
						onCancel={onCancel}
					/>
				</Box>
			)}
		</Box>
	);
};

export default AppleMusicProfile;
