import React from 'react';
import {
	Button,
	TextField,
	Typography,
	Grid,
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Box,
	Divider,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useFormik, FieldArray, FormikProvider, getIn } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';

const InstagramData = ({ artistData: instagramData, onSave, onCancel }) => {
	const theme = useTheme();
	const ageRangeOptions = [
		'13-17',
		'18-24',
		'25-34',
		'35-44',
		'45-54',
		'55-64',
		'65+',
	];

	// Validation schema using Yup
	const validationSchema = Yup.object().shape({
		views: Yup.object().shape({
			totalViews: Yup.number()
				.typeError('Total Views must be a valid number')
				.integer('Total Views must be an integer')
				.min(0, 'Total Views cannot be negative')
				.required('Total Views is required'),
			accountsReached: Yup.number()
				.typeError('Accounts Reached must be a valid number')
				.min(0, 'Accounts Reached cannot be negative')
				.required('Accounts Reached is required'),
			followers: Yup.number()
				.typeError('Followers must be a valid number')
				.max(100, 'Percent cannot exceed 100')
				.min(0, 'Followers cannot be negative')
				.required('Followers is required'),
			nonFollowers: Yup.number()
				.typeError('Non-Followers must be a valid number')
				.max(100, 'Percent cannot exceed 100')
				.min(0, 'Non-Followers cannot be negative')
				.required('Non-Followers is required'),
			stories: Yup.number()
				.typeError('Stories must be a valid number')
				.min(0, 'Stories cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Stories is required'),
			reels: Yup.number()
				.typeError('Reels must be a valid number')
				.min(0, 'Reels cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Reels is required'),
			posts: Yup.number()
				.typeError('Posts must be a valid number')
				.min(0, 'Posts cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Posts is required'),
		}),
		interactions: Yup.object().shape({
			totalInteractions: Yup.number()
				.typeError('Total Interactions must be a valid number')
				.integer('Total Interactions must be an integer')
				.min(0, 'Total Interactions cannot be negative')
				.required('Total Interactions is required'),
			accountsEngaged: Yup.number()
				.typeError('Accounts Engaged must be a valid number')
				.integer('Accounts Engaged must be an integer')
				.min(0, 'Accounts Engaged cannot be negative')
				.required('Accounts Engaged is required'),
			followers: Yup.number()
				.typeError('Followers Interactions must be a valid number')
				.min(0, 'Followers Interactions cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Followers Interactions is required'),
			nonFollowers: Yup.number()
				.typeError('Non-Followers Interactions must be a valid number')
				.min(0, 'Non-Followers Interactions cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Non-Followers Interactions is required'),
			stories: Yup.number()
				.typeError('Stories Interactions must be a valid number')
				.min(0, 'Stories Interactions cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Stories Interactions is required'),
			reels: Yup.number()
				.typeError('Reels Interactions must be a valid number')
				.min(0, 'Reels Interactions cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Reels Interactions is required'),
			posts: Yup.number()
				.typeError('Posts Interactions must be a valid number')
				.min(0, 'Posts Interactions cannot be negative')
				.max(100, 'Percent cannot exceed 100')
				.required('Posts Interactions is required'),
		}),
		followers: Yup.object().shape({
			overallFollowers: Yup.number()
				.typeError('Overall Followers must be a valid number')
				.integer('Overall Followers must be an integer')
				.min(0, 'Overall Followers cannot be negative')
				.required('Overall Followers is required'),
			followersChange: Yup.number()
				.typeError('Followers Change must be a valid decimal number')
				.required('Followers Change is required'),
			topCities: Yup.array()
				.of(
					Yup.object().shape({
						city: Yup.string()
							.typeError('City must be a string')
							.matches(/^[A-Za-z\s]+$/, 'City can only contain letters and spaces')
							.required('City is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(5, 'You can add up to 5 top cities only')
				.required('Top Cities are required')
				.min(5, 'At least 5 top cities must be added'),
			topCountries: Yup.array()
				.of(
					Yup.object().shape({
						country: Yup.string()
							.typeError('Country must be a string')
							.matches(
								/^[A-Za-z\s]+$/,
								'Country can only contain letters and spaces'
							)
							.required('Country is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(5, 'You can add up to 5 top countries only')
				.required('Top Countries are required')
				.min(5, 'At least 5 top countries must be added'),
			ageRanges: Yup.array()
				.of(
					Yup.object().shape({
						ageRange: Yup.string()
							.typeError('Age Range must be a string')
							.required('Age Range is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(3, 'You can add up to 3 age ranges only')
				.required('Age Ranges are required')
				.min(3, 'At least 3 age ranges must be added'),
			genderPercentM: Yup.number()
				.typeError('Gender Percent (M) must be a valid number')
				.min(0, 'Gender Percent (M) cannot be negative')
				.max(100, 'Gender Percent (M) cannot exceed 100')
				.required('Gender Percent (M) is required'),
			genderPercentF: Yup.number()
				.typeError('Gender Percent (F) must be a valid number')
				.min(0, 'Gender Percent (F) cannot be negative')
				.max(100, 'Gender Percent (F) cannot exceed 100')
				.required('Gender Percent (F) is required'),
		}),
		profileVisits: Yup.number()
			.typeError('Profile Visits must be a valid number')
			.integer('Profile Visits must be an integer')
			.min(0, 'Profile Visits cannot be negative')
			.required('Profile Visits is required'),
		externalLinks: Yup.number()
			.typeError('External Links must be a valid number')
			.integer('External Links Shared must be an integer')
			.min(0, 'External Links Shared cannot be negative')
			.required('External Links Shared is required'),
	});

	// Initialize Formik with enableReinitialize
	const formik = useFormik({
		initialValues: {
			views: {
				totalViews: instagramData?.views?.totalViews ?? '',
				followers: instagramData?.views?.followers ?? '',
				nonFollowers: instagramData?.views?.nonFollowers ?? '',
				accountsReached: instagramData?.views?.accountsReached ?? '',
				stories: instagramData?.views?.stories ?? '',
				reels: instagramData?.views?.reels ?? '',
				posts: instagramData?.views?.posts ?? '',
			},
			interactions: {
				totalInteractions: instagramData?.interactions?.totalInteractions ?? '',
				followers: instagramData?.interactions?.followers ?? '',
				nonFollowers: instagramData?.interactions?.nonFollowers ?? '',
				accountsEngaged: instagramData?.interactions?.accountsEngaged ?? '',
				stories: instagramData?.interactions?.stories ?? '',
				reels: instagramData?.interactions?.reels ?? '',
				posts: instagramData?.interactions?.posts ?? '',
			},
			followers: {
				overallFollowers: instagramData?.followers?.overallFollowers ?? '',
				followersChange: instagramData?.followers?.followersChange ?? '',
				topCities: Array.isArray(instagramData?.followers?.topCities)
					? instagramData.followers.topCities.map((item) => ({
							city: item.city ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ city: '', percent: '' },
							{ city: '', percent: '' },
							{ city: '', percent: '' },
							{ city: '', percent: '' },
							{ city: '', percent: '' },
					  ],
				topCountries: Array.isArray(instagramData?.followers?.topCountries)
					? instagramData.followers.topCountries.map((item) => ({
							country: item.country ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ country: '', percent: '' },
							{ country: '', percent: '' },
							{ country: '', percent: '' },
							{ country: '', percent: '' },
							{ country: '', percent: '' },
					  ],
				ageRanges: Array.isArray(instagramData?.followers?.ageRanges)
					? instagramData.followers.ageRanges.map((item) => ({
							ageRange: item.ageRange ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
					  ],
				genderPercentM: instagramData?.followers?.genderPercentM ?? '',
				genderPercentF: instagramData?.followers?.genderPercentF ?? '',
			},
			profileVisits: instagramData?.profileVisits ?? '',
			externalLinks: instagramData?.externalLinks ?? '',
		},
		validationSchema,
		onSubmit: (values) => {
			onSave(values);
		},
		enableReinitialize: true,
		validateOnMount: true,
	});

	// Helper function to render dynamic fields
	const renderDynamicFields = (
		name,
		label,
		arrayHelpers,
		fieldLabel1,
		fieldLabel2
	) => {
		// Determine the correct field name based on fieldLabel1
		let fieldName1;
		if (fieldLabel1 === 'Age Range') {
			fieldName1 = 'ageRange';
		} else if (fieldLabel1 === 'City') {
			fieldName1 = 'city';
		} else if (fieldLabel1 === 'Country') {
			fieldName1 = 'country';
		} else {
			fieldName1 = 'source';
		}

		return (
			<>
				<Typography
					variant="subtitle1"
					sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
				>
					{label}
				</Typography>
				{getIn(formik.values, name).map((_, index) => (
					<Box display="flex" alignItems="center" mb={2} key={index}>
						{/* Conditional rendering based on fieldLabel1 */}
						{fieldLabel1 === 'Age Range' ? (
							<FormControl fullWidth sx={{ mr: 2 }}>
								<Select
									label={`Age Range ${index + 1}`}
									name={`${name}[${index}].${fieldName1}`}
									value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
										Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
									}
								>
									{ageRangeOptions.map((range) => (
										<MenuItem key={range} value={range}>
											{range}
										</MenuItem>
									))}
								</Select>
								{getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`) && (
										<Typography color="error" variant="caption">
											{getIn(formik.errors, `${name}[${index}].${fieldName1}`)}
										</Typography>
									)}
							</FormControl>
						) : (
							<TextField
								fullWidth
								label={`${fieldLabel1}:`}
								name={`${name}[${index}].${fieldName1}`}
								type="text"
								inputMode="numeric"
								value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
								}
								helperText={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`)
								}
								variant="outlined"
								sx={{ mr: 2 }}
							/>
						)}
						<TextField
							fullWidth
							label={`${fieldLabel2}:`}
							name={`${name}[${index}].percent`}
							type="text"
							inputMode="numeric"
							value={getIn(formik.values, `${name}[${index}].percent`)}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								getIn(formik.touched, `${name}[${index}].percent`) &&
								Boolean(getIn(formik.errors, `${name}[${index}].percent`))
							}
							helperText={
								getIn(formik.touched, `${name}[${index}].percent`) &&
								getIn(formik.errors, `${name}[${index}].percent`)
							}
							variant="outlined"
							sx={{ mr: 2 }}
							InputProps={{
								endAdornment: <Typography variant="caption">%</Typography>,
								inputProps: { min: 0, max: 100 },
							}}
						/>
						<IconButton
							color="secondary"
							onClick={() => arrayHelpers.remove(index)}
							disabled={getIn(formik.values, name).length === 1}
						>
							<RemoveCircleOutline />
						</IconButton>
					</Box>
				))}
				{getIn(formik.values, name).length <
					(fieldLabel1 === 'Age Range' ? 3 : 5) && (
					<Button
						variant="outlined"
						startIcon={<AddCircleOutline />}
						onClick={() =>
							arrayHelpers.push(
								fieldLabel1 === 'Age Range'
									? { ageRange: '', percent: '' }
									: { [fieldName1]: '', percent: '' } // Dynamically set fieldName1
							)
						}
						sx={{ mb: 2 }}
					>
						Add {fieldLabel1}
					</Button>
				)}
			</>
		);
	};

	return (
		<FormikProvider value={formik}>
			<Box
				sx={{
					width: '100%',
					maxWidth: '750px',
					margin: '0 auto',
					padding: 2,
					backgroundColor: 'background.paper',
					borderRadius: 2,
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					{/* Header Section */}
					<Box textAlign="center" mb={4}>
						<Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
							Instagram Analytics Data
						</Typography>
						<Button
							variant="contained"
							color="primary"
							href="https://www.instagram.com/accounts/insights/"
							target="_blank"
							sx={{ ...theme.typography.button, mb: 2 }}
						>
							Open Instagram Analytics
						</Button>
						<Typography variant="subtitle1" sx={{ ...theme.typography.h4, mb: 2 }}>
							Last 30 Days
						</Typography>
						<Divider sx={{ mb: 2 }} />
					</Box>

					<Box mb={4}>
						<Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 600 }}>
							Views / Accounts Reached
						</Typography>
						{/* Views/Accounts Reached Data */}
						<Grid container spacing={2}>
							{/* Views/Accounts Reached */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Total Views"
									name="views.totalViews"
									type="text"
									inputMode="numeric"
									value={formik.values.views.totalViews}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.views?.totalViews &&
										Boolean(formik.errors.views?.totalViews)
									}
									helperText={
										formik.touched.views?.totalViews &&
										formik.errors.views?.totalViews
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Accounts Reached"
									name="views.accountsReached"
									type="text"
									inputMode="numeric"
									value={formik.values.views.accountsReached}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.views?.accountsReached &&
										Boolean(formik.errors.views?.accountsReached)
									}
									helperText={
										formik.touched.views?.accountsReached &&
										formik.errors.views?.accountsReached
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Followers (%)"
									name="views.followers"
									type="text"
									inputMode="numeric"
									value={formik.values.views.followers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.views?.followers &&
										Boolean(formik.errors.views?.followers)
									}
									helperText={
										formik.touched.views?.followers && formik.errors.views?.followers
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Non-Followers (%)"
									name="views.nonFollowers"
									type="text"
									inputMode="numeric"
									value={formik.values.views.nonFollowers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.views?.nonFollowers &&
										Boolean(formik.errors.views?.nonFollowers)
									}
									helperText={
										formik.touched.views?.nonFollowers &&
										formik.errors.views?.nonFollowers
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>

							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Reels (%)"
									name="views.reels"
									type="text"
									inputMode="numeric"
									value={formik.values.views.reels}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.views?.reels &&
										Boolean(formik.errors.views?.reels)
									}
									helperText={
										formik.touched.views?.reels && formik.errors.views?.reels
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Posts (%)"
									name="views.posts"
									type="text"
									inputMode="numeric"
									value={formik.values.views.posts}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.views?.posts &&
										Boolean(formik.errors.views?.posts)
									}
									helperText={
										formik.touched.views?.posts && formik.errors.views?.posts
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Stories (%)"
									name="views.stories"
									type="text"
									inputMode="numeric"
									value={formik.values.views.stories}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.views?.stories &&
										Boolean(formik.errors.views?.stories)
									}
									helperText={
										formik.touched.views?.stories && formik.errors.views?.stories
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
						</Grid>
					</Box>

					{/* Interactions/Accounts Engaged Data */}
					<Box mb={4}>
						<Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 600 }}>
							Interactions / Accounts Engaged
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Total Interactions"
									name="interactions.totalInteractions"
									type="text"
									inputMode="numeric"
									value={formik.values.interactions.totalInteractions}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.interactions?.totalInteractions &&
										Boolean(formik.errors.interactions?.totalInteractions)
									}
									helperText={
										formik.touched.interactions?.totalInteractions &&
										formik.errors.interactions?.totalInteractions
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Accounts Engaged"
									name="interactions.accountsEngaged"
									type="text"
									inputMode="numeric"
									value={formik.values.interactions.accountsEngaged}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.interactions?.accountsEngaged &&
										Boolean(formik.errors.interactions?.accountsEngaged)
									}
									helperText={
										formik.touched.interactions?.accountsEngaged &&
										formik.errors.interactions?.accountsEngaged
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Followers Interactions (%)"
									name="interactions.followers"
									type="text"
									inputMode="numeric"
									value={formik.values.interactions.followers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.interactions?.followers &&
										Boolean(formik.errors.interactions?.followers)
									}
									helperText={
										formik.touched.interactions?.followers &&
										formik.errors.interactions?.followers
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Non-Followers Interactions (%)"
									name="interactions.nonFollowers"
									type="text"
									inputMode="numeric"
									value={formik.values.interactions.nonFollowers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.interactions?.nonFollowers &&
										Boolean(formik.errors.interactions?.nonFollowers)
									}
									helperText={
										formik.touched.interactions?.nonFollowers &&
										formik.errors.interactions?.nonFollowers
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>

							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Reels Interactions (%)"
									name="interactions.reels"
									type="text"
									inputMode="numeric"
									value={formik.values.interactions.reels}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.interactions?.reels &&
										Boolean(formik.errors.interactions?.reels)
									}
									helperText={
										formik.touched.interactions?.reels &&
										formik.errors.interactions?.reels
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Posts Interactions (%)"
									name="interactions.posts"
									type="text"
									inputMode="numeric"
									value={formik.values.interactions.posts}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.interactions?.posts &&
										Boolean(formik.errors.interactions?.posts)
									}
									helperText={
										formik.touched.interactions?.posts &&
										formik.errors.interactions?.posts
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Stories Interactions (%)"
									name="interactions.stories"
									type="text"
									inputMode="numeric"
									value={formik.values.interactions.stories}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.interactions?.stories &&
										Boolean(formik.errors.interactions?.stories)
									}
									helperText={
										formik.touched.interactions?.stories &&
										formik.errors.interactions?.stories
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
						</Grid>
					</Box>

					{/* Followers Data */}
					<Box mb={4}>
						<Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 600 }}>
							Followers Data
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Overall Followers"
									name="followers.overallFollowers"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.overallFollowers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.overallFollowers &&
										Boolean(formik.errors.followers?.overallFollowers)
									}
									helperText={
										formik.touched.followers?.overallFollowers &&
										formik.errors.followers?.overallFollowers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Followers Change (%)"
									name="followers.followersChange"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.followersChange}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.followersChange &&
										Boolean(formik.errors.followers?.followersChange)
									}
									helperText={
										formik.touched.followers?.followersChange &&
										formik.errors.followers?.followersChange
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0 },
									}}
								/>
							</Grid>

							{/* Demographics */}
							<Grid item xs={12}>
								<Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 600 }}>
									Gender Demographics
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (M)"
									name="followers.genderPercentM"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.genderPercentM}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.genderPercentM &&
										Boolean(formik.errors.followers?.genderPercentM)
									}
									helperText={
										formik.touched.followers?.genderPercentM &&
										formik.errors.followers?.genderPercentM
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (F)"
									name="followers.genderPercentF"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.genderPercentF}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.genderPercentF &&
										Boolean(formik.errors.followers?.genderPercentF)
									}
									helperText={
										formik.touched.followers?.genderPercentF &&
										formik.errors.followers?.genderPercentF
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<FieldArray
									name="followers.ageRanges"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'followers.ageRanges',
											'Followers Age Ranges',
											arrayHelpers,
											'Age Range',
											'Percent'
										)
									}
								/>
								{formik.touched.followers?.ageRanges &&
									typeof formik.errors.followers?.ageRanges === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.followers?.ageRanges}
										</Typography>
									)}
							</Grid>

							{/* Top 5 Cities */}
							<Grid item xs={12}>
								<FieldArray
									name="followers.topCities"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'followers.topCities',
											'Top 5 Cities by Percent',
											arrayHelpers,
											'City',
											'Percent'
										)
									}
								/>
							</Grid>

							{/* Top 5 Countries */}
							<Grid item xs={12}>
								<FieldArray
									name="followers.topCountries"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'followers.topCountries',
											'Top 5 Countries by Percent',
											arrayHelpers,
											'Country',
											'Percent'
										)
									}
								/>
							</Grid>
						</Grid>
					</Box>

					{/* Profile Visits */}
					<Box mb={4}>
						<Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 600 }}>
							Profile Visits / External Links
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Profile Visits"
									name="profileVisits"
									type="text"
									inputMode="numeric"
									value={formik.values.profileVisits}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.profileVisits &&
										Boolean(formik.errors.profileVisits)
									}
									helperText={
										formik.touched.profileVisits && formik.errors.profileVisits
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="External Links"
									name="externalLinks"
									type="text"
									inputMode="numeric"
									value={formik.values.externalLinks}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.externalLinks &&
										Boolean(formik.errors.externalLinks)
									}
									helperText={
										formik.touched.externalLinks && formik.errors.externalLinks
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
						</Grid>
					</Box>

					{/* Submit and Cancel Buttons */}
					<Box mt={4} display="flex" justifyContent="center" gap={2}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ...theme.typography.button }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!formik.isValid || formik.isSubmitting}
							size="large"
							sx={{ ...theme.typography.button }}
						>
							Save Data
						</Button>
					</Box>
				</form>
			</Box>
		</FormikProvider>
	);
};

export default InstagramData;
