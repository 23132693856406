// src/components/charts/GeographicalLocationsChart.jsx
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, ToggleButton, ToggleButtonGroup, Grid, useTheme } from '@mui/material';
import ChartWrapper from './ChartWrapper';
import HeatmapLayer from './HeatmapLayer';
import LocationDetails from './LocationDetails';
import PopupContent from './PopupContent';
import { countryCoordinates, cityCoordinates } from '../../../utils/coordinates';
import L from 'leaflet';
import 'leaflet.markercluster';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import 'react-leaflet-markercluster/dist/styles.min.css';
import 'leaflet.heat';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// Fixing the default icon issue in Leaflet with React
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Define color scale based on rank
const getColorByRank = (rank, maxRank, theme) => {
	const saturation = 100 - (rank - 1) * (80 / maxRank); // Adjust saturation between 20 to 100
	return `hsl(220, ${saturation}%, 50%)`; // Blue hue
};

const GeographicalLocationsChart = ({ data }) => {
	const theme = useTheme();

	// Toggle States
	const [dataType, setDataType] = useState('topCountries');
	const [revenueType, setRevenueType] = useState('streaming');
	const [selectedPlatform, setSelectedPlatform] = useState('all');
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [mapMode, setMapMode] = useState('markers');

	const handleDataTypeChange = (event, newType) => {
		if (newType !== null) {
			setDataType(newType);
			// Reset revenue type if not topRevenue
			if (newType !== 'topRevenue') {
				setRevenueType('streaming');
			}
		}
	};

	const handleRevenueTypeChange = (event) => {
		setRevenueType(event.target.value);
	};

	const handleMapModeChange = (event, newMode) => {
		if (newMode !== null) {
			setMapMode(newMode);
			setSelectedLocation(null); // Optional: Reset selected location when mode changes
		}
	};

	// Prepare data based on toggle selections
	const filteredData = useMemo(() => {
		let locations = [];
		let metric = 'listeners'; // Default metric

		if (dataType === 'topCountries') {
			locations = [...data.topCountries];
		} else if (dataType === 'topCities') {
			locations = [...data.topCities];
		} else if (dataType === 'topRevenue') {
			locations = [...data.topRevenueLocations[revenueType]];
			metric = 'revenue'; // Use 'revenue' metric for topRevenue
		}

		// Filter by platform if not 'all'
		if (selectedPlatform !== 'all') {
			locations = locations.filter(
				(location) =>
					location.listenersPerPlatform[selectedPlatform] &&
					location.listenersPerPlatform[selectedPlatform] > 0
			);

			// Sort based on the selected metric
			locations.sort(
				(a, b) =>
					(b[selectedPlatform] || b[metric]) - (a[selectedPlatform] || a[metric])
			);

			// Reassign ranks based on the selected metric
			locations = locations.map((location, index) => ({
				...location,
				rank: index + 1,
			}));
		} else {
			// Sort based on the selected metric
			locations.sort((a, b) => b[metric] - a[metric]);

			// Reassign ranks based on the selected metric
			locations = locations.map((location, index) => ({
				...location,
				rank: index + 1,
			}));
		}

		return locations;
	}, [data, dataType, revenueType, selectedPlatform]);

	// Determine maximum rank for color scaling
	const maxRank = useMemo(() => {
		return filteredData.length;
	}, [filteredData]);

	// Prepare Heatmap Data
	const maxListeners = useMemo(() => {
		return Math.max(...filteredData.map((loc) => loc.listeners));
	}, [filteredData]);

	const heatmapPoints = useMemo(() => {
		const points = [];
		filteredData.forEach((location) => {
			const coordinates =
				dataType === 'topCountries'
					? countryCoordinates[location.country]
					: dataType === 'topCities'
					? cityCoordinates[location.city]
					: countryCoordinates[location.country]; // Assuming revenue locations are countries
			if (coordinates) {
				const intensity =
					dataType === 'topRevenue' ? location.revenue : location.listeners;
				points.push([
					coordinates[0],
					coordinates[1],
					intensity /
						(dataType === 'topRevenue'
							? Math.max(...filteredData.map((loc) => loc.revenue))
							: maxListeners),
				]);
			}
		});
		return points;
	}, [filteredData, dataType, maxListeners]);

	return (
		<ChartWrapper title="Geographical Locations">
			<Grid container spacing={2} mb={2}>
				{/* Data Type Toggle */}
				<Grid item xs={12} md={6}>
					<ToggleButtonGroup
						value={dataType}
						exclusive
						onChange={handleDataTypeChange}
						aria-label="data type"
						fullWidth
					>
						<ToggleButton
							value="topCountries"
							aria-label="Top Countries"
							sx={{
								textTransform: 'none',
								borderRadius: 2,
								borderColor:
									dataType === 'topCountries'
										? theme.palette.primary.main
										: theme.palette.divider,
								borderWidth: 2,
							}}
						>
							Top Countries
						</ToggleButton>
						<ToggleButton
							value="topCities"
							aria-label="Top Cities"
							sx={{
								textTransform: 'none',
								borderRadius: 2,
								borderColor:
									dataType === 'topCities'
										? theme.palette.primary.main
										: theme.palette.divider,
								borderWidth: 2,
							}}
						>
							Top Cities
						</ToggleButton>
						{/*
						<ToggleButton
							value="topRevenue"
							aria-label="Top Revenue Locations"
							sx={{
								textTransform: 'none',
								borderRadius: 2,
								borderColor:
									dataType === 'topRevenue'
										? theme.palette.primary.main
										: theme.palette.divider,
							}}
						>
							Top Revenue
						</ToggleButton>
                        */}
					</ToggleButtonGroup>
					{/*
					{dataType === 'topRevenue' && (
						<ToggleButtonGroup
							value={revenueType}
							exclusive
							onChange={handleRevenueTypeChange}
							aria-label="revenue type"
							sx={{ mt: 2, display: 'flex', justifyContent: 'right' }}
						>
							<ToggleButton
								value="streaming"
								aria-label="Streaming"
								sx={{
									textTransform: 'none',
									borderRadius: 2,
									borderColor:
										revenueType === 'streaming'
											? theme.palette.primary.main
											: theme.palette.divider,
								}}
							>
								Streaming
							</ToggleButton>
							<ToggleButton
								value="merch"
								aria-label="Merch Sales"
								sx={{
									textTransform: 'none',
									borderRadius: 2,
									borderColor:
										revenueType === 'merch'
											? theme.palette.primary.main
											: theme.palette.divider,
								}}
							>
								Merch Sales
							</ToggleButton>
							<ToggleButton
								value="touring"
								aria-label="Touring Sales"
								sx={{
									textTransform: 'none',
									borderRadius: 2,
									borderColor:
										revenueType === 'touring'
											? theme.palette.primary.main
											: theme.palette.divider,
								}}
							>
								Touring Sales
							</ToggleButton>
						</ToggleButtonGroup>
					)} */}
				</Grid>

				{/* Map Mode Toggle */}
				<Grid item xs={12} md={6}>
					<ToggleButtonGroup
						value={mapMode}
						exclusive
						onChange={handleMapModeChange}
						aria-label="map mode"
						fullWidth
					>
						<ToggleButton
							value="markers"
							aria-label="Markers"
							sx={{
								textTransform: 'none',
								borderRadius: 2,
								borderColor:
									mapMode === 'markers'
										? theme.palette.primary.main
										: theme.palette.divider,
								borderWidth: 2,
							}}
						>
							Markers
						</ToggleButton>
						<ToggleButton
							value="heatmap"
							aria-label="Heatmap"
							sx={{
								textTransform: 'none',
								borderRadius: 2,
								borderColor:
									mapMode === 'heatmap'
										? theme.palette.primary.main
										: theme.palette.divider,
								borderWidth: 2,
							}}
						>
							Heatmap
						</ToggleButton>
					</ToggleButtonGroup>
				</Grid>
			</Grid>

			<Grid container spacing={2}>
				{/* Map Container */}
				<Grid item xs={12} md={9} lg={10}>
					<Box
						sx={{
							borderRadius: 2,
							overflow: 'hidden',
							boxShadow: theme.shadows[2],
						}}
					>
						<MapContainer
							center={[20, 0]} // Center of the map [latitude, longitude]
							zoom={2}
							scrollWheelZoom={false}
							style={{ height: '500px', width: '100%' }}
						>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
							/>

							{mapMode === 'markers' && (
								<MarkerClusterGroup>
									{filteredData.map((location) => {
										const isCountry = location.country !== undefined;
										const name = isCountry ? location.country : location.city;
										const coordinates = isCountry
											? countryCoordinates[location.country]
											: cityCoordinates[location.city];

										if (!coordinates) return null;

										const color = getColorByRank(location.rank, maxRank, theme);

										// Create a custom icon with ranking number
										const customIcon = L.divIcon({
											html: `<div style="
												background-color: ${color};
												color: white;
												border-radius: 50%;
												width: 30px;
												height: 30px;
												display: flex;
												align-items: center;
												justify-content: center;
												border: 2px solid ${theme.palette.background.paper};
												font-weight: bold;
												font-size: 14px;
												box-shadow: 0 0 5px rgba(0,0,0,0.5);
												pointer-events: none;
												">
												${location.rank}
												</div>`,
											className: '',
											iconSize: [30, 30],
											iconAnchor: [15, 30],
										});

										return (
											<Marker
												key={name}
												position={coordinates}
												icon={customIcon}
												eventHandlers={{
													click: () => {
														setSelectedLocation({
															name,
															rank: location.rank,
															listeners: location.listeners,
															listenersPerPlatform: location.listenersPerPlatform,
															estimatedRevenue: location.estimatedRevenue,
														});
													},
												}}
											>
												<Popup>
													<PopupContent
														location={{
															name,
															rank: location.rank,
															listeners: location.listeners,
															listenersPerPlatform: location.listenersPerPlatform,
															estimatedRevenue: location.estimatedRevenue,
														}}
													/>
												</Popup>
											</Marker>
										);
									})}
								</MarkerClusterGroup>
							)}

							{mapMode === 'heatmap' && <HeatmapLayer points={heatmapPoints} />}
						</MapContainer>
					</Box>
				</Grid>

				{/* Location Details Pane */}
				<Grid item xs={12} md={3} lg={2}>
					<Box
						sx={{
							borderRadius: 2,
							boxShadow: theme.shadows[2],
							height: '500px',
							backgroundColor: theme.palette.background.paper,
							overflow: 'hidden',
						}}
					>
						<LocationDetails location={selectedLocation} />
					</Box>
				</Grid>
			</Grid>
		</ChartWrapper>
	);
};

GeographicalLocationsChart.propTypes = {
	data: PropTypes.shape({
		topCountries: PropTypes.arrayOf(
			PropTypes.shape({
				rank: PropTypes.number.isRequired,
				country: PropTypes.string.isRequired,
				listeners: PropTypes.number.isRequired,
				listenersPerPlatform: PropTypes.object.isRequired,
				estimatedRevenue: PropTypes.object.isRequired,
			})
		).isRequired,
		topCities: PropTypes.arrayOf(
			PropTypes.shape({
				rank: PropTypes.number.isRequired,
				city: PropTypes.string.isRequired,
				listeners: PropTypes.number.isRequired,
				listenersPerPlatform: PropTypes.object.isRequired,
				estimatedRevenue: PropTypes.object.isRequired,
			})
		).isRequired,
		topRevenueLocations: PropTypes.shape({
			streaming: PropTypes.arrayOf(
				PropTypes.shape({
					rank: PropTypes.number.isRequired,
					country: PropTypes.string.isRequired,
					revenue: PropTypes.number.isRequired,
					listenersPerPlatform: PropTypes.object.isRequired,
					estimatedRevenue: PropTypes.object.isRequired,
				})
			).isRequired,
			merch: PropTypes.arrayOf(
				PropTypes.shape({
					rank: PropTypes.number.isRequired,
					country: PropTypes.string.isRequired,
					revenue: PropTypes.number.isRequired,
					listenersPerPlatform: PropTypes.object.isRequired,
					estimatedRevenue: PropTypes.object.isRequired,
				})
			).isRequired,
			touring: PropTypes.arrayOf(
				PropTypes.shape({
					rank: PropTypes.number.isRequired,
					country: PropTypes.string.isRequired,
					revenue: PropTypes.number.isRequired,
					listenersPerPlatform: PropTypes.object.isRequired,
					estimatedRevenue: PropTypes.object.isRequired,
				})
			).isRequired,
		}).isRequired,
	}).isRequired,
};

export default GeographicalLocationsChart;
