// src/context/ConversationContext.js

import React, { createContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getContextData } from '../utils/apis/contextUtils';
import { getUserSession } from '../utils/apis/auth/sessionUtils';

export const ConversationContext = createContext();

export const ConversationProvider = ({ children }) => {
	const { conversationId } = useParams();
	const { userId } = getUserSession();
	const [contextData, setContextData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchContext = async () => {
			try {
				const data = await getContextData(conversationId);
				setContextData(data);
			} catch (err) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		if (conversationId) {
			fetchContext();
		}
	}, [conversationId]);

	return (
		<ConversationContext.Provider
			value={{ contextData, loading, error, conversationId, userId }}
		>
			{children}
		</ConversationContext.Provider>
	);
};
