// src/mockData/geographicalData.js
export const geographicalData = {
	geographicalLocations: {
		topCountries: [
			{
				rank: 1,
				country: 'United States',
				listeners: 500000,
				listenersPerPlatform: {
					Spotify: 200000,
					AppleMusic: 150000,
					YouTube: 150000,
				},
				estimatedRevenue: {
					streaming: 1000000,
					merch: 500000,
					touring: 750000,
				},
			},
			{
				rank: 2,
				country: 'United Kingdom',
				listeners: 300000,
				listenersPerPlatform: {
					Spotify: 120000,
					AppleMusic: 90000,
					YouTube: 90000,
				},
				estimatedRevenue: {
					streaming: 600000,
					merch: 300000,
					touring: 450000,
				},
			},
			{
				rank: 3,
				country: 'Canada',
				listeners: 150000,
				listenersPerPlatform: {
					Spotify: 60000,
					AppleMusic: 45000,
					YouTube: 45000,
				},
				estimatedRevenue: {
					streaming: 300000,
					merch: 150000,
					touring: 225000,
				},
			},
			{
				rank: 4,
				country: 'Australia',
				listeners: 120000,
				listenersPerPlatform: {
					Spotify: 48000,
					AppleMusic: 36000,
					YouTube: 36000,
				},
				estimatedRevenue: {
					streaming: 240000,
					merch: 120000,
					touring: 180000,
				},
			},
			{
				rank: 5,
				country: 'Germany',
				listeners: 100000,
				listenersPerPlatform: {
					Spotify: 40000,
					AppleMusic: 30000,
					YouTube: 30000,
				},
				estimatedRevenue: {
					streaming: 200000,
					merch: 100000,
					touring: 150000,
				},
			},
			{
				rank: 6,
				country: 'France',
				listeners: 90000,
				listenersPerPlatform: {
					Spotify: 36000,
					AppleMusic: 27000,
					YouTube: 27000,
				},
				estimatedRevenue: {
					streaming: 180000,
					merch: 90000,
					touring: 135000,
				},
			},
			{
				rank: 7,
				country: 'Brazil',
				listeners: 80000,
				listenersPerPlatform: {
					Spotify: 32000,
					AppleMusic: 24000,
					YouTube: 24000,
				},
				estimatedRevenue: {
					streaming: 160000,
					merch: 80000,
					touring: 120000,
				},
			},
			{
				rank: 8,
				country: 'Japan',
				listeners: 75000,
				listenersPerPlatform: {
					Spotify: 30000,
					AppleMusic: 22500,
					YouTube: 22500,
				},
				estimatedRevenue: {
					streaming: 150000,
					merch: 75000,
					touring: 112500,
				},
			},
			{
				rank: 9,
				country: 'South Korea',
				listeners: 70000,
				listenersPerPlatform: {
					Spotify: 28000,
					AppleMusic: 21000,
					YouTube: 21000,
				},
				estimatedRevenue: {
					streaming: 140000,
					merch: 70000,
					touring: 105000,
				},
			},
			{
				rank: 10,
				country: 'India',
				listeners: 65000,
				listenersPerPlatform: {
					Spotify: 26000,
					AppleMusic: 19500,
					YouTube: 19500,
				},
				estimatedRevenue: {
					streaming: 130000,
					merch: 65000,
					touring: 97500,
				},
			},
			{
				rank: 11,
				country: 'Mexico',
				listeners: 60000,
				listenersPerPlatform: {
					Spotify: 24000,
					AppleMusic: 18000,
					YouTube: 18000,
				},
				estimatedRevenue: {
					streaming: 120000,
					merch: 60000,
					touring: 90000,
				},
			},
			{
				rank: 12,
				country: 'Italy',
				listeners: 55000,
				listenersPerPlatform: {
					Spotify: 22000,
					AppleMusic: 16500,
					YouTube: 16500,
				},
				estimatedRevenue: {
					streaming: 110000,
					merch: 55000,
					touring: 82500,
				},
			},
			{
				rank: 13,
				country: 'Spain',
				listeners: 50000,
				listenersPerPlatform: {
					Spotify: 20000,
					AppleMusic: 15000,
					YouTube: 15000,
				},
				estimatedRevenue: {
					streaming: 100000,
					merch: 50000,
					touring: 75000,
				},
			},
			{
				rank: 14,
				country: 'Netherlands',
				listeners: 45000,
				listenersPerPlatform: {
					Spotify: 18000,
					AppleMusic: 13500,
					YouTube: 13500,
				},
				estimatedRevenue: {
					streaming: 90000,
					merch: 45000,
					touring: 67500,
				},
			},
			{
				rank: 15,
				country: 'Sweden',
				listeners: 40000,
				listenersPerPlatform: {
					Spotify: 16000,
					AppleMusic: 12000,
					YouTube: 12000,
				},
				estimatedRevenue: {
					streaming: 80000,
					merch: 40000,
					touring: 60000,
				},
			},
			{
				rank: 16,
				country: 'Norway',
				listeners: 35000,
				listenersPerPlatform: {
					Spotify: 14000,
					AppleMusic: 10500,
					YouTube: 10500,
				},
				estimatedRevenue: {
					streaming: 70000,
					merch: 35000,
					touring: 52500,
				},
			},
			{
				rank: 17,
				country: 'Russia',
				listeners: 30000,
				listenersPerPlatform: {
					Spotify: 12000,
					AppleMusic: 9000,
					YouTube: 9000,
				},
				estimatedRevenue: {
					streaming: 60000,
					merch: 30000,
					touring: 45000,
				},
			},
			{
				rank: 18,
				country: 'China',
				listeners: 25000,
				listenersPerPlatform: {
					Spotify: 10000,
					AppleMusic: 7500,
					YouTube: 7500,
				},
				estimatedRevenue: {
					streaming: 50000,
					merch: 25000,
					touring: 37500,
				},
			},
			{
				rank: 19,
				country: 'South Africa',
				listeners: 20000,
				listenersPerPlatform: {
					Spotify: 8000,
					AppleMusic: 6000,
					YouTube: 6000,
				},
				estimatedRevenue: {
					streaming: 40000,
					merch: 20000,
					touring: 30000,
				},
			},
			{
				rank: 20,
				country: 'New Zealand',
				listeners: 15000,
				listenersPerPlatform: {
					Spotify: 6000,
					AppleMusic: 4500,
					YouTube: 4500,
				},
				estimatedRevenue: {
					streaming: 30000,
					merch: 15000,
					touring: 22500,
				},
			},
		],
		topCities: [
			{
				rank: 1,
				city: 'New York',
				listeners: 100000,
				listenersPerPlatform: {
					Spotify: 40000,
					AppleMusic: 30000,
					YouTube: 30000,
				},
				estimatedRevenue: {
					streaming: 200000,
					merch: 100000,
					touring: 150000,
				},
			},
			{
				rank: 2,
				city: 'Los Angeles',
				listeners: 80000,
				listenersPerPlatform: {
					Spotify: 32000,
					AppleMusic: 24000,
					YouTube: 24000,
				},
				estimatedRevenue: {
					streaming: 160000,
					merch: 80000,
					touring: 120000,
				},
			},
			{
				rank: 3,
				city: 'London',
				listeners: 70000,
				listenersPerPlatform: {
					Spotify: 28000,
					AppleMusic: 21000,
					YouTube: 21000,
				},
				estimatedRevenue: {
					streaming: 140000,
					merch: 70000,
					touring: 105000,
				},
			},
			{
				rank: 4,
				city: 'Toronto',
				listeners: 50000,
				listenersPerPlatform: {
					Spotify: 20000,
					AppleMusic: 15000,
					YouTube: 15000,
				},
				estimatedRevenue: {
					streaming: 100000,
					merch: 50000,
					touring: 75000,
				},
			},
			{
				rank: 5,
				city: 'Sydney',
				listeners: 40000,
				listenersPerPlatform: {
					Spotify: 16000,
					AppleMusic: 12000,
					YouTube: 12000,
				},
				estimatedRevenue: {
					streaming: 80000,
					merch: 40000,
					touring: 60000,
				},
			},
			{
				rank: 6,
				city: 'Berlin',
				listeners: 35000,
				listenersPerPlatform: {
					Spotify: 14000,
					AppleMusic: 10500,
					YouTube: 10500,
				},
				estimatedRevenue: {
					streaming: 70000,
					merch: 35000,
					touring: 52500,
				},
			},
			{
				rank: 7,
				city: 'Paris',
				listeners: 30000,
				listenersPerPlatform: {
					Spotify: 12000,
					AppleMusic: 9000,
					YouTube: 9000,
				},
				estimatedRevenue: {
					streaming: 60000,
					merch: 30000,
					touring: 45000,
				},
			},
			{
				rank: 8,
				city: 'Tokyo',
				listeners: 25000,
				listenersPerPlatform: {
					Spotify: 10000,
					AppleMusic: 7500,
					YouTube: 7500,
				},
				estimatedRevenue: {
					streaming: 50000,
					merch: 25000,
					touring: 37500,
				},
			},
			{
				rank: 9,
				city: 'Seoul',
				listeners: 20000,
				listenersPerPlatform: {
					Spotify: 8000,
					AppleMusic: 6000,
					YouTube: 6000,
				},
				estimatedRevenue: {
					streaming: 40000,
					merch: 20000,
					touring: 30000,
				},
			},
			{
				rank: 10,
				city: 'Mumbai',
				listeners: 15000,
				listenersPerPlatform: {
					Spotify: 6000,
					AppleMusic: 4500,
					YouTube: 4500,
				},
				estimatedRevenue: {
					streaming: 30000,
					merch: 15000,
					touring: 22500,
				},
			},
			{
				rank: 11,
				city: 'Mexico City',
				listeners: 14000,
				listenersPerPlatform: {
					Spotify: 5600,
					AppleMusic: 4200,
					YouTube: 4200,
				},
				estimatedRevenue: {
					streaming: 28000,
					merch: 14000,
					touring: 21000,
				},
			},
			{
				rank: 12,
				city: 'Rome',
				listeners: 13000,
				listenersPerPlatform: {
					Spotify: 5200,
					AppleMusic: 3900,
					YouTube: 3900,
				},
				estimatedRevenue: {
					streaming: 26000,
					merch: 13000,
					touring: 19500,
				},
			},
			{
				rank: 13,
				city: 'Madrid',
				listeners: 12000,
				listenersPerPlatform: {
					Spotify: 4800,
					AppleMusic: 3600,
					YouTube: 3600,
				},
				estimatedRevenue: {
					streaming: 24000,
					merch: 12000,
					touring: 18000,
				},
			},
			{
				rank: 14,
				city: 'Amsterdam',
				listeners: 11000,
				listenersPerPlatform: {
					Spotify: 4400,
					AppleMusic: 3300,
					YouTube: 3300,
				},
				estimatedRevenue: {
					streaming: 22000,
					merch: 11000,
					touring: 16500,
				},
			},
			{
				rank: 15,
				city: 'Stockholm',
				listeners: 10000,
				listenersPerPlatform: {
					Spotify: 4000,
					AppleMusic: 3000,
					YouTube: 3000,
				},
				estimatedRevenue: {
					streaming: 20000,
					merch: 10000,
					touring: 15000,
				},
			},
			{
				rank: 16,
				city: 'Oslo',
				listeners: 9000,
				listenersPerPlatform: {
					Spotify: 3600,
					AppleMusic: 2700,
					YouTube: 2700,
				},
				estimatedRevenue: {
					streaming: 18000,
					merch: 9000,
					touring: 13500,
				},
			},
			{
				rank: 17,
				city: 'Moscow',
				listeners: 8000,
				listenersPerPlatform: {
					Spotify: 3200,
					AppleMusic: 2400,
					YouTube: 2400,
				},
				estimatedRevenue: {
					streaming: 16000,
					merch: 8000,
					touring: 12000,
				},
			},
			{
				rank: 18,
				city: 'Beijing',
				listeners: 7000,
				listenersPerPlatform: {
					Spotify: 2800,
					AppleMusic: 2100,
					YouTube: 2100,
				},
				estimatedRevenue: {
					streaming: 14000,
					merch: 7000,
					touring: 10500,
				},
			},
			{
				rank: 19,
				city: 'Johannesburg',
				listeners: 6000,
				listenersPerPlatform: {
					Spotify: 2400,
					AppleMusic: 1800,
					YouTube: 1800,
				},
				estimatedRevenue: {
					streaming: 12000,
					merch: 6000,
					touring: 9000,
				},
			},
			{
				rank: 20,
				city: 'Auckland',
				listeners: 5000,
				listenersPerPlatform: {
					Spotify: 2000,
					AppleMusic: 1500,
					YouTube: 1500,
				},
				estimatedRevenue: {
					streaming: 10000,
					merch: 5000,
					touring: 7500,
				},
			},
		],
		topRevenueLocations: {
			streaming: [
				{
					rank: 1,
					country: 'United States',
					revenue: 1000000,
					listenersPerPlatform: {
						Spotify: 200000,
						AppleMusic: 150000,
						YouTube: 150000,
					},
					estimatedRevenue: {
						streaming: 1000000,
					},
				},
				{
					rank: 2,
					country: 'United Kingdom',
					revenue: 600000,
					listenersPerPlatform: {
						Spotify: 120000,
						AppleMusic: 90000,
						YouTube: 90000,
					},
					estimatedRevenue: {
						streaming: 600000,
					},
				},
				{
					rank: 3,
					country: 'Canada',
					revenue: 300000,
					listenersPerPlatform: {
						Spotify: 60000,
						AppleMusic: 45000,
						YouTube: 45000,
					},
					estimatedRevenue: {
						streaming: 300000,
					},
				},
				{
					rank: 4,
					country: 'Australia',
					revenue: 240000,
					listenersPerPlatform: {
						Spotify: 48000,
						AppleMusic: 36000,
						YouTube: 36000,
					},
					estimatedRevenue: {
						streaming: 240000,
					},
				},
				{
					rank: 5,
					country: 'Germany',
					revenue: 200000,
					listenersPerPlatform: {
						Spotify: 40000,
						AppleMusic: 30000,
						YouTube: 30000,
					},
					estimatedRevenue: {
						streaming: 200000,
					},
				},
				// Add more streaming revenue locations
				{
					rank: 6,
					country: 'France',
					revenue: 180000,
					listenersPerPlatform: {
						Spotify: 36000,
						AppleMusic: 27000,
						YouTube: 27000,
					},
					estimatedRevenue: {
						streaming: 180000,
					},
				},
				{
					rank: 7,
					country: 'Brazil',
					revenue: 160000,
					listenersPerPlatform: {
						Spotify: 32000,
						AppleMusic: 24000,
						YouTube: 24000,
					},
					estimatedRevenue: {
						streaming: 160000,
					},
				},
				{
					rank: 8,
					country: 'Japan',
					revenue: 150000,
					listenersPerPlatform: {
						Spotify: 30000,
						AppleMusic: 22500,
						YouTube: 22500,
					},
					estimatedRevenue: {
						streaming: 150000,
					},
				},
				{
					rank: 9,
					country: 'South Korea',
					revenue: 140000,
					listenersPerPlatform: {
						Spotify: 28000,
						AppleMusic: 21000,
						YouTube: 21000,
					},
					estimatedRevenue: {
						streaming: 140000,
					},
				},
				{
					rank: 10,
					country: 'India',
					revenue: 130000,
					listenersPerPlatform: {
						Spotify: 26000,
						AppleMusic: 19500,
						YouTube: 19500,
					},
					estimatedRevenue: {
						streaming: 130000,
					},
				},
			],
			merch: [
				{
					rank: 1,
					country: 'United States',
					revenue: 500000,
					listenersPerPlatform: {
						Spotify: 200000,
						AppleMusic: 150000,
						YouTube: 150000,
					},
					estimatedRevenue: {
						merch: 500000,
					},
				},
				{
					rank: 2,
					country: 'United Kingdom',
					revenue: 300000,
					listenersPerPlatform: {
						Spotify: 120000,
						AppleMusic: 90000,
						YouTube: 90000,
					},
					estimatedRevenue: {
						merch: 300000,
					},
				},
				{
					rank: 3,
					country: 'Canada',
					revenue: 150000,
					listenersPerPlatform: {
						Spotify: 60000,
						AppleMusic: 45000,
						YouTube: 45000,
					},
					estimatedRevenue: {
						merch: 150000,
					},
				},
				{
					rank: 4,
					country: 'Australia',
					revenue: 120000,
					listenersPerPlatform: {
						Spotify: 48000,
						AppleMusic: 36000,
						YouTube: 36000,
					},
					estimatedRevenue: {
						merch: 120000,
					},
				},
				{
					rank: 5,
					country: 'Germany',
					revenue: 100000,
					listenersPerPlatform: {
						Spotify: 40000,
						AppleMusic: 30000,
						YouTube: 30000,
					},
					estimatedRevenue: {
						merch: 100000,
					},
				},
				// Add more merch revenue locations
				{
					rank: 6,
					country: 'France',
					revenue: 90000,
					listenersPerPlatform: {
						Spotify: 36000,
						AppleMusic: 27000,
						YouTube: 27000,
					},
					estimatedRevenue: {
						merch: 90000,
					},
				},
				{
					rank: 7,
					country: 'Brazil',
					revenue: 80000,
					listenersPerPlatform: {
						Spotify: 32000,
						AppleMusic: 24000,
						YouTube: 24000,
					},
					estimatedRevenue: {
						merch: 80000,
					},
				},
				{
					rank: 8,
					country: 'Japan',
					revenue: 75000,
					listenersPerPlatform: {
						Spotify: 30000,
						AppleMusic: 22500,
						YouTube: 22500,
					},
					estimatedRevenue: {
						merch: 75000,
					},
				},
				{
					rank: 9,
					country: 'South Korea',
					revenue: 70000,
					listenersPerPlatform: {
						Spotify: 28000,
						AppleMusic: 21000,
						YouTube: 21000,
					},
					estimatedRevenue: {
						merch: 70000,
					},
				},
				{
					rank: 10,
					country: 'India',
					revenue: 65000,
					listenersPerPlatform: {
						Spotify: 26000,
						AppleMusic: 19500,
						YouTube: 19500,
					},
					estimatedRevenue: {
						merch: 65000,
					},
				},
			],
			touring: [
				{
					rank: 1,
					country: 'United States',
					revenue: 750000,
					listenersPerPlatform: {
						Spotify: 200000,
						AppleMusic: 150000,
						YouTube: 150000,
					},
					estimatedRevenue: {
						touring: 750000,
					},
				},
				{
					rank: 2,
					country: 'United Kingdom',
					revenue: 450000,
					listenersPerPlatform: {
						Spotify: 120000,
						AppleMusic: 90000,
						YouTube: 90000,
					},
					estimatedRevenue: {
						touring: 450000,
					},
				},
				{
					rank: 3,
					country: 'Canada',
					revenue: 225000,
					listenersPerPlatform: {
						Spotify: 60000,
						AppleMusic: 45000,
						YouTube: 45000,
					},
					estimatedRevenue: {
						touring: 225000,
					},
				},
				{
					rank: 4,
					country: 'Australia',
					revenue: 180000,
					listenersPerPlatform: {
						Spotify: 48000,
						AppleMusic: 36000,
						YouTube: 36000,
					},
					estimatedRevenue: {
						touring: 180000,
					},
				},
				{
					rank: 5,
					country: 'Germany',
					revenue: 135000,
					listenersPerPlatform: {
						Spotify: 40000,
						AppleMusic: 30000,
						YouTube: 30000,
					},
					estimatedRevenue: {
						touring: 135000,
					},
				},
				// Add more touring revenue locations
				{
					rank: 6,
					country: 'France',
					revenue: 112500,
					listenersPerPlatform: {
						Spotify: 36000,
						AppleMusic: 27000,
						YouTube: 27000,
					},
					estimatedRevenue: {
						touring: 112500,
					},
				},
				{
					rank: 7,
					country: 'Brazil',
					revenue: 105000,
					listenersPerPlatform: {
						Spotify: 32000,
						AppleMusic: 24000,
						YouTube: 24000,
					},
					estimatedRevenue: {
						touring: 105000,
					},
				},
				{
					rank: 8,
					country: 'Japan',
					revenue: 112500,
					listenersPerPlatform: {
						Spotify: 30000,
						AppleMusic: 22500,
						YouTube: 22500,
					},
					estimatedRevenue: {
						touring: 112500,
					},
				},
				{
					rank: 9,
					country: 'South Korea',
					revenue: 105000,
					listenersPerPlatform: {
						Spotify: 28000,
						AppleMusic: 21000,
						YouTube: 21000,
					},
					estimatedRevenue: {
						touring: 105000,
					},
				},
				{
					rank: 10,
					country: 'India',
					revenue: 97500,
					listenersPerPlatform: {
						Spotify: 26000,
						AppleMusic: 19500,
						YouTube: 19500,
					},
					estimatedRevenue: {
						touring: 97500,
					},
				},
			],
		},
	},
};
