// src/utils/apis/contextUtils.js

import axios from 'axios';

export const getContextData = async (conversationId) => {
	try {
		const accessToken =
			localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/conversations/${conversationId}/context`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error(
			'Error fetching context data:',
			error.response?.data || error.message
		);
		throw new Error('Failed to fetch context data.');
	}
};

export const updateContextData = async (conversationId, formData) => {
	try {
		const accessToken =
			localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
		const response = await axios.put(
			`${process.env.REACT_APP_API_URL}/api/conversations/${conversationId}/context`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(formData),
			}
		);
		return response.data;
	} catch (error) {
		console.error(
			'Error saving context data:',
			error.response?.data || error.message
		);
		throw new Error('Failed to save context data.');
	}
};
