import React, { useContext } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../../context/ColorModeContext';
import { styled } from '@mui/system';

// Styled IconButton to match MAVA Design System
const StyledIconButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.text.primary,
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
	transition: 'background-color 0.3s',
}));

const ThemeToggleButton = () => {
	const colorMode = useContext(ColorModeContext);
	const theme = useTheme();

	return (
		<Tooltip title="Dark Mode Coming Soon">
			<StyledIconButton onClick={colorMode.toggleColorMode}>
				{theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
			</StyledIconButton>
		</Tooltip>
	);
};

export default ThemeToggleButton;
