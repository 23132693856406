// ArtistCard.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	IconButton,
	Typography,
	Box,
	useTheme,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteArtist } from '../../utils/apis/onboardingData/artistOnboardingUtils';

// Styled Components
const MediaContainer = styled(Box)(({ theme }) => ({
	position: 'relative',
	width: '100%',
	paddingTop: '100%',
	overflow: 'hidden',
	borderTopLeftRadius: theme.shape.borderRadius,
	borderTopRightRadius: theme.shape.borderRadius,
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	height: '100%',
	width: '100%',
	objectFit: 'cover',
}));

const PlaceholderBox = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.grey[300],
	borderTopLeftRadius: theme.shape.borderRadius,
	borderTopRightRadius: theme.shape.borderRadius,
}));

const DeleteIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	top: theme.spacing(1),
	right: theme.spacing(1),
	color: theme.palette.error.main,
	backgroundColor: theme.palette.background.paper,
	'&:hover': {
		backgroundColor: theme.palette.background.default,
	},
	boxShadow: theme.shadows[1],
}));

const ArtistCard = ({ artist, onSelect, onDelete, artistsLength }) => {
	const theme = useTheme();
	const [isDeleting, setIsDeleting] = useState(false);

	const handleDelete = async (e) => {
		e.stopPropagation();
		const confirmDelete = window.confirm(
			`Are you sure you want to delete '${artist.name || 'New Artist'}'?`
		);
		if (!confirmDelete) return;

		setIsDeleting(true);
		try {
			await deleteArtist(artist.id);
			onDelete();
		} catch (error) {
			console.error('Error deleting artist:', error);
			alert('Failed to delete artist. Please try again.');
		} finally {
			setIsDeleting(false);
		}
	};

	return (
		<Card
			sx={{
				maxWidth: 350,
				borderRadius: 2,
				boxShadow: theme.shadows[5],
				backgroundColor: 'background.paper',
				position: 'relative',
			}}
		>
			<CardActionArea
				onClick={() => onSelect(artist)}
				aria-label={`Select artist ${artist.name || 'New Artist'}`}
				sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
			>
				<MediaContainer>
					{artist.profilePhoto ? (
						<StyledCardMedia
							component="img"
							image={artist.profilePhoto}
							alt={artist.name || 'New Artist'}
						/>
					) : (
						<PlaceholderBox>
							<PersonIcon sx={{ fontSize: 100, color: 'grey.600' }} />
						</PlaceholderBox>
					)}
					{artistsLength > 1 && (
						<DeleteIconButton
							aria-label={`Delete ${artist.name || 'New Artist'}`}
							onClick={() => handleDelete(artist.id)} // Assuming each artist has an ID
							disabled={isDeleting}
						>
							<DeleteIcon fontSize="small" />
						</DeleteIconButton>
					)}
				</MediaContainer>
				<CardContent
					sx={{
						textAlign: 'center',
						flexGrow: 1,
						padding: theme.spacing(2),
					}}
				>
					<Typography
						gutterBottom
						variant="h4"
						component="div"
						color="text.primary"
						noWrap
					>
						{artist.name || 'New Artist'}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
};

ArtistCard.propTypes = {
	artist: PropTypes.shape({
		id: PropTypes.string.isRequired,
		name: PropTypes.string,
		profilePhoto: PropTypes.string,
	}).isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default ArtistCard;
