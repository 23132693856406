import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	IconButton,
	Tooltip,
	Avatar,
	Box,
} from '@mui/material';
import {
	Home as HomeIcon,
	Campaign as MarketingIcon,
	Assessment as DataAnalystIcon,
	Menu as MenuIcon,
	ChevronLeft as ChevronLeftIcon,
} from '@mui/icons-material';
import MAVAFavicon from '../../assets/MAVAFavicon.png';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

const drawerWidthOpen = 200;
const drawerWidthClosed = 80;

// Styled Drawer to apply MAVA Design System styles
const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
	width: open ? drawerWidthOpen : drawerWidthClosed,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	'& .MuiDrawer-paper': {
		width: open ? drawerWidthOpen : drawerWidthClosed,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,
		borderRight: `1px solid ${theme.palette.divider}`,
		boxShadow: theme.shadows[2],
		borderRadius: '0',
	},
}));

const Sidebar = ({ open, setOpen, selectedArtist, setShowModal }) => {
	const navigate = useNavigate();
	const theme = useTheme();

	const handleDrawerToggle = () => {
		setOpen(!open);
	};

	const menuItems = [
		{ text: 'Home', icon: <HomeIcon />, path: '/home' },
		{
			text: 'Marketing Assist',
			icon: <MarketingIcon />,
			path: '/marketing-assistant',
		},
		{
			text: 'Data Analyst',
			icon: <DataAnalystIcon />,
			path: '/data-analyst',
			disabled: true,
		},
	];

	return (
		<StyledDrawer variant="permanent" open={open}>
			<Box
				display="flex"
				alignItems="center"
				justifyContent={open ? 'space-between' : 'center'}
				p={2}
			>
				{open && (
					<Box
						component="img"
						src={MAVAFavicon}
						alt="Logo"
						sx={{
							width: '100%',
							height: 'auto',
							maxHeight: 40,
							objectFit: 'contain',
						}}
					/>
				)}
				<IconButton
					onClick={handleDrawerToggle}
					sx={{
						color: theme.palette.text.primary,
						'&:hover': {
							backgroundColor: theme.palette.action.hover,
						},
						borderRadius: '50%',
						transition: 'background-color 0.3s',
					}}
				>
					{open ? <ChevronLeftIcon /> : <MenuIcon />}
				</IconButton>
			</Box>
			<Box sx={{ justifyContent: 'center', display: 'flex' }}>
				<List>
					{/* Selected Artist */}
					<ListItem disablePadding>
						<ListItemButton
							onClick={() => setShowModal(true)}
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: 2,
								py: 1.5,
								px: open ? 2 : 'auto',
								borderRadius: 1,
								transition: 'background-color 0.3s',
								'&:hover': {
									backgroundColor: theme.palette.action.hover,
								},
							}}
						>
							<Avatar
								src={selectedArtist?.profilePhoto}
								alt={selectedArtist?.name}
								sx={{ width: 32, height: 32 }}
							/>
							{open && (
								<ListItemText
									primary={selectedArtist?.name || 'Select Artist'}
									primaryTypographyProps={{
										variant: 'body1',
										color: theme.palette.text.primary,
										fontWeight: theme.typography.fontWeightRegular,
									}}
								/>
							)}
						</ListItemButton>
					</ListItem>

					{/* Navigation Menu Items */}
					{menuItems.map((item) => (
						<Tooltip
							key={item.text}
							title={open ? '' : item.text}
							placement="right"
							arrow
						>
							<ListItem disablePadding>
								<ListItemButton
									onClick={() => !item.disabled && navigate(item.path)}
									disabled={item.disabled}
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 2,
										py: 1.5,
										px: open ? 2 : 'auto',
										borderRadius: 1,
										color: item.disabled
											? theme.palette.text.disabled
											: theme.palette.text.primary,
										transition: 'background-color 0.3s',
										'&:hover': {
											backgroundColor: !item.disabled
												? theme.palette.action.hover
												: 'inherit',
										},
										'&.Mui-disabled': {
											cursor: 'not-allowed',
										},
									}}
								>
									<ListItemIcon
										sx={{
											minWidth: 'auto',
											color: item.disabled
												? theme.palette.text.disabled
												: theme.palette.primary.main,
										}}
									>
										{item.icon}
									</ListItemIcon>
									{open && (
										<ListItemText
											primary={item.text}
											primaryTypographyProps={{
												variant: 'body1',
												color: 'inherit',
												fontWeight: theme.typography.fontWeightRegular,
											}}
										/>
									)}
								</ListItemButton>
							</ListItem>
						</Tooltip>
					))}
				</List>
			</Box>
		</StyledDrawer>
	);
};

export default Sidebar;
