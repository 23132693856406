// ImmediateNeeds.js

import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Grid,
	Slider,
	Card,
	CardContent,
	CardActions,
	Box,
	Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const ImmediateNeeds = ({ artistData: immediateNeeds, onSave }) => {
	const theme = useTheme();
	const [selectedNeeds, setSelectedNeeds] = useState([]);
	const [currentPage, setCurrentPage] = useState(0);

	// List of immediate needs options
	const immediateNeedsOptions = [
		{
			title: 'Social Media Growth',
			description: 'Expand social media presence and engagement.',
		},
		{
			title: 'Music Production Quality',
			description: 'Improve the quality of music production.',
		},
		{
			title: 'Brand Partnerships',
			description: 'Seek out and establish brand partnerships.',
		},
		{
			title: 'Touring & Live Performances',
			description: 'Increase live performances and tours.',
		},
		{
			title: 'Revenue Growth',
			description: 'Scale growth across various revenue streams.',
		},
		{
			title: 'Fan Engagement',
			description: 'Increase engagement with your current fanbase.',
		},
	];

	useEffect(() => {
		if (immediateNeeds && Array.isArray(immediateNeeds.selectedNeeds)) {
			// Set the initial state from the selectedNeeds array within the immediateNeeds object
			setSelectedNeeds(
				immediateNeeds.selectedNeeds.map((need) => ({
					title: need.title,
					description: need.description,
					priority: need.priority,
				}))
			);
		} else {
			// Initialize selectedNeeds with an empty array if it doesn't exist
			setSelectedNeeds([]);
		}
	}, [immediateNeeds]);

	// Function to check if a need is selected
	const isSelected = (title) => selectedNeeds.some((need) => need.title === title);

	// Toggle selection for an immediate need
	const toggleSelectNeed = (title, description) => {
		setSelectedNeeds((prevNeeds) => {
			if (isSelected(title)) {
				// If already selected, deselect
				return prevNeeds.filter((need) => need.title !== title);
			} else if (prevNeeds.length < 5) {
				// If not selected and under 5 selections, add to the list
				return [...prevNeeds, { title, description, priority: 'Medium' }];
			}
			return prevNeeds;
		});
	};

	// Update priority of selected needs
	const updatePriority = (title, newPriority) => {
		const priorityString =
			newPriority === 0 ? 'Low' : newPriority === 50 ? 'Medium' : 'High';
		setSelectedNeeds((prevNeeds) =>
			prevNeeds.map((need) =>
				need.title === title ? { ...need, priority: priorityString } : need
			)
		);
	};

	// Save and close
	const handleSave = () => {
		const updatedData = {
			selectedNeeds,
		};
		// Pass updated data to parent component via onSave
		onSave(updatedData);
	};

	const needsPerPage = 6;
	const pages = Math.ceil(immediateNeedsOptions.length / needsPerPage);
	const paginatedNeeds = immediateNeedsOptions.slice(
		currentPage * needsPerPage,
		(currentPage + 1) * needsPerPage
	);

	// Slider marks for Low, Medium, and High priorities
	const priorityMarks = [
		{ value: 0, label: 'Low' },
		{ value: 50, label: 'Medium' },
		{ value: 100, label: 'High' },
	];

	return (
		<Box sx={{ padding: 4 }}>
			<Typography variant="h4" align="center" sx={{ mb: 2 }}>
				What are your immediate needs?
			</Typography>
			<Typography variant="body1" align="center" sx={{ mb: 4 }}>
				Select 3-5 areas to prioritize.
			</Typography>

			<Grid container spacing={3}>
				{paginatedNeeds.map((need, index) => (
					<Grid item xs={12} sm={6} key={index}>
						<Card
							variant="outlined"
							sx={{
								cursor: 'pointer',
								borderColor: isSelected(need.title) ? 'primary.main' : 'grey.300',
								boxShadow: isSelected(need.title)
									? '0 4px 12px rgba(0, 0, 0, 0.1)'
									: 'none',
								'&:hover': {
									boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
								},
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
							}}
							onClick={() => toggleSelectNeed(need.title, need.description)}
						>
							<CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
								<Typography variant="h4" sx={{ mb: 1 }}>
									{need.title}
								</Typography>
								<Typography variant="body1" sx={{ textAlign: 'center', mb: 2 }}>
									{need.description}
								</Typography>

								<Divider sx={{ mb: 2 }} />
								<Typography variant="body1" sx={{ textAlign: 'center', mb: 1 }}>
									Priority:
								</Typography>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'center',
									}}
									onClick={(e) => e.stopPropagation()} // Prevents slider clicks from deselecting the card
								>
									<Grid container justifyContent="center">
										<Grid item xs={12} sm={8}>
											<Slider
												value={
													isSelected(need.title)
														? selectedNeeds.find(
																(selected) => selected.title === need.title
														  ).priority === 'Low'
															? 0
															: selectedNeeds.find(
																	(selected) => selected.title === need.title
															  ).priority === 'Medium'
															? 50
															: 100
														: 50
												}
												onChange={(e, value) =>
													isSelected(need.title) && updatePriority(need.title, value)
												}
												min={0}
												max={100}
												step={null}
												marks={priorityMarks}
												disabled={!isSelected(need.title)}
											/>
										</Grid>
									</Grid>
								</Box>
							</CardContent>
							<CardActions sx={{ justifyContent: 'center' }}>
								<Button
									variant={isSelected(need.title) ? 'contained' : 'outlined'}
									color={isSelected(need.title) ? 'primary' : 'default'}
									sx={{ mb: 1 }}
								>
									{isSelected(need.title) ? 'Selected' : 'Select'}
								</Button>
							</CardActions>
						</Card>
					</Grid>
				))}
			</Grid>

			{/* Pagination Controls */}
			<Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
				{currentPage > 0 && (
					<Button onClick={() => setCurrentPage(currentPage - 1)}>Previous</Button>
				)}
				{currentPage < pages - 1 && (
					<Button onClick={() => setCurrentPage(currentPage + 1)}>Next</Button>
				)}
			</Box>

			{/* Save and close button */}
			<Box textAlign="center" sx={{ mt: 4 }}>
				<Button
					onClick={handleSave}
					variant="contained"
					color="primary"
					disabled={selectedNeeds.length < 3}
				>
					Save Selection
				</Button>
			</Box>
		</Box>
	);
};

export default ImmediateNeeds;
