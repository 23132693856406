// components/modules/chatInterface/Header.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Grid } from '@mui/material';

const ChatHeader = ({ campaignTitle, campaignGoal }) => {
	return (
		<Box
			sx={{
				padding: 2,
				borderBottom: '1px solid',
				borderColor: 'divider',
				backgroundColor: 'background.paper',
			}}
		>
			<Grid container alignItems="center" textAlign={'center'}>
				<Grid item xs={12}>
					<Typography variant="h4" sx={{ fontWeight: 'bold', mb: 1 }}>
						Campaign: {campaignTitle}
					</Typography>
					{campaignGoal && (
						<Typography variant="body2" color="text.secondary">
							Goal: {campaignGoal}
						</Typography>
					)}
				</Grid>
				{/* Additional header elements can be added here */}
			</Grid>
		</Box>
	);
};

ChatHeader.propTypes = {
	campaignTitle: PropTypes.string.isRequired,
	campaignGoal: PropTypes.string,
};

export default ChatHeader;
