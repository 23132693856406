// src/context/UserContext.js
import React, {
	createContext,
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
} from 'react';
import { fetchCompanyData } from '../utils/apis/globalData/companyDataUtils';
import SessionContext from './SessionContext';
import { getUserSession } from '../utils/apis/auth/sessionUtils';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const { session } = useContext(SessionContext);
	const [userData, setUserData] = useState({
		email: '',
		firstName: '',
		lastName: '',
		companyName: '',
	});
	const [companyData, setCompanyData] = useState({});
	const [loading, setLoading] = useState(false);

	// Function to initialize user data
	const initializeUserData = useCallback(async () => {
		if (session.isLoggedIn) {
			const { email, firstName, lastName, companyName, companyId } =
				getUserSession();
			setUserData({ email, firstName, lastName, companyName });

			if (companyId) {
				setLoading(true);
				try {
					const fetchedCompanyData = await fetchCompanyData(companyId);
					setCompanyData(fetchedCompanyData);
				} catch (error) {
					console.error('Error fetching company data:', error);
				} finally {
					setLoading(false);
				}
			}
		} else {
			setUserData({
				email: '',
				firstName: '',
				lastName: '',
				companyName: '',
			});
			setCompanyData({});
		}
	}, [session.isLoggedIn, session.companyId]);

	useEffect(() => {
		initializeUserData();
	}, [initializeUserData]);

	// Memoize the context value to prevent unnecessary re-renders
	const contextValue = useMemo(
		() => ({
			userData,
			setUserData,
			companyData,
			setCompanyData,
			loading,
			setLoading,
			initializeUserData,
		}),
		[userData, companyData, loading, initializeUserData]
	);

	return (
		<UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
	);
};

export default UserContext;
