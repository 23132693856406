// src/themes/palette.js

const getPalette = (mode) => ({
	mode,
	primary: {
		main: '#28c0fd',
		contrastText: '#FFFFFF',
	},
	secondary: {
		main: '#A763FF',
		contrastText: '#FFFFFF',
	},
	success: {
		main: '#28A745',
		contrastText: '#FFFFFF',
	},
	error: {
		main: '#FF4B4B',
		contrastText: '#FFFFFF',
	},
	warning: {
		main: '#FFCC00',
		contrastText: '#212121',
	},
	info: {
		main: '#1E90FF',
		contrastText: '#FFFFFF',
	},
	background: {
		default: mode === 'dark' ? '#121212' : '#F5F5F5',
		paper: mode === 'dark' ? '#1E1E1E' : '#FFFFFF',
		login: mode === 'dark' ? '#121212' : '#121212',
	},
	text: {
		primary: mode === 'dark' ? '#FFFFFF' : '#212121',
		secondary: mode === 'dark' ? '#B0B0B0' : '#555555',
		disabled: mode === 'dark' ? '#757575' : '#9E9E9E',
	},
	divider: mode === 'dark' ? '#333333' : '#E0E0E0',
	action: {
		hover: mode === 'dark' ? '#333333' : '#E8E8E8',
		active: mode === 'dark' ? '#444444' : '#D0D0D0',
		disabled: '#9E9E9E', // Remains the same in both modes
	},
});

export default getPalette;
