// src/themes/typography.js

const pxToRem = (size) => `${size / 16}rem`;

const typography = (theme) => ({
	fontFamily: '"SF Pro", "Roboto", "Helvetica", "Arial", sans-serif',

	h1: {
		fontSize: pxToRem(36), // 2.25rem
		fontWeight: 700,
		lineHeight: 1.25,
		letterSpacing: '-0.01562em',
		textAlign: 'center',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(28), // 1.75rem
		},
	},

	h2: {
		fontSize: pxToRem(28), // 1.75rem
		fontWeight: 700,
		lineHeight: 1.3,
		letterSpacing: '-0.00833em',
		textAlign: 'center',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(24), // 1.5rem
		},
	},

	h3: {
		fontSize: pxToRem(24), // 1.5rem
		fontWeight: 700,
		lineHeight: 1.4,
		letterSpacing: '0em',
		textAlign: 'center',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(20), // 1.25rem
		},
	},

	h4: {
		fontSize: pxToRem(19.2), // 1.2rem
		fontWeight: 700,
		lineHeight: 1.4,
		letterSpacing: '0em',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(16), // 1rem
		},
	},

	h5: {
		fontSize: pxToRem(16), // 1rem
		fontWeight: 600,
		lineHeight: 1.5,
		letterSpacing: '0.00938em',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(14), // 0.875rem
		},
	},

	body1: {
		fontSize: pxToRem(16), // 1rem
		lineHeight: 1.6,
		letterSpacing: '0.00938em',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(14.4), // 0.9rem
		},
	},

	body2: {
		fontSize: pxToRem(14), // 0.875rem
		lineHeight: 1.5,
		letterSpacing: '0.01071em',
		color: theme.palette.text.secondary,
		marginBottom: theme.spacing(1.875), // 15px
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(12.8), // 0.8rem
		},
	},

	body3: {
		fontSize: pxToRem(20), // 1.25rem
		lineHeight: 1.5,
		letterSpacing: '0.01071em',
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(16), // 1rem
		},
	},

	button: {
		textTransform: 'none',
		fontWeight: 600,
		fontSize: pxToRem(14), // 0.875rem
		letterSpacing: '0.02857em',
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(12), // 0.75rem
		},
	},

	caption: {
		fontSize: pxToRem(12), // 0.75rem
		lineHeight: 1.35,
		letterSpacing: '0.03333em',
		fontWeight: 400,
		color: theme.palette.text.secondary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(10.4), // 0.65rem
		},
	},

	subtitle1: {
		fontSize: pxToRem(16), // 1rem
		lineHeight: 1.75,
		letterSpacing: '0.00938em',
		fontWeight: 500,
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(14.4), // 0.9rem
		},
	},

	subtitle2: {
		fontSize: pxToRem(14), // 0.875rem
		lineHeight: 1.57,
		letterSpacing: '0.00714em',
		fontWeight: 500,
		color: theme.palette.text.primary,
		[theme.breakpoints.down('sm')]: {
			fontSize: pxToRem(12.8), // 0.8rem
		},
	},
});

export default typography;
