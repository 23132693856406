// src/components/modules/chatInterface/subcomponents/navigationTabs/ChatFiles.jsx

import React, { useState, useEffect } from 'react';
import {
	Box,
	Grid,
	Typography,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	CircularProgress,
	Alert,
} from '@mui/material';
import FileCard from './FileCard';
import FilePreviewModal from './FilePreviewModal';
import axios from 'axios';

const ChatFiles = () => {
	const [files, setFiles] = useState([]);
	const [filteredFiles, setFilteredFiles] = useState([]);
	const [previewFile, setPreviewFile] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [filterType, setFilterType] = useState('All');
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	// Fetch files from backend
	useEffect(() => {
		const fetchFiles = async () => {
			try {
				const accessToken =
					localStorage.getItem('accessToken') ||
					sessionStorage.getItem('accessToken');
				const response = await axios.get(
					`${process.env.REACT_APP_API_URL}/api/files`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					}
				);
				// Assuming the response data is an array of files with id, name, type, and url
				setFiles(response.data);
				setFilteredFiles(response.data);
				setLoading(false);
			} catch (err) {
				console.error('Error fetching files:', err);
				if (err.response) {
					setError(`Failed to load files: ${err.response.data.message}`);
				} else if (err.request) {
					setError('Failed to load files: No response from server.');
				} else {
					setError(`Failed to load files: ${err.message}`);
				}
				setLoading(false);
			}
		};

		fetchFiles();
	}, []);

	// Filter files based on search and filterType
	useEffect(() => {
		const filter = () => {
			const matchesSearch = (file) =>
				file.name.toLowerCase().includes(searchQuery.toLowerCase());
			const matchesType = (file) =>
				filterType === 'All' || file.type.toLowerCase() === filterType.toLowerCase();

			const filtered = files.filter(
				(file) => matchesSearch(file) && matchesType(file)
			);
			setFilteredFiles(filtered);
		};

		filter();
	}, [searchQuery, filterType, files]);

	const handlePreview = (file) => {
		setPreviewFile(file);
	};

	const handleClosePreview = () => {
		setPreviewFile(null);
	};

	if (loading) {
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="100%">
				<CircularProgress color="primary" />
			</Box>
		);
	}

	if (error) {
		return (
			<Box p={3} sx={{ backgroundColor: 'background.default' }}>
				<Alert severity="error">{error}</Alert>
			</Box>
		);
	}

	return (
		<Box p={3} sx={{ backgroundColor: 'background.default' }}>
			<Typography variant="h4" sx={{ mb: 2 }} gutterBottom>
				Files
			</Typography>

			{/* Search and Filter Controls */}
			<Box display="flex" alignItems="center" mb={3} flexWrap="wrap" gap={2}>
				<TextField
					label="Search Files"
					variant="outlined"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					sx={{ flex: 1, minWidth: '200px' }}
				/>
				<FormControl variant="outlined" sx={{ minWidth: '150px' }}>
					<InputLabel id="filter-type-label">Filter by Type</InputLabel>
					<Select
						labelId="filter-type-label"
						value={filterType}
						label="Filter by Type"
						onChange={(e) => setFilterType(e.target.value)}
					>
						<MenuItem value="All">All</MenuItem>
						<MenuItem value="DOCX">DOCX</MenuItem>
						<MenuItem value="PDF">PDF</MenuItem>
						<MenuItem value="XLSX">XLSX</MenuItem>
						{/* Add more file types as needed */}
					</Select>
				</FormControl>
			</Box>

			{/* Files Grid */}
			<Grid container spacing={3}>
				{filteredFiles.length > 0 ? (
					filteredFiles.map((file) => (
						<Grid item xs={12} sm={6} md={4} key={file.id}>
							<FileCard file={file} onPreview={handlePreview} />
						</Grid>
					))
				) : (
					<Grid item xs={12}>
						<Typography variant="body2" color="text.secondary">
							No files found.
						</Typography>
					</Grid>
				)}
			</Grid>

			{/* File Preview Modal */}
			{previewFile && (
				<FilePreviewModal file={previewFile} onClose={handleClosePreview} />
			)}
		</Box>
	);
};

export default ChatFiles;
