// index.js
import React, { useContext } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './themes';
import { SessionProvider } from './context/SessionContext';
import { SocketProvider } from './context/SocketContext';
import { ConversationProvider } from './context/ConversationContext';
import { ArtistProvider } from './context/ArtistContext';
import { ColorModeContextProvider } from './context/ColorModeContext';
import { UserProvider } from './context/UserContext';
import LoadingWrapper from './context/LoadingWrapper';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<SessionProvider>
			<ArtistProvider>
				<UserProvider>
					<SocketProvider>
						<ConversationProvider>
							<ColorModeContextProvider>
								<ThemeProvider theme={theme}>
									<CssBaseline />
									<Router>
										<LoadingWrapper>
											<App />
										</LoadingWrapper>
									</Router>
								</ThemeProvider>
							</ColorModeContextProvider>
						</ConversationProvider>
					</SocketProvider>
				</UserProvider>
			</ArtistProvider>
		</SessionProvider>
	</React.StrictMode>
);

reportWebVitals();
