// src/utils/campaignsConfig.js

import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import FestivalIcon from '@mui/icons-material/Festival';

export const campaignTypes = [
	{
		id: 'new_music_release',
		title: 'New Music Release',
		description: 'Strategize a campaign for releasing new music.',
		icon: LibraryMusicIcon,
		goalName: 'New Music Release',
	},
];
