// src/components/artistOnboarding/step5/CareerProfileRecap.js

import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import RouteIcon from '@mui/icons-material/Route';
import BadgeIcon from '@mui/icons-material/Badge';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useTheme } from '@mui/material/styles';

const CareerProfileRecap = ({
	careerStageLabel,
	careerPath,
	currentMonthlyListeners,
	currentTotalFollowers,
}) => {
	const theme = useTheme();
	return (
		<Card
			sx={{
				backgroundColor: 'background.paper',
				boxShadow: 3,
				borderRadius: 2,
				height: '100%',
			}}
		>
			<CardContent>
				<Typography
					variant="h6"
					sx={{
						...theme.typography.h4,
						mb: 3,
						textAlign: 'center',
						color: 'primary.main',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 1,
					}}
				>
					<BadgeIcon /> Career Profile
				</Typography>
				<Grid container spacing={2}>
					{/* Career Stage */}
					<Grid item xs={12} sm={12}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<EmojiEventsIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Career Stage
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{careerStageLabel}
						</Typography>
					</Grid>

					{/* Career Path */}
					<Grid item xs={12} sm={12}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<RouteIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Career Path
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{careerPath}
						</Typography>
					</Grid>

					{/* Current Monthly Listeners */}
					<Grid item xs={12} sm={6}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<TrendingUpIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Monthly Listeners
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{Number(currentMonthlyListeners).toLocaleString()}
						</Typography>
					</Grid>

					{/* Current Total Social Followers */}
					<Grid item xs={12} sm={6}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<PeopleIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Social Following
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{Number(currentTotalFollowers).toLocaleString()}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default CareerProfileRecap;
