// components/modules/chatInterface/ChatInterface.jsx

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, CircularProgress, Alert, Grid } from '@mui/material';
import { Container } from '@mui/system';
import ChatHeader from './ChatHeader';
import NavigationTabs from './NavigationTabs';
import ChatOverview from './subcomponents/navigationTabs/ChatOverview';
import ChatFiles from './subcomponents/navigationTabs/ChatFiles';
import ChatSettings from './subcomponents/navigationTabs/ChatSettings';
import ChatWindow from './ChatWindow';
import InputArea from './InputArea';
import SuggestedPromptsSidebar from './subcomponents/SuggestedPromptsSidebar';
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom';
import {
	joinConversation,
	sendMessage as sendSocketMessage,
	setChatHistoryHandler,
	setNewMessageHandler,
	setErrorHandler,
} from '../../../utils/socket';
import { getUserSession } from '../../../utils/apis/auth/sessionUtils';
import { useSocket } from '../../../context/SocketContext';
import { ConversationContext } from '../../../context/ConversationContext';
import ErrorBoundary from '../../common/ErrorBoundary';

const ChatInterface = () => {
	const [tabIndex, setTabIndex] = useState(1);
	const [messages, setMessages] = useState([]);
	const [userMessage, setUserMessage] = useState('');
	const [error, setError] = useState(null);
	const [suggestedPrompts, setSuggestedPrompts] = useState([]);
	const [hasUserMessage, setHasUserMessage] = useState(false);
	const showSidebar = false;
	const handlersRegisteredRef = useRef(false);
	const { conversationId } = useParams();
	const { userId } = getUserSession();

	const {
		contextData,
		loading: contextLoading,
		error: contextError,
	} = useContext(ConversationContext);

	const socket = useSocket();
	const messagesEndRef = useRef(null);

	// Scroll to the dummy element whenever messages or tabIndex change
	useEffect(() => {
		if (tabIndex === 1 && messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'nearest',
			});
		}
	}, [messages, tabIndex]);

	// Initialize Socket.io connection
	useEffect(() => {
		if (
			socket?.connected &&
			conversationId &&
			userId &&
			!handlersRegisteredRef.current
		) {
			handlersRegisteredRef.current = true;
			// Set up handlers
			setChatHistoryHandler((history) => {
				setMessages(history);
				if (
					history.length > 0 &&
					history[history.length - 1].sender !== 'assistant'
				) {
					const typingMessage = {
						id: uuidv4(),
						sender: 'assistant',
						type: 'typing',
						text: 'AI is typing...',
						created_at: new Date(),
					};
					setMessages((prevMessages) => [...prevMessages, typingMessage]);
				}
			});

			setNewMessageHandler((message) => {
				if (message.sender !== 'assistant') {
					// Ignore messages from the user to prevent duplication
					return;
				}

				setMessages((prevMessages) => {
					// Check if the last message is a typing indicator
					const lastMessage = prevMessages[prevMessages.length - 1];
					if (lastMessage && lastMessage.type === 'typing') {
						// Replace the typing message with the actual AI message
						return [
							...prevMessages.slice(0, -1),
							{
								...message,
								id: uuidv4(), // Ensure unique ID
								created_at: new Date().toISOString(),
							},
						];
					} else {
						// If no typing indicator, just append the message
						return [
							...prevMessages,
							{ ...message, id: uuidv4(), created_at: new Date().toISOString() },
						];
					}
				});
			});

			// Listen for 'removeTypingIndicator' event
			socket.on('removeTypingIndicator', ({ id }) => {
				if (id) {
					setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== id));
				} else {
					// If no specific ID is provided, remove all typing indicators
					setMessages((prevMessages) =>
						prevMessages.filter((msg) => msg.type !== 'typing')
					);
				}
			});

			setErrorHandler((error) => {
				console.error('Handling socket error:', error);
				setError(error);

				// Optionally remove typing indicator on error
				setMessages((prevMessages) =>
					prevMessages.filter((msg) => msg.type !== 'typing')
				);
			});

			// Join the conversation
			joinConversation(conversationId);
		}

		if (tabIndex === 1 && messagesEndRef.current) {
			messagesEndRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});
		}

		// Cleanup on unmount
		return () => {
			handlersRegisteredRef.current = false;
			if (socket) {
				socket.off('removeTypingIndicator');
			}
			// Optionally reset other handlers
			setChatHistoryHandler(null);
			setNewMessageHandler(null);
			setErrorHandler(null);
		};
	}, [socket?.connected, conversationId, userId]);

	// Initialize suggested prompts
	useEffect(() => {
		setSuggestedPrompts([
			{
				id: uuidv4(),
				title: 'Audience Targeting Plan',
				description: `Create a detailed audience targeting plan for the new single, identifying demographics, psychographics, and behavior patterns.`,
				purpose: 'Identify and engage the right audience for maximum impact.',
				expectedOutput: 'A comprehensive targeting strategy document.',
				prompt: `Using the provided artist and audience information, create a detailed audience targeting plan for the new single. 
  Identify demographics, psychographics, and behavior patterns, and suggest the best ways to reach and engage this audience.`,
				icon: '🎯',
			},
			// ... Add more prompts as needed
		]);
	}, []);

	// Handle selection of a suggested prompt
	const handleSelectPrompt = async (promptObj) => {
		if (!conversationId) {
			setError('Conversation ID is missing. Please start a new conversation.');
			return;
		}

		setError(null);

		// Add user message
		const userMsg = {
			id: uuidv4(),
			sender: 'user',
			type: 'text',
			text: promptObj.prompt,
			created_at: new Date().toISOString(),
		};

		setMessages((prevMessages) => [...prevMessages, userMsg]);

		try {
			// Send the selected prompt via Socket.io
			sendSocketMessage(conversationId, userId, promptObj.prompt);
			setHasUserMessage(true);
		} catch (err) {
			console.error('Error sending prompt:', err);
			setError('Failed to send prompt. Please try again.');
		}
	};

	// Handle sending a user message
	const handleSendMessage = async () => {
		if (!userMessage.trim()) return; // Avoid sending empty messages
		if (!conversationId) {
			setError('Conversation ID is missing. Please start a new conversation.');
			return;
		}

		setError(null);

		// Add user message
		const userMsg = {
			id: uuidv4(),
			sender: 'user',
			type: 'text',
			text: userMessage,
			created_at: new Date().toISOString(),
		};

		setMessages((prevMessages) => [...prevMessages, userMsg]);

		try {
			// Send the user message via Socket.io
			sendSocketMessage(conversationId, userId, userMessage);
			setUserMessage('');
			setHasUserMessage(true);
		} catch (err) {
			console.error('Error sending message:', err);
			setError('Failed to send message. Please try again.');
		}
	};

	// Handle tab change
	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	if (contextLoading) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					backgroundColor: 'background.default',
				}}
			>
				<CircularProgress color="primary" />
			</Box>
		);
	}

	if (contextError) {
		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
					backgroundColor: 'background.default',
					padding: 2,
				}}
			>
				<Alert severity="error">{contextError}</Alert>
			</Box>
		);
	}

	return (
		<ErrorBoundary>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '94.5vh',
					backgroundColor: 'background.default',
				}}
			>
				<Box
					sx={{
						position: 'sticky',
						top: 0,
						zIndex: 10,
						backgroundColor: 'background.paper',
					}}
				>
					{/* Header */}
					<ChatHeader
						campaignTitle={contextData?.project_name || 'Campaign Title'}
						campaignGoal={contextData?.marketing_goal || 'Default Goal'}
					/>

					{/* Navigation Tabs */}
					<NavigationTabs value={tabIndex} handleChange={handleTabChange} />
				</Box>

				{/* Tab Content */}
				<Grid container sx={{ flexGrow: 1, overflow: 'hidden' }}>
					{tabIndex === 1 && (
						<Grid
							item
							xs={12}
							md={showSidebar && hasUserMessage ? 8 : 12}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
							}}
						>
							<Box sx={{ flexGrow: 1, overflow: 'auto' }}>
								<Container
									sx={{
										maxWidth: { xs: 'sm', md: 'lg' },
										display: 'flex',
										flexDirection: 'column',
										flexGrow: 1,
									}}
								>
									<ChatWindow messages={messages} messagesEndRef={messagesEndRef} />

									{error && (
										<Alert severity="error" sx={{ my: 2 }}>
											{error}
										</Alert>
									)}
								</Container>
							</Box>
							<Box>
								<InputArea
									userMessage={userMessage}
									setUserMessage={setUserMessage}
									onSend={handleSendMessage}
									loading={false}
								/>
							</Box>
						</Grid>
					)}
					{showSidebar && hasUserMessage && (
						<Grid
							item
							xs={12}
							md={4}
							sx={{
								borderLeft: { xs: 'none', md: '1px solid' },
								borderColor: 'divider',
								height: '100%',
								overflowY: 'auto',
								padding: 2,
								backgroundColor: 'background.paper',
							}}
						>
							<SuggestedPromptsSidebar
								prompts={suggestedPrompts}
								onSelect={handleSelectPrompt}
							/>
						</Grid>
					)}

					{/* Other Tabs Content */}
					{tabIndex === 0 && (
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								padding: 2,
								overflowY: 'auto',
							}}
						>
							<ChatOverview conversationContext={contextData} />
						</Grid>
					)}
					{tabIndex === 2 && (
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								padding: 2,
								overflowY: 'auto',
							}}
						>
							<ChatFiles />
						</Grid>
					)}
					{tabIndex === 3 && (
						<Grid
							item
							xs={12}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								height: '100%',
								padding: 2,
								overflowY: 'auto',
							}}
						>
							<ChatSettings />
						</Grid>
					)}
				</Grid>
			</Box>
		</ErrorBoundary>
	);
};

export default ChatInterface;
