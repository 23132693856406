// components/modules/chatInterface/subcomponents/MessageContent.jsx

import React from 'react';
import PropTypes from 'prop-types';
import OutputRenderer from './OutputRenderer';

const MessageContent = React.memo(({ message }) => {
	return <OutputRenderer message={message} />;
});

MessageContent.propTypes = {
	message: PropTypes.shape({
		id: PropTypes.string.isRequired,
		sender: PropTypes.oneOf(['user', 'assistant']).isRequired,
		type: PropTypes.string.isRequired,
		text: PropTypes.string,
		items: PropTypes.arrayOf(PropTypes.string),
		url: PropTypes.string,
		alt: PropTypes.string,
		data: PropTypes.object,
		fileUrl: PropTypes.string,
		fileName: PropTypes.string,
		created_at: PropTypes.string,
	}).isRequired,
};

export default MessageContent;
