import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Grid, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SpotifyArtistCard from '../../subcomponents/SpotifyArtistCard';
import { useTheme } from '@mui/material/styles';
import SpotifyData from './SpotifyData';

const SpotifyProfile = ({ artistData: spotify, onSave, onComplete, onCancel }) => {
	const theme = useTheme();
	const [searchResults, setSearchResults] = useState([]);
	const [spotifyProfile, setSpotifyProfile] = useState({
		selectedProfile: {},
		analyticsData: {},
	});
	const [step, setStep] = useState(1);

	const formik = useFormik({
		initialValues: {
			searchTerm: spotify?.artist?.stageName || '',
		},
		validationSchema: Yup.object({
			searchTerm: Yup.string().required('Search term is required'),
		}),
		onSubmit: (values) => {
			searchSpotifyProfiles(values.searchTerm);
		},
	});

	useEffect(() => {
		if (spotify) {
			setSpotifyProfile(spotify);
		}
	}, [spotify]);

	const mapArtistData = (artist) => ({
		spotifyArtistId: artist.id || artist.spotifyArtistId,
		name: artist.name || '',
		externalUrl: artist.external_urls?.spotify || artist.externalUrl || '',
		followers: artist.followers?.total || artist.followers || 0,
		genres: artist.genres ? artist.genres.slice(0, 5) : [],
		imageUrl: artist.images?.[0]?.url || artist.imageUrl || '/placeholder.jpg',
	});

	const searchSpotifyProfiles = async (searchTerm) => {
		try {
			const accessToken =
				sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

			if (!accessToken) {
				console.error('Failed to get access token');
				return;
			}

			const apiUrl = `${
				process.env.REACT_APP_API_URL
			}/api/spotify/search?q=${encodeURIComponent(searchTerm)}&type=artist`;
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			});

			if (!response.ok) {
				const errorText = await response.text();
				console.error('Error fetching Spotify profiles:', errorText);
				throw new Error(errorText || 'Failed to search Spotify profiles');
			}

			const data = await response.json();
			setSearchResults(data.artists.items);
		} catch (error) {
			console.error('Error fetching Spotify profiles:', error);
		}
	};

	const toggleSelectProfile = (profile) => {
		if (profile.id) {
			const mappedProfile = mapArtistData(profile);
			setSpotifyProfile({
				selectedProfile: mappedProfile,
				...(spotifyProfile.analyticsData || {}),
			});
		} else {
			setSpotifyProfile({ selectedProfile: {}, analyticsData: {} });
		}
	};

	const handleNext = () => {
		if (spotifyProfile?.selectedProfile?.spotifyArtistId) {
			onSave(spotifyProfile);
			setStep(2);
		} else {
			alert('Please select a Spotify profile.');
		}
	};

	const handleSaveAndComplete = (updatedData) => {
		const updatedSpotifyProfile = {
			...spotifyProfile,
			analyticsData: updatedData,
		};
		setSpotifyProfile(updatedSpotifyProfile);
		onSave(updatedSpotifyProfile);
		onComplete();
	};

	return (
		<Box
			sx={{
				width: '100%',
				maxWidth: '900px',
				minWidth: '600px',
				p: 2,
			}}
		>
			{step === 1 && (
				<Box className="spotify-profile-popup">
					<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
						Search Spotify Artist Profile
					</Typography>

					<form onSubmit={formik.handleSubmit}>
						<Box display="flex" justifyContent="center" mb={2}>
							<TextField
								fullWidth
								name="searchTerm"
								placeholder="Search Spotify Artist"
								value={formik.values.searchTerm}
								onChange={formik.handleChange}
								sx={{ maxWidth: '300px' }}
								error={
									formik.touched.searchTerm && Boolean(formik.errors.searchTerm)
								}
								helperText={formik.touched.searchTerm && formik.errors.searchTerm}
							/>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								sx={{ ml: 2 }}
							>
								Search
							</Button>
						</Box>
					</form>

					<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
						Search Results
					</Typography>

					<Box
						className="search-results-container"
						mb={3}
						sx={{ maxHeight: '400px', overflowY: 'auto' }}
					>
						<Grid container spacing={2} justifyContent="center">
							{searchResults.length === 0 ? (
								<Grid item xs={12}>
									<Typography
										variant="body2"
										sx={{ ...theme.typography.body2, textAlign: 'center' }}
									>
										No results found.
									</Typography>
								</Grid>
							) : (
								searchResults.map((artist) => (
									<Grid item key={artist.id}>
										<SpotifyArtistCard
											artist={artist}
											selected={
												spotifyProfile?.selectedProfile?.spotifyArtistId ===
												artist.id
											}
											onSelect={() => toggleSelectProfile(artist)}
											type="result"
										/>
									</Grid>
								))
							)}
						</Grid>
					</Box>

					<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
						Selected Spotify Profile
					</Typography>
					<Grid container spacing={2} justifyContent="center">
						<Grid item>
							{spotifyProfile?.selectedProfile?.spotifyArtistId ? (
								<SpotifyArtistCard
									artist={spotifyProfile.selectedProfile}
									selected
									onSelect={() =>
										toggleSelectProfile(spotifyProfile.selectedProfile)
									}
									type="selected"
								/>
							) : (
								<Box
									sx={{
										width: '250px',
										height: '100%',
										minHeight: '300px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: '1px dashed #ccc',
										borderRadius: '8px',
										backgroundColor: '#f9f9f9',
									}}
								>
									<Typography variant="body2" color="textSecondary">
										No Profile Selected
									</Typography>
								</Box>
							)}
						</Grid>
					</Grid>

					<Box display="flex" justifyContent="center" mt={2} gap={2}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ...theme.typography.button }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleNext}
							disabled={!spotifyProfile?.selectedProfile?.spotifyArtistId}
							sx={{ ...theme.typography.button }}
						>
							Save & Next
						</Button>
					</Box>
				</Box>
			)}
			{step === 2 && (
				<Box className="spotify-profile-popup">
					<SpotifyData
						artistData={spotifyProfile.analyticsData}
						onSave={handleSaveAndComplete}
						onCancel={onCancel}
					/>
				</Box>
			)}
		</Box>
	);
};

export default SpotifyProfile;
