// src/components/modules/chatInterface/subcomponents/navigationTabs/OverviewSummary.jsx

import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import CampaignIcon from '@mui/icons-material/Campaign';

const campaignPurpose = {
	'New Music Release':
		'This campaign is designed to maximize the reach and engagement of your new music release. It leverages targeted marketing strategies to increase streams, followers, and overall visibility across various platforms.',
	'Upcoming Tour':
		'This campaign focuses on promoting your upcoming tour, ensuring maximum attendance and engagement across all tour locations. It utilizes strategic advertising and social media promotion to boost ticket sales and fan interaction.',
};

const OverviewSummary = ({ campaignType }) => {
	return (
		<Card
			sx={{
				mb: 3,
				borderRadius: 2,
				boxShadow: 3,
				':hover': {
					transform: 'none',
				},
			}}
		>
			<CardContent>
				<Box display="flex" alignItems="center" mb={2}>
					<CampaignIcon color="primary" fontSize="large" />
					<Typography variant="h4" ml={1} sx={{ fontWeight: 'bold' }}>
						Campaign Overview
					</Typography>
				</Box>
				<Typography variant="body1">
					<strong>Type:</strong> {campaignType}
				</Typography>
				<Typography variant="body2" mt={1}>
					{campaignPurpose[campaignType] ||
						'This campaign is designed to achieve your specific marketing goals using AI-driven insights and strategies.'}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default OverviewSummary;
