// src/components/modules/chatInterface/subcomponents/navigationTabs/FilePreviewModal.jsx

import React, { useState } from 'react';
import {
	Modal,
	Box,
	Typography,
	IconButton,
	CircularProgress,
	Fade,
	Backdrop,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = (theme) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	maxHeight: '80vh',
	overflowY: 'auto',
	borderRadius: 2,
});

const FilePreviewModal = ({ file, onClose }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);

	const handleLoad = () => {
		setLoading(false);
	};

	const handleError = () => {
		setLoading(false);
		setError(true);
	};

	const renderPreview = () => {
		switch (file.type.toUpperCase()) {
			case 'PDF':
				return (
					<iframe
						src={file.previewUrl}
						title="PDF Preview"
						width="100%"
						height="600px"
						onLoad={handleLoad}
						onError={handleError}
						style={{ border: 'none' }}
					></iframe>
				);
			case 'DOCX':
			case 'XLSX':
				return (
					<iframe
						src={file.previewUrl}
						title={`${file.type.toUpperCase()} Preview`}
						width="100%"
						height="600px"
						onLoad={handleLoad}
						onError={handleError}
						style={{ border: 'none' }}
					></iframe>
				);
			default:
				return <Typography>Preview not available for this file type.</Typography>;
		}
	};

	return (
		<Modal
			open={Boolean(file)}
			onClose={onClose}
			aria-labelledby="file-preview-title"
			aria-describedby="file-preview-description"
			closeAfterTransition
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={Boolean(file)}>
				<Box sx={(theme) => style(theme)}>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						mb={2}
					>
						<Typography id="file-preview-title" variant="h4" component="h2">
							{file.name} Preview
						</Typography>
						<IconButton onClick={onClose}>
							<CloseIcon />
						</IconButton>
					</Box>
					{/* Loading Spinner */}
					{loading && (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="600px"
						>
							<CircularProgress color="primary" />
						</Box>
					)}
					{/* Error Message */}
					{error && (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="600px"
						>
							<Typography color="error">
								Failed to load the preview. Please try again later.
							</Typography>
						</Box>
					)}
					{/* Preview Content */}
					{!error && renderPreview()}
				</Box>
			</Fade>
		</Modal>
	);
};

export default FilePreviewModal;
