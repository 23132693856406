import React, { useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Typography,
	Paper,
	Box,
	Grid,
	Divider,
} from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import YouTubeData from './YouTubeData';
import { useTheme } from '@mui/material/styles';

const YouTubeProfile = ({ artistData: youtube, onSave, onComplete, onCancel }) => {
	const theme = useTheme();
	const [youtubeProfile, setYoutubeProfile] = useState({
		selectedProfile: {},
		analyticsData: {},
	});
	const [step, setStep] = useState(1);

	// CTA Button to YouTube Analytics Page
	const youtubeURL = 'https://youtube.com/';

	// Validation schema using Yup
	const validationSchemaStep1 = Yup.object().shape({
		channelName: Yup.string()
			.trim()
			.matches(
				/^[a-zA-Z0-9\s\-.,'&]+$/,
				'Channel Name can only contain letters, numbers, spaces, and basic punctuation.'
			)
			.min(3, 'Channel Name must be at least 3 characters long.')
			.max(50, 'Channel Name cannot exceed 50 characters.')
			.required('YouTube Channel Name is required.'),
		channelUrl: Yup.string()
			.trim()
			.url('Enter a valid URL.')
			.matches(
				/^https:\/\/(www\.)?youtube\.com\/(channel\/|user\/|c\/|@)[a-zA-Z0-9_-]+$/,
				'URL must start with https://www.youtube.com/channel/, https://www.youtube.com/user/, https://www.youtube.com/c/, or https://www.youtube.com/@.'
			)
			.required('YouTube Channel URL is required.'),
		subscribers: Yup.number()
			.typeError('Subscribers must be a valid number')
			.integer('Subscribers must be an integer')
			.min(0, 'Subscribers cannot be negative')
			.required('Subscribers is required'),
		watchTime: Yup.number()
			.typeError('Watch Time must be a valid number')
			.integer('Watch Time must be an integer')
			.min(0, 'Watch Time cannot be negative')
			.required('Watch Time is required'),
		views: Yup.number()
			.typeError('Views must be a valid number')
			.integer('Views must be an integer')
			.min(0, 'Views cannot be negative')
			.required('Views is required'),
	});

	// Initialize Formik
	const formik = useFormik({
		initialValues: {
			channelName: youtube?.selectedProfile?.channelName || '',
			channelUrl: youtube?.selectedProfile?.channelUrl || '',
			subscribers: youtube?.selectedProfile?.subscribers || '',
			watchTime: youtube?.selectedProfile?.watchTime || '',
			views: youtube?.selectedProfile?.views || '',
		},
		validationSchema: validationSchemaStep1,
		onSubmit: (values) => {
			const updatedProfile = {
				selectedProfile: values,
				analyticsData: youtubeProfile.analyticsData,
			};
			setYoutubeProfile(updatedProfile);
			onSave(updatedProfile);
			formik.setSubmitting(false);
			setStep(2);
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		if (youtube) {
			setYoutubeProfile(youtube);
		}
	}, [youtube]);

	// Handle saving both selected profile and analytics data
	const handleSaveAndComplete = (updatedData) => {
		const updatedYoutubeProfile = {
			...youtubeProfile,
			analyticsData: updatedData,
		};
		setYoutubeProfile(updatedYoutubeProfile);
		onSave(updatedYoutubeProfile);
		onComplete();
	};

	return (
		<FormikProvider value={formik}>
			{step === 1 && (
				<Box
					sx={{
						width: '100%',
						maxWidth: '600px',
						margin: '0 auto',
						padding: 2,
						backgroundColor: 'background.paper',
						borderRadius: 2,
					}}
				>
					<form onSubmit={formik.handleSubmit}>
						{/* Header Section */}
						<Box textAlign="center" mb={4}>
							<Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
								YouTube Profile Data
							</Typography>
							<Button
								variant="contained"
								color="primary"
								href={youtubeURL}
								target="_blank"
								sx={{ ...theme.typography.button, mb: 2 }}
							>
								Go to YouTube
							</Button>
							<Divider sx={{ mb: 2 }} />
						</Box>

						{/* Input Fields */}
						<Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label="YouTube Channel Name"
										name="channelName"
										value={formik.values.channelName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.channelName &&
											Boolean(formik.errors.channelName)
										}
										helperText={
											formik.touched.channelName && formik.errors.channelName
										}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label="YouTube Channel URL"
										name="channelUrl"
										value={formik.values.channelUrl}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.channelUrl && Boolean(formik.errors.channelUrl)
										}
										helperText={
											formik.touched.channelUrl && formik.errors.channelUrl
										}
										variant="outlined"
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2} sx={{ mt: 0.25 }}>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label="Subscribers"
										name="subscribers"
										value={formik.values.subscribers}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.subscribers &&
											Boolean(formik.errors.subscribers)
										}
										helperText={
											formik.touched.subscribers && formik.errors.subscribers
										}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label="Views"
										name="views"
										value={formik.values.views}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.views && Boolean(formik.errors.views)}
										helperText={formik.touched.views && formik.errors.views}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										fullWidth
										label="Watch Time"
										name="watchTime"
										value={formik.values.watchTime}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.watchTime && Boolean(formik.errors.watchTime)
										}
										helperText={formik.touched.watchTime && formik.errors.watchTime}
										variant="outlined"
									/>
								</Grid>
							</Grid>
						</Paper>

						{/* Next Button */}
						<Box
							textAlign="center"
							display="flex"
							justifyContent="center"
							mt={2}
							gap={2}
						>
							<Button
								variant="outlined"
								color="secondary"
								onClick={onCancel}
								sx={{ ...theme.typography.button }}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={!formik.isValid || formik.isSubmitting}
								size="large"
								sx={{ ...theme.typography.button }}
							>
								Save & Next
							</Button>
						</Box>
					</form>
				</Box>
			)}

			{step === 2 && (
				<YouTubeData
					artistData={youtubeProfile.analyticsData}
					onSave={handleSaveAndComplete}
					onCancel={onCancel}
				/>
			)}
		</FormikProvider>
	);
};

export default YouTubeProfile;
