import React, { useState, useEffect } from 'react';
import {
	Button,
	Typography,
	Grid,
	Card,
	CardContent,
	CardActionArea,
	List,
	ListItem,
	Box,
	Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
const stages = [
	{
		stageId: 'early_career',
		label: 'Early Career Artist',
		overview:
			'An artist building the foundation of their career, focusing on developing their sound, brand, and gaining early exposure.',
		metrics: [
			'Monthly Listeners: Less than 250K',
			'Social Media Followers: 1K - 50K',
			'Few streaming milestones',
			'Limited media coverage',
			'Occasional playlist features',
		],
	},
	{
		stageId: 'emerging_artist',
		label: 'Emerging Artist',
		overview:
			'An artist gaining traction, consistently releasing quality music, and starting to gain more recognition.',
		metrics: [
			'Monthly Listeners: 250K - 2M',
			'Social Media Followers: 50K - 500K',
			'Featured on editorial playlists',
			'Increasing media coverage',
			'Successful single/EP releases',
		],
	},
	{
		stageId: 'established_artist',
		label: 'Established Artist',
		overview:
			'A mainstream artist with significant industry recognition, a large, loyal fanbase, and multiple successful releases.',
		metrics: [
			'Monthly Listeners: Over 2M',
			'Social Media Followers: 500K+',
			'Major playlist placements',
			'Frequent media features',
			'Charting singles/albums',
			'Sold-out tours',
		],
	},
];

const CareerStage = ({ artistData: careerStage, onSave }) => {
	const theme = useTheme();
	const [selectedStage, setSelectedStage] = useState('');

	// Initialize selectedStage from artistData if available
	useEffect(() => {
		if (careerStage && careerStage.stageId) {
			const matchedStage = stages.find(
				(stage) => stage.stageId === careerStage.stageId
			);
			setSelectedStage(matchedStage);
		}
	}, [careerStage]);

	// Save the selected career stage
	const handleSave = () => {
		if (selectedStage) {
			const updatedData = {
				stageId: selectedStage.stageId,
				label: selectedStage.label,
				overview: selectedStage.overview,
				metrics: selectedStage.metrics,
			};
			onSave(updatedData);
		}
	};

	const toggleSelectStage = (stage) => {
		setSelectedStage(selectedStage === stage ? null : stage);
	};

	return (
		<Box sx={{ padding: 4 }}>
			<Typography variant="h4" align="center" sx={{ mb: 2 }}>
				What does each of these stages look like?
			</Typography>
			<Typography
				variant="subtitle1"
				align="center"
				sx={{ ...theme.typography.body1, mb: 2 }}
			>
				Current Career Stage
			</Typography>
			<Grid container spacing={4} justifyContent="center">
				{stages.map((stage) => (
					<Grid item xs={12} sm={6} md={4} key={stage.stageId}>
						<Card
							variant="outlined"
							sx={{
								cursor: 'pointer',
								border: '2px solid',
								borderColor:
									selectedStage && selectedStage.stageId === stage.stageId
										? 'primary.main'
										: 'grey.300',
								height: '100%',
								display: 'flex',
								flexDirection: 'column',
							}}
							onClick={() => toggleSelectStage(stage)}
						>
							<CardActionArea sx={{ height: '100%' }}>
								<CardContent sx={{ flexGrow: 1 }}>
									<Typography variant="h4" align="center" sx={{ mb: 1 }}>
										{stage.label}
									</Typography>
									<Typography variant="subtitle2" align="center" sx={{ mb: 1 }}>
										{stage.overview}
									</Typography>
									<Divider sx={{ mb: 2 }} />
									<Typography
										variant="subtitle2"
										align="center"
										sx={{ fontWeight: 'bold', mb: 1 }}
									>
										Typical Data Metrics at this Stage
									</Typography>
									<List dense>
										{stage.metrics.map((metric, index) => (
											<ListItem key={index} sx={{ py: 0.5 }}>
												<Typography variant="subtitle2">{metric}</Typography>
											</ListItem>
										))}
									</List>
								</CardContent>
							</CardActionArea>
						</Card>
					</Grid>
				))}
			</Grid>
			<Box textAlign="center" sx={{ mt: 2 }}>
				<Button
					onClick={handleSave}
					variant="contained"
					color="primary"
					sx={{ ...theme.typography.button }}
					disabled={!selectedStage}
				>
					Save Selection
				</Button>
			</Box>
		</Box>
	);
};

export default CareerStage;
