// VerifyEmail.jsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { styled } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	minHeight: '60vh',
	padding: theme.spacing(3),
	backgroundColor: theme.palette.background.default,
}));

const MessageTypography = styled(Typography)(({ theme }) => ({
	...theme.typography.h4,
	textAlign: 'center',
	marginBottom: theme.spacing(2),
	color: theme.palette.text.primary,
}));

const ActionButton = styled(Button)(({ theme }) => ({
	marginTop: theme.spacing(2),
	textTransform: 'none',
}));

const VerifyEmail = () => {
	const { token } = useParams();
	const [message, setMessage] = useState('Verifying your email...');
	const navigate = useNavigate();

	useEffect(() => {
		const verifyEmail = async () => {
			try {
				const response = await fetch(
					`${process.env.REACT_APP_API_URL}/api/auth/verify-email/${token}`
				);
				const data = await response.json();

				if (response.ok) {
					setMessage('Email verified successfully! Redirecting to login...');
					setTimeout(() => navigate('/login'), 3000);
				} else {
					setMessage(data.message || 'Email verification failed.');
				}
			} catch (error) {
				setMessage('Error verifying email.');
			}
		};

		verifyEmail();
	}, [token, navigate]);

	return (
		<Container>
			{message.includes('verifying') && <CircularProgress color="primary" />}
			<MessageTypography>{message}</MessageTypography>
			{!message.includes('verifying') && (
				<ActionButton
					variant="contained"
					color="primary"
					onClick={() => navigate('/login')}
				>
					Go to Login
				</ActionButton>
			)}
		</Container>
	);
};

export default VerifyEmail;
