// CampaignsToolbar.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Button,
} from '@mui/material';

const CampaignsToolbar = ({
	searchQuery,
	setSearchQuery,
	statusFilter,
	setStatusFilter,
	goalFilter,
	setGoalFilter,
	sortOption,
	setSortOption,
}) => {
	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				gap: 2,
				alignItems: 'center',
			}}
		>
			<TextField
				label="Search Campaigns"
				variant="outlined"
				value={searchQuery}
				onChange={(e) => setSearchQuery(e.target.value)}
				size="small"
				sx={{ minWidth: 200 }}
			/>

			<FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
				<InputLabel>Status</InputLabel>
				<Select
					label="Status"
					value={statusFilter}
					onChange={(e) => setStatusFilter(e.target.value)}
				>
					<MenuItem value="all">All</MenuItem>
					<MenuItem value="active">Active</MenuItem>
					<MenuItem value="paused">Paused</MenuItem>
					<MenuItem value="completed">Completed</MenuItem>
				</Select>
			</FormControl>

			<FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
				<InputLabel>Goal</InputLabel>
				<Select
					label="Goal"
					value={goalFilter}
					onChange={(e) => setGoalFilter(e.target.value)}
				>
					<MenuItem value="all">All</MenuItem>
					<MenuItem value="New Music Release">New Music Release</MenuItem>
					{/* Add more goals as needed */}
				</Select>
			</FormControl>

			<FormControl variant="outlined" size="small" sx={{ minWidth: 150 }}>
				<InputLabel>Sort By</InputLabel>
				<Select
					label="Sort By"
					value={sortOption}
					onChange={(e) => setSortOption(e.target.value)}
				>
					<MenuItem value="dateDesc">Date Descending</MenuItem>
					<MenuItem value="dateAsc">Date Ascending</MenuItem>
					{/* Add more sort options as needed */}
				</Select>
			</FormControl>

			{/* Add a button to clear filters or perform actions if needed */}
			<Button
				variant="contained"
				onClick={() => {
					setSearchQuery('');
					setStatusFilter('all');
					setGoalFilter('all');
					setSortOption('dateDesc');
				}}
			>
				Reset Filters
			</Button>
		</Box>
	);
};

CampaignsToolbar.propTypes = {
	searchQuery: PropTypes.string.isRequired,
	setSearchQuery: PropTypes.func.isRequired,
	statusFilter: PropTypes.string.isRequired,
	setStatusFilter: PropTypes.func.isRequired,
	goalFilter: PropTypes.string.isRequired,
	setGoalFilter: PropTypes.func.isRequired,
	sortOption: PropTypes.string.isRequired,
	setSortOption: PropTypes.func.isRequired,
};

export default CampaignsToolbar;
