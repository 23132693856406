import axios from 'axios';

// Utility function to get the access token
const getAccessToken = () => {
	return (
		localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken')
	);
};

// Fetch company data by companyId
export const fetchCompanyData = async (companyId) => {
	try {
		const accessToken = getAccessToken();
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/company/${companyId}`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error(
			'Error fetching company data:',
			error.response?.data || error.message
		);
		throw new Error('Failed to load company data.');
	}
};

// Update company data
export const updateCompanyData = async (companyId, updatedData) => {
	try {
		const accessToken = getAccessToken();
		const response = await axios.put(
			`${process.env.REACT_APP_API_URL}/api/company/${companyId}`,
			updatedData,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data.message;
	} catch (error) {
		console.error(
			'Error updating company data:',
			error.response?.data || error.message
		);
		throw new Error('Failed to update company data.');
	}
};
