import React from 'react';
import {
	Button,
	TextField,
	Typography,
	Grid,
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Box,
	Divider,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useFormik, FieldArray, FormikProvider, getIn } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';

const YouTubeData = ({ artistData: youtubeData, onSave, onCancel }) => {
	const theme = useTheme();
	const ageRangeOptions = [
		'13-17',
		'18-24',
		'25-34',
		'35-44',
		'45-54',
		'55-64',
		'65+',
	];
	const suggestedSources = ['Suggested Videos', 'Trending'];

	// Validation schema using Yup
	const validationSchema = Yup.object().shape({
		content: Yup.object().shape({
			videos: Yup.object().shape({
				views: Yup.number()
					.typeError('Views must be a valid number')
					.integer('Views must be an integer')
					.min(0, 'Views cannot be negative')
					.required('Views is required'),
				impressions: Yup.number()
					.typeError('Impressions must be a valid number')
					.integer('Impressions must be an integer')
					.min(0, 'Impressions cannot be negative')
					.required('Impressions is required'),
				impressionsCtr: Yup.number()
					.typeError('Impressions CTR must be a valid number')
					.min(0, 'Impressions CTR cannot be negative')
					.max(100, 'Impressions CTR cannot exceed 100')
					.required('Impressions CTR is required'),
				avgViewDuration: Yup.string()
					.typeError('Average View Duration must be a valid number')
					.matches(
						/^([0-9]?[0-9]):([0-5][0-9])$/,
						'Duration must be in the format mm:ss, with minutes between 0-99 and seconds between 00-59'
					)
					.required('Average View Duration is required'),
				viewerDiscovery: Yup.array()
					.of(
						Yup.object().shape({
							source: Yup.string()
								.typeError('Source must be a string')
								.matches(
									/^[A-Za-z0-9\s().\-\/&']+$/,
									'Source can only contain letters, spaces, parentheses, dashes, periods, forward slashes, ampersands, and apostrophes'
								)
								.required('Source is required'),
							percent: Yup.number()
								.typeError('Percent must be a valid number')
								.min(0, 'Percent cannot be negative')
								.max(100, 'Percent cannot exceed 100')
								.required('Percent is required'),
						})
					)
					.max(5, 'You can add up to 5 viewer discovery sources only')
					.required('Viewer Discovery sources are required')
					.min(1, 'At least 1 viewer discovery source must be added'),
				topVideos: Yup.array()
					.of(
						Yup.object().shape({
							source: Yup.string()
								.typeError('Video Name must be a string')
								.matches(
									/^[A-Za-z0-9\s().\-\/&']+$/,
									'Video Name can only contain letters, spaces, parentheses, dashes, periods, forward slashes, ampersands, and apostrophes'
								)
								.required('Video Name is required'),
							views: Yup.number()
								.typeError('Views must be a valid number')
								.min(0, 'Views cannot be negative')
								.required('Views is required'),
						})
					)
					.max(5, 'You can add up to 5 top videos only')
					.required('Top Videos are required')
					.min(1, 'At least 1 top video must be added'),
			}),
			shorts: Yup.object().shape({
				views: Yup.number()
					.typeError('Views must be a valid number')
					.integer('Views must be an integer')
					.min(0, 'Views cannot be negative')
					.required('Views is required'),
				likes: Yup.number()
					.typeError('Likes must be a valid number')
					.integer('Likes must be an integer')
					.min(0, 'Likes cannot be negative')
					.required('Likes is required'),
				subscribers: Yup.number()
					.typeError('Subscribers must be a valid number')
					.integer('Subscribers must be an integer')
					.min(0, 'Subscribers cannot be negative')
					.required('Subscribers is required'),
				viewerDiscovery: Yup.array()
					.of(
						Yup.object().shape({
							source: Yup.string()
								.typeError('Source must be a string')
								.matches(
									/^[A-Za-z0-9\s().\-\/&']+$/,
									'Source can only contain letters and spaces'
								)
								.required('Source is required'),
							percent: Yup.number()
								.typeError('Percent must be a valid number')
								.min(0, 'Percent cannot be negative')
								.max(100, 'Percent cannot exceed 100')
								.required('Percent is required'),
						})
					)
					.max(5, 'You can add up to 5 viewer discovery sources only')
					.required('Viewer Discovery sources are required')
					.min(1, 'At least 1 viewer discovery source must be added'),
				topShorts: Yup.array()
					.of(
						Yup.object().shape({
							source: Yup.string()
								.typeError('Short Name must be a string')
								.matches(
									/^[A-Za-z0-9\s().\-\/&']+$/,
									'Short Name can only contain letters, spaces, parentheses, dashes, periods, forward slashes, ampersands, and apostrophes'
								)
								.required('Short Name is required'),
							views: Yup.number()
								.typeError('Views must be a valid number')
								.min(0, 'Views cannot be negative')
								.required('Views is required'),
						})
					)
					.max(5, 'You can add up to 5 top shorts only')
					.required('Top Shorts are required')
					.min(1, 'At least 1 top short must be added'),
			}),
			songs: Yup.object().shape({
				topSongs: Yup.array()
					.of(
						Yup.object().shape({
							source: Yup.string()
								.typeError('Song Name must be a string')
								.matches(
									/^[A-Za-z\s().\-\/&']+$/,
									'Song Name can only contain letters, spaces, parentheses, dashes, periods, forward slashes, ampersands, and apostrophes'
								)
								.required('Song Name is required'),
							views: Yup.number()
								.typeError('Views must be a valid number')
								.min(0, 'Views cannot be negative')
								.required('Views is required'),
						})
					)
					.max(5, 'You can add up to 5 top songs only')
					.required('Top Songs are required')
					.min(1, 'At least 1 top song must be added'),
				popularSongsShorts: Yup.array()
					.of(
						Yup.object().shape({
							source: Yup.string()
								.typeError('Song Name must be a string')
								.matches(
									/^[A-Za-z\s().\-\/&']+$/,
									'Song Name can only contain letters, spaces, parentheses, dashes, periods, forward slashes, ampersands, and apostrophes'
								)
								.required('Song Name is required'),
							count: Yup.number()
								.typeError('Shorts Count must be a valid number')
								.min(0, 'Shorts Count cannot be negative')
								.required('Shorts Count is required'),
						})
					)
					.max(5, 'You can add up to 5 popular songs only')
					.required('Popular Songs are required')
					.min(1, 'At least 1 popular song must be added'),
			}),
		}),
		audience: Yup.object().shape({
			returningViewers: Yup.number()
				.typeError('Returning Viewers must be a valid number')
				.integer('Returning Viewers must be an integer')
				.min(0, 'Returning Viewers cannot be negative')
				.required('Returning Viewers is required'),
			uniqueViewers: Yup.number()
				.typeError('Unique Viewers must be a valid number')
				.integer('Unique Viewers must be an integer')
				.min(0, 'Unique Viewers cannot be negative')
				.required('Unique Viewers is required'),
			subscribersChange: Yup.number()
				.typeError('Subscribers Change must be a valid number')
				.integer('Subscribers Change must be an integer')
				.required('Subscribers Change is required'),
			genderPercentM: Yup.number()
				.typeError('Gender Percent (M) must be a valid number')
				.min(0, 'Gender Percent (M) cannot be negative')
				.max(100, 'Gender Percent (M) cannot exceed 100')
				.required('Gender Percent (M) is required'),
			genderPercentF: Yup.number()
				.typeError('Gender Percent (F) must be a valid number')
				.min(0, 'Gender Percent (F) cannot be negative')
				.max(100, 'Gender Percent (F) cannot exceed 100')
				.required('Gender Percent (F) is required'),
			ageRanges: Yup.array()
				.of(
					Yup.object().shape({
						ageRange: Yup.string()
							.typeError('Age Range must be a string')
							.required('Age Range is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(3, 'You can add up to 3 age ranges only')
				.required('Age Ranges are required')
				.min(3, 'At least 3 age ranges must be added'),
			watchHabits: Yup.array()
				.of(
					Yup.object().shape({
						source: Yup.string()
							.typeError('Video Name must be a string')
							.matches(
								/^[A-Za-z\s().\-\/&']+$/,
								'Video Name can only contain letters, spaces, parentheses, dashes, and periods'
							)
							.required('Video Name is required'),
						views: Yup.number()
							.typeError('Views must be a valid number')
							.min(0, 'Views cannot be negative')
							.required('Views is required'),
					})
				)
				.max(5, 'You can add up to 5 watch habits only')
				.required('Watch Habits are required')
				.min(5, 'At least 5 video must be added'),
			topCountries: Yup.array()
				.of(
					Yup.object().shape({
						country: Yup.string()
							.typeError('Country must be a string')
							.matches(
								/^[A-Za-z\s]+$/,
								'Country can only contain letters and spaces'
							)
							.required('Country is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(5, 'You can add up to 5 top countries only')
				.required('Top Countries are required')
				.min(5, 'At least 5 top countries must be added'),
			topCities: Yup.array()
				.of(
					Yup.object().shape({
						city: Yup.string()
							.typeError('City must be a string')
							.matches(/^[A-Za-z\s]+$/, 'City can only contain letters and spaces')
							.required('City is required'),
						views: Yup.number()
							.typeError('Views must be a valid number')
							.min(0, 'Views cannot be negative')
							.required('Views is required'),
					})
				)
				.max(5, 'You can add up to 5 top cities only')
				.required('Top Cities are required')
				.min(5, 'At least 5 top cities must be added'),
		}),
	});

	// Initialize Formik with enableReinitialize
	const formik = useFormik({
		initialValues: {
			content: {
				videos: {
					views: youtubeData?.content?.videos?.views ?? '',
					impressions: youtubeData?.content?.videos?.impressions ?? '',
					impressionsCtr: youtubeData?.content?.videos?.impressionsCtr ?? '',
					avgViewDuration: youtubeData?.content?.videos?.avgViewDuration ?? '',
					viewerDiscovery: Array.isArray(
						youtubeData?.content?.videos?.viewerDiscovery
					)
						? youtubeData.content.videos.viewerDiscovery.map((item) => ({
								source: item.source ?? '',
								percent: item.percent ?? '',
						  }))
						: [
								{ source: '', percent: '' },
								{ source: '', percent: '' },
								{ source: '', percent: '' },
								{ source: '', percent: '' },
								{ source: '', percent: '' },
						  ],
					topVideos: Array.isArray(youtubeData?.content?.videos?.topVideos)
						? youtubeData.content.videos.topVideos.map((item) => ({
								source: item.source ?? '',
								views: item.views ?? '',
						  }))
						: [
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
						  ],
				},
				shorts: {
					views: youtubeData?.content?.shorts?.views ?? '',
					likes: youtubeData?.content?.shorts?.likes ?? '',
					subscribers: youtubeData?.content?.shorts?.subscribers ?? '',
					viewerDiscovery: Array.isArray(
						youtubeData?.content?.shorts?.viewerDiscovery
					)
						? youtubeData.content.shorts.viewerDiscovery.map((item) => ({
								source: item.source ?? '',
								percent: item.percent ?? '',
						  }))
						: [
								{ source: '', percent: '' },
								{ source: '', percent: '' },
								{ source: '', percent: '' },
								{ source: '', percent: '' },
								{ source: '', percent: '' },
						  ],
					topShorts: Array.isArray(youtubeData?.content?.shorts?.topShorts)
						? youtubeData.content.shorts.topShorts.map((item) => ({
								source: item.source ?? '',
								views: item.views ?? '',
						  }))
						: [
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
						  ],
				},
				songs: {
					topSongs: Array.isArray(youtubeData?.content?.songs?.topSongs)
						? youtubeData.content.songs.topSongs.map((item) => ({
								source: item.source ?? '',
								views: item.views ?? '',
						  }))
						: [
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
								{ source: '', views: '' },
						  ],
					popularSongsShorts: Array.isArray(
						youtubeData?.content?.songs?.popularSongsShorts
					)
						? youtubeData.content.songs.popularSongsShorts.map((item) => ({
								source: item.source ?? '',
								count: item.count ?? '',
						  }))
						: [
								{ source: '', count: '' },
								{ source: '', count: '' },
								{ source: '', count: '' },
								{ source: '', count: '' },
								{ source: '', count: '' },
						  ],
				},
			},
			audience: {
				returningViewers: youtubeData?.audience?.returningViewers ?? '',
				uniqueViewers: youtubeData?.audience?.uniqueViewers ?? '',
				subscribersChange: youtubeData?.audience?.subscribersChange ?? '',
				genderPercentM: youtubeData?.audience?.genderPercentM ?? '',
				genderPercentF: youtubeData?.audience?.genderPercentF ?? '',
				ageRanges: Array.isArray(youtubeData?.audience?.ageRanges)
					? youtubeData.audience.ageRanges.map((item) => ({
							ageRange: item.ageRange ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
					  ],
				watchHabits: Array.isArray(youtubeData?.audience?.watchHabits)
					? youtubeData.audience.watchHabits.map((item) => ({
							source: item.source ?? '',
							views: item.views ?? '',
					  }))
					: [
							{ source: '', views: '' },
							{ source: '', views: '' },
							{ source: '', views: '' },
							{ source: '', views: '' },
							{ source: '', views: '' },
					  ],
				topCountries: Array.isArray(youtubeData?.audience?.topCountries)
					? youtubeData.audience.topCountries.map((item) => ({
							country: item.country ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ country: '', percent: '' },
							{ country: '', percent: '' },
							{ country: '', percent: '' },
							{ country: '', percent: '' },
							{ country: '', percent: '' },
					  ],
				topCities: Array.isArray(youtubeData?.audience?.topCities)
					? youtubeData.audience.topCities.map((item) => ({
							city: item.city ?? '',
							views: item.views ?? '',
					  }))
					: [
							{ city: '', views: '' },
							{ city: '', views: '' },
							{ city: '', views: '' },
							{ city: '', views: '' },
							{ city: '', views: '' },
					  ],
			},
		},
		validationSchema,
		onSubmit: (values) => {
			onSave(values);
		},
		enableReinitialize: true,
		validateOnMount: true,
	});

	const handleNumberChange = (field) => (event) => {
		const value = event.target.value;
		// Allow empty string for controlled inputs; otherwise, convert to number
		const parsedValue = value === '' ? '' : parseInt(value, 10);
		formik.setFieldValue(field, parsedValue);
	};

	// Helper function to render dynamic fields
	const renderDynamicFields = (
		name,
		label,
		arrayHelpers,
		fieldLabel1,
		fieldLabel2
	) => {
		// Determine the correct field name based on fieldLabel1
		let fieldName1 = '';
		if (fieldLabel1 === 'Age Range') {
			fieldName1 = 'ageRange';
		} else if (fieldLabel1 === 'City') {
			fieldName1 = 'city';
		} else if (fieldLabel1 === 'Country') {
			fieldName1 = 'country';
		} else if (
			fieldLabel1 === 'Source' ||
			fieldLabel1 === 'Video Name' ||
			fieldLabel1 === 'Short Name' ||
			fieldLabel1 === 'Song Name'
		) {
			fieldName1 = 'source';
		}

		let fieldName2 = '';
		if (fieldLabel2 === 'Views') {
			fieldName2 = 'views';
		} else if (fieldLabel2 === 'Percent') {
			fieldName2 = 'percent';
		} else if (fieldLabel2 === 'Shorts Count') {
			fieldName2 = 'count';
		} else {
			fieldName2 = 'value';
		}

		return (
			<>
				<Typography
					variant="subtitle1"
					sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
				>
					{label}
				</Typography>
				{getIn(formik.values, name).map((_, index) => (
					<Box display="flex" alignItems="center" mb={2} key={index}>
						{/* Conditional rendering based on fieldLabel1 */}
						{fieldLabel1 === 'Age Range' ? (
							<FormControl fullWidth sx={{ mr: 2 }}>
								<Select
									label={`${fieldLabel1} ${index + 1}`}
									name={`${name}[${index}].${fieldName1}`}
									value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
										Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
									}
								>
									{ageRangeOptions.map((range) => (
										<MenuItem key={range} value={range}>
											{range}
										</MenuItem>
									))}
								</Select>
								{getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`) && (
										<Typography color="error" variant="caption">
											{getIn(formik.errors, `${name}[${index}].${fieldName1}`)}
										</Typography>
									)}
							</FormControl>
						) : (
							<TextField
								fullWidth
								label={`${fieldLabel1}:`}
								name={`${name}[${index}].${fieldName1}`}
								type="text"
								inputMode="numeric"
								value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
								}
								helperText={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`)
								}
								variant="outlined"
								sx={{ mr: 2 }}
							/>
						)}
						<TextField
							fullWidth
							label={`${fieldLabel2}:`}
							name={`${name}[${index}].${fieldName2}`}
							type="text"
							inputMode="numeric"
							value={getIn(formik.values, `${name}[${index}].${fieldName2}`)}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								getIn(formik.touched, `${name}[${index}].${fieldName2}`) &&
								Boolean(getIn(formik.errors, `${name}[${index}].${fieldName2}`))
							}
							helperText={
								getIn(formik.touched, `${name}[${index}].${fieldName2}`) &&
								getIn(formik.errors, `${name}[${index}].${fieldName2}`)
							}
							variant="outlined"
							sx={{ mr: 2 }}
						/>
						<IconButton
							color="secondary"
							onClick={() => arrayHelpers.remove(index)}
							disabled={getIn(formik.values, name).length === 1}
						>
							<RemoveCircleOutline />
						</IconButton>
					</Box>
				))}
				{getIn(formik.values, name).length <
					(fieldLabel1 === 'Age Range' ? 3 : 5) && (
					<Button
						variant="outlined"
						startIcon={<AddCircleOutline />}
						onClick={() => arrayHelpers.push({ [fieldName1]: '', [fieldName2]: '' })}
						sx={{ mb: 2 }}
					>
						Add {fieldLabel1}
					</Button>
				)}
			</>
		);
	};

	return (
		<FormikProvider value={formik}>
			<Box
				sx={{
					width: '100%',
					maxWidth: '900px',
					margin: '0 auto',
					padding: 2,
					backgroundColor: 'background.paper',
					borderRadius: 2,
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					{/* Header Section */}
					<Box textAlign="center" mb={4}>
						<Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
							YouTube Analytics Data
						</Typography>
						<Button
							variant="contained"
							color="primary"
							href="https://studio.youtube.com/"
							target="_blank"
							sx={{ ...theme.typography.button, mb: 2 }}
						>
							Open YouTube Studio
						</Button>
						<Typography variant="subtitle1" sx={{ ...theme.typography.h4, mb: 2 }}>
							Last 28 Days
						</Typography>
						<Divider sx={{ mb: 2 }} />
					</Box>

					{/* Content Data - Videos */}
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Content Data (Videos)
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									label="Views"
									name="content.videos.views"
									type="text"
									inputMode="numeric"
									value={formik.values.content.videos.views}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.content?.videos?.views &&
										Boolean(formik.errors.content?.videos?.views)
									}
									helperText={
										formik.touched.content?.videos?.views &&
										formik.errors.content?.videos?.views
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									label="Impressions"
									name="content.videos.impressions"
									type="text"
									inputMode="numeric"
									value={formik.values.content.videos.impressions}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.content?.videos?.impressions &&
										Boolean(formik.errors.content?.videos?.impressions)
									}
									helperText={
										formik.touched.content?.videos?.impressions &&
										formik.errors.content?.videos?.impressions
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									label="Impressions CTR (%)"
									name="content.videos.impressionsCtr"
									type="text"
									inputMode="numeric"
									value={formik.values.content.videos.impressionsCtr}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.content?.videos?.impressionsCtr &&
										Boolean(formik.errors.content?.videos?.impressionsCtr)
									}
									helperText={
										formik.touched.content?.videos?.impressionsCtr &&
										formik.errors.content?.videos?.impressionsCtr
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={3}>
								<TextField
									fullWidth
									label="Average View Duration"
									name="content.videos.avgViewDuration"
									type="text"
									inputMode="numeric"
									value={formik.values.content.videos.avgViewDuration}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.content?.videos?.avgViewDuration &&
										Boolean(formik.errors.content?.videos?.avgViewDuration)
									}
									helperText={
										formik.touched.content?.videos?.avgViewDuration &&
										formik.errors.content?.videos?.avgViewDuration
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							{/* Viewer Discovery */}
							<Grid item xs={12}>
								<FieldArray
									name="content.videos.viewerDiscovery"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'content.videos.viewerDiscovery',
											'How Viewers Find Your Videos (Top 5)',
											arrayHelpers,
											'Source',
											'Percent'
										)
									}
								/>
								{formik.touched.content?.videos?.viewerDiscovery &&
									typeof formik.errors.content?.videos?.viewerDiscovery ===
										'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.content?.videos?.viewerDiscovery}
										</Typography>
									)}
							</Grid>
							{/* Top 5 Videos */}
							<Grid item xs={12}>
								<FieldArray
									name="content.videos.topVideos"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'content.videos.topVideos',
											'Top 5 Videos',
											arrayHelpers,
											'Video Name',
											'Views'
										)
									}
								/>
								{formik.touched.content?.videos?.topVideos &&
									typeof formik.errors.content?.videos?.topVideos === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.content?.videos?.topVideos}
										</Typography>
									)}
							</Grid>
						</Grid>
					</Box>

					{/* Content Data - Shorts */}
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Content Data (Shorts)
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Views"
									name="content.shorts.views"
									type="text"
									inputMode="numeric"
									value={formik.values.content.shorts.views}
									onChange={handleNumberChange('content.shorts.views')}
									onBlur={formik.handleBlur}
									error={
										formik.touched.content?.shorts?.views &&
										Boolean(formik.errors.content?.shorts?.views)
									}
									helperText={
										formik.touched.content?.shorts?.views &&
										formik.errors.content?.shorts?.views
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Likes"
									name="content.shorts.likes"
									type="text"
									inputMode="numeric"
									value={formik.values.content.shorts.likes}
									onChange={handleNumberChange('content.shorts.likes')}
									onBlur={formik.handleBlur}
									error={
										formik.touched.content?.shorts?.likes &&
										Boolean(formik.errors.content?.shorts?.likes)
									}
									helperText={
										formik.touched.content?.shorts?.likes &&
										formik.errors.content?.shorts?.likes
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Subscribers"
									name="content.shorts.subscribers"
									type="text"
									inputMode="numeric"
									value={formik.values.content.shorts.subscribers}
									onChange={handleNumberChange('content.shorts.subscribers')}
									onBlur={formik.handleBlur}
									error={
										formik.touched.content?.shorts?.subscribers &&
										Boolean(formik.errors.content?.shorts?.subscribers)
									}
									helperText={
										formik.touched.content?.shorts?.subscribers &&
										formik.errors.content?.shorts?.subscribers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							{/* Viewer Discovery */}
							<Grid item xs={12}>
								<FieldArray
									name="content.shorts.viewerDiscovery"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'content.shorts.viewerDiscovery',
											'How Viewers Find Your Shorts (Top 5)',
											arrayHelpers,
											'Source',
											'Percent'
										)
									}
								/>
								{formik.touched.content?.shorts?.viewerDiscovery &&
									typeof formik.errors.content?.shorts?.viewerDiscovery ===
										'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.content?.shorts?.viewerDiscovery}
										</Typography>
									)}
							</Grid>
							{/* Top 5 Shorts */}
							<Grid item xs={12}>
								<FieldArray
									name="content.shorts.topShorts"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'content.shorts.topShorts',
											'Top 5 Shorts',
											arrayHelpers,
											'Short Name',
											'Views'
										)
									}
								/>
								{formik.touched.content?.shorts?.topShorts &&
									typeof formik.errors.content?.shorts?.topShorts === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.content?.shorts?.topShorts}
										</Typography>
									)}
							</Grid>
						</Grid>
					</Box>

					{/* Content Data - Songs */}
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Content Data (Songs)
						</Typography>
						<Grid container spacing={2}>
							{/* Top 5 Songs */}
							<Grid item xs={12}>
								<FieldArray
									name="content.songs.topSongs"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'content.songs.topSongs',
											'Top 5 Songs',
											arrayHelpers,
											'Song Name',
											'Views'
										)
									}
								/>
								{formik.touched.content?.songs?.topSongs &&
									typeof formik.errors.content?.songs?.topSongs === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.content?.songs?.topSongs}
										</Typography>
									)}
							</Grid>
							{/* Popular Songs in Shorts */}
							<Grid item xs={12}>
								<FieldArray
									name="content.songs.popularSongsShorts"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'content.songs.popularSongsShorts',
											' Top 5 Popular Songs in Shorts',
											arrayHelpers,
											'Song Name',
											'Shorts Count'
										)
									}
								/>
								{formik.touched.content?.songs?.popularSongsShorts &&
									typeof formik.errors.content?.songs?.popularSongsShorts ===
										'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.content?.songs?.popularSongsShorts}
										</Typography>
									)}
							</Grid>
						</Grid>
					</Box>

					{/* Audience Data */}
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Audience Data
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Returning Viewers"
									name="audience.returningViewers"
									type="text"
									inputMode="numeric"
									value={formik.values.audience.returningViewers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.audience?.returningViewers &&
										Boolean(formik.errors.audience?.returningViewers)
									}
									helperText={
										formik.touched.audience?.returningViewers &&
										formik.errors.audience?.returningViewers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Unique Viewers"
									name="audience.uniqueViewers"
									type="text"
									inputMode="numeric"
									value={formik.values.audience.uniqueViewers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.audience?.uniqueViewers &&
										Boolean(formik.errors.audience?.uniqueViewers)
									}
									helperText={
										formik.touched.audience?.uniqueViewers &&
										formik.errors.audience?.uniqueViewers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Subscribers Change"
									name="audience.subscribersChange"
									type="text"
									inputMode="numeric"
									value={formik.values.audience.subscribersChange}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.audience?.subscribersChange &&
										Boolean(formik.errors.audience?.subscribersChange)
									}
									helperText={
										formik.touched.audience?.subscribersChange &&
										formik.errors.audience?.subscribersChange
									}
									variant="outlined"
								/>
							</Grid>
						</Grid>
					</Box>
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Gender Demographics
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (M)"
									name="audience.genderPercentM"
									type="text"
									inputMode="numeric"
									value={formik.values.audience.genderPercentM}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.audience?.genderPercentM &&
										Boolean(formik.errors.audience?.genderPercentM)
									}
									helperText={
										formik.touched.audience?.genderPercentM &&
										formik.errors.audience?.genderPercentM
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (F)"
									name="audience.genderPercentF"
									type="text"
									inputMode="numeric"
									value={formik.values.audience.genderPercentF}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.audience?.genderPercentF &&
										Boolean(formik.errors.audience?.genderPercentF)
									}
									helperText={
										formik.touched.audience?.genderPercentF &&
										formik.errors.audience?.genderPercentF
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							{/* Age Ranges */}
							<Grid item xs={12} sm={12}>
								<FieldArray
									name="audience.ageRanges"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'audience.ageRanges',
											'Top 3 Age Ranges',
											arrayHelpers,
											'Age Range',
											'Percent'
										)
									}
								/>
								{formik.touched.audience?.ageRanges &&
									typeof formik.errors.audience?.ageRanges === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.audience?.ageRanges}
										</Typography>
									)}
							</Grid>

							{/* Watch Habits */}
							<Grid item xs={12}>
								<FieldArray
									name="audience.watchHabits"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'audience.watchHabits',
											'What Your Audience Watches (Top 5)',
											arrayHelpers,
											'Video Name',
											'Views'
										)
									}
								/>
								{formik.touched.audience?.watchHabits &&
									typeof formik.errors.audience?.watchHabits === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.audience?.watchHabits}
										</Typography>
									)}
							</Grid>

							{/* Top Countries */}
							<Grid item xs={12}>
								<FieldArray
									name="audience.topCountries"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'audience.topCountries',
											'Top 5 Geographies',
											arrayHelpers,
											'Country',
											'Percent'
										)
									}
								/>
								{formik.touched.audience?.topCountries &&
									typeof formik.errors.audience?.topCountries === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.audience?.topCountries}
										</Typography>
									)}
							</Grid>

							{/* Top Cities */}
							<Grid item xs={12}>
								<FieldArray
									name="audience.topCities"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'audience.topCities',
											'Top 5 Cities',
											arrayHelpers,
											'City',
											'Views'
										)
									}
								/>
								{formik.touched.audience?.topCities &&
									typeof formik.errors.audience?.topCities === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.audience?.topCities}
										</Typography>
									)}
							</Grid>
						</Grid>
					</Box>

					{/* Submit and Cancel Buttons */}
					<Box mt={4} display="flex" justifyContent="center" gap={2}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ...theme.typography.button }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!formik.isValid || formik.isSubmitting}
							size="large"
							sx={{ ...theme.typography.button }}
						>
							Save Data
						</Button>
					</Box>
				</form>
			</Box>
		</FormikProvider>
	);
};

export default YouTubeData;
