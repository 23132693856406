// ArtistOnboardingStep3.js
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Button, Dialog, Typography, Grid, Chip, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import SpotifyProfile from '../dialogs/step3/SpotifyProfile';
import AppleMusicProfile from '../dialogs/step3/AppleMusicProfile';
import InstagramProfile from '../dialogs/step3/InstagramProfile';
import TikTokProfile from '../dialogs/step3/TikTokProfile';
import YoutubeProfile from '../dialogs/step3/YouTubeProfile';
import { SpotifyIcon, TiktokIcon } from 'hugeicons-react';
import { Apple, YouTube, Instagram } from '@mui/icons-material';
import { isPlatformComplete } from '../../../utils/validation/validation';
import { isEqual } from 'lodash';
import { useTheme } from '@mui/material/styles';

const ArtistOnboardingStep3 = ({ artistData, updateArtistData, onNext, onBack }) => {
	const theme = useTheme();
	// Define an icon map based on platform keys
	const platformIcons = useMemo(
		() => ({
			appleMusic: <Apple />,
			instagram: <Instagram />,
			spotify: <SpotifyIcon />,
			tiktok: <TiktokIcon />,
			youtube: <YouTube />,
		}),
		[]
	);

	// Define platforms with consistent keys and labels
	const platforms = useMemo(
		() => [
			{ key: 'spotify', label: 'Spotify' },
			{ key: 'appleMusic', label: 'Apple Music' },
			{ key: 'youtube', label: 'YouTube' },
			{ key: 'instagram', label: 'Instagram' },
			{ key: 'tiktok', label: 'TikTok' },
		],
		[]
	);

	// Initialize profiles based on artistData
	const initialProfiles = useMemo(
		() =>
			platforms.reduce((acc, platform) => {
				acc[platform.key] = artistData?.[platform.key] || {};
				return acc;
			}, {}),
		[artistData, platforms]
	);

	const [profiles, setProfiles] = useState(initialProfiles);
	const [openDialogs, setOpenDialogs] = useState(
		useMemo(
			() =>
				platforms.reduce((acc, platform) => {
					acc[platform.key] = false;
					return acc;
				}, {}),
			[platforms]
		)
	);

	// Synchronize profiles with artistData using deep comparison
	useEffect(() => {
		const updatedProfiles = platforms.reduce((acc, platform) => {
			acc[platform.key] = artistData?.[platform.key] || {};
			return acc;
		}, {});

		if (!isEqual(updatedProfiles, profiles)) {
			setProfiles(updatedProfiles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [artistData, platforms]);

	// Handler to open dialogs
	const handleOpenDialog = useCallback((platformKey) => {
		setOpenDialogs((prev) => ({ ...prev, [platformKey]: true }));
	}, []);

	// Handler to close dialogs
	const handleCloseDialog = useCallback((platformKey) => {
		setOpenDialogs((prev) => ({ ...prev, [platformKey]: false }));
	}, []);

	// Handler to update profiles and synchronize with artistData
	const handleSave = useCallback(
		(platformKey, updatedData) => {
			setProfiles((prev) => ({ ...prev, [platformKey]: updatedData }));
			const updatedArtistData = {
				...artistData,
				[platformKey]: updatedData,
			};
			updateArtistData(updatedArtistData);
		},
		[artistData, updateArtistData]
	);

	// Handler to mark a platform as complete and synchronize with artistData
	const handleComplete = useCallback(
		(platformKey) => {
			handleCloseDialog(platformKey);
		},
		[handleCloseDialog]
	);

	const handleCancel = useCallback(
		(platformKey) => {
			handleCloseDialog(platformKey);
		},
		[handleCloseDialog]
	);

	// StatusBadge Component with Tooltip for better UX
	const StatusBadge = useCallback(
		({ label, isComplete }) => (
			<Tooltip title={isComplete ? 'Complete' : 'Incomplete'}>
				<Chip
					icon={
						isComplete ? (
							<CheckCircleIcon sx={{ color: 'green' }} />
						) : (
							<RadioButtonUncheckedIcon />
						)
					}
					label={label}
					variant="outlined"
					color={isComplete ? 'success' : 'default'}
					sx={{ width: '100%', textAlign: 'center' }}
				/>
			</Tooltip>
		),
		[]
	);

	// Check if all platforms are complete
	const isStepComplete = useMemo(() => {
		return platforms.every((platform) => isPlatformComplete(profiles, platform.key));
	}, [platforms, profiles]);

	// Helper function to render platform dialogs
	const renderDialog = useCallback(
		(platform) => {
			const commonProps = {
				artistData: profiles[platform.key],
				onComplete: () => handleComplete(platform.key),
				onSave: (updatedData) => handleSave(platform.key, updatedData),
				onCancel: () => handleCancel(platform.key),
			};

			switch (platform.key) {
				case 'spotify':
					return <SpotifyProfile {...commonProps} />;
				case 'appleMusic':
					return <AppleMusicProfile {...commonProps} />;
				case 'instagram':
					return <InstagramProfile {...commonProps} />;
				case 'tiktok':
					return <TikTokProfile {...commonProps} />;
				case 'youtube':
					return <YoutubeProfile {...commonProps} />;
				default:
					return null;
			}
		},
		[profiles, handleComplete, handleSave, handleCancel]
	);

	return (
		<Box
			sx={{
				backgroundColor: '#ffffff',
				padding: '2rem',
				borderRadius: '8px',
				boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				maxWidth: '800px',
				margin: '0 auto',
			}}
		>
			{/* Header Section */}
			<Box sx={{ textAlign: 'center', mb: 2 }}>
				<Typography
					variant="h3"
					sx={{
						textAlign: 'center',
					}}
				>
					Data Integration
				</Typography>
			</Box>

			{/* Action Buttons Section */}
			<Box sx={{ mt: 3 }}>
				<Grid container spacing={3} sx={{ justifyContent: 'center' }}>
					{platforms.map((platform) => (
						<Grid item xs={12} sm={12} md={4} key={platform.key}>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
							>
								<Typography variant="h6" sx={{ ...theme.typography.h4, mb: 1 }}>
									{platform.label}
								</Typography>
								<Typography
									variant="body1"
									sx={{ ...theme.typography.body2, mb: 1 }}
								>
									Input platform analytics data
								</Typography>
								<Button
									variant="contained"
									onClick={() => handleOpenDialog(platform.key)}
									sx={{
										maxWidth: '100%',
										minWidth: '220px',
										justifyItems: 'center',
									}}
									startIcon={platformIcons[platform.key] || null}
								>
									Integrate Data
								</Button>
							</Box>
						</Grid>
					))}
				</Grid>
			</Box>

			{/* Progress Section */}
			<Box sx={{ mt: 5 }}>
				<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
					Platform Integration Status
				</Typography>
				<Grid container spacing={2} sx={{ justifyContent: 'center' }}>
					{platforms.map((platform) => (
						<Grid item xs={12} sm={6} md={2.4} key={platform.key}>
							<StatusBadge
								label={platform.label}
								isComplete={isPlatformComplete(profiles, platform.key)}
							/>
						</Grid>
					))}
				</Grid>
			</Box>

			{/* Navigation Buttons */}
			<Box sx={{ mt: 4 }} textAlign="center">
				<Button
					variant="contained"
					color="secondary"
					onClick={() => onBack(artistData)}
					sx={{ mr: 2 }}
				>
					Back
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => onNext(artistData)}
					// disabled={!isStepComplete}
				>
					Next
				</Button>
			</Box>

			{/* Dialogs for Each Platform */}
			{platforms.map((platform) => (
				<Dialog
					key={platform.key}
					open={openDialogs[platform.key]}
					onClose={(event, reason) => {
						if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
							return;
						}
						handleCancel(platform.key);
					}}
					maxWidth="md"
					fullWidth
				>
					{renderDialog(platform)}
				</Dialog>
			))}
		</Box>
	);
};

export default ArtistOnboardingStep3;
