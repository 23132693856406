// components/modules/chatInterface/subcomponents/messageTypes/TextMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import DOMPurify from 'dompurify';
import { Typography, useTheme } from '@mui/material';

/**
 * TextMessage Component
 *
 * Renders plain text messages with support for Markdown formatting.
 *
 * Props:
 * - text: The text content of the message, which may include Markdown syntax.
 */
const TextMessage = ({ text }) => {
	const theme = useTheme();
	try {
		const sanitizedText = DOMPurify.sanitize(text);

		return (
			<ReactMarkdown
				children={sanitizedText}
				remarkPlugins={[remarkGfm]}
				components={{
					h1: ({ node, ...props }) => (
						<Typography sx={{ ...theme.typography.h2, color: 'black' }} {...props} />
					),
					h2: ({ node, ...props }) => (
						<Typography sx={{ ...theme.typography.h3, color: 'black' }} {...props} />
					),
					h3: ({ node, ...props }) => (
						<Typography
							sx={{ fontWeight: 'bold', fontSize: '1.25rem', color: 'black' }}
							{...props}
						/>
					),
					p: ({ node, ...props }) => (
						<Typography variant="body1" sx={{ color: 'black' }} {...props} />
					),
					strong: ({ node, ...props }) => (
						<strong style={{ color: 'black' }} {...props} />
					),
					em: ({ node, ...props }) => <em style={{ color: 'black' }} {...props} />,
					ul: ({ node, ...props }) => <ul style={{ color: 'black' }} {...props} />,
					ol: ({ node, ...props }) => <ol style={{ color: 'black' }} {...props} />,
					li: ({ node, ...props }) => <li style={{ color: 'black' }} {...props} />,
					code: ({ node, inline, className, children, ...props }) => {
						return !inline ? (
							<Typography
								component="pre"
								sx={{
									backgroundColor: 'grey.100',
									padding: 1,
									borderRadius: 1,
									color: 'black',
								}}
								{...props}
							>
								<code>{children}</code>
							</Typography>
						) : (
							<code style={{ color: 'black' }} {...props}>
								{children}
							</code>
						);
					},
					blockquote: ({ node, ...props }) => (
						<Typography
							component="blockquote"
							sx={{ borderLeft: '4px solid grey.300', pl: 2, color: 'grey.700' }}
							{...props}
						/>
					),
				}}
			/>
		);
	} catch (error) {
		console.error('Error rendering TextMessage:', error);
		return (
			<Typography variant="body2" color="error">
				Failed to render message.
			</Typography>
		);
	}
};

TextMessage.propTypes = {
	text: PropTypes.string.isRequired,
};

export default React.memo(TextMessage);
