import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditCampaignModal from './EditCampaignModal';
import {
	updateCampaign,
	deleteCampaign,
} from '../../../../utils/apis/marketingAssistant/campaignUtils';
import {
	Card,
	CardContent,
	Typography,
	Box,
	Grid,
	IconButton,
	Tooltip,
} from '@mui/material';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CampaignIcon from '@mui/icons-material/Campaign';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import FestivalIcon from '@mui/icons-material/Festival';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const RecentCampaignCard = ({ campaign, onSelect, onUpdate }) => {
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const getCampaignIcon = (type) => {
		switch (type) {
			case 'New Music Release':
				return <LibraryMusicIcon fontSize="medium" color="primary" />;
			case 'Upcoming Tour':
				return <FestivalIcon fontSize="medium" color="primary" />;
			default:
				return <CampaignIcon fontSize="medium" color="primary" />;
		}
	};

	const getStatusColor = (status) => {
		switch (status.toLowerCase()) {
			case 'active':
				return 'success';
			case 'paused':
				return 'warning';
			case 'completed':
				return 'default';
			default:
				return 'default';
		}
	};

	const handleSelect = () => {
		onSelect(campaign.id);
	};

	const openEditModal = () => {
		setIsEditModalOpen(true);
	};

	const closeEditModal = () => {
		setIsEditModalOpen(false);
	};

	const handleSave = async (updatedData) => {
		try {
			await updateCampaign(campaign.id, updatedData);
			onUpdate();
			setIsEditModalOpen(false);
		} catch (error) {
			console.error('Error updating campaign:', error);
			alert('Failed to update campaign. Please try again.');
		}
	};

	const handleDelete = async () => {
		const confirmDelete = window.confirm(
			'Are you sure you want to delete this campaign?'
		);
		if (!confirmDelete) return;

		setIsDeleting(true);
		try {
			await deleteCampaign(campaign.id);
			onUpdate();
		} catch (error) {
			console.error('Error deleting campaign:', error);
			alert('Failed to delete campaign. Please try again.');
		} finally {
			setIsDeleting(false);
		}
	};

	const formattedDate = format(new Date(campaign.updated_at), 'MMM dd, yy');

	return (
		<>
			<Card
				variant="outlined"
				sx={{
					position: 'relative',
					cursor: 'pointer',
					height: '100%',
					width: '100%',
					maxWidth: 350,
					display: 'flex',
					flexDirection: 'column',
					'&:hover': { boxShadow: 6 },
					borderRadius: 2,
					backgroundColor: 'background.paper',
				}}
				onClick={handleSelect}
			>
				<CardContent sx={{ flexGrow: 1 }}>
					<Grid container spacing={1}>
						{/* Icon and Project Name */}
						<Grid item xs={12}>
							<Typography
								variant="h4"
								component="div"
								sx={{
									fontWeight: 'bold',
									color: 'text.primary',
									textAlign: 'center',
									mb: 1,
								}}
							>
								{campaign.project_name}
							</Typography>

							<Box display="flex" alignItems="center" gap={2}>
								{getCampaignIcon(campaign.marketing_goal)}
								<Typography
									variant="body2"
									color="text.secondary"
									sx={{ mt: 1, fontStyle: 'italic' }}
								>
									{campaign.marketing_goal}
								</Typography>
							</Box>
						</Grid>

						{/* Status */}
						<Grid item xs={12}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									backgroundColor: 'background.default',
									padding: '4px 8px',
									borderRadius: '8px',
									boxShadow: 1,
								}}
							>
								<FiberManualRecordIcon
									fontSize="small"
									color={getStatusColor(campaign.status)}
									sx={{ mr: 0.5 }}
								/>
								<Typography variant="caption" color="text.secondary">
									{campaign.status.charAt(0).toUpperCase() +
										campaign.status.slice(1)}
								</Typography>
							</Box>
						</Grid>
					</Grid>
				</CardContent>

				{/* Action Buttons */}
				<Box
					sx={{
						display: 'flex',
						flexDirection: { xs: 'column', sm: 'row' },
						justifyContent: 'space-between',
						alignItems: 'center',
						p: 2,
						borderTop: '1px solid',
						borderColor: 'divider',
						backgroundColor: 'background.default',
					}}
				>
					<Typography variant="caption" color="text.secondary">
						Last Updated: {formattedDate}
					</Typography>
					<Box mt={{ xs: 1, sm: 0 }} display="flex" alignItems="center" gap={1}>
						<Tooltip title="Edit Campaign">
							<IconButton
								aria-label={`Edit campaign ${campaign.project_name}`}
								onClick={(e) => {
									e.stopPropagation();
									openEditModal();
								}}
								sx={{ color: 'info.main' }}
								size="small"
							>
								<EditIcon fontSize="small" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Delete Campaign">
							<IconButton
								aria-label={`Delete campaign ${campaign.project_name}`}
								onClick={(e) => {
									e.stopPropagation();
									handleDelete();
								}}
								sx={{ color: 'error.main' }}
								disabled={isDeleting}
								size="small"
							>
								<DeleteIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Box>
				</Box>
			</Card>

			{/* Edit Campaign Modal */}
			<EditCampaignModal
				isOpen={isEditModalOpen}
				onClose={closeEditModal}
				campaign={campaign}
				onSave={handleSave}
			/>
		</>
	);
};

RecentCampaignCard.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
		project_name: PropTypes.string.isRequired,
		marketing_goal: PropTypes.string.isRequired,
		status: PropTypes.string.isRequired,
		conversation_id: PropTypes.string.isRequired,
		updated_at: PropTypes.string.isRequired,
	}).isRequired,
	onSelect: PropTypes.func.isRequired,
	onUpdate: PropTypes.func.isRequired,
};

export default RecentCampaignCard;
