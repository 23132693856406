import { questionToPromptMap } from './questionMapper';

// Function to generate the ChatGPT prompt
export const generateChatGPTPrompt = (
	formData,
	artistData,
	companyData,
	projectName
) => {
	if (!formData) {
		return 'Please provide valid form data.\n';
	}

	const { marketing_goal: marketingGoal } = formData;

	// Initialize the prompt with a clear and concise role definition
	let prompt = `You are a digital marketing expert specializing in music campaigns for a ${companyData?.type}.\n\n`;

	// Add a high-level strategy request tailored to the marketing goal and artist's attributes
	prompt += `Develop a high-level marketing strategy for the "${projectName}" project aimed at achieving the "${marketingGoal}" goal. Leverage ${artistData?.stageName}'s unique attributes to ensure the strategy aligns with both campaign objectives and audience engagement.\n\n`;

	{
		/*
	// Add an introductory line based on the campaign goal
	switch (marketingGoal) {
		case 'New Music Release':
			prompt += `Start by providingAssist in creating a comprehensive marketing plan".\n\n`;
			break;
		case 'Upcoming Tour':
			prompt += `Help develop a strategic marketing approach for the upcoming tour titled "${projectName}".\n\n`;
			break;
		default:
			prompt += `Provide guidance on crafting a marketing strategy for the project titled "${projectName}".\n\n`;
			break;
	}
	*/
	}

	// Dynamically build the rest of the prompt based on form data
	for (const key in formData) {
		if (questionToPromptMap[key]) {
			const additionalDetailsKey = `${key}Details`;

			// Only add additional details if available
			if (formData[additionalDetailsKey]) {
				const additionalDetails = formData[additionalDetailsKey];
				prompt += questionToPromptMap[key](formData[key], additionalDetails);
			} else {
				prompt += questionToPromptMap[key](formData[key]);
			}
		}
	}

	// Close the prompt with a call to action
	prompt += `\nPlease provide actionable insights and recommendations based on the above information.`;

	return prompt;
};
