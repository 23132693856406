import React from 'react';
import { Typography, Paper, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SpotifyArtistCard = ({ artist, selected, onSelect, type }) => {
	const theme = useTheme();
	const capitalizeGenres = (genres) => {
		return genres
			.map((genre) =>
				genre
					.split(' ')
					.map((word) =>
						word
							.split('-')
							.map((part) => part.charAt(0).toUpperCase() + part.slice(1))
							.join('-')
					)
					.join(' ')
			)
			.join(', ');
	};

	return (
		<Paper
			sx={{
				p: 2,
				width: '250px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				boxShadow: selected ? '0px 0px 8px 2px rgba(0, 0, 0, 0.2)' : 'none',
				cursor: 'pointer',
			}}
			onClick={onSelect}
		>
			<div
				style={{
					width: '100%',
					height: '250px',
					overflow: 'hidden',
					borderRadius: '8px',
					marginBottom: '8px',
				}}
			>
				<img
					src={artist.imageUrl || artist.images?.[0]?.url || '/placeholder.jpg'}
					alt={artist.name || 'Unknown Artist'}
					style={{
						width: '100%',
						height: '100%',
						objectFit: 'cover',
					}}
				/>
			</div>
			<Typography
				variant="body1"
				sx={{ ...theme.typography.body1, mb: 1, textAlign: 'center' }}
			>
				{artist.name || 'Unknown Artist'}
			</Typography>
			{type === 'selected' && (
				<>
					<Typography
						variant="caption"
						sx={{ ...theme.typography.caption, mb: 1, textAlign: 'center' }}
					>
						Followers: {artist.followers?.toLocaleString() || 'N/A'}
					</Typography>
					<Typography
						variant="caption"
						sx={{ ...theme.typography.caption, mb: 1, textAlign: 'center' }}
					>
						Genres:{' '}
						{artist.genres ? capitalizeGenres(artist.genres.slice(0, 3)) : 'N/A'}
					</Typography>
				</>
			)}
			<Button
				variant="contained"
				color={selected ? 'secondary' : 'primary'}
				sx={{ ...theme.typography.button, mt: 'auto' }}
				onClick={(e) => {
					e.stopPropagation();
					onSelect();
				}}
			>
				{selected ? 'Remove' : 'Select'}
			</Button>
		</Paper>
	);
};

export default SpotifyArtistCard;
