// EmailVerificationModal.jsx
import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

// Styled Components
const StyledModalBox = styled(Box)(({ theme }) => ({
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	maxWidth: 500,
	backgroundColor: theme.palette.background.paper,
	boxShadow: theme.shadows[6],
	padding: theme.spacing(5),
	borderRadius: theme.shape.borderRadius,
	textAlign: 'center',
}));

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
	...theme.typography.h5,
	marginBottom: theme.spacing(2),
	color: theme.palette.text.primary,
}));

const StyledTypographyDescription = styled(Typography)(({ theme }) => ({
	...theme.typography.body1,
	marginBottom: theme.spacing(4),
	color: theme.palette.text.secondary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(1.5, 4),
	textTransform: 'none',
	...theme.typography.button,
}));

const EmailVerificationModal = ({ open, handleClose }) => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="email-verification-modal"
			aria-describedby="email-verification-description"
		>
			<StyledModalBox>
				<StyledTypographyTitle id="email-verification-modal" variant="h5">
					Check Your Email!
				</StyledTypographyTitle>
				<StyledTypographyDescription id="email-verification-description">
					A verification link has been sent to your email. Please check your inbox
					and follow the instructions to verify your account.
				</StyledTypographyDescription>
				<StyledButton variant="contained" color="primary" onClick={handleClose}>
					Got It
				</StyledButton>
			</StyledModalBox>
		</Modal>
	);
};

export default EmailVerificationModal;
