// src/contexts/SocketContext.jsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import { initiateSocket, disconnectSocket } from '../utils/socket';
import { getUserSession } from '../utils/apis/auth/sessionUtils';
import SessionContext from './SessionContext';

const SocketContext = createContext();

export const useSocket = () => {
	return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
	const { session } = useContext(SessionContext);
	const [socket, setSocket] = useState(null);
	const { token } = getUserSession();

	useEffect(() => {
		const setupSocket = async () => {
			try {
				const connectedSocket = await initiateSocket(token);
				setSocket(connectedSocket);
			} catch (err) {
				console.error('Socket connection failed:', err);
			}
		};
		if (session.isLoggedIn) {
			setupSocket();
		}

		return () => {
			disconnectSocket();
			setSocket(null);
		};
	}, [token, session.isLoggedIn]);

	return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
};
