// components/modules/chatInterface/subcomponents/messageTypes/ImageMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

/**
 * ImageMessage Component
 *
 * Renders an image with optional alt text.
 *
 * Props:
 * - url: The URL of the image.
 * - alt: The alternative text for the image.
 */
const ImageMessage = ({ url, alt }) => {
	if (!url) {
		return (
			<Typography variant="body2" color="error">
				Image URL is missing.
			</Typography>
		);
	}
	return (
		<Box sx={{ textAlign: 'center', mt: 1 }}>
			<img
				src={url}
				alt={alt || 'Image'}
				style={{ maxWidth: '100%', borderRadius: 8 }}
			/>
		</Box>
	);
};

ImageMessage.propTypes = {
	url: PropTypes.string.isRequired,
	alt: PropTypes.string,
};

ImageMessage.defaultProps = {
	alt: 'Image',
};

export default React.memo(ImageMessage);
