import React, { useState, useEffect, useRef } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ isLoading, delay = 200 }) => {
	const [show, setShow] = useState(false);
	const timeoutId = useRef(null);

	useEffect(() => {
		if (isLoading) {
			// Set a timeout to delay the spinner appearance
			timeoutId.current = setTimeout(() => {
				setShow(true);
			}, delay);
		} else {
			// Clear the timeout and hide the spinner immediately
			clearTimeout(timeoutId.current);
			setShow(false);
		}

		// Cleanup the timeout on unmount or when isLoading changes
		return () => clearTimeout(timeoutId.current);
	}, [isLoading, delay]);

	return (
		<Backdrop
			sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
			open={show}
			transitionDuration={300} // Smooth transitions
		>
			<CircularProgress color="inherit" />
		</Backdrop>
	);
};

// Define prop types for better type checking
LoadingSpinner.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	delay: PropTypes.number, // Delay in milliseconds before showing the spinner
};

export default LoadingSpinner;
