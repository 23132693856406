import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import ArtistCard from './ArtistCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Install Swiper modules
SwiperCore.use([Navigation]);

const ArtistCarousel = ({ artists, onSelect, onUpdate, label }) => {
	const [showNavigation, setShowNavigation] = useState(false);
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);

	const prevRef = useRef(null);
	const nextRef = useRef(null);

	const swiperRef = useRef(null);

	const updateNavigationVisibility = () => {
		const windowWidth = window.innerWidth;
		let visibleSlides = 1;

		if (windowWidth >= 1920) visibleSlides = 5;
		else if (windowWidth >= 1400) visibleSlides = 4;
		else if (windowWidth >= 1000) visibleSlides = 3;
		else if (windowWidth >= 750) visibleSlides = 2;

		setShowNavigation(artists.length > visibleSlides);
	};

	useEffect(() => {
		updateNavigationVisibility();
		window.addEventListener('resize', updateNavigationVisibility);
		return () => window.removeEventListener('resize', updateNavigationVisibility);
	}, [artists]);

	return (
		<Box>
			<Typography
				variant="h4"
				sx={{
					mb: 1,
					fontWeight: 'bold',
					color: 'text.primary',
					textAlign: { xs: 'center', md: 'left' },
				}}
			>
				{label}
			</Typography>
			<Box sx={{ position: 'relative' }}>
				<Swiper
					modules={[Navigation]}
					navigation={{
						prevEl: prevRef.current,
						nextEl: nextRef.current,
					}}
					onBeforeInit={(swiper) => {
						swiper.params.navigation.prevEl = prevRef.current;
						swiper.params.navigation.nextEl = nextRef.current;
					}}
					onSwiper={(swiper) => {
						swiperRef.current = swiper;
					}}
					onSlideChange={(swiper) => {
						setIsBeginning(swiper.isBeginning);
						setIsEnd(swiper.isEnd);
					}}
					spaceBetween={16}
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						750: {
							slidesPerView: 2,
						},
						1000: {
							slidesPerView: 3,
						},
						1400: {
							slidesPerView: 4,
						},
						1920: {
							slidesPerView: 5,
						},
					}}
					style={{
						padding: '8px 0',
					}}
				>
					{artists.map((artist) => (
						<SwiperSlide key={artist.id}>
							<Box sx={{ p: 1 }}>
								<ArtistCard
									artist={artist}
									onSelect={onSelect}
									onDelete={onUpdate}
									artistsLength={artists.length}
									sx={{ maxWidth: '350px', width: '100%' }}
								/>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
				{showNavigation && (
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						sx={{
							width: '100%',
							pointerEvents: 'none',
						}}
					>
						<IconButton
							ref={prevRef}
							disabled={isBeginning}
							sx={{
								pointerEvents: 'auto',
								visibility: isBeginning ? 'hidden' : 'visible',
							}}
						>
							<ArrowBackIosIcon />
						</IconButton>
						<IconButton
							ref={nextRef}
							disabled={isEnd}
							sx={{
								pointerEvents: 'auto',
								visibility: isEnd ? 'hidden' : 'visible',
							}}
						>
							<ArrowForwardIosIcon />
						</IconButton>
					</Box>
				)}
			</Box>
		</Box>
	);
};

ArtistCarousel.propTypes = {
	artists: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string,
			profilePhoto: PropTypes.string,
		})
	).isRequired,
	onSelect: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
};

export default ArtistCarousel;
