// src/components/modules/chatInterface/subcomponents/navigationTabs/ChatSettings.jsx

import React, { useState } from 'react';
import { Box, Grid, Typography, Switch, FormControlLabel } from '@mui/material';
import SettingsOption from './SettingsOption';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import MoodIcon from '@mui/icons-material/Mood';
import SecurityIcon from '@mui/icons-material/Security';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import SaveIcon from '@mui/icons-material/Save';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const aiModels = [
	{ value: 'model-4o', label: 'Model 4o' },
	{ value: 'model-o1-mini', label: 'Model o1 mini' },
];

const responseFormats = [
	{ value: 'detailed', label: 'Detailed' },
	{ value: 'concise', label: 'Concise' },
	{ value: 'bullet-points', label: 'Bullet Points' },
];

const responseTones = [
	{ value: 'formal', label: 'Formal' },
	{ value: 'friendly', label: 'Friendly' },
	{ value: 'neutral', label: 'Neutral' },
	{ value: 'enthusiastic', label: 'Enthusiastic' },
];

const notificationPreferences = [
	{ value: 'all', label: 'All Notifications' },
	{ value: 'mentions', label: 'Only Mentions' },
	{ value: 'none', label: 'No Notifications' },
];

const ChatSettings = () => {
	// Mock state for demonstration
	const [aiModel, setAiModel] = useState('model-4o');
	const [responseFormat, setResponseFormat] = useState('detailed');
	const [responseTone, setResponseTone] = useState('neutral');
	const [notifications, setNotifications] = useState(true);
	const [notificationPreference, setNotificationPreference] = useState('all');
	const [dataPrivacy, setDataPrivacy] = useState('standard');

	return (
		<Box p={3} sx={{ backgroundColor: 'background.default' }}>
			<Typography variant="h4" sx={{ mb: 2 }} gutterBottom>
				Settings
			</Typography>
			<Grid container spacing={3}>
				{/* AI Model Selection */}
				<Grid item xs={12} md={6}>
					<SettingsOption
						label="AI Model"
						defaultValue={aiModel}
						options={aiModels}
						icon={<SettingsOptionIcon type="ai" />}
						tooltip="Select the AI model that powers your chat interactions."
						onChange={(value) => setAiModel(value)}
					/>
				</Grid>

				{/* Response Format Selection */}
				<Grid item xs={12} md={6}>
					<SettingsOption
						label="Response Format"
						defaultValue={responseFormat}
						options={responseFormats}
						icon={<SettingsOptionIcon type="format" />}
						tooltip="Choose how you want the AI to format its responses."
						onChange={(value) => setResponseFormat(value)}
					/>
				</Grid>

				{/* AI Response Tone Selection */}
				<Grid item xs={12} md={6}>
					<SettingsOption
						label="Response Tone"
						defaultValue={responseTone}
						options={responseTones}
						icon={<MoodIcon />}
						tooltip="Select the tone of the AI responses."
						onChange={(value) => setResponseTone(value)}
					/>
				</Grid>

				{/* Notification Preferences */}
				<Grid item xs={12} md={6}>
					<SettingsOption
						label="Notification Preferences"
						defaultValue={notificationPreference}
						options={notificationPreferences}
						icon={<NotificationsIcon />}
						tooltip="Customize your notification settings."
						onChange={(value) => setNotificationPreference(value)}
					/>
				</Grid>

				{/* Enable/Disable Notifications */}
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Switch
								checked={notifications}
								onChange={(e) => setNotifications(e.target.checked)}
								color="primary"
							/>
						}
						label="Enable Notifications"
					/>
				</Grid>

				{/* Data Privacy Settings */}
				<Grid item xs={12} md={6}>
					<SettingsOption
						label="Data Privacy"
						defaultValue={dataPrivacy}
						options={[
							{ value: 'standard', label: 'Standard' },
							{ value: 'enhanced', label: 'Enhanced' },
							{ value: 'strict', label: 'Strict' },
						]}
						icon={<SecurityIcon />}
						tooltip="Manage your data privacy settings."
						onChange={(value) => setDataPrivacy(value)}
					/>
				</Grid>

				{/* Accessibility Options */}
				<Grid item xs={12} md={6}>
					<SettingsOption
						label="Accessibility"
						defaultValue="default"
						options={[
							{ value: 'default', label: 'Default' },
							{ value: 'high-contrast', label: 'High Contrast' },
							{ value: 'large-text', label: 'Large Text' },
						]}
						icon={<AccessibilityNewIcon />}
						tooltip="Customize accessibility options for better usability."
					/>
				</Grid>

				{/* Additional Mock Settings Options */}
				<Grid item xs={12}>
					<Typography variant="subtitle1" gutterBottom>
						Advanced Settings
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<SettingsOption
								label="Custom Shortcuts"
								defaultValue="enabled"
								options={[
									{ value: 'enabled', label: 'Enabled' },
									{ value: 'disabled', label: 'Disabled' },
								]}
								icon={<SettingsOptionIcon type="shortcut" />}
								tooltip="Enable or disable custom keyboard shortcuts."
								onChange={(value) => {
									/* Handle change */
								}}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<SettingsOption
								label="Auto-Save"
								defaultValue="on"
								options={[
									{ value: 'on', label: 'On' },
									{ value: 'off', label: 'Off' },
								]}
								icon={<SettingsOptionIcon type="save" />}
								tooltip="Toggle auto-saving of your chat history."
								onChange={(value) => {
									/* Handle change */
								}}
							/>
						</Grid>
						{/* Add more settings options as needed */}
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

// Helper component to map icon types to actual icons
const SettingsOptionIcon = ({ type }) => {
	switch (type) {
		case 'ai':
			return <AccessibilityNewIcon />;
		case 'format':
			return <ColorLensIcon />;
		case 'shortcut':
			return <ShortcutIcon />;
		case 'save':
			return <SaveIcon />;
		default:
			return <InfoOutlinedIcon />;
	}
};

export default ChatSettings;
