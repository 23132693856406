// ProtectedRoute.js
import { Navigate, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import SessionContext from '../../context/SessionContext';

const ProtectedRoute = ({ children }) => {
	const location = useLocation();
	const { session } = useContext(SessionContext);
	const { isLoggedIn, isFirstLogin } = session;

	if (!isLoggedIn) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	if (isFirstLogin && location.pathname !== '/onboarding') {
		return <Navigate to="/onboarding" state={{ from: location }} replace />;
	}

	return children;
};

export default ProtectedRoute;
