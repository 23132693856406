// src/components/charts/HeatmapLayer.jsx
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const HeatmapLayer = ({ points }) => {
	const map = useMap();

	useEffect(() => {
		if (!map) return;

		const heat = L.heatLayer(points, {
			radius: 30,
			blur: 20,
			maxZoom: 17,
			gradient: {
				0.4: 'blue',
				0.6: 'cyan',
				0.7: 'lime',
				0.8: 'yellow',
				1.0: 'red',
			},
		});

		heat.addTo(map);

		return () => {
			heat.remove();
		};
	}, [map, points]);

	return null;
};

HeatmapLayer.propTypes = {
	points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number).isRequired)
		.isRequired,
};

export default HeatmapLayer;
