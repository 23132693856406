// SessionModal.jsx
import React, { useEffect, useState, useRef } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Button,
	CircularProgress,
} from '@mui/material';
import { styled } from '@mui/system';

// Styled Components
const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: theme.shape.borderRadius,
		padding: theme.spacing(4),
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
	},
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
	...theme.typography.h5,
	textAlign: 'center',
	marginBottom: theme.spacing(3),
	color: theme.palette.text.primary,
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: theme.spacing(3),
}));

const CountdownBox = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	gap: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(1, 4),
	textTransform: 'none',
	...theme.typography.button,
}));

const SessionModal = ({ onExtendSession, open, initialCountdown = 60 }) => {
	const [countdown, setCountdown] = useState(initialCountdown);
	const timerRef = useRef(null); // Use ref to store timer ID

	useEffect(() => {
		if (open) {
			// Reset countdown when modal opens
			setCountdown(initialCountdown);

			// Start countdown
			timerRef.current = setInterval(() => {
				setCountdown((prev) => {
					if (prev <= 1) {
						clearInterval(timerRef.current);
						onExtendSession(false);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
		}

		return () => {
			// Clear timer when modal closes or component unmounts
			if (timerRef.current) {
				clearInterval(timerRef.current);
				timerRef.current = null;
			}
		};
	}, [open, initialCountdown, onExtendSession]);

	return (
		<StyledDialog
			open={open}
			onClose={(event, reason) => {
				if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
					return;
				}
				onExtendSession(false);
			}}
		>
			<StyledDialogTitle>Session Expiring Soon</StyledDialogTitle>
			<StyledDialogContent>
				<Typography variant="body1" align="center">
					Your session is about to expire in {countdown} seconds.
				</Typography>
				<CountdownBox>
					<CircularProgress
						variant="determinate"
						value={(countdown / initialCountdown) * 100}
						size={60}
						thickness={5}
						color="primary"
					/>
					<Typography variant="subtitle1" color="text.primary">
						{countdown}s
					</Typography>
				</CountdownBox>
			</StyledDialogContent>
			<DialogActions
				sx={{
					justifyContent: 'center',
					gap: 3,
					paddingBottom: 2,
					paddingX: 4,
				}}
			>
				<StyledButton
					onClick={() => onExtendSession(true)}
					color="primary"
					variant="contained"
				>
					Extend Session
				</StyledButton>
				<StyledButton
					onClick={() => onExtendSession(false)}
					color="error"
					variant="outlined"
				>
					Log Out
				</StyledButton>
			</DialogActions>
		</StyledDialog>
	);
};

export default SessionModal;
