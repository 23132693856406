import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	Button,
	TextField,
	Typography,
	Grid2, // Using Grid2 for enhanced layout control
	Box,
	useTheme,
} from '@mui/material';
import SpotifyArtistCard from '../../subcomponents/SpotifyArtistCard';

const CareerIdols = ({ artistData, onSave }) => {
	const theme = useTheme();
	const [searchResults, setSearchResults] = useState([]);
	const [careerIdols, setCareerIdols] = useState({
		idol1: {},
		idol2: {},
		idol3: {},
	});

	useEffect(() => {
		if (artistData && artistData.idol1 && artistData.idol2 && artistData.idol3) {
			setCareerIdols(artistData);
		}
	}, [artistData]);

	const formik = useFormik({
		initialValues: {
			searchTerm: '',
		},
		validationSchema: Yup.object({
			searchTerm: Yup.string().required('Search term is required'),
		}),
		onSubmit: (values) => {
			searchArtists(values.searchTerm);
		},
	});

	const searchArtists = async (searchTerm) => {
		try {
			const accessToken =
				sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');
			if (!accessToken) {
				console.error('Failed to get access token');
				return;
			}
			const apiUrl = `${
				process.env.REACT_APP_API_URL
			}/api/spotify/search?q=${encodeURIComponent(searchTerm)}&type=artist`;
			const response = await fetch(apiUrl, {
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(errorText || 'Failed to search artists');
			}

			const data = await response.json();
			setSearchResults(data.artists.items);
		} catch (error) {
			console.error('Error fetching artist data:', error);
		}
	};

	const toggleSelectIdol = (artist) => {
		const spotifyArtistId = artist.spotifyArtistId || artist.id;

		const isSelected = Object.values(careerIdols).some(
			(idol) => idol.spotifyArtistId === spotifyArtistId
		);

		const newIdols = { ...careerIdols };

		if (isSelected) {
			// Remove the artist from selected idols
			Object.keys(newIdols).forEach((key) => {
				if (newIdols[key].spotifyArtistId === spotifyArtistId) {
					newIdols[key] = {}; // Reset the idol data
				}
			});
		} else {
			// Add the artist to the first available idol slot
			if (!newIdols.idol1.spotifyArtistId) {
				newIdols.idol1 = mapArtistData(artist);
			} else if (!newIdols.idol2.spotifyArtistId) {
				newIdols.idol2 = mapArtistData(artist);
			} else if (!newIdols.idol3.spotifyArtistId) {
				newIdols.idol3 = mapArtistData(artist);
			} else {
				alert('You have already selected 3 idols.');
				return;
			}
		}

		setCareerIdols(newIdols); // Update the state
	};

	const mapArtistData = (artist) => ({
		spotifyArtistId: artist.id,
		name: artist.name,
		externalUrl: artist.external_urls.spotify,
		followers: artist.followers.total,
		genres: artist.genres.slice(0, 5), // Limit to 5 genres
		imageUrl: artist.images[0]?.url,
	});

	const handleSave = () => {
		const selectedIdolsCount = Object.values(careerIdols).filter(
			(idol) => idol.spotifyArtistId
		).length;
		if (selectedIdolsCount === 3) {
			onSave(careerIdols);
		} else {
			alert('Please select 3 idols.');
		}
	};

	return (
		<Box
			sx={{
				width: '100%',
				maxWidth: '900px',
				minWidth: { xs: '100%', sm: '600px' },
				p: theme.spacing(3),
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[3],
			}}
		>
			<Typography
				variant="h3"
				sx={{
					mb: theme.spacing(3),
					textAlign: 'center',
					fontWeight: theme.typography.h4.fontWeight,
					color: theme.palette.text.primary,
				}}
			>
				Search Artist Name
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						mb: theme.spacing(3),
						gap: theme.spacing(2),
						flexDirection: { xs: 'column', sm: 'row' },
					}}
				>
					<TextField
						fullWidth
						name="searchTerm"
						placeholder="Search Artist Name"
						value={formik.values.searchTerm}
						onChange={formik.handleChange}
						variant="outlined"
						size="medium"
						sx={{
							maxWidth: '300px',
							backgroundColor: theme.palette.background.default,
							borderRadius: 2,
						}}
						error={formik.touched.searchTerm && Boolean(formik.errors.searchTerm)}
						helperText={formik.touched.searchTerm && formik.errors.searchTerm}
					/>
					<Button
						variant="contained"
						color="primary"
						type="submit"
						sx={{
							height: '56px',
							borderRadius: theme.shape.borderRadius,
							paddingX: theme.spacing(3),
							textTransform: 'none',
							fontWeight: theme.typography.button.fontWeight,
						}}
					>
						Search
					</Button>
				</Box>
			</form>

			<Box sx={{ mb: theme.spacing(4), maxHeight: '400px', overflowY: 'auto' }}>
				<Typography
					variant="h4"
					sx={{
						mb: theme.spacing(2),
						textAlign: 'center',
						fontWeight: theme.typography.h5.fontWeight,
						color: theme.palette.text.primary,
					}}
				>
					Search Results
				</Typography>
				<Grid2 container spacing={3} justifyContent="center">
					{searchResults.length === 0 ? (
						<Grid2 xs={12}>
							<Typography
								variant="body1"
								sx={{ textAlign: 'center', color: theme.palette.text.secondary }}
							>
								No results found.
							</Typography>
						</Grid2>
					) : (
						searchResults.map((artist) => (
							<Grid2 key={artist.id} xs={12} sm={6} md={4}>
								<SpotifyArtistCard
									artist={artist}
									selected={
										!!Object.values(careerIdols).find(
											(idol) => idol.spotifyArtistId === artist.id
										)
									}
									onSelect={() => toggleSelectIdol(artist)}
									type="result"
								/>
							</Grid2>
						))
					)}
				</Grid2>
			</Box>

			<Box sx={{ mb: theme.spacing(4) }}>
				<Typography
					variant="h5"
					sx={{
						mb: theme.spacing(2),
						textAlign: 'center',
						fontWeight: theme.typography.h5.fontWeight,
						color: theme.palette.text.primary,
					}}
				>
					Selected Idols
				</Typography>
				<Grid2 container spacing={3} justifyContent="center">
					{Object.keys(careerIdols).map((idolKey) => (
						<Grid2 key={idolKey} xs={12} sm={6} md={4}>
							{careerIdols[idolKey].name ? (
								<SpotifyArtistCard
									artist={careerIdols[idolKey]}
									selected
									onSelect={() => toggleSelectIdol(careerIdols[idolKey])}
									type="selected"
								/>
							) : (
								<Box
									sx={{
										width: '100%',
										minHeight: '200px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										border: `2px dashed ${theme.palette.divider}`,
										borderRadius: 2,
										backgroundColor: theme.palette.background.default,
									}}
								>
									<Typography
										variant="body2"
										color="textSecondary"
										sx={{ textAlign: 'center' }}
									>
										No Idol Selected
									</Typography>
								</Box>
							)}
						</Grid2>
					))}
				</Grid2>
			</Box>

			<Box sx={{ display: 'flex', justifyContent: 'center', mt: theme.spacing(2) }}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSave}
					disabled={
						Object.values(careerIdols).filter((idol) => idol.spotifyArtistId)
							.length !== 3
					}
					sx={{
						paddingX: theme.spacing(4),
						paddingY: theme.spacing(1.5),
						fontWeight: theme.typography.button.fontWeight,
						textTransform: 'none',
					}}
				>
					Save & Close
				</Button>
			</Box>
		</Box>
	);
};

export default CareerIdols;
