import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress, Typography } from '@mui/material';

const ProgressBar = ({ step, totalSteps, showLabel = true }) => {
	const progressPercentage = Math.min((step / totalSteps) * 100, 100); // Ensure it doesn't exceed 100%

	return (
		<Box display="flex" alignItems="center" width="100%">
			<Box width="100%" mr={1}>
				<LinearProgress
					variant="determinate"
					value={progressPercentage}
					sx={{ height: '20px', borderRadius: '10px' }}
				/>
			</Box>
			{showLabel && (
				<Box minWidth={35}>
					<Typography variant="body2" color="textSecondary">{`${Math.round(
						progressPercentage
					)}%`}</Typography>
				</Box>
			)}
		</Box>
	);
};

ProgressBar.propTypes = {
	step: PropTypes.number.isRequired,
	totalSteps: PropTypes.number.isRequired,
	showLabel: PropTypes.bool,
};

export default ProgressBar;
