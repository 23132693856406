// components/modules/chatInterface/subcomponents/FileDownloader.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Link } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';

/**
 * FileDownloader Component
 *
 * Provides a button to download files.
 *
 * Props:
 * - fileUrl: The URL of the file to download.
 * - fileName: The name to save the file as.
 */
const FileDownloader = ({ fileUrl, fileName }) => {
	return (
		<Button
			variant="outlined"
			color="primary"
			startIcon={<GetAppIcon />}
			component={Link}
			href={fileUrl}
			download={fileName}
			sx={{ mt: 1 }}
		>
			Download {fileName}
		</Button>
	);
};

FileDownloader.propTypes = {
	fileUrl: PropTypes.string.isRequired,
	fileName: PropTypes.string.isRequired,
};

export default React.memo(FileDownloader);
