import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import CampaignItem from './CampaignItem';
import CampaignsToolbar from './CampaignsToolbar';
import { fetchAllCampaigns } from '../../../../utils/apis/marketingAssistant/campaignUtils';
import ArtistContext from '../../../../context/ArtistContext';
import UserContext from '../../../../context/UserContext';
import {
	Box,
	Card,
	CardContent,
	Typography,
	CircularProgress,
	Alert,
	Grid,
	useTheme,
} from '@mui/material';
import LoadingSpinner from '../../../common/LoadingSpinner';

const AllCampaignsSection = ({ onSelectConversation, refreshTrigger, onUpdate }) => {
	const theme = useTheme();
	const { artistData } = useContext(ArtistContext);
	const [allCampaigns, setAllCampaigns] = useState([]);
	const [filteredCampaigns, setFilteredCampaigns] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	// Filters and Sorting
	const [searchQuery, setSearchQuery] = useState('');
	const [statusFilter, setStatusFilter] = useState('all');
	const [goalFilter, setGoalFilter] = useState('all');
	const [sortOption, setSortOption] = useState('dateDesc');

	// Fetch all campaigns from backend
	const fetchAllCampaignsFromAPI = async () => {
		setLoading(true);
		setError(null);
		try {
			const campaignsData = await fetchAllCampaigns(artistData.artistId);
			setAllCampaigns(campaignsData);
		} catch (err) {
			setError(err.message || 'Error fetching campaigns.');
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchAllCampaignsFromAPI();
	}, [artistData.artistId, refreshTrigger]);

	// Apply filters and sorting
	useEffect(() => {
		let campaigns = [...allCampaigns];

		// Filter by search query
		if (searchQuery) {
			campaigns = campaigns.filter((campaign) =>
				campaign.project_name.toLowerCase().includes(searchQuery.toLowerCase())
			);
		}

		// Filter by status
		if (statusFilter !== 'all') {
			campaigns = campaigns.filter((campaign) => campaign.status === statusFilter);
		}

		// Filter by goal
		if (goalFilter !== 'all') {
			campaigns = campaigns.filter(
				(campaign) => campaign.marketing_goal === goalFilter
			);
		}

		// Sort campaigns
		campaigns.sort((a, b) => {
			if (sortOption === 'dateAsc') {
				return new Date(a.updated_at) - new Date(b.updated_at);
			} else if (sortOption === 'dateDesc') {
				return new Date(b.updated_at) - new Date(a.updated_at);
			} else {
				return 0;
			}
		});

		setFilteredCampaigns(campaigns);
	}, [allCampaigns, searchQuery, statusFilter, goalFilter, sortOption]);

	return (
		<>
			{loading && <LoadingSpinner isLoading={true} />}
			<Box sx={{ p: { xs: 1, sm: 2, md: 3 } }}>
				<Typography variant="h4" sx={{ textAlign: 'left' }} gutterBottom>
					All Campaigns
				</Typography>
				<Box sx={{ mt: 2 }}>
					<CampaignsToolbar
						searchQuery={searchQuery}
						setSearchQuery={setSearchQuery}
						statusFilter={statusFilter}
						setStatusFilter={setStatusFilter}
						goalFilter={goalFilter}
						setGoalFilter={setGoalFilter}
						sortOption={sortOption}
						setSortOption={setSortOption}
					/>
				</Box>
				{error && !loading && (
					<Alert severity="error" sx={{ mt: 2 }}>
						{error}
					</Alert>
				)}
				{!loading && !error && (
					<Grid container spacing={2} sx={{ mt: 2 }}>
						{filteredCampaigns.map((campaign) => (
							<Grid
								item
								key={campaign.id}
								xs={12}
								sm={6}
								md={4}
								lg={3}
								xl={2}
								sx={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<Box
									sx={{
										width: '100%',
										maxWidth: 350,
									}}
								>
									<CampaignItem
										campaign={campaign}
										onSelect={onSelectConversation}
										onUpdate={onUpdate}
									/>
								</Box>
							</Grid>
						))}
					</Grid>
				)}
				{!loading && !error && filteredCampaigns.length === 0 && (
					<Card
						sx={{
							backgroundColor: theme.palette.background.paper,
							height: '100%',
							mt: 2,
						}}
					>
						<CardContent>
							<Typography variant="body2" sx={{ mt: 2 }}>
								No campaigns found.
							</Typography>
						</CardContent>
					</Card>
				)}
			</Box>
		</>
	);
};

AllCampaignsSection.propTypes = {
	onSelectConversation: PropTypes.func.isRequired,
	refreshTrigger: PropTypes.number,
	onUpdate: PropTypes.func.isRequired,
};

export default AllCampaignsSection;
