import React, { useContext } from 'react';
import LoadingSpinner from '../components/common/LoadingSpinner';
import ArtistContext from './ArtistContext';
import UserContext from './UserContext';
import SessionContext from './SessionContext';

const LoadingWrapper = ({ children }) => {
	const { session } = useContext(SessionContext);
	const { loading: artistLoading } = useContext(ArtistContext);
	const { loading: userLoading } = useContext(UserContext);

	const isLoading = artistLoading || userLoading;

	if (isLoading && session.isLoggedIn) {
		return <LoadingSpinner isLoading={true} />;
	}

	return children;
};

export default LoadingWrapper;
