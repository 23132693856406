import React, { useEffect, useState } from 'react';
import {
	Button,
	TextField,
	Typography,
	Paper,
	Box,
	Divider,
	Grid,
} from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import InstagramData from './InstagramData';
import { useTheme } from '@mui/material/styles';

const InstagramProfile = ({
	artistData: instagram,
	onSave,
	onComplete,
	onCancel,
}) => {
	const theme = useTheme();
	const [instagramProfile, setInstagramProfile] = useState({
		selectedProfile: {},
		analyticsData: {},
	});
	const [step, setStep] = useState(1);

	// CTA Button to Instagram Analytics Page
	const instagramAnalyticsURL = 'https://www.instagram.com/accounts/insights/';

	// Validation schema using Yup
	const validationSchemaStep1 = Yup.object().shape({
		username: Yup.string()
			.trim()
			.matches(
				/^[a-zA-Z0-9_.@]+$/,
				'Username can only contain letters, numbers, underscores, and periods.'
			)
			.min(3, 'Username must be at least 3 characters long.')
			.max(30, 'Username cannot exceed 30 characters.')
			.required('Instagram Username is required.'),
		followers: Yup.number()
			.typeError('Followers must be a valid number.')
			.integer('Followers must be a whole number.')
			.min(0, 'Followers cannot be negative.')
			.max(1000000000, 'Followers cannot exceed 1,000,000,000.')
			.required('Followers count is required.'),
		following: Yup.number()
			.typeError('Following must be a valid number.')
			.integer('Following must be a whole number.')
			.min(0, 'Following cannot be negative.')
			.max(1000000000, 'Following cannot exceed 1,000,000,000.')
			.required('Following count is required.'),
		posts: Yup.number()
			.typeError('Posts must be a valid number.')
			.integer('Posts must be a whole number.')
			.min(0, 'Posts cannot be negative.')
			.max(1000000, 'Posts cannot exceed 1,000,000.')
			.required('Number of posts is required.'),
	});

	// Initialize Formik
	const formik = useFormik({
		initialValues: {
			username: instagram?.selectedProfile?.username || '',
			followers: instagram?.selectedProfile?.followers || '',
			following: instagram?.selectedProfile?.following || '',
			posts: instagram?.selectedProfile?.posts || '',
		},
		validationSchema: validationSchemaStep1,
		onSubmit: (values) => {
			const updatedProfile = {
				selectedProfile: values,
				analyticsData: instagramProfile.analyticsData,
			};
			setInstagramProfile(updatedProfile);
			onSave(updatedProfile);
			formik.setSubmitting(false);
			setStep(2);
		},
		enableReinitialize: true,
	});

	useEffect(() => {
		if (instagram) {
			setInstagramProfile(instagram);
		}
	}, [instagram]);

	const handleSaveAndComplete = (updatedData) => {
		const updatedInstagramProfile = {
			...instagramProfile,
			analyticsData: updatedData,
		};
		setInstagramProfile(updatedInstagramProfile);
		onSave(updatedInstagramProfile);
		onComplete();
	};

	return (
		<FormikProvider value={formik}>
			{step === 1 && (
				<Box
					sx={{
						width: '100%',
						maxWidth: '600px',
						margin: '0 auto',
						padding: 2,
						backgroundColor: 'background.paper',
						borderRadius: 2,
					}}
				>
					<form onSubmit={formik.handleSubmit}>
						{/* Header Section */}
						<Box textAlign="center" mb={4}>
							<Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
								Instagram Profile Data
							</Typography>
							<Button
								variant="contained"
								color="primary"
								href={instagramAnalyticsURL}
								target="_blank"
								sx={{ ...theme.typography.button, mb: 2 }}
							>
								Go to Instagram Analytics
							</Button>
							<Typography variant="subtitle1" sx={{ ...theme.typography.h4, mb: 2 }}>
								Last 28 Days
							</Typography>
							<Divider sx={{ mb: 2 }} />
						</Box>

						{/* Input Fields */}
						<Paper elevation={3} sx={{ padding: 3, mb: 3 }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label="Instagram Username"
										name="username"
										value={formik.values.username}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.username && Boolean(formik.errors.username)
										}
										helperText={formik.touched.username && formik.errors.username}
										variant="outlined"
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										label="Followers"
										name="followers"
										type="text"
										inputMode="numeric"
										value={formik.values.followers}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.followers && Boolean(formik.errors.followers)
										}
										helperText={formik.touched.followers && formik.errors.followers}
										variant="outlined"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										label="Following"
										name="following"
										type="text"
										inputMode="numeric"
										value={formik.values.following}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={
											formik.touched.following && Boolean(formik.errors.following)
										}
										helperText={formik.touched.following && formik.errors.following}
										variant="outlined"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</Grid>
								<Grid item xs={12} sm={4}>
									<TextField
										fullWidth
										label="Posts"
										name="posts"
										type="text"
										inputMode="numeric"
										value={formik.values.posts}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										error={formik.touched.posts && Boolean(formik.errors.posts)}
										helperText={formik.touched.posts && formik.errors.posts}
										variant="outlined"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</Grid>
							</Grid>
						</Paper>

						{/* Next Button */}
						<Box
							textAlign="center"
							display="flex"
							justifyContent="center"
							mt={2}
							gap={2}
						>
							<Button
								variant="outlined"
								color="secondary"
								onClick={onCancel}
								sx={{ ...theme.typography.button }}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={!formik.isValid || formik.isSubmitting}
								size="large"
								sx={{ ...theme.typography.button }}
							>
								Save & Next
							</Button>
						</Box>
					</form>
				</Box>
			)}

			{step === 2 && (
				<InstagramData
					artistData={instagramProfile.analyticsData}
					onSave={handleSaveAndComplete}
					onCancel={onCancel}
				/>
			)}
		</FormikProvider>
	);
};

export default InstagramProfile;
