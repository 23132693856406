// src/utils/campaignUtils.js

import axios from 'axios';

// Fetch active campaigns from backend
export const fetchAllCampaigns = async (artistId) => {
	const accessToken =
		localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
	const response = await axios.get(
		`${process.env.REACT_APP_API_URL}/api/campaigns/all/${artistId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
	return response.data.campaigns;
};

// Fetch active campaigns from backend
export const fetchActiveCampaigns = async (artistId) => {
	const accessToken =
		localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
	const response = await axios.get(
		`${process.env.REACT_APP_API_URL}/api/campaigns/active/${artistId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
	return response.data.campaigns;
};

// Update a campaign's details
export const updateCampaign = async (campaignId, updatedData) => {
	const accessToken =
		localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
	const response = await axios.put(
		`${process.env.REACT_APP_API_URL}/api/campaigns/${campaignId}`,
		updatedData,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		}
	);
	return response.data;
};

// Delete a campaign
export const deleteCampaign = async (campaignId) => {
	const accessToken =
		localStorage.getItem('accessToken') || sessionStorage.getItem('accessToken');
	const response = await axios.delete(
		`${process.env.REACT_APP_API_URL}/api/campaigns/${campaignId}`,
		{
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);
	return response.data;
};
