// components/modules/chatInterface/subcomponents/messageTypes/TypingIndicator.jsx

import React from 'react';
import { Box, Typography, keyframes } from '@mui/material';
import PropTypes from 'prop-types';

// Define the animation for the dots
const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
`;

// Styling for the dots
const Dot = ({ delay }) => (
	<Box
		sx={{
			width: 8,
			height: 8,
			margin: '0 2px',
			backgroundColor: 'grey.500',
			borderRadius: '50%',
			display: 'inline-block',
			animation: `${bounce} 1.4s infinite ease-in-out`,
			animationDelay: delay,
		}}
	/>
);

Dot.propTypes = {
	delay: PropTypes.string.isRequired,
};

const TypingIndicator = () => {
	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				backgroundColor: 'grey.300',
				color: 'text.primary',
				borderRadius: 2,
				wordBreak: 'break-word',
			}}
			aria-live="polite"
		>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<Dot delay="0s" />
				<Dot delay="0.2s" />
				<Dot delay="0.4s" />
			</Box>
			<Typography variant="body2" sx={{ marginLeft: 1, color: 'black' }}>
				AI is typing...
			</Typography>
		</Box>
	);
};

export default TypingIndicator;
