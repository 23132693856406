// src/pages/homePage/StartPage.jsx
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import WelcomeMessage from './subcomponents/WelcomeMessage';
import ArtistOverview from './subcomponents/ArtistOverview';
import ArtistPerformanceCharts from './subcomponents/ArtistPerformanceCharts';
import Notifications from './subcomponents/Notifications';
import RecentCampaignsCarousel from '../modules/marketingAssistant/homePage/RecentCampaignsCarousel';
import { performanceData as mockPerformanceData } from '../../mockData/performanceData';
import { geographicalData as mockGeographicalData } from '../../mockData/geographicalData';
import { notifications as mockNotifications } from '../../mockData/notifications';
import { Box, Grid, useTheme } from '@mui/material';
import LoadingSpinner from '../common/LoadingSpinner';
import ArtistContext from '../../context/ArtistContext';
import UserContext from '../../context/UserContext';

const StartPage = () => {
	const { artistData } = useContext(ArtistContext);
	const { userData } = useContext(UserContext);
	const navigate = useNavigate();
	const theme = useTheme();
	const [performanceData, setPerformanceData] = useState(null);
	const [geographicalData, setGeographicalData] = useState(null);
	const [notifications, setNotifications] = useState([]);
	const [refreshTrigger, setRefreshTrigger] = useState(0);
	const isDataLoading = !artistData || Object.keys(artistData).length === 0;

	useEffect(() => {
		const loadData = async () => {
			try {
				setPerformanceData(mockPerformanceData);
				setGeographicalData(mockGeographicalData);
				setNotifications(mockNotifications);
			} catch (error) {
				console.error('Error loading data:', error);
			}
		};

		if (artistData?.artistId) {
			loadData();
		}
	}, [artistData]);

	const handleSelectConversation = (convId) => {
		navigate(`/marketing-assistant/c/${convId}`);
	};

	const handleRefresh = useCallback(() => {
		setRefreshTrigger((prev) => prev + 1);
	}, []);

	return (
		<Box
			sx={{
				padding: theme.spacing(4),
				backgroundColor: theme.palette.background.default,
				minHeight: '100vh',
			}}
		>
			{isDataLoading ? (
				<LoadingSpinner isLoading={true} />
			) : (
				<Grid container spacing={4}>
					{/* Centered Welcome Message */}
					<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
						<WelcomeMessage
							userName={userData.firstName || 'User'}
							artistName={artistData.stageName}
						/>
					</Grid>

					{/* Artist Overview and Notifications side by side */}
					<Grid container item spacing={4} xs={12}>
						<Grid item xs={12} md={6}>
							<ArtistOverview artistData={artistData} />
						</Grid>
						<Grid item xs={12} md={6}>
							<Notifications notifications={notifications} />
						</Grid>
					</Grid>

					{/* Recent Campaigns Carousel */}
					<Grid item xs={12}>
						<RecentCampaignsCarousel
							onSelectConversation={handleSelectConversation}
							refreshTrigger={refreshTrigger}
							onUpdate={handleRefresh}
						/>
					</Grid>

					{/* Full-width Artist Performance Charts */}
					<Grid item xs={12}>
						{performanceData && (
							<ArtistPerformanceCharts
								performanceData={performanceData}
								geographicalData={geographicalData}
							/>
						)}
					</Grid>
				</Grid>
			)}
		</Box>
	);
};

export default StartPage;
