// components/modules/chatInterface/subcomponents/messageTypes/TableMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Typography,
	Box,
} from '@mui/material';

/**
 * TableMessage Component
 *
 * Renders data in a table format.
 *
 * Props:
 * - data: An object containing 'headers' and 'rows'.
 * - text: Description or title for the table.
 */
const TableMessage = ({ data, text }) => {
	if (!data || !data.headers || !data.rows) {
		return (
			<Typography variant="body2" color="error">
				Invalid table data.
			</Typography>
		);
	}

	return (
		<Box sx={{ mb: 2 }}>
			{text && (
				<Typography variant="subtitle1" gutterBottom>
					{text}
				</Typography>
			)}
			<TableContainer
				component={Paper}
				variant="outlined"
				sx={{
					mt: 2,
					backgroundColor: 'background.paper',
					boxShadow: 3,
					width: '100%',
					maxHeight: 400,
					overflow: 'auto',
					'@media (max-width:600px)': {
						maxWidth: '100%',
						maxHeight: 300,
					},
				}}
			>
				<Table aria-label="data table">
					<TableHead>
						<TableRow>
							{data.headers.map((header, index) => (
								<TableCell
									key={index}
									sx={{
										fontWeight: 'bold',
										borderBottom: '2px solid rgba(224, 224, 224, 1)',
										padding: '12px 16px',
										backgroundColor: 'grey.200',
										textAlign: index === 0 ? 'left' : 'center',
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
									}}
								>
									{header}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data.rows.map((row, rowIndex) => (
							<TableRow
								key={rowIndex}
								sx={{
									backgroundColor: rowIndex % 2 === 0 ? 'grey.100' : 'white',
								}}
							>
								{row.map((cell, cellIndex) => (
									<TableCell
										key={cellIndex}
										sx={{
											borderBottom: '1px solid rgba(224, 224, 224, 1)',
											padding: '12px 16px',
											textAlign: cellIndex === 0 ? 'left' : 'center',
											wordBreak: 'break-word',
										}}
									>
										{cell}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};

TableMessage.propTypes = {
	data: PropTypes.shape({
		headers: PropTypes.arrayOf(PropTypes.string).isRequired,
		rows: PropTypes.arrayOf(PropTypes.array).isRequired,
	}).isRequired,
	text: PropTypes.string,
};

TableMessage.defaultProps = {
	text: '',
};

export default React.memo(TableMessage);
