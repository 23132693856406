// src/utils/validation/validation.js

export const validateRequired = (value) => {
	if (!value || value.toString().trim() === '') {
		return 'This field is required.';
	}
	return '';
};

export const validatePhoneNumber = (value) => {
	const phonePattern = /^[0-9]{10,15}$/;
	if (!phonePattern.test(value)) {
		return 'Enter a valid phone number (10-15 digits).';
	}
	return '';
};

export const validateNumber = (value, min = 0) => {
	if (isNaN(value)) {
		return 'Enter a valid number.';
	}
	if (Number(value) < min) {
		return `Number must be at least ${min}.`;
	}
	return '';
};

export const validateURL = (value) => {
	const urlPattern = new RegExp(
		/^(https?:\/\/)?((([a-z\d](([a-z\d-]*[a-z\d])?))\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i
	);
	if (!urlPattern.test(value)) {
		return 'Enter a valid URL.';
	}
	return '';
};

export const validateMinLength = (value, minLength) => {
	if (value.length < minLength) {
		return `Must be at least ${minLength} characters.`;
	}
	return '';
};

export const validateMaxLength = (value, maxLength) => {
	if (value.length > maxLength) {
		return `Must be no more than ${maxLength} characters.`;
	}
	return '';
};

export const isPlatformComplete = (profiles, platformKey) => {
	const data = profiles[platformKey];

	// Define paths to exclude from validation
	const excludedPaths = [
		['selectedProfile', 'genres'], // Adjust paths as needed
		// Add more paths if there are other fields to exclude
	];

	// Recursive helper function to validate all nested fields
	const hasValidFields = (value, currentPath = []) => {
		// Check if the current path is excluded
		if (isPathExcluded(currentPath)) {
			return true; // Skip validation for excluded paths
		}

		if (Array.isArray(value)) {
			// Check that the array is not empty
			if (value.length === 0) return false;
			// Every item in the array must have valid fields
			return value.every((item, index) =>
				hasValidFields(item, [...currentPath, index])
			);
		} else if (typeof value === 'object' && value !== null) {
			// Check that the object is not empty
			if (Object.keys(value).length === 0) return false;
			// Every property in the object must have valid fields
			return Object.entries(value).every(([key, val]) =>
				hasValidFields(val, [...currentPath, key])
			);
		} else {
			// For primitive values, use the isValid function
			return isValid(value);
		}
	};

	// Helper function to determine if the current path is excluded
	const isPathExcluded = (path) => {
		return excludedPaths.some((excludedPath) => {
			if (excludedPath.length > path.length) return false;
			for (let i = 0; i < excludedPath.length; i++) {
				if (excludedPath[i] !== path[i]) return false;
			}
			return true;
		});
	};

	// Helper function to validate individual primitive values
	const isValid = (value) => {
		if (typeof value === 'string') {
			return value.trim() !== '';
		}
		if (typeof value === 'number') {
			// Assuming that 0 is a valid number. Adjust if necessary.
			return true;
		}
		return value !== null && value !== undefined;
	};

	// Ensure that data, analyticsData, and selectedProfile exist and are objects
	if (
		!data ||
		typeof data !== 'object' ||
		!data.analyticsData ||
		typeof data.analyticsData !== 'object' ||
		!data.selectedProfile ||
		typeof data.selectedProfile !== 'object'
	) {
		return false;
	}

	// Validate all fields in analyticsData
	const analyticsDataComplete = hasValidFields(data.analyticsData, [
		'analyticsData',
	]);

	// Validate all fields in selectedProfile
	const selectedProfileComplete = hasValidFields(data.selectedProfile, [
		'selectedProfile',
	]);

	return analyticsDataComplete && selectedProfileComplete;
};
