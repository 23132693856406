// src/components/initialOnboarding/artistOnboarding5/CareerAssessmentRecap.js

import React, { useState } from 'react';
import {
	Card,
	CardContent,
	Typography,
	Tabs,
	Tab,
	Box,
	List,
	ListItem,
	ListItemText,
	Grid,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import HistoryIcon from '@mui/icons-material/History';
import FlagIcon from '@mui/icons-material/Flag';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { useTheme } from '@mui/material/styles';

const CareerAssessmentRecap = ({
	immediateNeeds,
	recentActivities,
	careerObjectives,
}) => {
	const theme = useTheme();
	const [tabIndex, setTabIndex] = useState(0);

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
	};

	return (
		<Card
			sx={{
				backgroundColor: 'background.paper',
				boxShadow: 3,
				borderRadius: 2,
				height: '100%',
			}}
		>
			<CardContent>
				<Typography
					variant="h6"
					sx={{
						...theme.typography.h4,
						textAlign: 'center',
						color: 'primary.main',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						mb: 1,
						mt: 1,
						gap: 1,
					}}
				>
					<AssessmentIcon /> Career Assessment
				</Typography>

				{/* Tabs for Navigation */}
				<Tabs
					value={tabIndex}
					onChange={handleTabChange}
					variant="fullWidth"
					indicatorColor="primary"
					textColor="primary"
					sx={{ marginBottom: 1 }}
				>
					<Tab icon={<HelpIcon />} label="Immediate Needs" />
					<Tab icon={<HistoryIcon />} label="Recent Activities" />
					<Tab icon={<FlagIcon />} label="Career Objectives" />
				</Tabs>

				{/* Tab Panels */}
				<Box>
					{/* Immediate Needs Tab */}
					{tabIndex === 0 && (
						<Box>
							{immediateNeeds?.length > 0 ? (
								<List dense>
									{immediateNeeds?.slice(0, 3).map((need, index) => (
										<ListItem key={index} sx={{ justifyContent: 'center', py: 0.5 }}>
											<ListItemText
												primary={need.title}
												sx={{ textAlign: 'center' }}
											/>
										</ListItem>
									))}
									{immediateNeeds?.length > 3 && (
										<ListItem sx={{ justifyContent: 'center', py: 0.5 }}>
											<ListItemText
												primary={`+${immediateNeeds?.length - 3} more`}
												sx={{
													textAlign: 'center',
													fontStyle: 'italic',
													color: 'text.secondary',
												}}
											/>
										</ListItem>
									)}
								</List>
							) : (
								<Typography variant="body1" align="center">
									No immediate needs selected.
								</Typography>
							)}
						</Box>
					)}

					{/* Recent Activities Tab */}
					{tabIndex === 1 && (
						<Box>
							{recentActivities?.length > 0 ? (
								<List dense>
									{recentActivities?.slice(0, 3).map((activity, index) => (
										<ListItem key={index} sx={{ justifyContent: 'center', py: 0.5 }}>
											<ListItemText
												primary={activity.category || 'No category'}
												sx={{ textAlign: 'center' }}
											/>
										</ListItem>
									))}
									{recentActivities?.length > 3 && (
										<ListItem sx={{ justifyContent: 'center', py: 0.5 }}>
											<ListItemText
												primary={`+${recentActivities?.length - 3} more`}
												sx={{
													textAlign: 'center',
													fontStyle: 'italic',
													color: 'text.secondary',
												}}
											/>
										</ListItem>
									)}
								</List>
							) : (
								<Typography variant="body1" align="center">
									No recent activities recorded.
								</Typography>
							)}
						</Box>
					)}

					{/* Career Objectives Tab */}
					{tabIndex === 2 && (
						<Box sx={{ padding: 1 }}>
							{careerObjectives ? (
								<Grid container spacing={2}>
									{/* Long-Term Goals Column */}
									<Grid item xs={12} md={4}>
										<Typography
											variant="subtitle2"
											sx={{ textAlign: 'center', fontWeight: 'bold' }}
										>
											Long-Term Goals
										</Typography>
										<List dense>
											{careerObjectives?.longTerm &&
												Object.keys(careerObjectives?.longTerm)
													.filter(
														(key) =>
															key.startsWith('longTermGoal') &&
															key.endsWith('Category')
													)
													.slice(0, 2) // Adjust the slice as needed
													.map((key, index) => {
														const goalNumber = key.match(/\d+/)[0];
														const primaryCategory = careerObjectives?.longTerm[key];
														const secondaryCategory =
															careerObjectives?.longTerm[
																`longTermGoal${goalNumber}Secondary`
															] || 'N/A'; // Fallback if secondary category is missing

														return (
															<ListItem
																key={index}
																sx={{ justifyContent: 'center', py: 0.25 }}
															>
																<ListItemText
																	primary={`${primaryCategory} - ${secondaryCategory}`}
																	sx={{ textAlign: 'center' }}
																/>
															</ListItem>
														);
													})}
											{careerObjectives?.longTerm &&
												Object.keys(careerObjectives?.longTerm).filter(
													(key) =>
														key.startsWith('longTermGoal') &&
														key.endsWith('Category')
												).length > 2 && (
													<ListItem sx={{ justifyContent: 'center', py: 0.25 }}>
														<ListItemText
															primary={`+${
																Object.keys(careerObjectives?.longTerm).filter(
																	(key) =>
																		key.startsWith('longTermGoal') &&
																		key.endsWith('Category')
																).length - 2
															} more`}
															sx={{
																textAlign: 'center',
																fontStyle: 'italic',
																color: 'text.secondary',
															}}
														/>
													</ListItem>
												)}
										</List>
									</Grid>

									{/* Short-Term Goals Column */}
									<Grid item xs={12} md={4}>
										<Typography
											variant="subtitle2"
											sx={{ textAlign: 'center', fontWeight: 'bold' }}
										>
											Short-Term Goals
										</Typography>
										<List dense>
											{careerObjectives?.shortTerm &&
												Object.keys(careerObjectives?.shortTerm)
													.filter(
														(key) =>
															key.startsWith('shortTermGoal') &&
															!key.includes('Secondary')
													)
													.slice(0, 2) // Adjust the slice as needed
													.map((key, index) => {
														const goalNumber = key.match(/\d+/)[0];
														const primaryGoal = careerObjectives?.shortTerm[key];
														const secondaryGoal =
															careerObjectives?.shortTerm[
																`shortTermSecondaryGoal${goalNumber}`
															] || 'N/A'; // Fallback if secondary goal is missing

														return (
															<ListItem
																key={index}
																sx={{ justifyContent: 'center', py: 0.25 }}
															>
																<ListItemText
																	primary={`${primaryGoal} - ${secondaryGoal}`}
																	sx={{ textAlign: 'center' }}
																/>
															</ListItem>
														);
													})}
											{careerObjectives?.shortTerm &&
												Object.keys(careerObjectives?.shortTerm).filter(
													(key) =>
														key.startsWith('shortTermGoal') &&
														!key.includes('Secondary')
												).length > 2 && (
													<ListItem sx={{ justifyContent: 'center', py: 0.25 }}>
														<ListItemText
															primary={`+${
																Object.keys(careerObjectives?.shortTerm).filter(
																	(key) =>
																		key.startsWith('shortTermGoal') &&
																		!key.includes('Secondary')
																).length - 2
															} more`}
															sx={{
																textAlign: 'center',
																fontStyle: 'italic',
																color: 'text.secondary',
															}}
														/>
													</ListItem>
												)}
										</List>
									</Grid>

									{/* Key Milestones Column */}
									<Grid item xs={12} md={4}>
										<Typography
											variant="subtitle2"
											sx={{ textAlign: 'center', fontWeight: 'bold' }}
										>
											Key Milestones
										</Typography>
										<List dense>
											{careerObjectives?.milestones &&
												Object.keys(careerObjectives?.milestones)
													.filter(
														(key) =>
															key.startsWith('milestone') && key.endsWith('Category')
													)
													.slice(0, 2) // Adjust the slice as needed
													.map((key, index) => {
														const milestoneNumber = key.match(/\d+/)[0];
														const primaryCategory =
															careerObjectives?.milestones[key];
														const secondaryCategory =
															careerObjectives?.milestones[
																`milestone${milestoneNumber}Secondary`
															] || 'N/A'; // Fallback if secondary category is missing

														return (
															<ListItem
																key={index}
																sx={{ justifyContent: 'center', py: 0.25 }}
															>
																<ListItemText
																	primary={`${primaryCategory} - ${secondaryCategory}`}
																	sx={{ textAlign: 'center' }}
																/>
															</ListItem>
														);
													})}
											{careerObjectives?.milestones &&
												Object.keys(careerObjectives?.milestones).filter(
													(key) =>
														key.startsWith('milestone') && key.endsWith('Category')
												).length > 2 && (
													<ListItem sx={{ justifyContent: 'center', py: 0.25 }}>
														<ListItemText
															primary={`+${
																Object.keys(careerObjectives?.milestones).filter(
																	(key) =>
																		key.startsWith('milestone') &&
																		key.endsWith('Category')
																).length - 2
															} more`}
															sx={{
																textAlign: 'center',
																fontStyle: 'italic',
																color: 'text.secondary',
															}}
														/>
													</ListItem>
												)}
										</List>
									</Grid>
								</Grid>
							) : (
								<Typography variant="body1" align="center">
									No career objectives provided.
								</Typography>
							)}
						</Box>
					)}
				</Box>
			</CardContent>
		</Card>
	);
};

export default CareerAssessmentRecap;
