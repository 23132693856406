import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Card, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EmailVerificationModal from '../../components/modals/EmailVerificationModal';
import MAVALogo from '../../assets/MAVALogo.png';

const Signup = () => {
	const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		password: '',
	});
	const [modalOpen, setModalOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/api/auth/signup`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(formData),
				}
			);

			const data = await response.json();

			if (response.ok) {
				setModalOpen(true);
			} else {
				setErrorMessage(data.message || 'Signup failed');
			}
		} catch (error) {
			setErrorMessage('Error signing up. Please try again later.');
		}
	};

	const handleCloseModal = () => {
		setModalOpen(false);
		navigate('/login');
	};

	return (
		<Box
			sx={{
				backgroundColor: 'background.login',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 2,
			}}
		>
			<Card
				className="fade-in"
				sx={{
					padding: 4,
					borderRadius: 3,
					width: '100%',
					maxWidth: 450,
					boxShadow: 3,
					textAlign: 'center',
					backgroundColor: 'background.paper',
					'&:hover': {
						transform: 'none',
					},
				}}
			>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<img src={MAVALogo} alt="MAVA Logo" style={{ maxWidth: 225 }} />
					</Grid>
					<Grid item>
						<Typography variant="h3" gutterBottom>
							Create a New Account
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body1" sx={{ mb: 2 }}>
							Start making better data-driven decisions today.
						</Typography>
					</Grid>
					{errorMessage && (
						<Grid item>
							<Typography color="error" variant="caption">
								{errorMessage}
							</Typography>
						</Grid>
					)}
					<Grid item>
						<form onSubmit={handleSubmit}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										label="First Name"
										name="firstName"
										variant="outlined"
										fullWidth
										value={formData.firstName}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										label="Last Name"
										name="lastName"
										variant="outlined"
										fullWidth
										value={formData.lastName}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Email / Username"
										name="email"
										variant="outlined"
										fullWidth
										value={formData.email}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="New Password"
										type="password"
										name="password"
										variant="outlined"
										fullWidth
										value={formData.password}
										onChange={handleChange}
										required
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
										sx={{ textTransform: 'none', borderRadius: 2 }}
									>
										Continue
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
					<Grid item>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}>
							— OR —
						</Typography>
					</Grid>
					<Grid item container spacing={1}>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								disabled
								sx={{ textTransform: 'none', borderRadius: 2 }}
							>
								Sign up with Google
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								disabled
								sx={{ textTransform: 'none', borderRadius: 2 }}
							>
								Sign up with Microsoft
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								fullWidth
								disabled
								sx={{ textTransform: 'none', borderRadius: 2 }}
							>
								Sign up with Apple
							</Button>
						</Grid>
					</Grid>
					<Grid item>
						<Typography variant="body2" sx={{ color: 'text.secondary' }}>
							Have an account already?{' '}
							<a href="/login" style={{ color: '#28c0fd', textDecoration: 'none' }}>
								Log in to MAVA
							</a>
						</Typography>
					</Grid>
				</Grid>
			</Card>

			{/* Email Verification Modal */}
			<EmailVerificationModal open={modalOpen} handleClose={handleCloseModal} />
		</Box>
	);
};

export default Signup;
