// src/components/ArtistOnboarding/OnboardingStepper.js

import React from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import PropTypes from 'prop-types';
import '../../styles/components/onboarding/OnboardingStepper.css';

const InterfaceStepper = ({ steps, activeStep }) => {
	return (
		<Stepper activeStep={activeStep} alternativeLabel className="onboarding-stepper">
			{steps.map((label) => (
				<Step key={label}>
					<StepLabel>{label}</StepLabel>
				</Step>
			))}
		</Stepper>
	);
};

InterfaceStepper.propTypes = {
	steps: PropTypes.arrayOf(PropTypes.string).isRequired,
	activeStep: PropTypes.number.isRequired,
};

export default InterfaceStepper;
