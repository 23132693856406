// components/modules/chatInterface/subcomponents/messageTypes/ListMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import {
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Collapse,
	Box,
} from '@mui/material';
import { ExpandLess, ExpandMore, FiberManualRecord } from '@mui/icons-material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useTheme } from '@mui/material/styles';
/**
 * ListMessage Component
 *
 * Renders a list of items with support for multi-level nested lists and Markdown styling.
 *
 * Props:
 * - items: An array of strings or objects representing list items.
 * - text: Raw text that may contain list items.
 * - messageId: Unique ID of the message.
 */
const ListMessage = ({ items, text, messageId }) => {
	const theme = useTheme();
	// Helper function to parse list items from text
	const parseListItems = (text) => {
		if (!text) return [];
		const regex = /[-•]\s*(.*)/g;
		const parsedItems = [];
		let match;
		while ((match = regex.exec(text)) !== null) {
			parsedItems.push(match[1].trim());
		}
		return parsedItems;
	};

	const listItems =
		Array.isArray(items) && items.length > 0 ? items : parseListItems(text);

	if (listItems.length === 0) {
		return (
			<Typography variant="body2" color="text.secondary">
				No items to display.
			</Typography>
		);
	}

	/**
	 * Recursive component to render list items, handling multi-level nested structures.
	 */
	const RenderListItems = ({ items, depth = 0 }) => {
		const [openIndices, setOpenIndices] = React.useState({});

		const handleToggle = (index) => {
			setOpenIndices((prev) => ({ ...prev, [index]: !prev[index] }));
		};

		return (
			<List dense>
				{items.map((item, index) => {
					// Handle nested list objects with 'category' and 'items' keys
					if (typeof item === 'object' && !Array.isArray(item)) {
						const { category, items: nestedItems, description } = item;

						if (!category || !Array.isArray(nestedItems)) {
							return (
								<ListItem key={index} disableGutters>
									<ListItemText
										primary={
											<Typography variant="body2" color="error">
												Invalid nested list structure.
											</Typography>
										}
									/>
								</ListItem>
							);
						}

						return (
							<Box key={index} sx={{ mb: 1 }}>
								<ListItem
									button
									onClick={() => handleToggle(index)}
									disableGutters
									sx={{ pl: 0 }}
								>
									<ListItemIcon sx={{ minWidth: '30px' }}>
										<FiberManualRecord fontSize="small" />
									</ListItemIcon>
									<ListItemText
										primary={
											<ReactMarkdown
												remarkPlugins={[remarkGfm]}
												components={{
													h1: ({ node, ...props }) => (
														<Typography sx={{ ...theme.typography.h2 }} {...props} />
													),
													h2: ({ node, ...props }) => (
														<Typography sx={{ ...theme.typography.h3 }} {...props} />
													),
													h3: ({ node, ...props }) => (
														<Typography
															sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
															{...props}
														/>
													),
													h4: ({ node, ...props }) => (
														<Typography
															sx={{ fontWeight: 'bold', fontSize: '1rem' }}
															{...props}
														/>
													),
													p: ({ node, ...props }) => (
														<Typography variant="body2" {...props} />
													),
													strong: ({ node, ...props }) => <strong {...props} />,
													em: ({ node, ...props }) => <em {...props} />,
													ul: ({ node, ...props }) => <ul {...props} />,
													ol: ({ node, ...props }) => <ol {...props} />,
													li: ({ node, ...props }) => <li {...props} />,
													code: ({
														node,
														inline,
														className,
														children,
														...props
													}) => {
														return !inline ? (
															<Typography
																component="pre"
																sx={{
																	backgroundColor: 'grey.100',
																	padding: 1,
																	borderRadius: 1,
																}}
																{...props}
															>
																<code>{children}</code>
															</Typography>
														) : (
															<code {...props}>{children}</code>
														);
													},
													blockquote: ({ node, ...props }) => (
														<Typography
															component="blockquote"
															sx={{
																borderLeft: '4px solid grey.300',
																pl: 2,
																color: 'grey.700',
															}}
															{...props}
														/>
													),
												}}
											>
												{`#### ${category}`}
											</ReactMarkdown>
										}
										secondary={
											description ? (
												<ReactMarkdown
													remarkPlugins={[remarkGfm]}
													components={{
														h1: ({ node, ...props }) => (
															<Typography
																sx={{ ...theme.typography.h2 }}
																{...props}
															/>
														),
														h2: ({ node, ...props }) => (
															<Typography
																sx={{ ...theme.typography.h3 }}
																{...props}
															/>
														),
														h3: ({ node, ...props }) => (
															<Typography
																sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
																{...props}
															/>
														),
														p: ({ node, ...props }) => (
															<Typography variant="body2" {...props} />
														),
														strong: ({ node, ...props }) => <strong {...props} />,
														em: ({ node, ...props }) => <em {...props} />,
														ul: ({ node, ...props }) => <ul {...props} />,
														ol: ({ node, ...props }) => <ol {...props} />,
														li: ({ node, ...props }) => <li {...props} />,
														code: ({
															node,
															inline,
															className,
															children,
															...props
														}) => {
															return !inline ? (
																<Typography
																	component="pre"
																	sx={{
																		backgroundColor: 'grey.100',
																		padding: 1,
																		borderRadius: 1,
																	}}
																	{...props}
																>
																	<code>{children}</code>
																</Typography>
															) : (
																<code {...props}>{children}</code>
															);
														},
														blockquote: ({ node, ...props }) => (
															<Typography
																component="blockquote"
																sx={{
																	borderLeft: '4px solid grey.300',
																	pl: 2,
																	color: 'grey.700',
																}}
																{...props}
															/>
														),
													}}
												>
													{description}
												</ReactMarkdown>
											) : null
										}
									/>
									{openIndices[index] ? <ExpandLess /> : <ExpandMore />}
								</ListItem>
								<Collapse in={openIndices[index]} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{nestedItems.map((subItem, subIndex) => (
											<ListItem key={subIndex} sx={{ pl: 4 }} disableGutters>
												<ListItemIcon sx={{ minWidth: '30px' }}>
													<FiberManualRecord fontSize="small" />
												</ListItemIcon>
												<ListItemText
													primary={
														<ReactMarkdown
															remarkPlugins={[remarkGfm]}
															components={{
																h1: ({ node, ...props }) => (
																	<Typography
																		sx={{ ...theme.typography.h2 }}
																		{...props}
																	/>
																),
																h2: ({ node, ...props }) => (
																	<Typography
																		sx={{ ...theme.typography.h3 }}
																		{...props}
																	/>
																),
																h3: ({ node, ...props }) => (
																	<Typography
																		sx={{
																			fontWeight: 'bold',
																			fontSize: '1.25rem',
																		}}
																		{...props}
																	/>
																),
																p: ({ node, ...props }) => (
																	<Typography variant="body2" {...props} />
																),
																strong: ({ node, ...props }) => (
																	<strong {...props} />
																),
																em: ({ node, ...props }) => <em {...props} />,
																ul: ({ node, ...props }) => <ul {...props} />,
																ol: ({ node, ...props }) => <ol {...props} />,
																li: ({ node, ...props }) => <li {...props} />,
																code: ({
																	node,
																	inline,
																	className,
																	children,
																	...props
																}) => {
																	return !inline ? (
																		<Typography
																			component="pre"
																			sx={{
																				backgroundColor: 'grey.100',
																				padding: 1,
																				borderRadius: 1,
																			}}
																			{...props}
																		>
																			<code>{children}</code>
																		</Typography>
																	) : (
																		<code {...props}>{children}</code>
																	);
																},
																blockquote: ({ node, ...props }) => (
																	<Typography
																		component="blockquote"
																		sx={{
																			borderLeft: '4px solid grey.300',
																			pl: 2,
																			color: 'grey.700',
																		}}
																		{...props}
																	/>
																),
															}}
														>
															{subItem}
														</ReactMarkdown>
													}
												/>
											</ListItem>
										))}
									</List>
								</Collapse>
							</Box>
						);
					}

					// Handle arrays within objects (shouldn't exceed multi-level nesting)
					if (Array.isArray(item)) {
						return <RenderListItems key={index} items={item} depth={depth} />;
					}

					// Default rendering for string items
					if (typeof item === 'string') {
						return (
							<ListItem key={index} disableGutters sx={{ pl: depth * 2 }}>
								<ListItemIcon sx={{ minWidth: '30px' }}>
									<FiberManualRecord fontSize="small" />
								</ListItemIcon>
								<ListItemText
									primary={
										<ReactMarkdown
											remarkPlugins={[remarkGfm]}
											components={{
												h1: ({ node, ...props }) => (
													<Typography sx={{ ...theme.typography.h2 }} {...props} />
												),
												h2: ({ node, ...props }) => (
													<Typography sx={{ ...theme.typography.h3 }} {...props} />
												),
												h3: ({ node, ...props }) => (
													<Typography
														sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
														{...props}
													/>
												),
												p: ({ node, ...props }) => (
													<Typography variant="body2" {...props} />
												),
												strong: ({ node, ...props }) => <strong {...props} />,
												em: ({ node, ...props }) => <em {...props} />,
												ul: ({ node, ...props }) => <ul {...props} />,
												ol: ({ node, ...props }) => <ol {...props} />,
												li: ({ node, ...props }) => <li {...props} />,
												code: ({ node, inline, className, children, ...props }) => {
													return !inline ? (
														<Typography
															component="pre"
															sx={{
																backgroundColor: 'grey.100',
																padding: 1,
																borderRadius: 1,
															}}
															{...props}
														>
															<code>{children}</code>
														</Typography>
													) : (
														<code {...props}>{children}</code>
													);
												},
												blockquote: ({ node, ...props }) => (
													<Typography
														component="blockquote"
														sx={{
															borderLeft: '4px solid grey.300',
															pl: 2,
															color: 'grey.700',
														}}
														{...props}
													/>
												),
											}}
										>
											{item}
										</ReactMarkdown>
									}
								/>
							</ListItem>
						);
					}

					// Handle unexpected item structures
					return (
						<ListItem key={index} disableGutters>
							<ListItemText
								primary={
									<Typography variant="body2" color="error">
										Unsupported item structure.
									</Typography>
								}
							/>
						</ListItem>
					);
				})}
			</List>
		);
	};

	return (
		<Box>
			{text && (
				<Box sx={{ mb: 2 }}>
					<ReactMarkdown
						children={text}
						remarkPlugins={[remarkGfm]}
						components={{
							h1: ({ node, ...props }) => (
								<Typography sx={{ ...theme.typography.h2 }} {...props} />
							),
							h2: ({ node, ...props }) => (
								<Typography sx={{ ...theme.typography.h3 }} {...props} />
							),
							h3: ({ node, ...props }) => (
								<Typography
									sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}
									{...props}
								/>
							),
							p: ({ node, ...props }) => <Typography variant="body1" {...props} />,
							strong: ({ node, ...props }) => <strong {...props} />,
							em: ({ node, ...props }) => <em {...props} />,
							ul: ({ node, ...props }) => <ul {...props} />,
							ol: ({ node, ...props }) => <ol {...props} />,
							li: ({ node, ...props }) => <li {...props} />,
							code: ({ node, inline, className, children, ...props }) => {
								return !inline ? (
									<Typography
										component="pre"
										sx={{ backgroundColor: 'grey.100', padding: 1, borderRadius: 1 }}
										{...props}
									>
										<code>{children}</code>
									</Typography>
								) : (
									<code {...props}>{children}</code>
								);
							},
							blockquote: ({ node, ...props }) => (
								<Typography
									component="blockquote"
									sx={{ borderLeft: '4px solid grey.300', pl: 2, color: 'grey.700' }}
									{...props}
								/>
							),
						}}
					/>
				</Box>
			)}
			<RenderListItems items={listItems} />
		</Box>
	);
};

ListMessage.propTypes = {
	items: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object, // Allows objects with 'category' and 'items' keys
		])
	),
	text: PropTypes.string,
	messageId: PropTypes.string.isRequired, // Unique ID of the message
};

ListMessage.defaultProps = {
	items: [],
	text: '',
};

export default React.memo(ListMessage);
