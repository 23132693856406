import React, { useContext } from 'react';
import {
	AppBar,
	Toolbar,
	Box,
	Tooltip,
	Button,
	IconButton,
	useMediaQuery,
	Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
	Settings as SettingsIcon,
	PersonAdd as PersonAddIcon,
} from '@mui/icons-material';
import LogoutButton from './LogoutButton';
import MAVALogo from '../../assets/MAVALogo.png';
import SessionContext from '../../context/SessionContext';
import ThemeToggleButton from './ThemeToggleButton';
import { styled } from '@mui/system';

const drawerWidthOpen = 200;
const drawerWidthClosed = 80;

// Styled AppBar to apply MAVA Design System styles
const StyledAppBar = styled(AppBar)(({ theme, open, sidebarHiding }) => ({
	width: sidebarHiding
		? '100%'
		: open
		? `calc(100% - ${drawerWidthOpen}px)`
		: `calc(100% - ${drawerWidthClosed}px)`,
	marginLeft: open ? `${drawerWidthOpen}px` : `${drawerWidthClosed}px`,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	backgroundColor: theme.palette.background.paper,
	color: theme.palette.text.primary,
	boxShadow: theme.shadows[1],
}));

const Header = ({ onLogout, open, sidebarHiding }) => {
	const navigate = useNavigate();
	const { session } = useContext(SessionContext);
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isMdOrLess = useMediaQuery(theme.breakpoints.down('md'));

	const menuItems = [
		{
			text: 'Add / Update Artist Roster',
			icon: <PersonAddIcon fontSize="medium" />,
			path: '/add-artist',
		},
		{
			text: 'Profile Settings',
			icon: <SettingsIcon fontSize="medium" />,
			path: '/settings',
			disabled: true,
		},
	];

	return (
		<StyledAppBar position="fixed" open={open} sidebarHiding={sidebarHiding}>
			<Toolbar>
				<Grid container alignItems="center" justifyContent="space-between">
					{/* Logo Section */}
					<Grid item>
						<Box display="flex" alignItems="center">
							<Box
								component="img"
								src={MAVALogo}
								alt="App Logo"
								sx={{
									height: isMobile ? theme.spacing(5) : theme.spacing(7.5),
									objectFit: 'contain',
									maxWidth: isMobile ? theme.spacing(15) : theme.spacing(18),
								}}
							/>
						</Box>
					</Grid>

					{/* Navigation and Actions */}
					<Grid item>
						<Box display="flex" alignItems="center">
							{!session.isFirstLogin && (
								<Box display="flex" alignItems="center" gap={2}>
									{menuItems.map((item) => (
										<Tooltip key={item.text} title={item.text} arrow>
											{isMdOrLess ? (
												<IconButton
													onClick={() => !item.disabled && navigate(item.path)}
													disabled={item.disabled}
													sx={{
														color: theme.palette.text.primary,
														'&:hover': {
															backgroundColor: theme.palette.action.hover,
														},
													}}
												>
													{item.icon}
												</IconButton>
											) : (
												<Button
													startIcon={item.icon}
													onClick={() => !item.disabled && navigate(item.path)}
													disabled={item.disabled}
													sx={{
														color: theme.palette.text.primary,
														textTransform: 'none',
														'&:hover': {
															backgroundColor: theme.palette.action.hover,
														},
														fontWeight: theme.typography.fontWeightMedium,
														fontSize: theme.typography.body2.fontSize,
														borderRadius: 2,
														paddingX: 2,
														maxWidth: 150,
													}}
												>
													{item.text}
												</Button>
											)}
										</Tooltip>
									))}
								</Box>
							)}

							<Box sx={{ ml: 2 }} />

							{/* Theme Toggle */}
							<ThemeToggleButton />

							<Box sx={{ mr: 2 }} />

							{/* Logout Button */}
							<LogoutButton onLogout={onLogout} />
						</Box>
					</Grid>
				</Grid>
			</Toolbar>
		</StyledAppBar>
	);
};

export default Header;
