// src/components/marketingAssistant/InputQuestionComponent.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, TextField, Button, Grid } from '@mui/material';
import { validateAnswer } from '../../../../utils/processing/validation';

const InputQuestionComponent = ({
	question,
	inputType = 'text',
	keyName,
	dataType = 'string',
	additionalDetailsAllowed = false,
	additionalDetailsRequired = false,
	handleNext,
	handleBack,
	formData = {},
}) => {
	const [answer, setAnswer] = useState('');
	const [additionalDetails, setAdditionalDetails] = useState('');
	const [error, setError] = useState(null);

	useEffect(() => {
		if (formData[keyName]) {
			setAnswer(formData[keyName]);
		} else {
			setAnswer('');
		}
		if (formData[`${keyName}Details`]) {
			setAdditionalDetails(formData[`${keyName}Details`]);
		} else {
			setAdditionalDetails('');
		}
		setError(null);
	}, [keyName, formData]);

	const validateInput = () => {
		const validationError = validateAnswer(answer, dataType, false);
		if (validationError) {
			setError(validationError);
			return false;
		}

		// Validate additional details only if they are required
		if (additionalDetailsAllowed && additionalDetailsRequired) {
			if (!additionalDetails || additionalDetails.trim() === '') {
				setError('Please provide additional details.');
				return false;
			}
		}

		setError(null);
		return true;
	};

	const handleNextSubmit = () => {
		if (validateInput()) {
			const updatedData = { [keyName]: answer };

			if (additionalDetailsAllowed) {
				// Include additional details only if required or provided
				if (additionalDetailsRequired || additionalDetails.trim() !== '') {
					updatedData[`${keyName}Details`] = additionalDetails.trim();
				} else if (formData[`${keyName}Details`]) {
					// Remove existing details if not required and not provided
					updatedData[`${keyName}Details`] = undefined;
				}
			}

			handleNext(updatedData);
		}
	};

	const handleBackSubmit = () => {
		handleBack({ [keyName]: answer });
	};

	const handleDetailsChange = (e) => setAdditionalDetails(e.target.value);

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Typography
				variant="h4"
				component="h2"
				sx={{
					mb: 2,
					textAlign: 'center',
					color: 'text.primary',
				}}
			>
				{question}
			</Typography>

			<TextField
				type={inputType}
				value={answer}
				onChange={(e) => setAnswer(e.target.value)}
				variant="outlined"
				fullWidth
				placeholder="Enter Your Answer"
				error={!!error}
				helperText={error ? error : ' '}
				sx={{
					mb: 2,
				}}
			/>

			{additionalDetailsAllowed && (
				<Box
					sx={{
						width: '100%',
						mb: 2,
					}}
				>
					<Typography
						variant="subtitle1"
						sx={{
							mb: 1,
							color: 'text.secondary',
						}}
					>
						Add additional details:
						{additionalDetailsRequired && (
							<Typography
								component="span"
								sx={{
									color: 'error.main',
									ml: 0.5,
								}}
							>
								*
							</Typography>
						)}
					</Typography>
					<TextField
						type="text"
						placeholder="Enter Additional Details"
						value={additionalDetails}
						onChange={handleDetailsChange}
						variant="outlined"
						fullWidth
						error={
							!!error &&
							additionalDetailsRequired &&
							(!additionalDetails || additionalDetails.trim() === '')
						}
						helperText={
							!!error &&
							additionalDetailsRequired &&
							(!additionalDetails || additionalDetails.trim() === '')
								? 'Additional details are required.'
								: ' '
						}
					/>
				</Box>
			)}

			<Grid container spacing={2} sx={{ mt: 2, width: '100%' }}>
				<Grid item xs={6}>
					<Button
						variant="contained"
						color="secondary"
						fullWidth
						onClick={handleBackSubmit}
						sx={{
							borderRadius: 2,
							py: 1.5,
						}}
					>
						Back
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						onClick={handleNextSubmit}
						sx={{
							borderRadius: 2,
							py: 1.5,
						}}
					>
						Next
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

InputQuestionComponent.propTypes = {
	question: PropTypes.string.isRequired,
	inputType: PropTypes.string,
	keyName: PropTypes.string.isRequired,
	dataType: PropTypes.string,
	additionalDetailsAllowed: PropTypes.bool,
	additionalDetailsRequired: PropTypes.bool,
	handleNext: PropTypes.func.isRequired,
	handleBack: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
};

export default InputQuestionComponent;
