import React, { useState, useCallback, useContext, useRef } from 'react';
import { throttle } from 'lodash'; // Use throttle instead of debounce
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CampaignOptionsSection from './homePage/CampaignOptionsSection';
import RecentCampaignsCarousel from './homePage/RecentCampaignsCarousel';
import AllCampaignsSection from './homePage/AllCampaignsSection';
import QuestionFlow from './questionInterface/QuestionFlow';
import { initiateConversation } from '../../../utils/promptUtils';
import { Box, Grid, useTheme } from '@mui/material';
import { cleanFormData } from '../../../utils/processing/cleanFormData';
import ArtistContext from '../../../context/ArtistContext';
import MarketingHeader from './homePage/MarketingHeader';
import LoadingSpinner from '../../common/LoadingSpinner';

const MarketingAssistant = ({ companyData }) => {
	const [loading, setLoading] = useState(false);
	const { artistData } = useContext(ArtistContext);
	const navigate = useNavigate();
	const theme = useTheme();
	const [refreshTrigger, setRefreshTrigger] = useState(0);
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState({});

	// Create a ref to hold the throttled function
	const throttledStartRef = useRef();

	// Initialize the throttled function once
	if (!throttledStartRef.current) {
		throttledStartRef.current = throttle(
			async (campaignDetails) => {
				try {
					setLoading(true);
					const response = await initiateConversation(
						cleanFormData({ ...formData, ...campaignDetails }),
						artistData,
						companyData
					);

					if (response && response.conversation_id) {
						const newConvId = response.conversation_id;
						navigate(`/marketing-assistant/c/${newConvId}`);
						setRefreshTrigger((prev) => prev + 1);
					} else {
						throw new Error('Failed to create a new conversation.');
					}
					setLoading(false);
				} catch (error) {
					console.error(
						'Error starting conversation:',
						error.response?.data || error.message
					);
					setLoading(false);
					alert('Failed to start conversation. Please try again.');
				}
			},
			2000,
			{
				// Set throttle delay as needed (e.g., 2000ms)
				leading: true, // Execute on the leading edge (immediately)
				trailing: false, // Do not execute on the trailing edge
			}
		);
	}

	// Wrap the throttled function with useCallback to maintain referential integrity
	const startNewConversation = useCallback((campaignDetails) => {
		throttledStartRef.current(campaignDetails);
	}, []);

	const handleSelectConversation = (convId) => {
		navigate(`/marketing-assistant/c/${convId}`);
	};

	const handleSelectCampaignGoal = (goal) => {
		setFormData({ ...formData, marketing_goal: goal });
		setStep(1);
	};

	const handleNext = (data) => {
		setFormData((prev) => ({ ...prev, ...data }));
		setStep((prevStep) => prevStep + 1);
	};

	const handleBack = (data) => {
		setFormData((prev) => ({ ...prev, ...data }));
		setStep((prevStep) => prevStep - 1);
	};

	const handleRefresh = useCallback(() => {
		setLoading(true);
		setRefreshTrigger((prev) => prev + 1);
		setLoading(false);
	}, []);

	return (
		<>
			{loading && <LoadingSpinner isLoading={true} />}
			<Box sx={{ flexGrow: 1, width: '100%', p: theme.spacing(2) }}>
				{step > 0 ? (
					<Box sx={{ width: '100%' }}>
						<QuestionFlow
							step={step}
							artistData={artistData}
							formData={formData}
							handleNext={handleNext}
							handleBack={handleBack}
							generatePrompt={startNewConversation}
						/>
					</Box>
				) : (
					<Grid container spacing={4}>
						<Grid item xs={12} sx={{ mt: 2 }}>
							<MarketingHeader artistName={artistData.stageName} />
						</Grid>
						<Grid item xs={12}>
							<CampaignOptionsSection onStartCampaign={handleSelectCampaignGoal} />
						</Grid>
						<Grid item xs={12}>
							<RecentCampaignsCarousel
								onSelectConversation={handleSelectConversation}
								refreshTrigger={refreshTrigger}
								onUpdate={handleRefresh}
							/>
						</Grid>
						<Grid item xs={12}>
							<AllCampaignsSection
								onSelectConversation={handleSelectConversation}
								refreshTrigger={refreshTrigger}
								onUpdate={handleRefresh}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
		</>
	);
};

MarketingAssistant.propTypes = {
	companyData: PropTypes.object.isRequired,
};

export default MarketingAssistant;
