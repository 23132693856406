import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, MenuItem, Grid, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
	fetchManagerOnboardingData,
	saveManagerOnboardingData,
} from '../../../utils/apis/onboardingData/managerOnboardingUtils';
import LoadingSpinner from '../../common/LoadingSpinner';
import { useTheme } from '@mui/material/styles';

const roleOptions = ['Manager', 'A&R', 'Executive', 'Marketing Director'];

const validationSchema = Yup.object({
	companyName: Yup.string().required('Company Name is required'),
	role: Yup.string().required('Role is required'),
	phoneNumber: Yup.string()
		.typeError('Phone Number must be valid')
		.matches(/^\+?[1-9]\d{1,14}$/, 'Enter a valid phone number')
		.required('Phone Number is required'),
	artistRosterSize: Yup.number()
		.typeError('Roster size must be a valid number')
		.min(0, 'Roster size cannot be negative')
		.required('Artist Roster Size is required'),
	/*monthlyBudget: Yup.number()
		.typeError('Budget must be a valid number')
		.min(0, 'Budget cannot be negative')
		.required('Monthly Budget is required'),
	*/
	companyWebsite: Yup.string()
		.matches(
			/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(:[0-9]{1,5})?(\/\S*)?$/,
			'Enter a valid URL'
		)
		.required('Company Website is required'),
});

const initialManagerData = {
	companyName: '',
	role: '',
	phoneNumber: '',
	artistRosterSize: '',
	monthlyBudget: '',
	companyWebsite: '',
};

const ManagerOnboarding = ({ onComplete }) => {
	const theme = useTheme();
	const [managerData, setManagerData] = useState(initialManagerData);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const initializeData = async () => {
			try {
				const manager = await fetchManagerOnboardingData();
				if (manager) {
					setManagerData(manager);
				}
			} catch (error) {
				console.error('Error initializing manager onboarding data:', error);
			} finally {
				setIsLoading(false);
			}
		};
		initializeData();
	}, []);

	const formik = useFormik({
		initialValues: managerData,
		validationSchema,
		enableReinitialize: true, // Allow reinitialization when managerData changes
		onSubmit: async (values) => {
			try {
				await saveManagerOnboardingData(values);
				onComplete();
			} catch (error) {
				console.error('Error saving manager data:', error);
			}
		},
	});

	return (
		<Box
			sx={{
				backgroundColor: '#f5f5f5',
				padding: '2rem',
				borderRadius: '8px',
				boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				maxWidth: '900px',
				margin: '0 auto',
			}}
		>
			<LoadingSpinner isLoading={isLoading} />
			<Typography
				variant="h2"
				sx={{
					...theme.typography.h4,
					ml: 2,
					marginBottom: '1.5rem',
					textAlign: 'center',
				}}
			>
				Welcome to MAVA Onboarding
			</Typography>
			<Box
				sx={{
					backgroundColor: '#ffffff',
					padding: '2rem',
					borderRadius: '8px',
					boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
					maxWidth: '650px',
					margin: '0 auto',
				}}
			>
				<Typography
					variant="h2"
					sx={{
						...theme.typography.h3,
						ml: 2,
						marginBottom: '1.5rem',
						textAlign: 'center',
					}}
				>
					Tell Us More About You!
				</Typography>

				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								select
								label="Your Role"
								fullWidth
								variant="outlined"
								{...formik.getFieldProps('role')}
								error={formik.touched.role && Boolean(formik.errors.role)}
								helperText={formik.touched.role && formik.errors.role}
							>
								{roleOptions.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						</Grid>

						<Grid item xs={12}>
							<TextField
								label="Phone Number"
								inputMode="tel"
								fullWidth
								variant="outlined"
								{...formik.getFieldProps('phoneNumber')}
								error={
									formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
								}
								helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								label="Artist Roster Size"
								inputMode="numeric"
								fullWidth
								variant="outlined"
								{...formik.getFieldProps('artistRosterSize')}
								error={
									formik.touched.artistRosterSize &&
									Boolean(formik.errors.artistRosterSize)
								}
								helperText={
									formik.touched.artistRosterSize && formik.errors.artistRosterSize
								}
								inputProps={{ min: 0 }}
							/>
						</Grid>
						{/*
						<Grid item xs={12}>
							<TextField
								label="Monthly Marketing Budget ($)"
								inputMode="numeric"
								fullWidth
								variant="outlined"
								{...formik.getFieldProps('monthlyBudget')}
								error={
									formik.touched.monthlyBudget &&
									Boolean(formik.errors.monthlyBudget)
								}
								helperText={
									formik.touched.monthlyBudget && formik.errors.monthlyBudget
								}
								inputProps={{ min: 0 }}
							/>
						</Grid>
						*/}

						<Grid item xs={12}>
							<TextField
								label="Company Name"
								fullWidth
								variant="outlined"
								{...formik.getFieldProps('companyName')}
								error={
									formik.touched.companyName && Boolean(formik.errors.companyName)
								}
								helperText={formik.touched.companyName && formik.errors.companyName}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								label="Company Website"
								fullWidth
								variant="outlined"
								{...formik.getFieldProps('companyWebsite')}
								error={
									formik.touched.companyWebsite &&
									Boolean(formik.errors.companyWebsite)
								}
								helperText={
									formik.touched.companyWebsite && formik.errors.companyWebsite
								}
							/>
						</Grid>

						<Grid item xs={12} textAlign="center">
							<Button type="submit" variant="contained" color="primary">
								Next
							</Button>
						</Grid>
					</Grid>
				</form>
			</Box>
		</Box>
	);
};

export default ManagerOnboarding;
