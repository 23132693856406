import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Typography, Box, Card, Grid } from '@mui/material';

const ResetPassword = () => {
	const { token } = useParams();
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [message, setMessage] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (password !== confirmPassword) {
			setMessage('Passwords do not match.');
			return;
		}

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/api/auth/reset-password/${token}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ newPassword: password }),
				}
			);

			const data = await response.json();

			if (response.ok) {
				setMessage('Password reset successful. Redirecting to login...');
				setTimeout(() => {
					navigate('/login');
				}, 3000); // Redirect to login after 3 seconds
			} else {
				setMessage(data.message || 'Error resetting password.');
			}
		} catch (error) {
			setMessage('Error resetting password.');
		}
	};

	return (
		<Box
			className="fade-in"
			sx={{
				backgroundColor: 'background.login',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 2,
			}}
		>
			<Card
				sx={{
					padding: 4,
					borderRadius: 3,
					width: '100%',
					maxWidth: 400,
					boxShadow: 3,
					textAlign: 'center',
					backgroundColor: 'background.paper',
					'&:hover': {
						transform: 'none',
					},
				}}
			>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<Typography variant="h4" gutterBottom>
							Reset Your Password
						</Typography>
					</Grid>
					<Grid item>
						<form onSubmit={handleSubmit}>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<TextField
										label="New Password"
										type="password"
										variant="outlined"
										fullWidth
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								</Grid>
								<Grid item>
									<TextField
										label="Confirm New Password"
										type="password"
										variant="outlined"
										fullWidth
										value={confirmPassword}
										onChange={(e) => setConfirmPassword(e.target.value)}
										required
									/>
								</Grid>
								<Grid item>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
										sx={{ textTransform: 'none', borderRadius: 2 }}
									>
										Reset Password
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
					{message && (
						<Grid item>
							<Typography
								color={message.includes('successful') ? 'success.main' : 'error'}
								variant="caption"
							>
								{message}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Card>
		</Box>
	);
};

export default ResetPassword;
