// InAppOnboarding.jsx
import React, { useState, useContext } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import ArtistOnboardingSelect from './ArtistOnboardingSelect';
import ArtistOnboarding from './ArtistOnboarding';
import { createNewArtist } from '../../utils/apis/onboardingData/artistOnboardingUtils';
import LoadingSpinner from '../common/LoadingSpinner';
import ArtistContext from '../../context/ArtistContext';

const InAppOnboarding = ({ onComplete }) => {
	const { artists, loadArtists } = useContext(ArtistContext);
	const [step, setStep] = useState(0);
	const [selectedArtist, setSelectedArtist] = useState(null);
	const [isAddingNewArtist, setIsAddingNewArtist] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleSelectArtist = (artist) => {
		setSelectedArtist(artist);
		setIsAddingNewArtist(false);
		setStep(1);
	};

	const handleAddNewArtist = async () => {
		setLoading(true);
		try {
			const newArtist = await createNewArtist();
			if (newArtist && newArtist?.id) {
				setSelectedArtist(newArtist);
				setIsAddingNewArtist(true);
				setStep(1);
				await loadArtists();
			} else {
				// Handle creation failure (e.g., show error message)
			}
		} catch (error) {
			console.error('Error adding new artist:', error);
		}
		setLoading(false);
	};

	const handleArtistComplete = () => {
		setStep(0);
		setIsAddingNewArtist(false);
		setSelectedArtist(null);
		onComplete();
	};

	return (
		<>
			{step === 0 && (
				<ArtistOnboardingSelect
					artists={artists}
					onSelectArtist={handleSelectArtist}
					onAddNewArtist={handleAddNewArtist}
					onUpdate={loadArtists}
				/>
			)}
			{step === 1 && (
				<Box
					sx={{
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						p: 4,
					}}
				>
					<Grid container justifyContent="center" sx={{ mb: 3 }}>
						<Grid item xs={12} sm={8} md={6}>
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									gap: 2,
									flexWrap: 'wrap',
								}}
							>
								<Button
									color="primary"
									variant="contained"
									onClick={() => setStep(0)}
									sx={{
										borderRadius: 2,
										textTransform: 'none',
										paddingX: 3,
										paddingY: 1,
									}}
								>
									Select Different Artist
								</Button>
							</Box>
						</Grid>
					</Grid>
					<ArtistOnboarding
						selectedArtist={selectedArtist}
						isAddingNewArtist={isAddingNewArtist}
						onComplete={handleArtistComplete}
					/>
				</Box>
			)}
			{loading && (
				<Box
					sx={{
						textAlign: 'center',
						mt: 4,
						p: 2,
						backgroundColor: 'background.paper',
						borderRadius: 2,
						boxShadow: 3,
					}}
				>
					<Typography variant="4" sx={{ mb: 2 }}>
						Creating new artist...
					</Typography>
					<LoadingSpinner isLoading={loading} />
				</Box>
			)}
		</>
	);
};

export default InAppOnboarding;
