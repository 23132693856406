import React from 'react';
import {
	Button,
	Typography,
	Grid,
	TextField,
	MenuItem,
	Box,
	Paper,
} from '@mui/material';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';

const activityCategories = [
	'Content & Social Media Marketing',
	'Audience & Fan Engagement',
	'Music Release & Distribution Strategy',
	'Advertising & Paid Promotion',
	'Live Performances & Touring',
	'Collaboration & Networking',
	'Brand & Sponsorship Opportunities',
	'Data & Analytics Monitoring',
];

const secondaryOptions = {
	'Content & Social Media Marketing': [
		'Social Media Campaigns (Instagram, TikTok, YouTube)',
		'Influencer Partnerships',
		'Regular Content Posting (Reels, Stories, Livestreams)',
		'Social Media Ads & Boosted Posts',
	],
	'Audience & Fan Engagement': [
		'Responding to Fans (DMs, Comments)',
		'Live Fan Interaction (Q&As, Livestreams)',
		'Exclusive Content for Subscribers/Superfans',
		'Email Newsletters & Updates',
	],
	'Music Release & Distribution Strategy': [
		'Single/EP/Album Releases',
		'Playlist Pitching & Submissions',
		'Music Video Promotion',
		'Collaborations & Features on Releases',
	],
	'Advertising & Paid Promotion': [
		'Facebook/Instagram Ads',
		'YouTube Ads (TrueView, Pre-rolls)',
		'Google Search & Display Ads',
		'Spotify Ad Campaigns',
	],
	'Live Performances & Touring': [
		'Virtual Concerts/Performances',
		'In-Person Concerts & Tours',
		'Festival Appearances',
		'Booking for Live Shows',
	],
	'Collaboration & Networking': [
		'Artist Features & Collaborations',
		'Producer/Writer Sessions',
		'Networking Events (In-Person or Virtual)',
		'Online Music Communities Engagement',
	],
	'Brand & Sponsorship Opportunities': [
		'Brand Partnerships & Endorsements',
		'Sponsorship Deals',
		'Merchandise Collaborations',
		'Product Placements in Content',
	],
	'Data & Analytics Monitoring': [
		'Social Media Insights Analysis',
		'Streaming Data Monitoring (Spotify for Artists, Apple Music)',
		'Website Analytics',
		'Ad Campaign Performance Reviews',
	],
};

const RecentActivities = ({ artistData: recentActivities, onSave, onCancel }) => {
	const theme = useTheme();
	// Define the validation schema using Yup
	const validationSchema = Yup.object().shape({
		activity1Category: Yup.string()
			.oneOf(activityCategories, 'Select a valid activity category')
			.required('Activity 1 Category is required'),
		activity1Secondary: Yup.string()
			.required('Activity 1 Secondary Category is required')
			.test(
				'secondaryCategory',
				'Select a valid secondary category',
				function (value) {
					const { activity1Category } = this.parent;
					return (
						secondaryOptions[activity1Category] &&
						secondaryOptions[activity1Category].includes(value)
					);
				}
			),
		activity1Description: Yup.string()
			.trim()
			.min(10, 'Description must be at least 10 characters')
			.max(500, 'Description cannot exceed 500 characters')
			.required('Activity 1 Description is required'),

		activity2Category: Yup.string()
			.oneOf(activityCategories, 'Select a valid activity category')
			.required('Activity 2 Category is required'),
		activity2Secondary: Yup.string()
			.required('Activity 2 Secondary Category is required')
			.test(
				'secondaryCategory',
				'Select a valid secondary category',
				function (value) {
					const { activity2Category } = this.parent;
					return (
						secondaryOptions[activity2Category] &&
						secondaryOptions[activity2Category].includes(value)
					);
				}
			),
		activity2Description: Yup.string()
			.trim()
			.min(10, 'Description must be at least 10 characters')
			.max(500, 'Description cannot exceed 500 characters')
			.required('Activity 2 Description is required'),

		activity3Category: Yup.string()
			.oneOf(activityCategories, 'Select a valid activity category')
			.required('Activity 3 Category is required'),
		activity3Secondary: Yup.string()
			.required('Activity 3 Secondary Category is required')
			.test(
				'secondaryCategory',
				'Select a valid secondary category',
				function (value) {
					const { activity3Category } = this.parent;
					return (
						secondaryOptions[activity3Category] &&
						secondaryOptions[activity3Category].includes(value)
					);
				}
			),
		activity3Description: Yup.string()
			.trim()
			.min(10, 'Description must be at least 10 characters')
			.max(500, 'Description cannot exceed 500 characters')
			.required('Activity 3 Description is required'),

		activity4Category: Yup.string()
			.oneOf(activityCategories, 'Select a valid activity category')
			.required('Activity 4 Category is required'),
		activity4Secondary: Yup.string()
			.required('Activity 4 Secondary Category is required')
			.test(
				'secondaryCategory',
				'Select a valid secondary category',
				function (value) {
					const { activity4Category } = this.parent;
					return (
						secondaryOptions[activity4Category] &&
						secondaryOptions[activity4Category].includes(value)
					);
				}
			),
		activity4Description: Yup.string()
			.trim()
			.min(10, 'Description must be at least 10 characters')
			.max(500, 'Description cannot exceed 500 characters')
			.required('Activity 4 Description is required'),
	});

	// Initialize Formik
	const formik = useFormik({
		initialValues: {
			activity1Category: recentActivities.activity1Category || '',
			activity1Secondary: recentActivities.activity1Secondary || '',
			activity1Description: recentActivities.activity1Description || '',

			activity2Category: recentActivities.activity2Category || '',
			activity2Secondary: recentActivities.activity2Secondary || '',
			activity2Description: recentActivities.activity2Description || '',

			activity3Category: recentActivities.activity3Category || '',
			activity3Secondary: recentActivities.activity3Secondary || '',
			activity3Description: recentActivities.activity3Description || '',

			activity4Category: recentActivities.activity4Category || '',
			activity4Secondary: recentActivities.activity4Secondary || '',
			activity4Description: recentActivities.activity4Description || '',
		},
		validationSchema,
		onSubmit: (values) => {
			// Structure the data as an object
			onSave(values);
		},
		enableReinitialize: true,
		validateOnMount: true,
	});

	return (
		<FormikProvider value={formik}>
			<Box sx={{ padding: 4 }}>
				<Typography variant="h4" align="center" sx={{ textAlign: 'center', mb: 2 }}>
					What have you been focusing on in the past year?
				</Typography>
				<Typography variant="body1" align="center" sx={{ mb: 4 }}>
					Recent Activities
				</Typography>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={4}>
						{[1, 2, 3, 4].map((num) => (
							<Grid item xs={6} key={num}>
								<Paper elevation={3} sx={{ padding: 3 }}>
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
										mb={2}
									>
										<Typography variant="h4" sx={{ mb: 2 }}>
											Activity {num}
										</Typography>
									</Box>
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<TextField
												select
												fullWidth
												label="Activity Category"
												name={`activity${num}Category`}
												value={formik.values[`activity${num}Category`]}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={
													formik.touched[`activity${num}Category`] &&
													Boolean(formik.errors[`activity${num}Category`])
												}
												helperText={
													formik.touched[`activity${num}Category`] &&
													formik.errors[`activity${num}Category`]
												}
												variant="outlined"
											>
												<MenuItem value="">Select from dropdown</MenuItem>
												{activityCategories.map((category, catIndex) => (
													<MenuItem key={catIndex} value={category}>
														{category}
													</MenuItem>
												))}
											</TextField>
										</Grid>
										<Grid item xs={12} sm={12}>
											<TextField
												select
												fullWidth
												label="Secondary Category"
												name={`activity${num}Secondary`}
												value={formik.values[`activity${num}Secondary`]}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={
													formik.touched[`activity${num}Secondary`] &&
													Boolean(formik.errors[`activity${num}Secondary`])
												}
												helperText={
													formik.touched[`activity${num}Secondary`] &&
													formik.errors[`activity${num}Secondary`]
												}
												variant="outlined"
												disabled={!formik.values[`activity${num}Category`]}
											>
												<MenuItem value="">Select from dropdown</MenuItem>
												{formik.values[`activity${num}Category`] &&
													secondaryOptions[
														formik.values[`activity${num}Category`]
													]?.map((option, secIndex) => (
														<MenuItem key={secIndex} value={option}>
															{option}
														</MenuItem>
													))}
											</TextField>
										</Grid>
										<Grid item xs={12}>
											<TextField
												fullWidth
												label="Describe the Outcomes from Your Efforts"
												name={`activity${num}Description`}
												value={formik.values[`activity${num}Description`]}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												error={
													formik.touched[`activity${num}Description`] &&
													Boolean(formik.errors[`activity${num}Description`])
												}
												helperText={
													formik.touched[`activity${num}Description`] &&
													formik.errors[`activity${num}Description`]
												}
												variant="outlined"
												multiline
												rows={4}
											/>
										</Grid>
									</Grid>
								</Paper>
							</Grid>
						))}
					</Grid>
					{/* Save and Cancel Buttons */}
					<Box textAlign="center" sx={{ mt: 4 }}>
						<Button
							type="submit"
							variant="contained"
							color="primary"
							disabled={!formik.isValid || formik.isSubmitting}
							sx={{ ...theme.typography.button }}
						>
							Save Selection
						</Button>
						<Button
							type="button"
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ml: 2, ...theme.typography.button }}
						>
							Cancel
						</Button>
					</Box>
				</form>
			</Box>
		</FormikProvider>
	);
};

export default RecentActivities;
