// sessionUtils.js
import { jwtDecode } from 'jwt-decode';

const EXTEND_SESSION_THRESHOLD = 120; // 120 seconds before expiry to extend
const SHOW_MODAL_THRESHOLD = 60; // 60 seconds before expiry to show modal

// Function to check if the token is about to be extended within EXTEND_SESSION_THRESHOLD
export const isTokenAboutToExtend = (token) => {
	if (!token) return false;
	const decoded = jwtDecode(token);
	const currentTime = Math.floor(Date.now() / 1000);
	return decoded.exp - currentTime <= EXTEND_SESSION_THRESHOLD;
};

// Function to check if the token is about to expire within SHOW_MODAL_THRESHOLD
export const isTokenAboutToExpire = (token) => {
	if (!token) return false;
	const decoded = jwtDecode(token);
	const currentTime = Math.floor(Date.now() / 1000);
	return decoded.exp - currentTime <= SHOW_MODAL_THRESHOLD;
};

// Function to check if the token is expired
export const isTokenExpired = (token) => {
	if (!token) return true;
	const decoded = jwtDecode(token);
	const currentTime = Math.floor(Date.now() / 1000);
	return decoded.exp <= currentTime; // Use <= to ensure token is considered expired at exact time
};

// Function to get the token's expiry timestamp
export const getTokenExpiry = (token) => {
	if (!token) return null;
	const decoded = jwtDecode(token);
	return decoded.exp * 1000;
};

// Function to extend the session (get a new access token)
export const extendSession = async () => {
	const accessToken = getAccessToken();

	if (!accessToken) {
		return null;
	}

	try {
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/auth/extend-session`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`, // Include token in headers
				},
			}
		);

		if (!response.ok) {
			throw new Error('Failed to extend session');
		}

		const data = await response.json();

		// Update the token in storage
		updateAccessToken(data.accessToken);

		return data.accessToken;
	} catch (error) {
		console.error('Error extending session:', error);
		return null;
	}
};

// Helper function to get access token from storage
export const getAccessToken = () => {
	return (
		sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken')
	);
};

// Helper function to update access token in storage
const updateAccessToken = (newToken) => {
	if (sessionStorage.getItem('accessToken')) {
		sessionStorage.setItem('accessToken', newToken);
	} else if (localStorage.getItem('accessToken')) {
		localStorage.setItem('accessToken', newToken);
	}
};

// Function to log out user session
export const logoutUserSession = (navigate) => {
	// Clear session data from both localStorage and sessionStorage
	sessionStorage.clear();
	localStorage.clear();

	// Redirect to login page
	navigate('/login');
};

// Function to store the session information based on rememberMe
export const storeUserSession = (
	accessToken,
	email,
	firstName,
	lastName,
	companyName,
	isFirstLogin,
	companyId,
	userId,
	rememberMe
) => {
	const storage = rememberMe ? localStorage : sessionStorage;
	storage.setItem('accessToken', accessToken);
	storage.setItem('email', email);
	storage.setItem('firstName', firstName);
	storage.setItem('lastName', lastName);
	storage.setItem('companyName', companyName);
	storage.setItem('isFirstLogin', JSON.stringify(isFirstLogin));
	storage.setItem('companyId', companyId);
	storage.setItem('userId', userId);
};

// Function to get the current user session from storage
export const getUserSession = () => {
	const storage = sessionStorage.getItem('accessToken')
		? sessionStorage
		: localStorage;
	const token = storage.getItem('accessToken');
	const email = storage.getItem('email');
	const firstName = storage.getItem('firstName');
	const lastName = storage.getItem('lastName');
	const companyName = storage.getItem('companyName');
	const isFirstLogin = JSON.parse(storage.getItem('isFirstLogin') || 'false');
	const companyId = storage.getItem('companyId');
	const userId = storage.getItem('userId');

	return {
		token,
		email,
		firstName,
		lastName,
		companyName,
		isFirstLogin,
		companyId,
		userId,
	};
};

export const updateIsFirstLogin = async (isFirstLogin) => {
	if (sessionStorage.getItem('accessToken')) {
		sessionStorage.setItem('isFirstLogin', JSON.stringify(isFirstLogin));
	} else if (localStorage.getItem('accessToken')) {
		localStorage.setItem('isFirstLogin', JSON.stringify(isFirstLogin));
	}
};
