export const saveManagerOnboardingData = async (managerData) => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return;
		}

		// Use the appropriate API endpoint for saving manager onboarding data
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/onboarding/manager/save`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
				body: JSON.stringify(managerData),
			}
		);

		if (!response.ok) {
			throw new Error('Failed to save manager onboarding data');
		}
		return true;
	} catch (error) {
		console.error('Error saving manager onboarding data:', error);
		return false;
	}
};

export const fetchManagerOnboardingData = async () => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return null;
		}

		// Use the appropriate API endpoint for fetching manager onboarding data
		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/onboarding/manager/get`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);

		if (!response.ok) {
			throw new Error('Failed to fetch manager onboarding data');
		}

		const managerData = await response.json();
		return managerData;
	} catch (error) {
		console.error('Error fetching manager onboarding data:', error);
		return null;
	}
};
