// src/components/homePage/ArtistOverview.jsx
import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	Avatar,
	Grid2,
	Box,
	useTheme,
} from '@mui/material';

const ArtistOverview = ({ artistData }) => {
	const theme = useTheme();

	return (
		<Card
			sx={{
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[2],
				height: '100%',
				':hover': {
					transform: 'none',
				},
			}}
		>
			<CardContent>
				<Grid2 container spacing={2} alignItems="center">
					{/* Artist Photo */}
					<Grid2>
						<Avatar
							src={artistData?.profilePhoto}
							alt={`Profile of ${artistData?.stageName}`}
							sx={{ width: 80, height: 80 }}
						/>
					</Grid2>
					{/* Artist Name */}
					<Grid2>
						<Typography variant="h3" component="h2">
							{artistData?.stageName}
						</Typography>
					</Grid2>
				</Grid2>

				{/* Artist Bio */}
				<Box sx={{ marginTop: theme.spacing(3) }}>
					<Typography
						variant="body1"
						color="textSecondary"
						sx={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							overflow: 'hidden',
							WebkitLineClamp: 5,
							textOverflow: 'ellipsis',
						}}
					>
						{artistData?.bio}
					</Typography>
				</Box>
				{/* Artist Metrics */}
				<Box
					sx={{
						marginTop: theme.spacing(4),
						maxHeight: 250,
						overflowY: 'auto',
					}}
				>
					<Grid2 container spacing={4}>
						{artistData?.genre && (
							<Grid2 xs={6} sm={4}>
								<Typography variant="h5" color="textPrimary">
									Genres:
								</Typography>
								<Typography variant="body1">{artistData.genre}</Typography>
							</Grid2>
						)}
						{artistData?.location && (
							<Grid2 xs={6} sm={4}>
								<Typography variant="h5" color="textPrimary">
									Location:
								</Typography>
								<Typography variant="body1">{artistData.location}</Typography>
							</Grid2>
						)}
						{artistData?.totalListeners && (
							<Grid2 xs={6} sm={4}>
								<Typography variant="h5" color="textPrimary">
									Monthly Listeners:
								</Typography>
								{/*
								<Typography variant="body1">
									{artistData.totalListeners.toLocaleString()}
								</Typography>
								*/}
								<Typography variant="body1">1,593,506</Typography>
							</Grid2>
						)}
						{artistData?.totalFollowers && (
							<Grid2 xs={6} sm={4}>
								<Typography variant="h5" color="textPrimary">
									Total Following:
								</Typography>
								{/*
								<Typography variant="body1">
									{artistData.totalFollowers.toLocaleString()}
								</Typography>
								*/}
								<Typography variant="body1">1,559,487</Typography>
							</Grid2>
						)}
						{artistData?.yearsActive && (
							<Grid2 xs={6} sm={4}>
								<Typography variant="h5" color="textPrimary">
									Years Active:
								</Typography>
								<Typography variant="body1">{artistData.yearsActive}</Typography>
							</Grid2>
						)}
					</Grid2>
				</Box>
			</CardContent>
		</Card>
	);
};

export default ArtistOverview;
