// components/modules/chatInterface/ChatWindow.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Avatar } from '@mui/material';
import MessageContent from './subcomponents/MessageContent';
import { v4 as uuidv4 } from 'uuid';
import ErrorBoundary from '../../common/ErrorBoundary';
import FallbackMessage from './subcomponents/messageTypes/FallbackMessage';

const ChatWindow = ({ messages, messagesEndRef }) => {
	return (
		<Box
			sx={{
				flexGrow: 1,
				padding: {
					xs: 2,
					sm: 3,
					md: 4,
				},
				backgroundColor: 'background.default',
				width: '100%',
			}}
			id="chat-history"
			aria-live="polite"
		>
			{messages.map((message) => {
				// Validate message structure
				if (!message.id || !message.sender || !message.type || !message.created_at) {
					return (
						<Box
							key={uuidv4()}
							sx={{
								display: 'flex',
								mb: 2,
								flexDirection: 'row',
								alignItems: 'flex-start',
							}}
						>
							<Avatar
								src={'/path/to/error/avatar.png'}
								alt="Error"
								sx={{
									width: 32,
									height: 32,
									marginRight: 2,
								}}
							/>
							<Box
								sx={{
									backgroundColor: 'error.light',
									color: 'error.dark',
									borderRadius: 2,
									padding: 1.5,
									wordBreak: 'break-word',
									maxWidth: {
										sm: '60%',
										md: '65%',
										lg: '70%',
									},
								}}
							>
								<Typography variant="body2">
									Received a malformed message.
								</Typography>
							</Box>
						</Box>
					);
				}

				return (
					<Box
						key={message.id}
						sx={{
							display: 'flex',
							mb: 4,
							flexDirection: message.sender === 'user' ? 'row-reverse' : 'row',
							alignItems: 'flex-start',
						}}
					>
						{/* Sender Avatar */}
						<Avatar
							src={
								message.sender === 'user'
									? '/path/to/user/avatar.png'
									: '/path/to/assistant/avatar.png'
							}
							alt={message.sender === 'user' ? 'User' : 'Assistant'}
							sx={{
								width: 32,
								height: 32,
								mr: message.sender === 'assistant' ? 2 : 0,
								ml: message.sender === 'user' ? 2 : 0,
								bgcolor:
									message.sender === 'user' ? 'primary.main' : 'secondary.main',
							}}
						/>

						{/* Message Content */}
						<Box
							sx={{
								backgroundColor:
									message.sender === 'user' ? 'primary.main' : 'grey.300',
								color:
									message.sender === 'user'
										? 'primary.contrastText'
										: 'text.primary',
								borderRadius: 2,
								padding: 1.5,
								maxWidth: {
									sm: '60%',
									md: '65%',
									lg: '70%',
								},
								wordBreak: 'break-word',
							}}
						>
							<ErrorBoundary fallback={(error) => <FallbackMessage error={error} />}>
								<MessageContent message={message} />
							</ErrorBoundary>
							<Typography
								variant="caption"
								sx={{ display: 'block', textAlign: 'right', mt: 0.5 }}
							>
								{new Date(message.created_at).toLocaleTimeString([], {
									hour: '2-digit',
									minute: '2-digit',
								})}
							</Typography>
						</Box>
					</Box>
				);
			})}
			{/* Dummy element to scroll into view */}
			<div ref={messagesEndRef} />
		</Box>
	);
};

ChatWindow.propTypes = {
	messages: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			sender: PropTypes.oneOf(['user', 'assistant']).isRequired,
			type: PropTypes.string.isRequired,
			text: PropTypes.string,
			items: PropTypes.arrayOf(PropTypes.string),
			url: PropTypes.string,
			alt: PropTypes.string,
			data: PropTypes.object,
			fileUrl: PropTypes.string,
			fileName: PropTypes.string,
			created_at: PropTypes.string.isRequired,
		})
	).isRequired,
	messagesEndRef: PropTypes.object,
};

export default ChatWindow;
