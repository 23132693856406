// ArtistOnboardingSelect.jsx
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Grid2 } from '@mui/material';
import ArtistCarousel from './ArtistCarousel';
import AddNewArtistCard from './AddNewArtistCard';

const ArtistOnboardingSelect = ({
	artists,
	onSelectArtist,
	onAddNewArtist,
	onUpdate,
}) => {
	const [existingArtists, setExistingArtists] = useState([]);
	const [inProgressArtists, setInProgressArtists] = useState([]);

	useEffect(() => {
		const existing = artists.filter((artist) => artist.onboardingComplete);
		const inProgress = artists.filter((artist) => !artist.onboardingComplete);
		setExistingArtists(existing);
		setInProgressArtists(inProgress);
	}, [artists]);

	const handleRefresh = useCallback(() => {
		onUpdate();
	}, []);

	return (
		<Box sx={{ p: 4, backgroundColor: 'background.default' }}>
			<Box>
				<Typography
					variant="h2"
					sx={{
						mb: 1,
						textAlign: 'center',
						fontWeight: 'bold',
						color: 'text.primary',
					}}
				>
					Update Your Roster's Data
				</Typography>
				<Typography
					variant="subtitle1"
					color="text.secondary"
					sx={{ mb: 4, textAlign: 'center' }}
				>
					Select an artist to update their data or add a new artist.
				</Typography>
				<Grid2 container spacing={4} justifyContent="center">
					<Grid2 xs={12} sx={{ width: '100%' }}>
						<ArtistCarousel
							artists={existingArtists}
							onSelect={onSelectArtist}
							onUpdate={handleRefresh}
							label="Existing Artists"
						/>
					</Grid2>
				</Grid2>
				<Grid2 xs={12} sm={8} md={6} lg={4}>
					<Box sx={{ mb: 4, mt: 4 }}>
						<Typography
							variant="h4"
							sx={{
								mb: 2,
								textAlign: 'center',
								fontWeight: 'bold',
								color: 'text.primary',
							}}
						>
							Add New Artists
						</Typography>
						<AddNewArtistCard onAdd={onAddNewArtist} />
					</Box>
				</Grid2>
				<Grid2 container spacing={4} justifyContent="center">
					{inProgressArtists.length > 0 && (
						<Grid2 xs={12} sx={{ width: '100%' }}>
							<ArtistCarousel
								artists={inProgressArtists}
								onSelect={onSelectArtist}
								onUpdate={handleRefresh}
								label="In Progress"
							/>
						</Grid2>
					)}
				</Grid2>
			</Box>
		</Box>
	);
};

export default ArtistOnboardingSelect;
