// components/modules/chatInterface/InputArea.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

/**
 * InputArea Component
 *
 * Provides an input field and send button for user messages.
 *
 * Props:
 * - userMessage: The current value of the input field.
 * - setUserMessage: Function to update the input field value.
 * - onSend: Function to handle sending the message.
 * - loading: Boolean indicating if a message is being sent.
 */
const InputArea = ({ userMessage, setUserMessage, onSend, loading }) => {
	const handleSend = () => {
		if (userMessage.trim() !== '') {
			onSend();
		}
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			handleSend();
			event.preventDefault();
		}
	};

	return (
		<Box
			sx={{
				padding: 2,
				borderTop: '1px solid',
				borderColor: 'divider',
				backgroundColor: 'background.paper',
				width: '100%',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					maxWidth: 'md',
					gap: 2,
					margin: '0 auto',
				}}
			>
				<TextField
					variant="outlined"
					value={userMessage}
					onChange={(e) => setUserMessage(e.target.value)}
					placeholder="Type your message..."
					onKeyPress={handleKeyPress}
					aria-label="Type your message"
					multiline
					maxRows={4}
					fullWidth
					sx={{
						backgroundColor: 'background.default',
						borderRadius: 1,
					}}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSend}
					disabled={loading}
					aria-label="Send message"
					endIcon={<SendIcon />}
					sx={{
						textTransform: 'none',
						borderRadius: 2,
						padding: '12px 16px',
						height: 'fit-content', // Adjust height based on content
						minWidth: '80px', // Ensure button is not too small
					}}
				>
					Send
				</Button>
			</Box>
		</Box>
	);
};

InputArea.propTypes = {
	userMessage: PropTypes.string.isRequired,
	setUserMessage: PropTypes.func.isRequired,
	onSend: PropTypes.func.isRequired,
	loading: PropTypes.bool.isRequired,
};

export default React.memo(InputArea);
