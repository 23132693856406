// components/modules/chatInterface/NavigationTabs.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Box, useTheme } from '@mui/material';

const NavigationTabs = ({ value, handleChange }) => {
	const theme = useTheme();

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
				backgroundColor: 'background.paper',
			}}
		>
			<Tabs
				value={value}
				onChange={handleChange}
				variant="fullWidth"
				aria-label="Chat Interface Navigation Tabs"
				textColor="primary"
				indicatorColor="primary"
			>
				<Tab label="Overview" id="tab-overview" aria-controls="tabpanel-overview" />
				<Tab label="Chat" id="tab-chat" aria-controls="tabpanel-chat" />
				<Tab label="Files" id="tab-files" aria-controls="tabpanel-files" />
				<Tab label="Settings" id="tab-settings" aria-controls="tabpanel-settings" />
			</Tabs>
		</Box>
	);
};

NavigationTabs.propTypes = {
	value: PropTypes.number.isRequired,
	handleChange: PropTypes.func.isRequired,
};

export default NavigationTabs;
