// src/utils/campaignQuestionsConfig.js

export const campaignQuestionsConfig = {
	'New Music Release': [
		{
			type: 'input',
			question: 'What is the name of the project (e.g., song title, album name)?',
			inputType: 'text',
			dataType: 'string',
			key: 'projectName',
			validation: { required: true },
			additionalDetailsAllowed: false,
		},
		{
			type: 'selection',
			question: 'What type of music release is this?',
			options: [
				{ label: 'Single', value: 'Single', icon: '1️⃣' },
				{ label: 'EP', value: 'EP', icon: '💿' },
				{ label: 'Album', value: 'Album', icon: '💽' },
				{ label: 'Mixtape', value: 'Mixtape', icon: '📼' },
			],
			key: 'releaseType',
			allowMultiple: true,
			customInputAllowed: true,
			additionalDetailsAllowed: false,
			additionalDetailsRequired: false,
			dataType: 'string',
			validation: { required: true },
		},
		{
			type: 'input',
			question: 'When is the release date for the new music release?',
			inputType: 'date',
			dataType: 'date',
			key: 'releaseDate',
			validation: { required: true },
			additionalDetailsAllowed: false,
		},
		{
			type: 'selection',
			question: 'What are the key genres of the new music release?',
			options: [
				{ label: 'Pop', value: 'Pop', icon: '🎤' },
				{ label: 'Rock', value: 'Rock', icon: '🎸' },
				{ label: 'Hip-Hop/Rap', value: 'Hip-Hop/Rap', icon: '🎧' },
				{ label: 'R&B/Soul', value: 'R&B/Soul', icon: '🎵' },
				{ label: 'Electronic', value: 'Electronic', icon: '🎹' },
				{ label: 'Country', value: 'Country', icon: '🤠' },
				{ label: 'Jazz', value: 'Jazz', icon: '🎷' },
				{ label: 'Classical', value: 'Classical', icon: '🎻' },
				{ label: 'Reggae', value: 'Reggae', icon: '🌴' },
				{ label: 'Latin', value: 'Latin', icon: '🎺' },
			],
			key: 'releaseGenres',
			allowMultiple: true,
			customInputAllowed: true,
			additionalDetailsAllowed: false,
			additionalDetailsRequired: false,
			dataType: 'array',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'What are the moods of the new music release?',
			options: [
				{ label: 'Upbeat', value: 'Upbeat', icon: '🎉' },
				{ label: 'Melancholic', value: 'Melancholic', icon: '😔' },
				{ label: 'Romantic', value: 'Romantic', icon: '❤️' },
				{ label: 'Dark', value: 'Dark', icon: '🌑' },
				{ label: 'Happy', value: 'Happy', icon: '😊' },
				{ label: 'Chill', value: 'Chill', icon: '😎' },
				{ label: 'Mysterious', value: 'Mysterious', icon: '🕵️' },
				{ label: 'Inspiring', value: 'Inspiring', icon: '✨' },
				{ label: 'Aggressive', value: 'Aggressive', icon: '🔥' },
			],
			key: 'mood',
			allowMultiple: true,
			customInputAllowed: true,
			additionalDetailsAllowed: false,
			additionalDetailsRequired: false,
			dataType: 'array',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'What’s your budget for the campaign?',
			options: [
				{
					label: 'Total Budget',
					value: 'Total Budget',
					icon: '💰',
					additionalDetailsType: 'currency',
					placeholder: 'Enter Total Budget',
				},
				{
					label: 'Monthly Budget',
					value: 'Monthly Budget',
					icon: '📅',
					additionalDetailsType: 'currency',
					placeholder: 'Enter Monthly Budget',
				},
				{
					label: 'Daily Budget',
					value: 'Daily Budget',
					icon: '📆',
					additionalDetailsType: 'currency',
					placeholder: 'Enter Daily Budget',
				},
			],
			key: 'budget',
			allowMultiple: false,
			customInputAllowed: false,
			additionalDetailsAllowed: true,
			additionalDetailsRequired: true,
			dataType: 'string',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'Which platforms are you focusing on for this campaign?',
			options: [
				{ label: 'Spotify', value: 'Spotify', icon: '🎵' },
				{ label: 'Apple Music', value: 'Apple Music', icon: '🍎' },
				{ label: 'TikTok', value: 'TikTok', icon: '🎶' },
				{ label: 'Instagram', value: 'Instagram', icon: '📸' },
				{ label: 'YouTube', value: 'YouTube', icon: '▶️' },
			],
			key: 'platformFocus',
			allowMultiple: true,
			customInputAllowed: true,
			additionalDetailsAllowed: false,
			additionalDetailsRequired: false,
			dataType: 'array',
			validation: { required: true },
		},
		{
			type: 'selection',
			question:
				'What is your primary goal for this campaign? (e.g., 50,000 streams in the first month)',
			options: [
				{
					label: 'Streaming Growth',
					value: 'Streaming Growth',
					icon: '📈',
					additionalDetailsType: 'text',
					placeholder: 'Enter target streams',
				},
				{
					label: 'DSP Follower Growth',
					value: 'DSP Follower Growth',
					icon: '👥',
					additionalDetailsType: 'text',
					placeholder: 'Enter target followers',
				},
				{
					label: 'Sustained Monthly Listeners Growth',
					value: 'Sustained Monthly Listeners Growth',
					icon: '📊',
					additionalDetailsType: 'text',
					placeholder: 'Enter target listeners',
				},
				{
					label: 'YouTube Subscriber Growth',
					value: 'YouTube Subscriber Growth',
					icon: '📺',
					additionalDetailsType: 'text',
					placeholder: 'Enter target subscribers',
				},
				{
					label: 'Instagram Follower Growth',
					value: 'Instagram Follower Growth',
					icon: '📸',
					additionalDetailsType: 'text',
					placeholder: 'Enter target followers',
				},
				{
					label: 'TikTok Follower Growth',
					value: 'TikTok Follower Growth',
					icon: '🎶',
					additionalDetailsType: 'text',
					placeholder: 'Enter target followers',
				},
			],
			key: 'campaignPrimaryGoal',
			allowMultiple: false,
			customInputAllowed: false,
			additionalDetailsAllowed: true,
			additionalDetailsRequired: true,
			dataType: 'string',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'What are your secondary goals for this campaign?',
			options: [
				{
					label: 'Streaming Growth',
					value: 'Streaming Growth',
					icon: '📈',
					additionalDetailsType: 'text',
					placeholder: 'Enter target streams',
				},
				{
					label: 'Spotify Follower Growth',
					value: 'Spotify Follower Growth',
					icon: '👥',
					additionalDetailsType: 'text',
					placeholder: 'Enter target Spotify followers amount',
				},
				{
					label: 'YouTube Subscriber Growth',
					value: 'YouTube Subscriber Growth',
					icon: '📺',
					additionalDetailsType: 'text',
					placeholder: 'Enter target subscribers',
				},
				{
					label: 'Instagram Follower Growth',
					value: 'Instagram Follower Growth',
					icon: '📸',
					additionalDetailsType: 'text',
					placeholder: 'Enter target followers',
				},
				{
					label: 'TikTok Follower Growth',
					value: 'TikTok Follower Growth',
					icon: '🎶',
					additionalDetailsType: 'text',
					placeholder: 'Enter target followers',
				},
				{
					label: 'Sustained Monthly Listeners Growth',
					value: 'Sustained Monthly Listeners Growth',
					icon: '📊',
					additionalDetailsType: 'text',
					placeholder: 'Enter target sustainedlisteners',
				},
			],
			key: 'campaignSecondaryGoals',
			allowMultiple: true,
			customInputAllowed: true,
			additionalDetailsAllowed: true,
			additionalDetailsRequired: false,
			dataType: 'array',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'What is your desired start date for the campaign?',
			options: [
				{ label: 'Start on Release Day', value: 'Start on Release Day', icon: '📅' },
				{
					label: '1 Week Before Release Date',
					value: '1 Week Before Release Date',
					icon: '📆',
				},
				{
					label: '2 Weeks Before Release Date',
					value: '2 Weeks Before Release Date',
					icon: '📆',
				},
				{
					label: '1 Month Before Release Date',
					value: '1 Month Before Release Date',
					icon: '📅',
				},
			],
			key: 'campaignStartDate',
			allowMultiple: false,
			customInputAllowed: true,
			additionalDetailsAllowed: false,
			additionalDetailsRequired: false,
			dataType: 'string',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'What is your duration for the campaign?',
			options: [
				{ label: '1 Month', value: '1 Month', icon: '📅' },
				{ label: '2 Months', value: '2 Months', icon: '📅' },
				{ label: '3 Months', value: '3 Months', icon: '📅' },
				{ label: '6 Months', value: '6 Months', icon: '📅' },
			],
			key: 'campaignDuration',
			allowMultiple: false,
			customInputAllowed: true,
			additionalDetailsAllowed: false,
			additionalDetailsRequired: false,
			dataType: 'string',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'Which ad platform would you like to use for this campaign?',
			options: [
				{
					label: 'Meta (Instagram/FB) Ads',
					value: 'Meta/Instagram/FB Ads',
					icon: '📱',
				},
				{ label: 'YouTube Ads', value: 'YouTube Ads', icon: '🔍' },
				{ label: 'TikTok Ads', value: 'TikTok Ads', icon: '🎶' },
			],
			key: 'adPlatforms',
			allowMultiple: true,
			customInputAllowed: true,
			additionalDetailsAllowed: false,
			additionalDetailsRequired: false,
			dataType: 'array',
			validation: { required: true },
		},
		{
			type: 'selection',
			question: 'Do you have ad creatives ready for this campaign?',
			options: [
				{
					label: 'Yes',
					value: 'Yes',
					icon: '✅',
					additionalDetailsType: 'text',
					placeholder: 'What type of ad creatives are ready for this campaign?',
				},
				{ label: 'No', value: 'No', icon: '❌' },
			],
			key: 'adCreativesReady',
			allowMultiple: false,
			customInputAllowed: false,
			additionalDetailsAllowed: true,
			additionalDetailsRequired: false,
			additionalDetailsType: 'text',
			placeholder: 'Please provide details of previous strategies',
			dataType: 'string',
			validation: { required: true },
		},
		{
			type: 'selection',
			question:
				'Have you tried previous marketing strategies for this artist? If yes, which ones?',
			options: [
				{
					label: 'Yes',
					value: 'Yes',
					icon: '✅',
					additionalDetailsType: 'text',
					placeholder: 'What strategies did you implement previously?',
				},
				{ label: 'No', value: 'No', icon: '❌' },
			],
			key: 'previousStrategies',
			allowMultiple: false,
			customInputAllowed: false,
			additionalDetailsAllowed: true,
			additionalDetailsRequired: false,
			dataType: 'string',
			validation: { required: true },
		},
	],
	// Add more campaign types as needed
};
