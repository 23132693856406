// src/mockData/performanceData.js
export const performanceData = {
	// New Data Points
	ageDistribution: {
		overall: [
			{ ageGroup: '13-17', percentage: 10 },
			{ ageGroup: '18-24', percentage: 40 },
			{ ageGroup: '25-34', percentage: 25 },
			{ ageGroup: '35-44', percentage: 15 },
			{ ageGroup: '45+', percentage: 10 },
		],
		perPlatform: {
			Instagram: [
				{ ageGroup: '13-17', percentage: 12 },
				{ ageGroup: '18-24', percentage: 45 },
				{ ageGroup: '25-34', percentage: 25 },
				{ ageGroup: '35-44', percentage: 12 },
				{ ageGroup: '45+', percentage: 6 },
			],
			Twitter: [
				{ ageGroup: '13-17', percentage: 8 },
				{ ageGroup: '18-24', percentage: 35 },
				{ ageGroup: '25-34', percentage: 35 },
				{ ageGroup: '35-44', percentage: 15 },
				{ ageGroup: '45+', percentage: 7 },
			],
			Facebook: [
				{ ageGroup: '13-17', percentage: 3 },
				{ ageGroup: '18-24', percentage: 20 },
				{ ageGroup: '25-34', percentage: 45 },
				{ ageGroup: '35-44', percentage: 20 },
				{ ageGroup: '45+', percentage: 12 },
			],
			TikTok: [
				{ ageGroup: '13-17', percentage: 18 },
				{ ageGroup: '18-24', percentage: 50 },
				{ ageGroup: '25-34', percentage: 20 },
				{ ageGroup: '35-44', percentage: 7 },
				{ ageGroup: '45+', percentage: 5 },
			],
			YouTube: [
				{ ageGroup: '13-17', percentage: 10 },
				{ ageGroup: '18-24', percentage: 40 },
				{ ageGroup: '25-34', percentage: 35 },
				{ ageGroup: '35-44', percentage: 10 },
				{ ageGroup: '45+', percentage: 5 },
			],
			Spotify: [
				{ ageGroup: '13-17', percentage: 5 },
				{ ageGroup: '18-24', percentage: 35 },
				{ ageGroup: '25-34', percentage: 40 },
				{ ageGroup: '35-44', percentage: 15 },
				{ ageGroup: '45+', percentage: 5 },
			],
		},
	},
	genderBreakdown: {
		overall: [
			{ gender: 'Male', percentage: 50 },
			{ gender: 'Female', percentage: 48 },
			{ gender: 'Other', percentage: 2 },
		],
		perPlatform: {
			Instagram: [
				{ gender: 'Male', percentage: 49 },
				{ gender: 'Female', percentage: 48 },
				{ gender: 'Other', percentage: 3 },
			],
			Twitter: [
				{ gender: 'Male', percentage: 47 },
				{ gender: 'Female', percentage: 50 },
				{ gender: 'Other', percentage: 3 },
			],
			Facebook: [
				{ gender: 'Male', percentage: 52 },
				{ gender: 'Female', percentage: 45 },
				{ gender: 'Other', percentage: 3 },
			],
			TikTok: [
				{ gender: 'Male', percentage: 48 },
				{ gender: 'Female', percentage: 49 },
				{ gender: 'Other', percentage: 3 },
			],
			YouTube: [
				{ gender: 'Male', percentage: 46 },
				{ gender: 'Female', percentage: 51 },
				{ gender: 'Other', percentage: 3 },
			],
			Spotify: [
				{ gender: 'Male', percentage: 50 },
				{ gender: 'Female', percentage: 48 },
				{ gender: 'Other', percentage: 2 },
			],
		},
	},
	growthTrends: {
		followersOverTime: [
			{ week: 'Week 1', followers: 1500000 },
			{ week: 'Week 2', followers: 1515000 },
			{ week: 'Week 3', followers: 1510000 },
			{ week: 'Week 4', followers: 1520000 },
			{ week: 'Week 5', followers: 1518000 },
			{ week: 'Week 6', followers: 1525000 },
			{ week: 'Week 7', followers: 1532000 },
			{ week: 'Week 8', followers: 1527000 },
			{ week: 'Week 9', followers: 1535000 },
			{ week: 'Week 10', followers: 1543000 },
			{ week: 'Week 11', followers: 1550000 },
			{ week: 'Week 12', followers: 1558000 },
		],
		listenersOverTime: [
			{ week: 'Week 1', listeners: 1500000 },
			{ week: 'Week 2', listeners: 1512000 },
			{ week: 'Week 3', listeners: 1508000 },
			{ week: 'Week 4', listeners: 1525000 },
			{ week: 'Week 5', listeners: 1520000 },
			{ week: 'Week 6', listeners: 1530000 },
			{ week: 'Week 7', listeners: 1542000 },
			{ week: 'Week 8', listeners: 1537000 },
			{ week: 'Week 9', listeners: 1548000 },
			{ week: 'Week 10', listeners: 1560000 },
			{ week: 'Week 11', listeners: 1575000 },
			{ week: 'Week 12', listeners: 1600000 },
		],
	},
};
