import React, { useEffect, useState } from 'react';
import { Typography, TextField, Button, MenuItem, Grid, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useTheme } from '@mui/material/styles';
import * as Yup from 'yup';

const genreOptions = [
	'Pop',
	'Synth-Pop',
	'Indie Pop',
	'Hip-Hop',
	'Trap',
	'Lo-Fi Hip-Hop',
	'Rock',
	'Alternative Rock',
	'Punk Rock',
	'R&B',
	'Neo-Soul',
	'Funk',
	'Electronic',
	'House',
	'Dubstep',
	'Country',
	'Bluegrass',
];

// Yup validation schema
const validationSchema = Yup.object({
	stageName: Yup.string().required('Stage Name is required'),
	genre: Yup.array().min(1, 'At least one genre must be selected').required(),
	yearsActive: Yup.number()
		.min(0, 'Years Active cannot be negative')
		.typeError('Value must be a number')
		.max(40, 'Please enter a reasonable number of years')
		.required('Years Active is required'),

	bio: Yup.string()
		.min(250, 'Bio must be at least 250 characters')
		.max(1000, 'Bio cannot exceed 500 characters')
		.required('Short Bio is required'),
	location: Yup.string()
		.matches(
			/^[a-zA-Z\s]+,\s?[a-zA-Z]{2}$/,
			'Enter a valid location (e.g., "City, State")'
		)
		.required('Location is required'),
});

const ArtistOnboardingStep1 = ({ artistData, updateArtistData, onNext }) => {
	const theme = useTheme();
	const [initialValues, setInitialValues] = useState({
		stageName: '',
		genre: [],
		yearsActive: '',
		location: '',
		bio: '',
	});

	useEffect(() => {
		if (artistData) {
			setInitialValues({
				stageName: artistData.artist.stageName || '',
				genre: artistData.artist.genre || [],
				yearsActive: artistData.artist.yearsActive || '',
				location: artistData.artist.location || '',
				bio: artistData.artist.bio || '',
			});
		}
	}, [artistData]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: (values) => {
			const updatedData = {
				artist: {
					...values,
				},
			};
			updateArtistData(updatedData);
			onNext();
		},
	});

	const handleGenreChange = (event) => {
		const {
			target: { value },
		} = event;
		formik.setFieldValue(
			'genre',
			typeof value === 'string' ? value.split(',') : value
		);
	};

	return (
		<Box
			sx={{
				backgroundColor: '#ffffff',
				padding: '2rem',
				borderRadius: '8px',
				boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				maxWidth: '650px',
				margin: '0 auto',
			}}
		>
			<Typography
				variant="h2"
				sx={{
					...theme.typography.h3,
					ml: 2,
					marginBottom: '1.5rem',
					textAlign: 'center',
				}}
			>
				Artist Profile Basics
			</Typography>

			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<TextField
							label="Stage Name (Name on DSPs)"
							fullWidth
							variant="outlined"
							{...formik.getFieldProps('stageName')}
							error={formik.touched.stageName && Boolean(formik.errors.stageName)}
							helperText={formik.touched.stageName && formik.errors.stageName}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							select
							label="Genres (Select at least one)"
							fullWidth
							variant="outlined"
							value={formik.values.genre}
							onChange={handleGenreChange}
							error={formik.touched.genre && Boolean(formik.errors.genre)}
							helperText={formik.touched.genre && formik.errors.genre}
							SelectProps={{
								multiple: true,
								renderValue: (selected) => selected.join(', '),
							}}
						>
							{genreOptions.map((genreOption) => (
								<MenuItem key={genreOption} value={genreOption}>
									{formik.values.genre.includes(genreOption) ? '✅ ' : ''}
									{genreOption}
								</MenuItem>
							))}
						</TextField>
					</Grid>

					<Grid item xs={12}>
						<TextField
							label="Years Professionally Active"
							inputMode="numeric"
							fullWidth
							variant="outlined"
							{...formik.getFieldProps('yearsActive')}
							error={
								formik.touched.yearsActive && Boolean(formik.errors.yearsActive)
							}
							helperText={formik.touched.yearsActive && formik.errors.yearsActive}
							inputProps={{ min: 0 }}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							label="Brief Artist Bio"
							fullWidth
							variant="outlined"
							multiline
							rows={6}
							{...formik.getFieldProps('bio')}
							error={formik.touched.bio && Boolean(formik.errors.bio)}
							helperText={formik.touched.bio && formik.errors.bio}
						/>
					</Grid>

					<Grid item xs={12}>
						<TextField
							label="Location (City, State) e.g. Chicago, IL"
							fullWidth
							variant="outlined"
							{...formik.getFieldProps('location')}
							error={formik.touched.location && Boolean(formik.errors.location)}
							helperText={formik.touched.location && formik.errors.location}
						/>
					</Grid>

					<Grid item xs={12} textAlign="center">
						<Button type="submit" variant="contained" color="primary">
							Next
						</Button>
					</Grid>
				</Grid>
			</form>
		</Box>
	);
};

export default ArtistOnboardingStep1;
