// src/App.js
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useContext, useState } from 'react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import {
	getUserSession,
	isTokenExpired,
	extendSession,
	getTokenExpiry,
	logoutUserSession,
} from './utils/apis/auth/sessionUtils';
import VerifyEmail from './components/modals/VerifyEmail';
import Login from './components/userStart/Login';
import Signup from './components/userStart/Signup';
import ForgotPassword from './components/userStart/ForgotPassword';
import ResetPassword from './components/userStart/ResetPassword';
import InAppOnboarding from './components/onboarding/InAppOnboarding';
import StartPage from './components/accountStart/StartPage';
import MarketingAssistant from './components/modules/marketingAssistant/MarketingAssistant';
import DataAnalyst from './components/modules/dataAnalyst/DataAnalyst';
import ProtectedRoute from './components/utility/ProtectedRoute';
import SessionModal from './components/modals/SessionModal';
import Layout from './components/primary/Layout';
import ArtistModal from './components/modals/ArtistModal';
import TikTokProfile from './components/onboarding/dialogs/step3/TikTokProfile';
import ChatInterface from './components/modules/chatInterface/ChatInterface';
import InitialOnboarding from './components/onboarding/InitialOnboarding';
import LoadingSpinner from './components/common/LoadingSpinner';
import SessionContext from './context/SessionContext';
import ArtistContext from './context/ArtistContext';
import UserContext from './context/UserContext';
import { ConversationProvider } from './context/ConversationContext';

function App() {
	const navigate = useNavigate();
	const { session, setSession } = useContext(SessionContext);
	const {
		artists,
		selectedArtist,
		artistData,
		showModal,
		setShowModal,
		handleArtistSelect,
		clearSelectedArtist,
	} = useContext(ArtistContext);
	const { companyData, setCompanyData, initializeUserData } =
		useContext(UserContext);

	const [showSessionModal, setShowSessionModal] = useState(false);
	const [sessionModalCountdown, setSessionModalCountdown] = useState(60);
	const [lastActivityTime, setLastActivityTime] = useState(Date.now());
	const [tokenExpiry, setTokenExpiry] = useState(null);
	const modalTimerRef = useRef(null);
	const extendTimerRef = useRef(null);
	const expiryTimerRef = useRef(null);

	// Function to clear session data and timers
	const clearSessionData = () => {
		// Clear both storages to ensure complete cleanup
		sessionStorage.clear();
		localStorage.clear();

		// Reset state
		setSession({ isLoggedIn: false, isFirstLogin: false, companyId: null }); // Included companyId
		setCompanyData({});
		setShowModal(false);
		setShowSessionModal(false);
		setTokenExpiry(null);
		clearSelectedArtist();

		// Clear any existing timers
		if (modalTimerRef.current) {
			clearTimeout(modalTimerRef.current);
			modalTimerRef.current = null;
		}
		if (extendTimerRef.current) {
			clearTimeout(extendTimerRef.current);
			extendTimerRef.current = null;
		}
		if (expiryTimerRef.current) {
			clearTimeout(expiryTimerRef.current);
			expiryTimerRef.current = null;
		}

		logoutUserSession(navigate);
	};

	// Function to initialize session and fetch company data
	const initializeSession = async () => {
		const { token, isFirstLogin, companyId } = getUserSession();

		// Only update session if values have changed
		if (
			session.isLoggedIn !== !!token ||
			session.isFirstLogin !== isFirstLogin ||
			session.companyId !== companyId
		) {
			setSession({ isLoggedIn: !!token, isFirstLogin, companyId });
		}

		if (token) {
			const expiry = getTokenExpiry(token);
			setTokenExpiry(expiry);

			// Check if token has already expired
			if (isTokenExpired(token)) {
				clearSessionData();
				return;
			}

			// Set timers based on token expiry
			setupTimers(expiry);
		}

		// Removed initializeUserData call from App.js
		// Let UserContext handle it via its useEffect
	};

	// Function to handle user activity
	const handleUserActivity = () => {
		setLastActivityTime(Date.now());
	};

	// Function to setup modal and extend session timers
	const setupTimers = (expiry) => {
		const currentTime = Date.now();
		const timeUntilExpiry = expiry - currentTime;
		const timeUntilExtend = timeUntilExpiry - 120 * 1000; // 120 seconds before expiry
		const timeUntilModal = timeUntilExpiry - 60 * 1000; // 60 seconds before expiry

		// Clear existing timers
		if (extendTimerRef.current) {
			clearTimeout(extendTimerRef.current);
			extendTimerRef.current = null;
		}
		if (modalTimerRef.current) {
			clearTimeout(modalTimerRef.current);
			modalTimerRef.current = null;
		}
		if (expiryTimerRef.current) {
			clearTimeout(expiryTimerRef.current);
			expiryTimerRef.current = null;
		}

		// Set timer to extend session 120 seconds before expiry
		if (timeUntilExtend > 0) {
			extendTimerRef.current = setTimeout(() => {
				extendSessionIfNeeded();
			}, timeUntilExtend);
		}

		// Set timer to show modal 60 seconds before expiry
		if (timeUntilModal > 0) {
			modalTimerRef.current = setTimeout(() => {
				setShowSessionModal(true);
			}, timeUntilModal);
		}

		// Set timer to logout at expiry
		if (timeUntilExpiry > 0) {
			expiryTimerRef.current = setTimeout(() => {
				handleLogout();
			}, timeUntilExpiry);
		} else {
			handleLogout();
		}
	};

	// Function to extend session if user was active within the last 5 minutes
	const extendSessionIfNeeded = async () => {
		const timeSinceLastActivity = Date.now() - lastActivityTime;
		if (timeSinceLastActivity < 5 * 60 * 1000) {
			// active within last 5 minutes
			const newToken = await extendSession();
			if (!newToken) {
				clearSessionData();
			} else {
				const newExpiry = getTokenExpiry(newToken);
				setTokenExpiry(newExpiry);
				setLastActivityTime(Date.now());
				setupTimers(newExpiry);
			}
		}
	};

	// useEffect to initialize session and set up activity listeners on mount
	useEffect(() => {
		initializeSession();
		// List of events to listen to for user activity
		const activityEvents = [
			'keydown',
			'mousedown',
			'scroll',
			'touchstart',
			'pointerdown',
		];

		// Add event listeners for each activity event
		activityEvents.forEach((event) =>
			window.addEventListener(event, handleUserActivity)
		);

		return () => {
			// Clean up event listeners on unmount
			activityEvents.forEach((event) =>
				window.removeEventListener(event, handleUserActivity)
			);

			// Clear any existing timers
			if (extendTimerRef.current) {
				clearTimeout(extendTimerRef.current);
				extendTimerRef.current = null;
			}
			if (modalTimerRef.current) {
				clearTimeout(modalTimerRef.current);
				modalTimerRef.current = null;
			}
			if (expiryTimerRef.current) {
				clearTimeout(expiryTimerRef.current);
				expiryTimerRef.current = null;
			}
		};
	}, []); // Empty dependency array ensures this runs once on mount

	// Listen for storage changes to sync session across tabs
	useEffect(() => {
		const syncSession = (event) => {
			if (event.key === 'accessToken') {
				initializeSession();
			}
		};

		window.addEventListener('storage', syncSession);

		return () => {
			window.removeEventListener('storage', syncSession);
		};
	}, []);

	// useEffect to close session modal if the user is not logged in
	useEffect(() => {
		if (!session.isLoggedIn && showSessionModal) {
			setShowSessionModal(false); // Close the modal if the user is not logged in
		}
	}, [session.isLoggedIn, showSessionModal]);

	// Function to handle session extension decision
	const handleSessionExtension = async (shouldExtendSession) => {
		if (shouldExtendSession) {
			try {
				const newToken = await extendSession();
				if (!newToken) {
					clearSessionData();
					navigate('/login');
				} else {
					const newExpiry = getTokenExpiry(newToken);
					setTokenExpiry(newExpiry);
					setLastActivityTime(Date.now());
					setupTimers(newExpiry);
					setShowSessionModal(false);
				}
			} catch (error) {
				console.error('Error extending session:', error);
				clearSessionData();
				navigate('/login');
			}
		} else {
			clearSessionData();
			navigate('/login');
		}
	};

	const handleLogout = () => {
		clearSessionData();
	};

	// Updated handleLogin to include companyId
	const handleLogin = (isFirstLogin, companyId) => {
		setSession({ isLoggedIn: true, isFirstLogin, companyId });
	};

	const handleInAppOnboardingComplete = async () => {
		// If there are additional data fetching steps after onboarding, handle them here
		navigate('/home');
	};

	return (
		<>
			{/* Routes */}
			<Routes>
				{/* Public Routes */}
				<Route path="/login" element={<Login onLogin={handleLogin} />} />
				<Route path="/signup" element={<Signup />} />
				<Route path="/verify-email/:token" element={<VerifyEmail />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/reset-password/:token" element={<ResetPassword />} />
				<Route path="/tiktok-redirect" element={<TikTokProfile />} />

				{/* Protected Routes */}
				<Route
					path="/"
					element={
						<ProtectedRoute>
							<Layout onLogout={handleLogout}>
								<StartPage selectedArtist={selectedArtist} artistData={artistData} />
							</Layout>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/home"
					element={
						<ProtectedRoute>
							<Layout onLogout={handleLogout}>
								<StartPage />
							</Layout>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/onboarding"
					element={
						<ProtectedRoute>
							<Layout onLogout={handleLogout}>
								<InitialOnboarding />
							</Layout>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/add-artist"
					element={
						<ProtectedRoute>
							<Layout onLogout={handleLogout}>
								<InAppOnboarding onComplete={handleInAppOnboardingComplete} />
							</Layout>
						</ProtectedRoute>
					}
				/>

				<Route
					path="/marketing-assistant/c/:conversationId"
					element={
						<ProtectedRoute>
							<Layout onLogout={handleLogout}>
								<ConversationProvider>
									<ChatInterface />
								</ConversationProvider>
							</Layout>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/marketing-assistant/*"
					element={
						<ProtectedRoute>
							<Layout onLogout={handleLogout}>
								<MarketingAssistant
									artistData={artistData}
									companyData={companyData}
								/>
							</Layout>
						</ProtectedRoute>
					}
				/>
				<Route
					path="/data-analyst"
					element={
						<ProtectedRoute>
							<Layout onLogout={handleLogout}>
								<DataAnalyst companyData={companyData} />
							</Layout>
						</ProtectedRoute>
					}
				/>

				{/* Catch-all Route */}
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>

			{/* Artist Selection Modal */}
			{showModal && (
				<ArtistModal
					artists={artists}
					onSelect={handleArtistSelect}
					onClose={() => setShowModal(false)}
				/>
			)}

			{/* Session Modal */}
			{session.isLoggedIn && (
				<SessionModal
					open={showSessionModal}
					onExtendSession={handleSessionExtension}
					initialCountdown={sessionModalCountdown}
				/>
			)}
			<div>
				{/* ... */}
				<SpeedInsights />
			</div>
		</>
	);
}

export default App;
