import React, { useState, useEffect } from 'react';
import { Button, DialogContent, Typography, Grid, Box } from '@mui/material';
import CareerPathCard from './CareerPathCard';
import { useTheme } from '@mui/material/styles';

const careerPaths = [
	{
		name: 'Recording Artist',
		icon: '🎤',
		overview:
			'A Recording Artist focuses primarily on producing and releasing music for commercial distribution.',
		dayToDay:
			'Writing and recording music, collaborating with producers and engineers, practicing vocals or instruments.',
		revenueStreams:
			'Streaming royalties, music sales, licensing deals, sync placements, and performance royalties.',
		skills: 'Vocal skills, instrument proficiency, songwriting.',
		careerLength: '10-20 years on average.',
	},
	{
		name: 'Recording & Performing Artist',
		icon: '🎸',
		overview: 'Combines studio work with live performances and touring.',
		dayToDay:
			'Rehearsing for live performances, recording new music, collaborating with booking agents and venues.',
		revenueStreams:
			'Concert ticket sales, touring revenue, streaming, merchandise sales, and licensing deals.',
		skills: 'Stage presence, vocal/instrument proficiency, live performance.',
		careerLength: '5-30 years depending on success and sustainability.',
	},
	{
		name: 'Songwriter',
		icon: '✍️',
		overview:
			'Focuses on composing music and lyrics, often for other artists or for media placements.',
		dayToDay:
			'Writing lyrics and melodies, collaborating with other artists, pitching songs to labels and supervisors.',
		revenueStreams:
			'Publishing royalties, licensing fees, co-writing fees, and song placements.',
		skills: 'Lyric writing, music composition, pitching.',
		careerLength: '10-40 years depending on demand and success.',
	},
];

const CareerPath = ({ artistData: careerPath, onSave }) => {
	const theme = useTheme();
	const [selectedPath, setSelectedPath] = useState('');

	// Ensure selectedPath is initialized correctly when artistData changes
	useEffect(() => {
		if (careerPath && careerPath.pathName) {
			const matchingPath = careerPaths.find(
				(path) => path.name === careerPath.pathName
			);
			setSelectedPath(matchingPath || null);
		}
	}, [careerPath]);

	const handleSave = () => {
		if (selectedPath) {
			const updatedData = {
				pathName: selectedPath.name,
				overview: selectedPath.overview,
				dayToDay: selectedPath.dayToDay,
				revenueStreams: selectedPath.revenueStreams,
				skills: selectedPath.skills,
				careerLength: selectedPath.careerLength,
			};
			onSave(updatedData);
		}
	};

	const toggleSelectPath = (path) => {
		setSelectedPath(selectedPath === path ? null : path);
	};

	return (
		<Box
			p={3}
			sx={{
				borderRadius: '8px',
				margin: '0 auto',
				textAlign: 'center',
			}}
		>
			<DialogContent>
				<Typography variant="h3" sx={{ textAlign: 'center', mb: 2 }}>
					Select Your Artist's Career Path
				</Typography>
				<Grid container spacing={3} justifyContent="center">
					{careerPaths.map((path) => (
						<Grid item xs={12} sm={6} md={4} key={path.name}>
							<CareerPathCard
								path={path}
								selected={selectedPath?.name === path.name}
								onSelect={() => toggleSelectPath(path)}
							/>
						</Grid>
					))}
				</Grid>
				<Box mt={2}>
					<Button
						onClick={handleSave}
						color="primary"
						variant="contained"
						sx={{ ...theme.typography.button }}
						disabled={!selectedPath}
					>
						Save & Close
					</Button>
				</Box>
			</DialogContent>
		</Box>
	);
};

export default CareerPath;
