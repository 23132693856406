// components/modules/chatInterface/subcomponents/messageTypes/FileMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import FileDownloader from '../FileDownloader';
import { Typography } from '@mui/material';

/**
 * FileMessage Component
 *
 * Provides a downloadable file link.
 *
 * Props:
 * - fileUrl: The URL of the file to download.
 * - fileName: The name of the file.
 */
const FileMessage = ({ fileUrl, fileName }) => {
	if (!fileUrl || !fileName) {
		return (
			<Typography variant="body2" color="error">
				File information is incomplete.
			</Typography>
		);
	}
	return <FileDownloader fileUrl={fileUrl} fileName={fileName} />;
};

FileMessage.propTypes = {
	fileUrl: PropTypes.string.isRequired,
	fileName: PropTypes.string.isRequired,
};

export default React.memo(FileMessage);
