// src/mockData/notifications.js
export const notifications = [
	{
		id: 1,
		title: 'New Feature Released',
		message: 'Check out the new analytics dashboard for deeper insights.',
	},
	{
		id: 2,
		title: 'Data Analyst Feature',
		message: 'Our Data Analyst feature is only a few weeks away!',
	},
	{
		id: 3,
		title: 'Upcoming Webinar',
		message: 'Join our webinar on artist branding strategies next Friday.',
	},
];
