// components/modules/chatInterface/subcomponents/OutputRenderer.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import TextMessage from './messageTypes/TextMessage';
import ListMessage from './messageTypes/ListMessage';
import TableMessage from './messageTypes/TableMessage';
import ImageMessage from './messageTypes/ImageMessage';
import VisualizationMessage from './messageTypes/VisualizationMessage';
import FileMessage from './messageTypes/FileMessage';
import DefaultMessage from './messageTypes/DefaultMessage';
import FallbackMessage from './messageTypes/FallbackMessage';
import TypingIndicator from './messageTypes/TypingIndicator';

const OutputRenderer = React.memo(({ message }) => {
	const renderContent = () => {
		if (!message.type) {
			return (
				<Typography variant="body2" color="error">
					Message type is missing.
				</Typography>
			);
		}
		try {
			switch (message.type) {
				case 'typing':
					return <TypingIndicator />;
				case 'text':
					if (typeof message.text !== 'string') {
						throw new Error('Invalid data for text message.');
					}
					return <TextMessage text={message.text} />;
				case 'list':
					if (!Array.isArray(message.items)) {
						throw new Error('Invalid data for list message.');
					}
					return (
						<ListMessage
							items={message.items}
							text={message.text}
							messageId={message.id}
						/>
					);
				case 'table':
					if (
						typeof message.data !== 'object' ||
						!message.data.headers ||
						!message.data.rows
					) {
						throw new Error('Invalid data for table message.');
					}
					return <TableMessage data={message.data} text={message.text} />;
				case 'image':
					if (typeof message.url !== 'string') {
						throw new Error('Invalid data for image message.');
					}
					return <ImageMessage url={message.url} alt={message.alt} />;
				case 'visualization':
					if (typeof message.data !== 'object') {
						throw new Error('Invalid data for visualization message.');
					}
					return <VisualizationMessage data={message.data} text={message.text} />;
				case 'file':
					if (
						typeof message.fileUrl !== 'string' ||
						typeof message.fileName !== 'string'
					) {
						throw new Error('Invalid data for file message.');
					}
					return (
						<FileMessage fileUrl={message.fileUrl} fileName={message.fileName} />
					);
				default:
					return <DefaultMessage text={message.text} />;
			}
		} catch (error) {
			console.error('Error in OutputRenderer:', error);
			return <FallbackMessage error={error} />;
		}
	};

	return <Box>{renderContent()}</Box>;
});

OutputRenderer.propTypes = {
	message: PropTypes.shape({
		type: PropTypes.string.isRequired,
		text: PropTypes.string,
		items: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					category: PropTypes.string.isRequired,
					items: PropTypes.arrayOf(PropTypes.string).isRequired,
					description: PropTypes.string, // Optional
				}),
			])
		),
		url: PropTypes.string,
		alt: PropTypes.string,
		data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		fileUrl: PropTypes.string,
		fileName: PropTypes.string,
	}).isRequired,
};

export default OutputRenderer;
