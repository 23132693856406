// AppleMusicData.js
import React from 'react';
import {
	Button,
	TextField,
	Typography,
	Grid,
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Box,
	Divider,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useFormik, FieldArray, FormikProvider, getIn } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';

const AppleMusicData = ({ artistData: appleData, onSave, onCancel }) => {
	const theme = useTheme();
	const ageRangeOptions = ['18-24', '25-34', '35-44', '45-54', '55-64', '65+'];

	// Validation schema using Yup based on backend's expected data
	const validationSchema = Yup.object().shape({
		plays: Yup.number()
			.typeError('Plays must be a valid number')
			.integer('Plays must be an integer')
			.min(0, 'Plays cannot be negative')
			.required('Plays is required'),

		playsChange: Yup.number()
			.typeError('Plays Change must be a valid number')
			.required('Plays Change is required'),

		averageDailyListeners: Yup.number()
			.typeError('Average Daily Listeners must be a valid number')
			.integer('Average Daily Listeners must be an integer')
			.min(0, 'Average Daily Listeners cannot be negative')
			.required('Average Daily Listeners is required'),

		averageDailyListenersChange: Yup.number()
			.typeError('Average Daily Listeners Change must be a valid number')
			.required('Average Daily Listeners Change is required'),

		shazams: Yup.number()
			.typeError('Shazams must be a valid number')
			.integer('Shazams must be an integer')
			.min(0, 'Shazams cannot be negative')
			.required('Shazams is required'),

		shazamsChange: Yup.number()
			.typeError('Shazams Change must be a valid number')
			.required('Shazams Change is required'),

		radioSpins: Yup.number()
			.typeError('Radio Spins must be a valid number')
			.integer('Radio Spins must be an integer')
			.min(0, 'Radio Spins cannot be negative')
			.required('Radio Spins is required'),

		radioSpinsChange: Yup.number()
			.typeError('Radio Spins Change must be a valid number')
			.required('Radio Spins Change is required'),

		purchases: Yup.number()
			.typeError('Purchases must be a valid number')
			.integer('Purchases must be an integer')
			.min(0, 'Purchases cannot be negative')
			.required('Purchases is required'),

		purchasesChange: Yup.number()
			.typeError('Purchases Change must be a valid number')
			.required('Purchases Change is required'),

		topCountries: Yup.array()
			.of(
				Yup.object().shape({
					country: Yup.string()
						.typeError('Country must be a string')
						.matches(/^[A-Za-z\s]+$/, 'Country can only contain letters and spaces')
						.required('Country is required'),
					streams: Yup.number()
						.typeError('streams must be a valid number')
						.integer('streams must be an integer')
						.min(0, 'streams cannot be negative')
						.required('streams is required'),
					percentChange: Yup.number()
						.typeError('Percent Change must be a valid number')
						.required('Percent Change is required'),
				})
			)
			.max(5, 'You can add up to 5 countries only')
			.required('Top Countries are required')
			.min(5, 'At least 5 countries must be added'),

		topCities: Yup.array()
			.of(
				Yup.object().shape({
					city: Yup.string()
						.typeError('City must be a string')
						.matches(/^[A-Za-z\s]+$/, 'City can only contain letters and spaces')
						.required('City is required'),
					streams: Yup.number()
						.typeError('Plays must be a valid number')
						.integer('Plays must be an integer')
						.min(0, 'Plays cannot be negative')
						.required('Plays is required'),
					percentChange: Yup.number()
						.typeError('Percent Change must be a valid number')
						.required('Percent Change is required'),
				})
			)
			.max(5, 'You can add up to 5 cities only')
			.required('Top Cities are required')
			.min(5, 'At least 5 cities must be added'),

		topSongs: Yup.array()
			.of(
				Yup.object().shape({
					song: Yup.string()
						.typeError('Song must be a string')
						.required('Song is required'),
					streams: Yup.number()
						.typeError('Plays must be a valid number')
						.integer('Plays must be an integer')
						.min(0, 'Plays cannot be negative')
						.required('Plays is required'),
					percentChange: Yup.number()
						.typeError('Percent Change must be a valid number')
						.required('Percent Change is required'),
				})
			)
			.max(5, 'You can add up to 5 songs only')
			.required('Top Songs are required')
			.min(5, 'At least 5 songs must be added'),
		genderPlaysM: Yup.number()
			.typeError('Gender Plays (M) must be a valid number')
			.required('Gender Plays (M) is required'),
		genderPlaysMChange: Yup.number()
			.typeError('Gender Percent Change (M) must be a valid number')
			.required('Gender Percent Change (M) is required'),
		genderPlaysF: Yup.number()
			.typeError('Gender Plays (F) must be a valid number')
			.required('Gender Plays (F) is required'),
		genderPlaysFChange: Yup.number()
			.typeError('Gender Percent Change (F) must be a valid number')
			.required('Gender Percent Change (F) is required'),
		ageRanges: Yup.array()
			.of(
				Yup.object().shape({
					ageRange: Yup.string()
						.typeError('Age Range must be a string')
						.required('Age Range is required'),
					streams: Yup.number()
						.typeError('Plays must be a valid number')
						.min(0, 'Plays cannot be negative')
						.required('Plays is required'),
					percentChange: Yup.number()
						.typeError('Percent Change must be a valid number')
						.required('Percent Change is required'),
				})
			)
			.max(3, 'You can add up to 3 age ranges only')
			.required('Age Ranges are required')
			.min(3, 'At least 3 age ranges must be added'),
	});

	// Initialize Formik with enableReinitialize
	const formik = useFormik({
		initialValues: {
			plays: appleData?.plays ?? '',
			playsChange: appleData?.playsChange ?? '',
			averageDailyListeners: appleData?.averageDailyListeners ?? '',
			averageDailyListenersChange: appleData?.averageDailyListenersChange ?? '',
			shazams: appleData?.shazams ?? '',
			shazamsChange: appleData?.shazamsChange ?? '',
			radioSpins: appleData?.radioSpins ?? '',
			radioSpinsChange: appleData?.radioSpinsChange ?? '',
			purchases: appleData?.purchases ?? '',
			purchasesChange: appleData?.purchasesChange ?? '',
			topCountries: Array.isArray(appleData?.topCountries)
				? appleData.topCountries.map((item) => ({
						country: item.country ?? '',
						streams: item.streams ?? '',
						percentChange: item.percentChange ?? '',
				  }))
				: [
						{ country: '', streams: '', percentChange: '' },
						{ country: '', streams: '', percentChange: '' },
						{ country: '', streams: '', percentChange: '' },
						{ country: '', streams: '', percentChange: '' },
						{ country: '', streams: '', percentChange: '' },
				  ],
			topCities: Array.isArray(appleData?.topCities)
				? appleData.topCities.map((item) => ({
						city: item.city ?? '',
						streams: item.streams ?? '',
						percentChange: item.percentChange ?? '',
				  }))
				: [
						{ city: '', streams: '', percentChange: '' },
						{ city: '', streams: '', percentChange: '' },
						{ city: '', streams: '', percentChange: '' },
						{ city: '', streams: '', percentChange: '' },
						{ city: '', streams: '', percentChange: '' },
				  ],
			topSongs: Array.isArray(appleData?.topSongs)
				? appleData.topSongs.map((item) => ({
						song: item.song ?? '',
						streams: item.streams ?? '',
						percentChange: item.percentChange ?? '',
				  }))
				: [
						{ song: '', streams: '', percentChange: '' },
						{ song: '', streams: '', percentChange: '' },
						{ song: '', streams: '', percentChange: '' },
						{ song: '', streams: '', percentChange: '' },
						{ song: '', streams: '', percentChange: '' },
				  ],
			ageRanges: Array.isArray(appleData?.ageRanges)
				? appleData.ageRanges.map((item) => ({
						ageRange: item.ageRange ?? '',
						streams: item.streams ?? '',
						percentChange: item.percentChange ?? '',
				  }))
				: [
						{ ageRange: '', streams: '', percentChange: '' },
						{ ageRange: '', streams: '', percentChange: '' },
						{ ageRange: '', streams: '', percentChange: '' },
				  ],

			genderPlaysM: appleData?.genderPlaysM ?? '',
			genderPlaysMChange: appleData?.genderPlaysMChange ?? '',
			genderPlaysF: appleData?.genderPlaysF ?? '',
			genderPlaysFChange: appleData?.genderPlaysFChange ?? '',
		},
		validationSchema,
		onSubmit: (values) => {
			onSave(values);
		},
		enableReinitialize: true,
		validateOnMount: true,
	});

	const renderDynamicFields = (
		name,
		label,
		arrayHelpers,
		fieldLabel1,
		fieldLabel2,
		fieldLabel3 // New parameter for the third field label
	) => {
		// Determine the correct field names based on labels
		let fieldName1;
		if (fieldLabel1 === 'Age Range') {
			fieldName1 = 'ageRange';
		} else if (fieldLabel1 === 'City') {
			fieldName1 = 'city';
		} else if (fieldLabel1 === 'Country') {
			fieldName1 = 'country';
		} else if (fieldLabel1 === 'Song') {
			fieldName1 = 'song';
		} else {
			fieldName1 = 'source';
		}

		let fieldName2;
		if (fieldLabel2 === 'Streams' || fieldLabel2 === 'Plays') {
			fieldName2 = 'streams';
		} else if (fieldLabel2 === 'Percent') {
			fieldName2 = 'percent';
		} else {
			fieldName2 = 'value';
		}

		// New logic to determine the field name based on fieldLabel3
		let fieldName3;
		if (fieldLabel3 === 'Percent Change') {
			fieldName3 = 'percentChange';
		}

		return (
			<>
				<Typography
					variant="subtitle1"
					sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
				>
					{label}
				</Typography>
				{getIn(formik.values, name).map((_, index) => (
					<Box display="flex" alignItems="center" mb={2} key={index}>
						{/* Conditional rendering based on fieldLabel1 */}
						{fieldLabel1 === 'Age Range' ? (
							<FormControl fullWidth sx={{ mr: 2 }}>
								<Select
									label={`Age Range ${index + 1}`}
									name={`${name}[${index}].${fieldName1}`}
									value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
										Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
									}
								>
									{ageRangeOptions.map((range) => (
										<MenuItem key={range} value={range}>
											{range}
										</MenuItem>
									))}
								</Select>
								{getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`) && (
										<Typography color="error" variant="caption">
											{getIn(formik.errors, `${name}[${index}].${fieldName1}`)}
										</Typography>
									)}
							</FormControl>
						) : (
							<TextField
								fullWidth
								label={`${fieldLabel1}:`}
								name={`${name}[${index}].${fieldName1}`}
								value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
								}
								helperText={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`)
								}
								variant="outlined"
								sx={{ mr: 2 }}
							/>
						)}

						{/* Second Field */}
						<TextField
							fullWidth
							label={`${fieldLabel2}:`}
							name={`${name}[${index}].${fieldName2}`}
							type="text"
							inputMode="numeric"
							value={getIn(formik.values, `${name}[${index}].${fieldName2}`)}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								getIn(formik.touched, `${name}[${index}].${fieldName2}`) &&
								Boolean(getIn(formik.errors, `${name}[${index}].${fieldName2}`))
							}
							helperText={
								getIn(formik.touched, `${name}[${index}].${fieldName2}`) &&
								getIn(formik.errors, `${name}[${index}].${fieldName2}`)
							}
							variant="outlined"
							sx={{ mr: 2 }}
							InputProps={
								fieldLabel2 === 'Percent'
									? { endAdornment: <Typography variant="caption">%</Typography> }
									: {}
							}
						/>

						{/* Third Field for Change Percentage */}
						<TextField
							fullWidth
							label={`${fieldLabel3}:`}
							name={`${name}[${index}].${fieldName3}`}
							type="text"
							inputMode="numeric"
							value={getIn(formik.values, `${name}[${index}].${fieldName3}`)}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								getIn(formik.touched, `${name}[${index}].${fieldName3}`) &&
								Boolean(getIn(formik.errors, `${name}[${index}].${fieldName3}`))
							}
							helperText={
								getIn(formik.touched, `${name}[${index}].${fieldName3}`) &&
								getIn(formik.errors, `${name}[${index}].${fieldName3}`)
							}
							variant="outlined"
							sx={{ mr: 2 }}
							InputProps={{
								endAdornment: <Typography variant="caption">%</Typography>,
							}}
						/>

						<IconButton
							color="secondary"
							onClick={() => arrayHelpers.remove(index)}
							disabled={getIn(formik.values, name).length === 1}
						>
							<RemoveCircleOutline />
						</IconButton>
					</Box>
				))}
				{getIn(formik.values, name).length <
					(fieldLabel1 === 'Age Range' ? 3 : 5) && (
					<Button
						variant="outlined"
						startIcon={<AddCircleOutline />}
						onClick={() =>
							arrayHelpers.push({
								[fieldName1]: '',
								[fieldName2]: '',
								[fieldName3]: '', // Add the third field to the initial object
							})
						}
						sx={{ mb: 2 }}
					>
						Add {fieldLabel1}
					</Button>
				)}
			</>
		);
	};

	return (
		<FormikProvider value={formik}>
			<Box
				sx={{
					width: '100%',
					maxWidth: '750px',
					margin: '0 auto',
					padding: 2,
					backgroundColor: 'background.paper',
					borderRadius: 2,
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					{/* Header Section */}
					<Box textAlign="center" mb={4}>
						<Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
							Apple Music For Artists Data
						</Typography>
						<Button
							variant="contained"
							color="primary"
							href="https://artists.apple.com/"
							target="_blank"
							className="cta-button"
							sx={{ ...theme.typography.button, mb: 2 }}
						>
							Open Apple Music for Artists
						</Button>
						<Typography variant="subtitle1" sx={{ ...theme.typography.h4, mb: 2 }}>
							Past 4 Weeks
						</Typography>
						<Divider sx={{ mb: 2 }} />
					</Box>

					{/* Main Form Grid */}
					<Grid container spacing={3}>
						{/* Plays */}
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Plays"
								name="plays"
								type="text"
								inputMode="numeric"
								value={formik.values.plays}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={formik.touched.plays && Boolean(formik.errors.plays)}
								helperText={formik.touched.plays && formik.errors.plays}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Plays Change (%)"
								name="playsChange"
								type="text"
								inputMode="numeric"
								value={formik.values.playsChange}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={
									formik.touched.playsChange && Boolean(formik.errors.playsChange)
								}
								helperText={formik.touched.playsChange && formik.errors.playsChange}
								variant="outlined"
								InputProps={{
									endAdornment: <Typography variant="caption">%</Typography>,
								}}
							/>
						</Grid>

						{/* Average Daily Listeners */}
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Average Daily Listeners"
								name="averageDailyListeners"
								type="text"
								inputMode="numeric"
								value={formik.values.averageDailyListeners}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={
									formik.touched.averageDailyListeners &&
									Boolean(formik.errors.averageDailyListeners)
								}
								helperText={
									formik.touched.averageDailyListeners &&
									formik.errors.averageDailyListeners
								}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Average Daily Listeners Change (%)"
								name="averageDailyListenersChange"
								type="text"
								inputMode="numeric"
								value={formik.values.averageDailyListenersChange}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={
									formik.touched.averageDailyListenersChange &&
									Boolean(formik.errors.averageDailyListenersChange)
								}
								helperText={
									formik.touched.averageDailyListenersChange &&
									formik.errors.averageDailyListenersChange
								}
								variant="outlined"
								InputProps={{
									endAdornment: <Typography variant="caption">%</Typography>,
								}}
							/>
						</Grid>

						{/* Shazams */}
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Shazams"
								name="shazams"
								type="text"
								inputMode="numeric"
								value={formik.values.shazams}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={formik.touched.shazams && Boolean(formik.errors.shazams)}
								helperText={formik.touched.shazams && formik.errors.shazams}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Shazams Change (%)"
								name="shazamsChange"
								type="text"
								inputMode="numeric"
								value={formik.values.shazamsChange}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={
									formik.touched.shazamsChange &&
									Boolean(formik.errors.shazamsChange)
								}
								helperText={
									formik.touched.shazamsChange && formik.errors.shazamsChange
								}
								variant="outlined"
								InputProps={{
									endAdornment: <Typography variant="caption">%</Typography>,
								}}
							/>
						</Grid>

						{/* Radio Spins */}
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Radio Spins"
								name="radioSpins"
								type="text"
								inputMode="numeric"
								value={formik.values.radioSpins}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={
									formik.touched.radioSpins && Boolean(formik.errors.radioSpins)
								}
								helperText={formik.touched.radioSpins && formik.errors.radioSpins}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Radio Spins Change (%)"
								name="radioSpinsChange"
								type="text"
								inputMode="numeric"
								value={formik.values.radioSpinsChange}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={
									formik.touched.radioSpinsChange &&
									Boolean(formik.errors.radioSpinsChange)
								}
								helperText={
									formik.touched.radioSpinsChange && formik.errors.radioSpinsChange
								}
								variant="outlined"
								InputProps={{
									endAdornment: <Typography variant="caption">%</Typography>,
								}}
							/>
						</Grid>

						{/* Purchases */}
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Purchases"
								name="purchases"
								type="text"
								inputMode="numeric"
								value={formik.values.purchases}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={formik.touched.purchases && Boolean(formik.errors.purchases)}
								helperText={formik.touched.purchases && formik.errors.purchases}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								fullWidth
								label="Purchases Change (%)"
								name="purchasesChange"
								type="text"
								inputMode="numeric"
								value={formik.values.purchasesChange}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								error={
									formik.touched.purchasesChange &&
									Boolean(formik.errors.purchasesChange)
								}
								helperText={
									formik.touched.purchasesChange && formik.errors.purchasesChange
								}
								variant="outlined"
								InputProps={{
									endAdornment: <Typography variant="caption">%</Typography>,
								}}
							/>
						</Grid>
						<Grid item xs={12} sm={12}>
							<Typography variant="body1" sx={{ fontWeight: 600 }}>
								Gender Demographics
							</Typography>
						</Grid>
						{/* Gender Demographics */}
						<Grid item xs={6} sm={3}>
							<TextField
								fullWidth
								label="Gender Plays (M)"
								name="genderPlaysM"
								type="text"
								inputMode="numeric"
								value={formik.values.genderPlaysM}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched.genderPlaysM && Boolean(formik.errors.genderPlaysM)
								}
								helperText={
									formik.touched.genderPlaysM && formik.errors.genderPlaysM
								}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={6} sm={3}>
							<TextField
								fullWidth
								label="(M) % Change"
								name="genderPlaysMChange"
								type="text"
								inputMode="numeric"
								value={formik.values.genderPlaysMChange}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched.genderPlaysMChange &&
									Boolean(formik.errors.genderPlaysMChange)
								}
								helperText={
									formik.touched.genderPlaysMChange &&
									formik.errors.genderPlaysMChange
								}
								variant="outlined"
							/>
						</Grid>
						<Grid item xs={6} sm={3}>
							<TextField
								fullWidth
								label="Gender Plays (F)"
								name="genderPlaysF"
								type="text"
								inputMode="numeric"
								value={formik.values.genderPlaysF}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched.genderPlaysF && Boolean(formik.errors.genderPlaysF)
								}
								helperText={
									formik.touched.genderPlaysF && formik.errors.genderPlaysF
								}
								variant="outlined"
							/>
						</Grid>

						<Grid item xs={6} sm={3}>
							<TextField
								fullWidth
								label="(F) % Change"
								name="genderPlaysFChange"
								type="text"
								inputMode="numeric"
								value={formik.values.genderPlaysFChange}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									formik.touched.genderPlaysFChange &&
									Boolean(formik.errors.genderPlaysFChange)
								}
								helperText={
									formik.touched.genderPlaysFChange &&
									formik.errors.genderPlaysFChange
								}
								variant="outlined"
							/>
						</Grid>

						{/* Age Ranges Dynamic Fields */}
						<Grid item xs={12} sm={12}>
							<FieldArray
								name="ageRanges"
								render={(arrayHelpers) =>
									renderDynamicFields(
										'ageRanges',
										'Age Ranges',
										arrayHelpers,
										'Age Range',
										'Plays',
										'Percent Change'
									)
								}
							/>
							{formik.touched.ageRanges &&
								typeof formik.errors.ageRanges === 'string' && (
									<Typography color="error" variant="caption">
										{formik.errors.ageRanges}
									</Typography>
								)}
						</Grid>

						{/* Dynamic Fields for Top 5 Countries */}
						<Grid item xs={12}>
							<FieldArray
								name="topCountries"
								render={(arrayHelpers) =>
									renderDynamicFields(
										'topCountries',
										'Top 5 Countries by Plays',
										arrayHelpers,
										'Country',
										'Plays',
										'Percent Change'
									)
								}
							/>
						</Grid>

						{/* Dynamic Fields for Top 5 Cities */}
						<Grid item xs={12}>
							<FieldArray
								name="topCities"
								render={(arrayHelpers) =>
									renderDynamicFields(
										'topCities',
										'Top 5 Cities by Plays',
										arrayHelpers,
										'City',
										'Plays',
										'Percent Change'
									)
								}
							/>
						</Grid>

						{/* Dynamic Fields for Top 5 Songs */}
						<Grid item xs={12}>
							<FieldArray
								name="topSongs"
								render={(arrayHelpers) =>
									renderDynamicFields(
										'topSongs',
										'Top 5 Songs by Plays',
										arrayHelpers,
										'Song',
										'Plays',
										'Percent Change'
									)
								}
							/>
						</Grid>
					</Grid>

					{/* Submit and Cancel Buttons */}
					<Box mt={4} display="flex" justifyContent="center" gap={2}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ...theme.typography.button }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!formik.isValid || formik.isSubmitting}
							size="large"
							sx={{ ...theme.typography.button }}
						>
							Save Data
						</Button>
					</Box>
				</form>
			</Box>
		</FormikProvider>
	);
};

export default AppleMusicData;
