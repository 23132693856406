import React from 'react';
import {
	Button,
	DialogActions,
	DialogContent,
	Typography,
	TextField,
	Grid,
	MenuItem,
	Box,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';

// Reusable GoalSection Component
const GoalSection = ({
	goalNumber,
	mainGoalOptions,
	secondaryGoalOptions,
	formik,
}) => {
	const mainCategory = formik.values[`goal${goalNumber}Category`];
	const secondaryOptions = secondaryGoalOptions[mainCategory] || [];

	return (
		<Grid item sm={12} md={4}>
			<Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
				{`Goal ${goalNumber}`}
			</Typography>

			{/* Select Goal Category */}
			<TextField
				select
				fullWidth
				label="Select Goal Category"
				name={`goal${goalNumber}Category`}
				value={formik.values[`goal${goalNumber}Category`]}
				onChange={(e) => {
					formik.handleChange(e);
					// Reset secondary goal when main category changes
					formik.setFieldValue(`goal${goalNumber}Secondary`, '');
				}}
				onBlur={formik.handleBlur}
				error={
					formik.touched[`goal${goalNumber}Category`] &&
					Boolean(formik.errors[`goal${goalNumber}Category`])
				}
				helperText={
					formik.touched[`goal${goalNumber}Category`] &&
					formik.errors[`goal${goalNumber}Category`]
				}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{mainGoalOptions.map((option) => (
					<MenuItem key={option} value={option}>
						{option}
					</MenuItem>
				))}
			</TextField>

			{/* Select Specific Goal */}
			<TextField
				select
				fullWidth
				label="Select Specific Goal"
				name={`goal${goalNumber}Secondary`}
				value={formik.values[`goal${goalNumber}Secondary`]}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={
					formik.touched[`goal${goalNumber}Secondary`] &&
					Boolean(formik.errors[`goal${goalNumber}Secondary`])
				}
				helperText={
					formik.touched[`goal${goalNumber}Secondary`] &&
					formik.errors[`goal${goalNumber}Secondary`]
				}
				disabled={!mainCategory}
				sx={{ mt: 2 }}
			>
				<MenuItem value="">
					<em>None</em>
				</MenuItem>
				{secondaryOptions.map((subOption) => (
					<MenuItem key={subOption} value={subOption}>
						{subOption}
					</MenuItem>
				))}
			</TextField>

			{/* Describe Your Goal */}
			<TextField
				fullWidth
				label="Briefly Describe Your Goal"
				multiline
				minRows={4}
				name={`goal${goalNumber}Detail`}
				value={formik.values[`goal${goalNumber}Detail`]}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={
					formik.touched[`goal${goalNumber}Detail`] &&
					Boolean(formik.errors[`goal${goalNumber}Detail`])
				}
				helperText={
					formik.touched[`goal${goalNumber}Detail`] &&
					formik.errors[`goal${goalNumber}Detail`]
				}
				sx={{ mt: 2 }}
			/>

			{/* Quantify Your Goal */}
			{/*}
			<TextField
				fullWidth
				label="Quantify Your Goal"
				type="number"
				name={`goal${goalNumber}Value`}
				value={formik.values[`goal${goalNumber}Value`]}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				error={
					formik.touched[`goal${goalNumber}Value`] &&
					Boolean(formik.errors[`goal${goalNumber}Value`])
				}
				helperText={
					formik.touched[`goal${goalNumber}Value`] &&
					formik.errors[`goal${goalNumber}Value`]
				}
				sx={{ mt: 2 }}
			/>
			*/}
		</Grid>
	);
};

const ShortTermGoals = ({ artistData, onSave, onPopupBack, onPopupNext }) => {
	const theme = useTheme();
	const mainGoalOptions = [
		'Press Coverage',
		'Social Media Growth',
		'Music/Content Releases',
		'Event Attendance',
		'Merch Sales',
		'Brand Deals',
	];

	const secondaryGoalOptions = {
		'Press Coverage': [
			'Interviews',
			'Press Releases',
			'Blog/Online Features',
			'Podcast Appearances',
		],
		'Social Media Growth': [
			'Follower Count Increase',
			'Engagement Rate Increase',
			'Content Posting Frequency',
		],
		'Music/Content Releases': [
			'Single Release',
			'EP Release',
			'Album Release',
			'Playlist Placement',
		],
		'Event Attendance': [
			'Concerts/Tour Attendance',
			'Festival Appearances',
			'Live Streaming Event',
		],
		'Merch Sales': ['Merchandise Launch', 'Units Sold', 'Average Order Value'],
		'Brand Deals': [
			'Sponsorship',
			'Endorsement',
			'Licensing Deal',
			'In-Kind Partnerships',
		],
	};

	// Formik setup
	const formik = useFormik({
		initialValues: {
			goal1Category: artistData.shortTermGoal1 || '',
			goal1Secondary: artistData.shortTermSecondaryGoal1 || '',
			goal1Detail: artistData.shortTermDetail1 || '',
			goal1Value: artistData.shortTermValue1 || '',
			goal2Category: artistData.shortTermGoal2 || '',
			goal2Secondary: artistData.shortTermSecondaryGoal2 || '',
			goal2Detail: artistData.shortTermDetail2 || '',
			goal2Value: artistData.shortTermValue2 || '',
			goal3Category: artistData.shortTermGoal3 || '',
			goal3Secondary: artistData.shortTermSecondaryGoal3 || '',
			goal3Detail: artistData.shortTermDetail3 || '',
			goal3Value: artistData.shortTermValue3 || '',
		},
		validationSchema: Yup.object({
			goal1Category: Yup.string().required('Goal 1 category is required'),
			goal1Secondary: Yup.string().required('Goal 1 specific goal is required'),
			goal1Detail: Yup.string()
				.min(50, 'Goal 1 description must be at least 50 characters long')
				.required('Goal 1 description is required'),
			goal1Value: Yup.number()
				.nullable()
				.notRequired()
				.typeError('Goal 1 value must be a number'),
			goal2Category: Yup.string().required('Goal 2 category is required'),
			goal2Secondary: Yup.string().required('Goal 2 specific goal is required'),
			goal2Detail: Yup.string()
				.min(50, 'Goal 2 description must be at least 50 characters long')
				.required('Goal 2 description is required'),
			goal2Value: Yup.number()
				.nullable()
				.notRequired()
				.typeError('Goal 2 value must be a number'),
			goal3Category: Yup.string().required('Goal 3 category is required'),
			goal3Secondary: Yup.string().required('Goal 3 specific goal is required'),
			goal3Detail: Yup.string()
				.min(50, 'Goal 3 description must be at least 50 characters long')
				.required('Goal 3 description is required'),
			goal3Value: Yup.number()
				.nullable()
				.notRequired()
				.typeError('Goal 3 value must be a number'),
		}),
		onSubmit: (values) => {
			const updatedData = {
				shortTermGoal1: values.goal1Category,
				shortTermGoal2: values.goal2Category,
				shortTermGoal3: values.goal3Category,
				shortTermSecondaryGoal1: values.goal1Secondary,
				shortTermSecondaryGoal2: values.goal2Secondary,
				shortTermSecondaryGoal3: values.goal3Secondary,
				shortTermDetail1: values.goal1Detail,
				shortTermDetail2: values.goal2Detail,
				shortTermDetail3: values.goal3Detail,
				shortTermValue1: values.goal1Value,
				shortTermValue2: values.goal2Value,
				shortTermValue3: values.goal3Value,
			};
			onSave(updatedData);
			onPopupNext();
		},
	});

	return (
		<Box p={3}>
			<DialogContent>
				<Typography variant="h4" sx={{ textAlign: 'center', mb: 3 }}>
					What are your short-term goals for this year?
				</Typography>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={4}>
						{/* Goal 1 */}
						<GoalSection
							goalNumber={1}
							mainGoalOptions={mainGoalOptions}
							secondaryGoalOptions={secondaryGoalOptions}
							formik={formik}
						/>

						{/* Goal 2 */}
						<GoalSection
							goalNumber={2}
							mainGoalOptions={mainGoalOptions}
							secondaryGoalOptions={secondaryGoalOptions}
							formik={formik}
						/>

						{/* Goal 3 */}
						<GoalSection
							goalNumber={3}
							mainGoalOptions={mainGoalOptions}
							secondaryGoalOptions={secondaryGoalOptions}
							formik={formik}
						/>
					</Grid>

					{/* Form Actions */}
					<Grid container sx={{ justifyContent: 'center', mt: 2 }}>
						<Grid item xs={12}>
							<Box display="flex" justifyContent="center" alignItems="center">
								<DialogActions>
									<Button
										onClick={onPopupBack}
										color="secondary"
										variant="contained"
										sx={{ ...theme.typography.button, mr: 2 }}
									>
										Back
									</Button>
									<Button
										type="submit"
										color="primary"
										variant="contained"
										sx={{ ...theme.typography.button }}
									>
										Next
									</Button>
								</DialogActions>
							</Box>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Box>
	);
};

export default ShortTermGoals;
