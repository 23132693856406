import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Grid,
	Modal,
	Box,
	Typography,
	TextField,
	Button,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { campaignTypes } from '../../../../utils/configs/campaignsConfig';
import CampaignIcon from '@mui/icons-material/Campaign';

const EditCampaignModal = ({ isOpen, onClose, campaign, onSave }) => {
	const [formData, setFormData] = useState({
		project_name: '',
		marketing_goal: '',
		// Add other fields as necessary
	});

	useEffect(() => {
		if (campaign) {
			setFormData({
				project_name: campaign.project_name,
				marketing_goal: campaign.marketing_goal,
				// Initialize other fields as necessary
			});
		}
	}, [campaign]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		onSave(formData);
	};

	const modalStyle = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: { xs: '90%', sm: 500 },
		bgcolor: 'background.paper',
		borderRadius: 2,
		boxShadow: 24,
		p: 4,
		maxHeight: '90vh',
		overflowY: 'auto',
	};

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			aria-labelledby="edit-campaign-modal"
			aria-describedby="modal-to-edit-campaign-details"
		>
			<Box sx={modalStyle}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<Typography
						id="edit-campaign-modal"
						variant="h4"
						component="h2"
						sx={{ fontWeight: 'bold' }}
					>
						Edit Campaign
					</Typography>
					<IconButton
						onClick={onClose}
						size="small"
						aria-label="Close edit campaign modal"
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box component="form" onSubmit={handleSubmit} mt={2}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								label="Project Name"
								name="project_name"
								value={formData.project_name}
								onChange={handleChange}
								fullWidth
								required
								variant="outlined"
								size="small"
								sx={{ borderRadius: 2 }}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth required variant="outlined" size="small">
								<InputLabel id="marketing-goal-label">Marketing Goal</InputLabel>
								<Select
									labelId="marketing-goal-label"
									id="marketing-goal-select"
									name="marketing_goal"
									value={formData.marketing_goal}
									label="Marketing Goal"
									onChange={handleChange}
									renderValue={(selected) => {
										const selectedType = campaignTypes.find(
											(ct) => ct.goalName === selected
										);
										return (
											<Box display="flex" alignItems="center">
												{selectedType &&
													React.createElement(selectedType.icon, {
														fontSize: 'small',
														color: 'primary',
													})}
												<Typography
													variant="body1"
													sx={{ ml: 1, color: 'text.primary' }}
												>
													{selected}
												</Typography>
											</Box>
										);
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												maxHeight: 300,
											},
										},
									}}
								>
									{campaignTypes.map((type) => (
										<MenuItem key={type.id} value={type.goalName}>
											<ListItemIcon>
												{type.icon ? (
													React.createElement(type.icon, {
														fontSize: 'small',
														color: 'primary',
													})
												) : (
													<CampaignIcon fontSize="small" color="primary" />
												)}
											</ListItemIcon>
											<ListItemText primary={type.goalName} />
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
						{/* Add more fields as necessary */}
						<Grid item xs={12}>
							<Grid container spacing={2} justifyContent="flex-end">
								<Grid item xs={12} sm="auto">
									<Button
										onClick={onClose}
										fullWidth
										variant="outlined"
										color="primary"
										sx={{ borderRadius: 2 }}
									>
										Cancel
									</Button>
								</Grid>
								<Grid item xs={12} sm="auto">
									<Button
										type="submit"
										variant="contained"
										fullWidth
										sx={{ backgroundColor: 'success.main', borderRadius: 2 }}
									>
										Save Changes
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Box>
		</Modal>
	);
};

EditCampaignModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
		project_name: PropTypes.string.isRequired,
		marketing_goal: PropTypes.string.isRequired,
	}).isRequired,
	onSave: PropTypes.func.isRequired,
};

export default EditCampaignModal;
