// components/modules/chatInterface/subcomponents/FallbackMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const FallbackMessage = ({ error }) => (
	<Box
		sx={{
			backgroundColor: 'error.light',
			color: 'error.dark',
			borderRadius: 2,
			padding: 1.5,
			maxWidth: '70%',
			wordBreak: 'break-word',
		}}
	>
		<Typography variant="body2">
			An error occurred while rendering this message.
		</Typography>
		<Typography variant="body2" color="error.dark">
			{error.toString()}
		</Typography>
	</Box>
);

FallbackMessage.propTypes = {
	error: PropTypes.object.isRequired,
};

export default FallbackMessage;
