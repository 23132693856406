// SpotifyData.js
import React from 'react';
import {
	Button,
	TextField,
	Typography,
	Grid,
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Box,
	Divider,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useFormik, FieldArray, FormikProvider, getIn } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';

const SpotifyData = ({ artistData: spotifyData, onSave, onCancel }) => {
	const theme = useTheme();
	const ageRangeOptions = [
		'<18',
		'18-22',
		'23-27',
		'28-34',
		'35-44',
		'45-59',
		'60+',
	];
	// Validation schema using Yup based on backend's expected data
	const validationSchema = Yup.object().shape({
		listeners: Yup.number()
			.typeError('Listeners must be a valid number')
			.integer('Listeners must be an integer')
			.min(0, 'Listeners cannot be negative')
			.required('Listeners is required'),

		listenersChange: Yup.number()
			.typeError('Listeners Change must be a valid number')
			.required('Listeners Change is required'),

		streams: Yup.number()
			.typeError('Streams must be a valid number')
			.integer('Streams must be an integer')
			.min(0, 'Streams cannot be negative')
			.required('Streams is required'),

		streamsChange: Yup.number()
			.typeError('Streams Change must be a valid number')
			.required('Streams Change is required'),

		streamsPerListener: Yup.number()
			.typeError('Streams Per Listener must be a valid number')
			.required('Streams Per Listener is required'),

		streamsPerListenerChange: Yup.number()
			.typeError('Streams Per Listener Change must be a valid number')
			.required('Streams Per Listener Change is required'),

		saves: Yup.number()
			.typeError('Saves must be a valid number')
			.integer('Saves must be an integer')
			.min(0, 'Saves cannot be negative')
			.required('Saves is required'),

		savesChange: Yup.number()
			.typeError('Saves Change must be a valid number')
			.required('Saves Change is required'),

		playlistAdds: Yup.number()
			.typeError('Playlist Adds must be a valid number')
			.integer('Playlist Adds must be an integer')
			.min(0, 'Playlist Adds cannot be negative')
			.required('Playlist Adds is required'),

		playlistAddsChange: Yup.number()
			.typeError('Playlist Adds Change must be a valid number')
			.required('Playlist Adds Change is required'),

		totalFollowers: Yup.number()
			.typeError('Total Followers must be a valid number')
			.integer('Total Followers must be an integer')
			.min(0, 'Total Followers cannot be negative')
			.required('Total Followers is required'),

		followersChange: Yup.number()
			.typeError('Followers Change must be a valid number')
			.required('Followers Change is required'),

		topCountries: Yup.array()
			.of(
				Yup.object().shape({
					country: Yup.string()
						.typeError('Country must be a string')
						.matches(/^[A-Za-z\s]+$/, 'Country can only contain letters and spaces')
						.required('Country is required'),
					listeners: Yup.number()
						.typeError('Listeners must be a valid number')
						.integer('Listeners must be an integer')
						.min(0, 'Listeners cannot be negative')
						.required('Listeners is required'),
				})
			)
			.max(5, 'You can add up to 5 countries only')
			.required('Top Countries are required')
			.min(5, 'At least 5 countries must be added'),

		topCities: Yup.array()
			.of(
				Yup.object().shape({
					city: Yup.string()
						.typeError('City must be a string')
						.matches(/^[A-Za-z\s]+$/, 'City can only contain letters and spaces')
						.required('City is required'),
					listeners: Yup.number()
						.typeError('Listeners must be a valid number')
						.integer('Listeners must be an integer')
						.min(0, 'Listeners cannot be negative')
						.required('Listeners is required'),
				})
			)
			.max(5, 'You can add up to 5 cities only')
			.required('Top Cities are required')
			.min(5, 'At least 5 cities must be added'),

		topSongs: Yup.array()
			.of(
				Yup.object().shape({
					song: Yup.string()
						.typeError('Song must be a string')
						.required('Song is required'),
					streams: Yup.number()
						.typeError('Streams must be a valid number')
						.integer('Streams must be an integer')
						.min(-1, 'Streams cannot be negative')
						.required('Streams is required'),
					listeners: Yup.number()
						.typeError('Listeners must be a valid number')
						.integer('Listeners must be an integer')
						.min(-1, 'Listeners cannot be negative')
						.required('Listeners is required'),
					saves: Yup.number()
						.typeError('Saves must be a valid number')
						.integer('Saves must be an integer')
						.min(-1, 'Saves cannot be negative')
						.required('Saves is required'),
				})
			)
			.max(5, 'You can add up to 5 songs only')
			.required('Top Songs are required')
			.min(5, 'At least 5 songs must be added'),

		ageRanges: Yup.array()
			.of(
				Yup.object().shape({
					ageRange: Yup.string()
						.typeError('Age Range must be a string')
						.required('Age Range is required'),
					percent: Yup.number()
						.typeError('Percent must be a valid number')
						.min(0, 'Percent cannot be negative')
						.max(100, 'Percent cannot exceed 100')
						.required('Percent is required'),
				})
			)
			.max(3, 'You can add up to 3 age ranges only')
			.required('Age Ranges are required')
			.min(3, 'At least 3 age ranges must be added'),

		// Add Gender Percentages
		genderPercentM: Yup.number()
			.typeError('Gender Percent (M) must be a valid number')
			.min(0, 'Gender Percent (M) cannot be negative')
			.max(100, 'Gender Percent (M) cannot exceed 100')
			.required('Gender Percent (M) is required'),
		genderPercentF: Yup.number()
			.typeError('Gender Percent (F) must be a valid number')
			.min(0, 'Gender Percent (F) cannot be negative')
			.max(100, 'Gender Percent (F) cannot exceed 100')
			.required('Gender Percent (F) is required'),
	});

	// Initialize Formik with enableReinitialize
	const formik = useFormik({
		initialValues: {
			listeners: spotifyData?.listeners ?? '',
			listenersChange: spotifyData?.listenersChange ?? '',
			streams: spotifyData?.streams ?? '',
			streamsChange: spotifyData?.streamsChange ?? '',
			streamsPerListener: spotifyData?.streamsPerListener ?? '',
			streamsPerListenerChange: spotifyData?.streamsPerListenerChange ?? '',
			saves: spotifyData?.saves ?? '',
			savesChange: spotifyData?.savesChange ?? '',
			playlistAdds: spotifyData?.playlistAdds ?? '',
			playlistAddsChange: spotifyData?.playlistAddsChange ?? '',
			totalFollowers: spotifyData?.totalFollowers ?? '',
			followersChange: spotifyData?.followersChange ?? '',
			topCountries: Array.isArray(spotifyData?.topCountries)
				? spotifyData.topCountries.map((item) => ({
						country: item.country ?? '',
						listeners: item.listeners ?? '',
				  }))
				: [
						{ country: '', listeners: '' },
						{ country: '', listeners: '' },
						{ country: '', listeners: '' },
						{ country: '', listeners: '' },
						{ country: '', listeners: '' },
				  ],
			topCities: Array.isArray(spotifyData?.topCities)
				? spotifyData.topCities.map((item) => ({
						city: item.city ?? '',
						listeners: item.listeners ?? '',
				  }))
				: [
						{ city: '', listeners: '' },
						{ city: '', listeners: '' },
						{ city: '', listeners: '' },
						{ city: '', listeners: '' },
						{ city: '', listeners: '' },
				  ],
			topSongs: Array.isArray(spotifyData?.topSongs)
				? spotifyData.topSongs.map((item) => ({
						song: item.song ?? '',
						streams: item.streams ?? '',
						listeners: item.listeners ?? '',
						saves: item.saves ?? '',
				  }))
				: [
						{ song: '', streams: '', listeners: '', saves: '' },
						{ song: '', streams: '', listeners: '', saves: '' },
						{ song: '', streams: '', listeners: '', saves: '' },
						{ song: '', streams: '', listeners: '', saves: '' },
						{ song: '', streams: '', listeners: '', saves: '' },
				  ],
			ageRanges: Array.isArray(spotifyData?.ageRanges)
				? spotifyData.ageRanges.map((item) => ({
						ageRange: item.ageRange ?? '',
						percent: item.percent ?? '',
				  }))
				: [
						{ ageRange: '', percent: '' },
						{ ageRange: '', percent: '' },
						{ ageRange: '', percent: '' },
				  ],

			genderPercentM: spotifyData?.genderPercentM ?? '',
			genderPercentF: spotifyData?.genderPercentF ?? '',
		},
		validationSchema,
		onSubmit: (values) => {
			onSave(values);
		},
		enableReinitialize: true,
		validateOnMount: true,
	});

	const renderDynamicFields = (
		name,
		label,
		arrayHelpers,
		fieldLabel1,
		fieldLabel2
	) => {
		// Determine the correct field name based on fieldLabel1
		let fieldName1;
		if (fieldLabel1 === 'Age Range') {
			fieldName1 = 'ageRange';
		} else if (fieldLabel1 === 'City') {
			fieldName1 = 'city';
		} else if (fieldLabel1 === 'Country') {
			fieldName1 = 'country';
		} else if (fieldLabel1 === 'Song') {
			fieldName1 = 'song';
		} else {
			fieldName1 = 'source';
		}
		// Determine the correct field name based on fieldLabel2
		let fieldName2;
		if (fieldLabel2 === 'Listeners') {
			fieldName2 = 'listeners';
		} else if (fieldLabel2 === 'Streams') {
			fieldName2 = 'streams';
		} else if (fieldLabel2 === 'Percent') {
			fieldName2 = 'percent';
		} else {
			fieldName2 = 'value';
		}

		return (
			<>
				<Typography
					variant="subtitle1"
					sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
				>
					{label}
				</Typography>
				{getIn(formik.values, name).map((_, index) => (
					<Box display="flex" alignItems="center" mb={2} key={index}>
						{/* Conditional rendering based on fieldLabel1 */}
						{fieldLabel1 === 'Age Range' ? (
							<FormControl fullWidth sx={{ mr: 2 }}>
								<Select
									label={`Age Range ${index + 1}`}
									name={`${name}[${index}].${fieldName1}`}
									value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
										Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
									}
								>
									{ageRangeOptions.map((range) => (
										<MenuItem key={range} value={range}>
											{range}
										</MenuItem>
									))}
								</Select>
								{getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`) && (
										<Typography color="error" variant="caption">
											{getIn(formik.errors, `${name}[${index}].${fieldName1}`)}
										</Typography>
									)}
							</FormControl>
						) : (
							<TextField
								fullWidth
								label={`${fieldLabel1}:`}
								name={`${name}[${index}].${fieldName1}`}
								value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
								}
								helperText={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`)
								}
								variant="outlined"
								sx={{ mr: 2 }}
							/>
						)}
						<TextField
							fullWidth
							label={`${fieldLabel2}:`}
							name={`${name}[${index}].${fieldName2}`}
							type="text"
							inputMode="numeric"
							value={getIn(formik.values, `${name}[${index}].${fieldName2}`)}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								getIn(formik.touched, `${name}[${index}].${fieldName2}`) &&
								Boolean(getIn(formik.errors, `${name}[${index}].${fieldName2}`))
							}
							helperText={
								getIn(formik.touched, `${name}[${index}].${fieldName2}`) &&
								getIn(formik.errors, `${name}[${index}].${fieldName2}`)
							}
							variant="outlined"
							sx={{ mr: 2 }}
						/>
						<IconButton
							color="secondary"
							onClick={() => arrayHelpers.remove(index)}
							disabled={getIn(formik.values, name).length === 1}
						>
							<RemoveCircleOutline />
						</IconButton>
					</Box>
				))}
				{getIn(formik.values, name).length <
					(fieldLabel1 === 'Age Range' ? 3 : 5) && (
					<Button
						variant="outlined"
						startIcon={<AddCircleOutline />}
						onClick={() =>
							arrayHelpers.push({
								[fieldName1]: '',
								[fieldName2]: '',
							})
						}
						sx={{ mb: 2 }}
					>
						Add {fieldLabel1}
					</Button>
				)}
			</>
		);
	};

	return (
		<FormikProvider value={formik}>
			<Box
				sx={{
					width: '100%',
					maxWidth: '750px',
					margin: '0 auto',
					padding: 2,
					backgroundColor: 'background.paper',
					borderRadius: 2,
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					{/* Header Section */}
					<Box textAlign="center" mb={4}>
						<Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
							Spotify For Artists Data
						</Typography>
						<Button
							variant="contained"
							color="primary"
							href="https://artists.spotify.com/"
							target="_blank"
							className="cta-button"
							sx={{ ...theme.typography.button, mb: 2 }}
						>
							Open Spotify for Artists
						</Button>
						<Typography variant="h4" sx={{ textAlign: 'center', mb: 2 }}>
							Past 28 Days
						</Typography>
						<Divider sx={{ mb: 2 }} />
					</Box>

					<Box mb={4}>
						<Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 600 }}>
							Audience Stats
						</Typography>

						{/* Main Form Grid */}
						<Grid container spacing={3}>
							{/* Listeners */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Listeners"
									name="listeners"
									type="text"
									inputMode="numeric"
									value={formik.values.listeners}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.listeners && Boolean(formik.errors.listeners)
									}
									helperText={formik.touched.listeners && formik.errors.listeners}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Listeners Change (%)"
									name="listenersChange"
									type="text"
									inputMode="numeric"
									value={formik.values.listenersChange}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.listenersChange &&
										Boolean(formik.errors.listenersChange)
									}
									helperText={
										formik.touched.listenersChange && formik.errors.listenersChange
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
									}}
								/>
							</Grid>

							{/* Streams */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Streams"
									name="streams"
									type="text"
									inputMode="numeric"
									value={formik.values.streams}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={formik.touched.streams && Boolean(formik.errors.streams)}
									helperText={formik.touched.streams && formik.errors.streams}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Streams Change (%)"
									name="streamsChange"
									type="text"
									inputMode="numeric"
									value={formik.values.streamsChange}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.streamsChange &&
										Boolean(formik.errors.streamsChange)
									}
									helperText={
										formik.touched.streamsChange && formik.errors.streamsChange
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
									}}
								/>
							</Grid>

							{/* Streams Per Listener */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Streams Per Listener"
									name="streamsPerListener"
									type="text"
									inputMode="numeric"
									value={formik.values.streamsPerListener}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.streamsPerListener &&
										Boolean(formik.errors.streamsPerListener)
									}
									helperText={
										formik.touched.streamsPerListener &&
										formik.errors.streamsPerListener
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Streams Per Listener Change (%)"
									name="streamsPerListenerChange"
									type="text"
									inputMode="numeric"
									value={formik.values.streamsPerListenerChange}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.streamsPerListenerChange &&
										Boolean(formik.errors.streamsPerListenerChange)
									}
									helperText={
										formik.touched.streamsPerListenerChange &&
										formik.errors.streamsPerListenerChange
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
									}}
								/>
							</Grid>

							{/* Saves */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Saves"
									name="saves"
									type="text"
									inputMode="numeric"
									value={formik.values.saves}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.saves && Boolean(formik.errors.saves)}
									helperText={formik.touched.saves && formik.errors.saves}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Saves Change (%)"
									name="savesChange"
									type="text"
									inputMode="numeric"
									value={formik.values.savesChange}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.savesChange && Boolean(formik.errors.savesChange)
									}
									helperText={
										formik.touched.savesChange && formik.errors.savesChange
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
									}}
								/>
							</Grid>

							{/* Playlist Adds */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Playlist Adds"
									name="playlistAdds"
									type="text"
									inputMode="numeric"
									value={formik.values.playlistAdds}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.playlistAdds &&
										Boolean(formik.errors.playlistAdds)
									}
									helperText={
										formik.touched.playlistAdds && formik.errors.playlistAdds
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Playlist Adds Change (%)"
									name="playlistAddsChange"
									type="text"
									inputMode="numeric"
									value={formik.values.playlistAddsChange}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.playlistAddsChange &&
										Boolean(formik.errors.playlistAddsChange)
									}
									helperText={
										formik.touched.playlistAddsChange &&
										formik.errors.playlistAddsChange
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
									}}
								/>
							</Grid>

							{/* Total Followers */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Total Followers"
									name="totalFollowers"
									type="text"
									inputMode="numeric"
									value={formik.values.totalFollowers}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.totalFollowers &&
										Boolean(formik.errors.totalFollowers)
									}
									helperText={
										formik.touched.totalFollowers && formik.errors.totalFollowers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Followers Change (%)"
									name="followersChange"
									type="text"
									inputMode="numeric"
									value={formik.values.followersChange}
									onBlur={formik.handleBlur}
									onChange={formik.handleChange}
									error={
										formik.touched.followersChange &&
										Boolean(formik.errors.followersChange)
									}
									helperText={
										formik.touched.followersChange && formik.errors.followersChange
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
									}}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box mb={4}>
						<Typography variant="body1" sx={{ mt: 2, mb: 2, fontWeight: 600 }}>
							Gender Demographics
						</Typography>
						<Grid container spacing={2}>
							{/* Gender Demographics */}
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (M)"
									name="genderPercentM"
									type="text"
									inputMode="numeric"
									value={formik.values.genderPercentM}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.genderPercentM &&
										Boolean(formik.errors.genderPercentM)
									}
									helperText={
										formik.touched.genderPercentM && formik.errors.genderPercentM
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (F)"
									name="genderPercentF"
									type="text"
									inputMode="numeric"
									value={formik.values.genderPercentF}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.genderPercentF &&
										Boolean(formik.errors.genderPercentF)
									}
									helperText={
										formik.touched.genderPercentF && formik.errors.genderPercentF
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box mb={4}>
						{/* Age Ranges Multi-Select */}
						<Grid item xs={12} sm={12}>
							<FieldArray
								name="ageRanges"
								render={(arrayHelpers) =>
									renderDynamicFields(
										'ageRanges',
										'Top 3 Age Ranges',
										arrayHelpers,
										'Age Range',
										'Percent'
									)
								}
							/>
							{formik.touched.ageRanges &&
								typeof formik.errors.ageRanges === 'string' && (
									<Typography color="error" variant="caption">
										{formik.errors.ageRanges}
									</Typography>
								)}
						</Grid>

						{/* Dynamic Fields for Top 5 Countries */}
						<Grid item xs={12}>
							<FieldArray
								name="topCountries"
								render={(arrayHelpers) =>
									renderDynamicFields(
										'topCountries',
										'Top 5 Countries by Listeners',
										arrayHelpers,
										'Country',
										'Listeners'
									)
								}
							/>
						</Grid>

						{/* Dynamic Fields for Top 5 Cities */}
						<Grid item xs={12}>
							<FieldArray
								name="topCities"
								render={(arrayHelpers) =>
									renderDynamicFields(
										'topCities',
										'Top 5 Cities by Listeners',
										arrayHelpers,
										'City',
										'Listeners'
									)
								}
							/>
						</Grid>

						{/* Dynamic Fields for Top 5 Songs */}
						<Grid item xs={12}>
							<FieldArray
								name="topSongs"
								render={(arrayHelpers) => (
									<>
										<Typography
											variant="subtitle1"
											sx={{
												...theme.typography.body1,
												mt: 2,
												mb: 2,
												fontWeight: 600,
											}}
										>
											Top 5 Songs by Metrics
										</Typography>
										{formik.values.topSongs.map((_, index) => (
											<Box display="flex" alignItems="center" mb={2} key={index}>
												<TextField
													fullWidth
													label={`Song ${index + 1}`}
													name={`topSongs[${index}].song`}
													value={formik.values.topSongs[index].song}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													error={Boolean(
														getIn(formik.errors, `topSongs[${index}].song`)
													)}
													helperText={getIn(
														formik.errors,
														`topSongs[${index}].song`
													)}
													sx={{ mr: 2 }}
												/>
												<TextField
													fullWidth
													label="Streams"
													name={`topSongs[${index}].streams`}
													value={formik.values.topSongs[index].streams}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													error={Boolean(
														getIn(formik.errors, `topSongs[${index}].streams`)
													)}
													helperText={getIn(
														formik.errors,
														`topSongs[${index}].streams`
													)}
													sx={{ mr: 2 }}
												/>
												<TextField
													fullWidth
													label="Listeners"
													name={`topSongs[${index}].listeners`}
													value={formik.values.topSongs[index].listeners}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													error={Boolean(
														getIn(formik.errors, `topSongs[${index}].listeners`)
													)}
													helperText={getIn(
														formik.errors,
														`topSongs[${index}].listeners`
													)}
													sx={{ mr: 2 }}
												/>
												<TextField
													fullWidth
													label="Saves"
													name={`topSongs[${index}].saves`}
													value={formik.values.topSongs[index].saves}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													error={Boolean(
														getIn(formik.errors, `topSongs[${index}].saves`)
													)}
													helperText={getIn(
														formik.errors,
														`topSongs[${index}].saves`
													)}
													sx={{ mr: 2 }}
												/>

												<IconButton
													color="secondary"
													onClick={() => arrayHelpers.remove(index)}
												>
													<RemoveCircleOutline />
												</IconButton>
											</Box>
										))}
										{formik.values.topSongs.length < 5 && ( // Show the button only if less than 5 songs are added
											<Button
												variant="outlined"
												startIcon={<AddCircleOutline />}
												onClick={() =>
													arrayHelpers.push({
														song: '',
														streams: '',
														listeners: '',
														saves: '',
													})
												}
												sx={{ mb: 2 }}
											>
												Add Song
											</Button>
										)}
									</>
								)}
							/>
						</Grid>
					</Box>

					{/* Submit and Cancel Buttons */}
					<Box mt={4} display="flex" justifyContent="center" gap={2}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ...theme.typography.button }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!formik.isValid || formik.isSubmitting}
							size="large"
							sx={{ ...theme.typography.button }}
						>
							Save Data
						</Button>
					</Box>
				</form>
			</Box>
		</FormikProvider>
	);
};

export default SpotifyData;
