import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, Typography, Button, Box } from '@mui/material';

const CampaignOptionCard = ({ campaign, onStartCampaign }) => {
	const handleStart = () => {
		onStartCampaign(campaign.title);
	};

	return (
		<Card
			variant="outlined"
			sx={{
				position: 'relative',
				cursor: 'pointer',
				height: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				'&:hover': { boxShadow: 6 },
				borderRadius: 2,
				backgroundColor: 'background.paper',
				flexGrow: 1,
			}}
			onClick={handleStart}
		>
			<CardContent sx={{ flexGrow: 1 }}>
				<Grid
					item
					xs={12}
					sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
				>
					{/* Icon */}
					<Box
						sx={{
							fontSize: 'large',
							marginBottom: 2,
							color: 'primary.main',
						}}
					>
						{React.createElement(campaign.icon)}
					</Box>

					{/* Title */}
					<Typography
						variant="h4"
						component="div"
						gutterBottom
						align="center"
						sx={{
							fontWeight: 'bold',
							color: 'text.primary',
							textAlign: 'center',
							mb: 1,
						}}
					>
						{campaign.title}
					</Typography>

					{/* Description */}
					<Typography variant="body2" color="text.secondary" align="center">
						{campaign.description}
					</Typography>

					{/* Start Campaign Button */}
					<Button
						variant="contained"
						color="primary"
						onClick={(e) => {
							e.stopPropagation();
							handleStart();
						}}
						sx={{
							marginTop: '10px',
							width: '150px',
							borderRadius: 2,
						}}
					>
						Start Campaign
					</Button>
				</Grid>
			</CardContent>
		</Card>
	);
};

CampaignOptionCard.propTypes = {
	campaign: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		description: PropTypes.string.isRequired,
		icon: PropTypes.elementType.isRequired,
	}).isRequired,
	onStartCampaign: PropTypes.func.isRequired,
};

export default CampaignOptionCard;
