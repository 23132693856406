import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CampaignOptionCard from './CampaignOptionCard';
import { campaignTypes } from '../../../../utils/configs/campaignsConfig';
import { Box, Typography, Grid, IconButton } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Install Swiper modules
SwiperCore.use([Navigation]);

const CampaignOptionsSection = ({ onStartCampaign }) => {
	const [showNavigation, setShowNavigation] = useState(false);

	const updateNavigationVisibility = () => {
		const windowWidth = window.innerWidth;
		let visibleSlides = 1;

		if (windowWidth >= 1920) visibleSlides = 5;
		else if (windowWidth >= 1400) visibleSlides = 4;
		else if (windowWidth >= 1000) visibleSlides = 3;
		else if (windowWidth >= 750) visibleSlides = 2;

		setShowNavigation(campaignTypes.length > visibleSlides);
	};

	useEffect(() => {
		updateNavigationVisibility();
		window.addEventListener('resize', updateNavigationVisibility);
		return () => window.removeEventListener('resize', updateNavigationVisibility);
	}, [campaignTypes]);

	return (
		<Box sx={{ p: 1 }}>
			<Typography variant="h4" sx={{ textAlign: 'left' }} gutterBottom>
				Start a New Campaign
			</Typography>
			<>
				<Swiper
					spaceBetween={16}
					navigation={{
						prevEl: '.swiper-button-prev',
						nextEl: '.swiper-button-next',
					}}
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						750: {
							slidesPerView: 2,
						},
						1000: {
							slidesPerView: 3,
						},
						1400: {
							slidesPerView: 4,
						},
						1920: {
							slidesPerView: 5,
						},
					}}
					style={{ padding: '8px 0' }}
				>
					{campaignTypes.map((campaign) => (
						<SwiperSlide key={campaign.id}>
							<Box sx={{ p: 2 }}>
								<CampaignOptionCard
									campaign={campaign}
									onStartCampaign={onStartCampaign}
								/>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
				{showNavigation && (
					<Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
						<IconButton className="swiper-button-prev">
							<ArrowBackIosIcon />
						</IconButton>
						<IconButton className="swiper-button-next">
							<ArrowForwardIosIcon />
						</IconButton>
					</Box>
				)}
			</>
		</Box>
	);
};

CampaignOptionsSection.propTypes = {
	onStartCampaign: PropTypes.func.isRequired,
};

export default CampaignOptionsSection;
