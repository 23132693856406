// src/utils/validation.js

export const validateAnswer = (answer, dataType, allowMultiple) => {
	if (allowMultiple && (!Array.isArray(answer) || answer.length === 0)) {
		return 'Please select at least one option.';
	}
	if (!allowMultiple && (!answer || answer === '')) {
		return 'This field is required.';
	}

	// Type-specific validations
	if (dataType === 'number') {
		if (allowMultiple) {
			if (!Array.isArray(answer) || answer.some((item) => isNaN(Number(item)))) {
				return 'Please enter valid numbers.';
			}
		} else {
			if (isNaN(Number(answer))) {
				return 'Please enter a valid number.';
			}
		}
	}

	if (dataType === 'currency') {
		const currencyRegex = /^\$?\d+(,\d{3})*(\.\d{2})?$/;
		if (allowMultiple) {
			if (
				!Array.isArray(answer) ||
				answer.some((item) => !currencyRegex.test(item))
			) {
				return 'Please enter valid currency amounts (e.g., 1000, $1,000.00).';
			}
		} else {
			if (!currencyRegex.test(answer)) {
				return 'Please enter a valid currency amount (e.g., 1000, $1,000.00).';
			}
		}
	}

	if (dataType === 'date') {
		const isValidDate = (date) => !isNaN(Date.parse(date));
		if (allowMultiple) {
			if (!Array.isArray(answer) || answer.some((item) => !isValidDate(item))) {
				return 'Please enter valid dates.';
			}
		} else {
			if (!isValidDate(answer)) {
				return 'Please enter a valid date.';
			}
		}
	}

	if (dataType === 'email') {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (allowMultiple) {
			if (!Array.isArray(answer) || answer.some((item) => !emailRegex.test(item))) {
				return 'Please enter valid email addresses.';
			}
		} else {
			if (!emailRegex.test(answer)) {
				return 'Please enter a valid email address.';
			}
		}
	}

	if (dataType === 'url') {
		try {
			new URL(allowMultiple ? answer[0] : answer);
		} catch (_) {
			return 'Please enter a valid URL.';
		}
	}

	return null;
};

export const validateAdditionalDetails = (additionalDetails, optionConfig) => {
	const { additionalDetailsType } = optionConfig;
	if (!additionalDetailsType) return null;

	if (!additionalDetails || additionalDetails.trim() === '') {
		return 'Please provide additional details.';
	}

	if (additionalDetailsType === 'number') {
		if (isNaN(Number(additionalDetails))) {
			return 'Please enter a valid number.';
		}
	}

	if (additionalDetailsType === 'currency') {
		const currencyRegex = /^\$?\d+(,\d{3})*(\.\d{2})?$/;
		if (!currencyRegex.test(additionalDetails)) {
			return 'Please enter a valid currency amount (e.g., 1000, $1,000.00).';
		}
	}

	// Add more types as needed

	return null;
};
