// src/components/artistOnboarding/step5/ArtistProfileRecap.js

import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { useTheme } from '@mui/material/styles';

const ArtistProfileRecap = ({ stageName, genre, location, yearsActive, bio }) => {
	const theme = useTheme();
	const maxBioLength = 80;
	const truncatedBio =
		bio.length > maxBioLength ? `${bio.substring(0, maxBioLength)}...` : bio;

	return (
		<Card
			sx={{
				backgroundColor: 'background.paper',
				boxShadow: 3,
				borderRadius: 2,
				height: '100%',
			}}
		>
			<CardContent>
				<Typography
					variant="h6"
					sx={{
						...theme.typography.h4,
						mb: 3,
						textAlign: 'center',
						color: 'primary.main',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						gap: 1,
					}}
				>
					<PersonIcon /> Artist Profile
				</Typography>
				<Grid container spacing={2}>
					{/* Stage Name */}
					<Grid item xs={6}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<PersonIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Stage Name
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{stageName}
						</Typography>
					</Grid>

					{/* Genre */}
					<Grid item xs={6}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<MusicNoteIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Genre
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{genre.join(', ')}
						</Typography>
					</Grid>

					{/* Location */}
					<Grid item xs={6}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<LocationOnIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Location
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{location}
						</Typography>
					</Grid>

					{/* Years Active */}
					<Grid item xs={6}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<AccessTimeIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Years Active
							</Typography>
						</Box>
						<Typography variant="body1" align="center" sx={{ mt: 0.5 }}>
							{yearsActive}
						</Typography>
					</Grid>

					{/* Bio */}
					<Grid item xs={12}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								gap: 1,
							}}
						>
							<TextSnippetIcon color="secondary" />
							<Typography variant="subtitle2" color="text.secondary">
								Brief Bio
							</Typography>
						</Box>
						<Typography
							variant="body1"
							align="center"
							sx={{
								mt: 0.5,
								maxWidth: '100%',
								whiteSpace: 'normal',
								wordWrap: 'break-word',
								textAlign: 'center',
							}}
						>
							{truncatedBio}
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default ArtistProfileRecap;
