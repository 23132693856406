// src/components/modules/chatInterface/subcomponents/navigationTabs/FileCard.jsx

import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	CardActions,
	Button,
	Box,
} from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const getFileIcon = (type) => {
	switch (type.toUpperCase()) {
		case 'DOCX':
			return <DescriptionIcon fontSize="large" color="primary" />;
		case 'PDF':
			return <PictureAsPdfIcon fontSize="large" color="secondary" />;
		case 'XLSX':
			return <InsertDriveFileIcon fontSize="large" color="action" />;
		default:
			return <InsertDriveFileIcon fontSize="large" />;
	}
};

const FileCard = ({ file, onPreview }) => {
	const supportedTypes = ['PDF', 'DOCX', 'XLSX'];
	const isSupported = supportedTypes.includes(file.type.toUpperCase());

	return (
		<Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
			<CardContent sx={{ flexGrow: 1 }}>
				<Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
					{getFileIcon(file.type)}
				</Box>
				<Typography variant="subtitle1" gutterBottom align="center">
					{file.name}
				</Typography>
				<Typography variant="body2" color="text.secondary" align="center">
					{file.type} File
				</Typography>
			</CardContent>
			<CardActions sx={{ justifyContent: 'center' }}>
				<Button
					size="small"
					onClick={() => onPreview(file)}
					disabled={!isSupported}
					sx={{
						textTransform: 'none',
						borderRadius: 2,
					}}
				>
					Preview
				</Button>
				<Button
					size="small"
					href={file.downloadUrl} // Use downloadUrl from backend
					download
					target="_blank"
					rel="noopener noreferrer"
					sx={{
						textTransform: 'none',
						borderRadius: 2,
					}}
				>
					Download
				</Button>
			</CardActions>
		</Card>
	);
};

export default FileCard;
