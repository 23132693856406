// src/utils/coordinates.js

export const countryCoordinates = {
	'United States': [37.0902, -95.7129],
	'United Kingdom': [55.3781, -3.436],
	Canada: [56.1304, -106.3468],
	Australia: [-25.2744, 133.7751],
	Germany: [51.1657, 10.4515],
	France: [46.2276, 2.2137],
	Brazil: [-14.235, -51.9253],
	Japan: [36.2048, 138.2529],
	'South Korea': [35.9078, 127.7669],
	India: [20.5937, 78.9629],
	Mexico: [23.6345, -102.5528],
	Italy: [41.8719, 12.5674],
	Spain: [40.4637, -3.7492],
	Netherlands: [52.1326, 5.2913],
	Sweden: [60.1282, 18.6435],
	Norway: [60.472, 8.4689],
	Russia: [61.524, 105.3188],
	China: [35.8617, 104.1954],
	'South Africa': [-30.5595, 22.9375],
	'New Zealand': [-40.9006, 174.886],
	// Add more countries as needed
};

export const cityCoordinates = {
	'New York': [40.7128, -74.006],
	'Los Angeles': [34.0522, -118.2437],
	London: [51.5074, -0.1278],
	Toronto: [43.6532, -79.3832],
	Sydney: [-33.8688, 151.2093],
	Berlin: [52.52, 13.405],
	Paris: [48.8566, 2.3522],
	Tokyo: [35.6762, 139.6503],
	Seoul: [37.5665, 126.978],
	Mumbai: [19.076, 72.8777],
	'Mexico City': [19.4326, -99.1332],
	Rome: [41.9028, 12.4964],
	Madrid: [40.4168, -3.7038],
	Amsterdam: [52.3676, 4.9041],
	Stockholm: [59.3293, 18.0686],
	Oslo: [59.9139, 10.7522],
	Moscow: [55.7558, 37.6173],
	Beijing: [39.9042, 116.4074],
	Johannesburg: [-26.2041, 28.0473],
	Auckland: [-36.8485, 174.7633],
	// Add more cities as needed
};
