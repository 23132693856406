// socket.js
import { io } from 'socket.io-client';

let socket;
let connectPromise;
const joinedConversations = new Set();

export const initiateSocket = (token) => {
	if (!socket) {
		socket = io(`${process.env.REACT_APP_API_URL}`, {
			auth: {
				token,
			},
			reconnectionAttempts: 5, // Optional: Limit reconnection attempts
		});

		connectPromise = new Promise((resolve, reject) => {
			socket.on('connect', () => {
				resolve(socket);
			});

			socket.on('connect_error', (error) => {
				reject(error);
			});
		});

		// Handle incoming chat history
		socket.on('chatHistory', (messages) => {
			if (socket.chatHistoryHandler) {
				socket.chatHistoryHandler(messages);
			}
		});

		// Handle new incoming messages
		socket.on('newMessage', (message) => {
			if (socket.newMessageHandler) {
				socket.newMessageHandler(message);
			}
		});

		// Handle errors
		socket.on('error', (error) => {
			console.error('WebSocket error:', error);
			if (socket.errorHandler) {
				socket.errorHandler(error);
			}
		});

		// Optional: Handle reconnection attempts
		socket.on('reconnect_attempt', (attempt) => {
			console.log(`Reconnection attempt ${attempt}`);
		});

		socket.on('reconnect_failed', () => {
			console.error('Reconnection failed');
		});
	} else {
		console.log('Socket already initialized');
	}
	return connectPromise;
};

export const joinConversation = (conversationId) => {
	if (socket && socket.connected) {
		if (joinedConversations.has(conversationId)) {
			return;
		}

		socket.emit('joinConversation', { conversationId }, (response) => {
			if (response.error) {
				console.error('Failed to join conversation:', response.error);
			} else {
				joinedConversations.add(conversationId);
			}
		});
	} else {
		console.error('Cannot join conversation, socket not connected');
	}
};

export const disconnectSocket = () => {
	if (socket) {
		socket.disconnect();
		socket = null;
		connectPromise = null;
		joinedConversations.clear();
	}
};

export const sendMessage = (conversationId, userId, message) => {
	if (socket && socket.connected) {
		socket.emit('sendMessage', { conversationId, userId, message }, (response) => {
			if (response.error) {
				console.error('Failed to send message:', response.error);
			}
		});
	} else {
		console.error('Cannot send message, socket not connected');
	}
};

export const setChatHistoryHandler = (handler) => {
	if (socket) {
		socket.chatHistoryHandler = handler;
	}
};

export const setNewMessageHandler = (handler) => {
	if (socket) {
		socket.newMessageHandler = handler;
	}
};

export const setErrorHandler = (handler) => {
	if (socket) {
		socket.errorHandler = handler;
	}
};

export const createConversation = (
	marketing_goal,
	form_data,
	project_name,
	artist_id,
	artist_data
) => {
	return new Promise((resolve, reject) => {
		if (!socket) {
			reject('Socket not initialized');
			return;
		}
		socket.emit(
			'createConversation',
			{ marketing_goal, form_data, project_name, artist_id, artist_data },
			(response) => {
				if (response.error) {
					console.error('Error creating conversation:', response.error);
					reject(response.error);
				} else {
					resolve(response.conversationId);
				}
			}
		);
	});
};
