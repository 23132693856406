// src/components/charts/PopupContent.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

const PopupContent = ({ location }) => {
	return (
		<Box>
			<Typography variant="h5" color="primary" gutterBottom>
				{location?.name || ''}
			</Typography>
			<Typography variant="body2" color="textSecondary">
				<strong>Rank:</strong> {location?.rank || ''}
			</Typography>
			<Typography variant="body2" color="textSecondary">
				<strong>Listeners:</strong> {location?.listeners?.toLocaleString() || ''}
			</Typography>
			<Typography variant="body2" color="textSecondary">
				<strong>Estimated Revenue:</strong> $
				{Object.values(location?.estimatedRevenue)
					.reduce((acc, curr) => acc + curr, 0)
					.toLocaleString() || ''}
			</Typography>
		</Box>
	);
};

PopupContent.propTypes = {
	location: PropTypes.shape({
		name: PropTypes.string.isRequired,
		rank: PropTypes.number.isRequired,
		listeners: PropTypes.number.isRequired,
		listenersPerPlatform: PropTypes.object.isRequired,
		estimatedRevenue: PropTypes.object.isRequired,
	}).isRequired,
};

export default PopupContent;
