// src/components/charts/LocationDetails.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@mui/material';

const LocationDetails = ({ location }) => {
	if (!location) {
		return (
			<Box p={3}>
				<Typography variant="h4" color="primary" gutterBottom>
					Select a Location
				</Typography>
				<Typography variant="body2" color="textSecondary">
					Click on a marker to see details here.
				</Typography>
			</Box>
		);
	}

	return (
		<Box p={3}>
			<Typography variant="h4" color="primary" gutterBottom>
				{location.name}
			</Typography>
			<Divider sx={{ mb: 2 }} />
			<Typography variant="body1">
				<strong>Rank:</strong> {location.rank}
			</Typography>
			<Typography variant="body1">
				<strong>Listeners:</strong> {location.listeners.toLocaleString()}
			</Typography>
			<Box mt={2}>
				<Typography variant="h5" gutterBottom>
					Listeners per Platform
				</Typography>
				{Object.entries(location.listenersPerPlatform).map(([platform, count]) => (
					<Typography key={platform} variant="body2">
						{platform.charAt(0).toUpperCase() + platform.slice(1)}:{' '}
						{count.toLocaleString()}
					</Typography>
				))}
			</Box>
			<Box mt={2}>
				<Typography variant="h5" gutterBottom>
					Estimated Revenue
				</Typography>
				{Object.entries(location.estimatedRevenue).map(([type, amount]) => (
					<Typography key={type} variant="body2">
						{type.charAt(0).toUpperCase() + type.slice(1)}: $
						{amount.toLocaleString()}
					</Typography>
				))}
			</Box>
		</Box>
	);
};

LocationDetails.propTypes = {
	location: PropTypes.shape({
		name: PropTypes.string.isRequired,
		rank: PropTypes.number.isRequired,
		listeners: PropTypes.number.isRequired,
		listenersPerPlatform: PropTypes.object.isRequired,
		estimatedRevenue: PropTypes.object.isRequired,
	}),
};

export default LocationDetails;
