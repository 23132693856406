// src/components/marketingAssistant/SelectionQuestionComponent.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Typography,
	TextField,
	Button,
	Grid,
	Checkbox,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import {
	validateAnswer,
	validateAdditionalDetails,
} from '../../../../utils/processing/validation';

const SelectionQuestionComponent = ({
	question,
	options = [],
	keyName,
	dataType = 'array',
	allowMultiple = false,
	customInputAllowed = false,
	additionalDetailsAllowed = false,
	additionalDetailsRequired = false,
	handleNext,
	handleBack,
	formData = {},
}) => {
	const [answer, setAnswer] = useState(allowMultiple ? [] : '');
	const [customInput, setCustomInput] = useState('');
	const [additionalDetails, setAdditionalDetails] = useState({});
	const [error, setError] = useState(null);
	const [availableOptions, setAvailableOptions] = useState(options || []);

	useEffect(() => {
		// Initialize selected answers from formData
		if (formData[keyName]) {
			setAnswer(formData[keyName]);
		} else {
			setAnswer(allowMultiple ? [] : '');
		}

		// Initialize additional details from formData
		if (formData[`${keyName}Details`]) {
			setAdditionalDetails({ ...formData[`${keyName}Details`] });
		} else {
			setAdditionalDetails({});
		}

		// Initialize available options with initial options and custom options
		const customOptions = formData[`${keyName}CustomOptions`] || [];
		setAvailableOptions([...options, ...customOptions]);

		setError(null);
	}, [keyName, formData, allowMultiple, options]);

	useEffect(() => {
		setAvailableOptions([
			...options,
			...(formData[`${keyName}CustomOptions`] || []),
		]);
	}, [options, formData, keyName]);

	const validateInput = () => {
		const validationError = validateAnswer(answer, dataType, allowMultiple);
		if (validationError) {
			setError(validationError);
			return false;
		}

		if (additionalDetailsAllowed) {
			const selectedOptions = allowMultiple ? answer : [answer];
			for (let optionValue of selectedOptions) {
				const optionConfig = availableOptions.find(
					(opt) => opt.value === optionValue
				);

				if (optionConfig && optionConfig.additionalDetailsType) {
					// Only validate additional details if required
					if (additionalDetailsRequired) {
						const detailError = validateAdditionalDetails(
							additionalDetails[optionValue],
							optionConfig
						);
						if (detailError) {
							setError(detailError);
							return false;
						}
					}
				}
			}
		}

		setError(null);
		return true;
	};

	const handleNextSubmit = () => {
		if (validateInput()) {
			const updatedData = { [keyName]: answer };

			// Save custom options
			const initialOptions = options || [];
			const customOptions = availableOptions.filter(
				(opt) => !initialOptions.some((initialOpt) => initialOpt.value === opt.value)
			);
			if (customOptions.length > 0) {
				updatedData[`${keyName}CustomOptions`] = customOptions;
			}

			if (additionalDetailsAllowed) {
				if (additionalDetailsRequired || Object.keys(additionalDetails).length > 0) {
					const detailsCopy = {};
					Object.keys(additionalDetails).forEach((option) => {
						if (
							additionalDetails[option] !== '' &&
							additionalDetails[option] !== null &&
							additionalDetails[option] !== undefined
						) {
							detailsCopy[option] = additionalDetails[option];
						}
					});
					if (Object.keys(detailsCopy).length > 0) {
						updatedData[`${keyName}Details`] = detailsCopy;
					}
				} else {
					if (formData[`${keyName}Details`]) {
						updatedData[`${keyName}Details`] = undefined;
					}
				}
			}

			handleNext(updatedData);
		}
	};

	const handleBackSubmit = () => {
		handleBack({ [keyName]: answer });
	};

	const handleCheckboxChange = (event, optionValue) => {
		const checked = event.target.checked;
		if (checked) {
			setAnswer((prev) => [...prev, optionValue]);
		} else {
			setAnswer((prev) => prev.filter((item) => item !== optionValue));

			// Remove additional details if the option is deselected
			setAdditionalDetails((prev) => {
				const updatedDetails = { ...prev };
				delete updatedDetails[optionValue];
				return updatedDetails;
			});
		}
	};

	const handleOptionChange = (event) => {
		const selectedValue = event.target.value;
		setAnswer(selectedValue);

		// Remove additional details for previous selection if not required
		setAdditionalDetails({});
	};

	const handleCustomInput = (e) => setCustomInput(e.target.value);

	const addCustomOption = () => {
		if (customInput.trim()) {
			const newOption = {
				label: customInput.trim(),
				value: customInput.trim(),
				icon: '',
				// If you want custom options to have additional details, define additionalDetailsType
				// additionalDetailsType: 'text', // Example
			};
			setAvailableOptions((prev) => [...prev, newOption]);

			setCustomInput('');
		}
	};

	const handleDetailsChange = (option, details) => {
		setAdditionalDetails((prev) => ({
			...prev,
			[option]: details,
		}));
	};

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Typography
				variant="h4"
				component="h2"
				sx={{
					mb: 2,
					textAlign: 'center',
					color: 'text.primary',
				}}
			>
				{question}
			</Typography>

			{availableOptions.length > 0 && (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
					}}
				>
					{allowMultiple ? (
						availableOptions.map((option, index) => (
							<Box key={index} sx={{ width: '100%' }}>
								<FormControlLabel
									control={
										<Checkbox
											checked={answer.includes(option.value)}
											onChange={(event) => handleCheckboxChange(event, option.value)}
											color="primary"
										/>
									}
									label={
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Typography variant="h6" sx={{ mr: 1 }}>
												{option.icon}
											</Typography>
											<Typography variant="body1">{option.label}</Typography>
										</Box>
									}
								/>

								{additionalDetailsAllowed &&
									answer.includes(option.value) &&
									option.additionalDetailsType && (
										<Box
											sx={{
												mt: 1,
												pl: 4,
											}}
										>
											<Typography
												variant="subtitle1"
												sx={{
													mb: 1,
													color: 'text.secondary',
												}}
											>
												{option.additionalDetailsType === 'number' &&
													'Enter Your Goal Number:'}
												{option.additionalDetailsType === 'currency' &&
													'Enter Amount:'}
												{option.additionalDetailsType === 'text' &&
													'Provide Details:'}
												{additionalDetailsRequired && (
													<Typography
														component="span"
														sx={{
															color: 'error.main',
															ml: 0.5,
														}}
													>
														*
													</Typography>
												)}
											</Typography>
											<TextField
												type={
													option.additionalDetailsType === 'currency'
														? 'text'
														: option.additionalDetailsType === 'number'
														? 'number'
														: 'text'
												}
												placeholder={option.placeholder || 'Enter details'}
												value={additionalDetails[option.value] || ''}
												onChange={(e) =>
													handleDetailsChange(option.value, e.target.value)
												}
												variant="outlined"
												fullWidth
												error={
													additionalDetailsRequired &&
													(!additionalDetails[option.value] ||
														additionalDetails[option.value].trim() === '')
												}
												helperText={
													additionalDetailsRequired &&
													(!additionalDetails[option.value] ||
														additionalDetails[option.value].trim() === '')
														? 'Details are required'
														: ' '
												}
											/>
										</Box>
									)}
							</Box>
						))
					) : (
						<RadioGroup value={answer} onChange={handleOptionChange}>
							{availableOptions.map((option, index) => (
								<Box key={index} sx={{ width: '100%' }}>
									<FormControlLabel
										value={option.value}
										control={<Radio color="primary" />}
										label={
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<Typography variant="h6" sx={{ mr: 1 }}>
													{option.icon}
												</Typography>
												<Typography variant="body1">{option.label}</Typography>
											</Box>
										}
									/>

									{additionalDetailsAllowed &&
										answer === option.value &&
										option.additionalDetailsType && (
											<Box
												sx={{
													mt: 1,
													pl: 4,
												}}
											>
												<Typography
													variant="subtitle1"
													sx={{
														mb: 1,
														color: 'text.secondary',
													}}
												>
													{option.additionalDetailsType === 'number' &&
														'Enter Your Goal Number:'}
													{option.additionalDetailsType === 'currency' &&
														'Enter Amount:'}
													{option.additionalDetailsType === 'text' &&
														'Provide details:'}
													{additionalDetailsRequired && (
														<Typography
															component="span"
															sx={{
																color: 'error.main',
																ml: 0.5,
															}}
														>
															*
														</Typography>
													)}
												</Typography>
												<TextField
													type={
														option.additionalDetailsType === 'currency'
															? 'text'
															: option.additionalDetailsType === 'number'
															? 'number'
															: 'text'
													}
													placeholder={option.placeholder || 'Enter details'}
													value={additionalDetails[option.value] || ''}
													onChange={(e) =>
														handleDetailsChange(option.value, e.target.value)
													}
													variant="outlined"
													fullWidth
													error={
														!!error &&
														additionalDetailsRequired &&
														(!additionalDetails[option.value] ||
															additionalDetails[option.value].trim() === '')
													}
													helperText={
														!!error &&
														additionalDetailsRequired &&
														(!additionalDetails[option.value] ||
															additionalDetails[option.value].trim() === '')
															? error
															: ' '
													}
												/>
											</Box>
										)}
								</Box>
							))}
						</RadioGroup>
					)}
				</Box>
			)}

			{customInputAllowed && (
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						mt: 2,
						gap: 2,
					}}
				>
					<TextField
						type="text"
						value={customInput}
						onChange={handleCustomInput}
						placeholder="Enter custom option"
						variant="outlined"
						fullWidth
					/>
					<Button
						variant="contained"
						color="primary"
						onClick={addCustomOption}
						sx={{
							borderRadius: 2,
							py: 1.5,
						}}
					>
						Add
					</Button>
				</Box>
			)}

			{error && (
				<Typography
					variant="body2"
					sx={{
						color: 'error.main',
						mt: 1,
						width: '100%',
						textAlign: 'left',
					}}
				>
					{error}
				</Typography>
			)}

			<Grid container spacing={2} sx={{ mt: 3, width: '100%' }}>
				<Grid item xs={6}>
					<Button
						variant="contained"
						color="secondary"
						fullWidth
						onClick={handleBackSubmit}
						sx={{
							borderRadius: 2,
							py: 1.5,
						}}
					>
						Back
					</Button>
				</Grid>
				<Grid item xs={6}>
					<Button
						variant="contained"
						color="primary"
						fullWidth
						onClick={handleNextSubmit}
						sx={{
							borderRadius: 2,
							py: 1.5,
						}}
					>
						Next
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

SelectionQuestionComponent.propTypes = {
	question: PropTypes.string.isRequired,
	options: PropTypes.array,
	keyName: PropTypes.string.isRequired,
	dataType: PropTypes.string,
	allowMultiple: PropTypes.bool,
	customInputAllowed: PropTypes.bool,
	additionalDetailsAllowed: PropTypes.bool,
	additionalDetailsRequired: PropTypes.bool,
	handleNext: PropTypes.func.isRequired,
	handleBack: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
};

export default SelectionQuestionComponent;
