// src/components/homePage/Notifications.jsx
import React from 'react';
import {
	Card,
	CardContent,
	Typography,
	List,
	ListItem,
	ListItemText,
	Divider,
	useTheme,
} from '@mui/material';

const Notifications = ({ notifications }) => {
	const theme = useTheme();

	if (!notifications || notifications.length === 0) {
		return null;
	}

	return (
		<Card
			sx={{
				backgroundColor: theme.palette.background.paper,
				boxShadow: theme.shadows[2],
				height: '100%',
				':hover': {
					transform: 'none',
				},
			}}
		>
			<CardContent>
				<Typography variant="h4" sx={{ ml: 1 }} gutterBottom>
					Latest Updates
				</Typography>
				<List>
					{notifications.map((note, index) => (
						<React.Fragment key={note.id}>
							<ListItem alignItems="flex-start">
								<ListItemText
									primary={
										<Typography variant="h5" color="primary">
											{note.title}
										</Typography>
									}
									secondary={
										<Typography variant="body2" color="textSecondary">
											{note.message}
										</Typography>
									}
								/>
							</ListItem>
							{index < notifications.length - 1 && <Divider component="li" />}
						</React.Fragment>
					))}
				</List>
			</CardContent>
		</Card>
	);
};

export default Notifications;
