import axios from 'axios';

// Fetch list of artists
export const fetchArtists = async () => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return [];
		}

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/artists/get`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);

		if (!response.ok) {
			throw new Error('Failed to fetch artists');
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Error fetching artists:', error);
		return [];
	}
};

// Fetch detailed artist data by ID
export const fetchArtistData = async (artistId) => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return null;
		}

		const response = await fetch(
			`${process.env.REACT_APP_API_URL}/api/artists/${artistId}`,
			{
				method: 'GET',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);

		if (!response.ok) {
			throw new Error('Failed to fetch artist data');
		}

		const data = await response.json();
		return data;
	} catch (error) {
		console.error('Error fetching artist data:', error);
		return null;
	}
};

export const fetchAudienceData = async (artistId) => {
	try {
		const accessToken =
			sessionStorage.getItem('accessToken') || localStorage.getItem('accessToken');

		if (!accessToken) {
			console.error('Failed to get access token');
			return [];
		}
		const response = await axios.get(
			`${process.env.REACT_APP_API_URL}/api/artists/${artistId}/audience-data`,
			{
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error(
			'Error fetching audience data:',
			error.response?.data || error.message
		);
		throw new Error('Failed to fetch audience data.');
	}
};
