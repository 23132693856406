// src/components/charts/GenderBreakdownChart.jsx
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import ChartWrapper from './ChartWrapper';
import { chunk } from 'lodash';

const GenderBreakdownChart = ({ data, onPerPlatformToggle }) => {
	const theme = useTheme();
	const [view, setView] = useState('overall');
	const [selectedPlatform, setSelectedPlatform] = useState('Instagram');

	const platforms = Object.keys(data.perPlatform);

	const isXs = useMediaQuery(theme.breakpoints.only('xs'));
	const isSm = useMediaQuery(theme.breakpoints.only('sm'));
	const isMd = useMediaQuery(theme.breakpoints.only('md'));
	const isLg = useMediaQuery(theme.breakpoints.only('lg'));
	const isXl = useMediaQuery(theme.breakpoints.only('xl'));

	// Set chunk size based on screen width
	const chunkSize = isXs ? 3 : isSm ? 3 : isMd ? 6 : isLg ? 3 : isXl ? 6 : 6;

	useEffect(() => {
		if (view === 'perPlatform') {
			const numberOfPlatforms = platforms.length;
			const rows = Math.ceil(numberOfPlatforms / chunkSize);
			onPerPlatformToggle(rows);
		} else {
			onPerPlatformToggle(0);
		}
	}, [view, platforms, isXs, isSm, isMd, isLg, isXl, onPerPlatformToggle]);

	const handleViewChange = (event, newView) => {
		if (newView !== null) {
			setView(newView);
		}
	};

	const handlePlatformChange = (event, newPlatform) => {
		if (newPlatform !== null) {
			setSelectedPlatform(newPlatform);
		}
	};

	const prepareChartData = () => {
		if (view === 'overall') {
			const labels = data.overall.map((item) => item.gender);
			const percentages = data.overall.map((item) => item.percentage);
			return {
				labels,
				datasets: [
					{
						data: percentages,
						backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
						hoverBackgroundColor: [
							'#FF6384CC',
							'#36A2EBCC',
							'#FFCE56CC',
							'#4BC0C0CC',
							'#9966FFCC',
						],
					},
				],
			};
		} else if (view === 'perPlatform') {
			const platformData = data.perPlatform[selectedPlatform];
			const labels = platformData.map((item) => item.gender);
			const percentages = platformData.map((item) => item.percentage);
			return {
				labels,
				datasets: [
					{
						data: percentages,
						backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF'],
						hoverBackgroundColor: [
							'#FF6384CC',
							'#36A2EBCC',
							'#FFCE56CC',
							'#4BC0C0CC',
							'#9966FFCC',
						],
					},
				],
			};
		}
	};

	const chartData = prepareChartData();

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					color: theme.palette.text.primary,
				},
			},
			tooltip: {
				callbacks: {
					label: (context) => `${context.label}: ${context.parsed}%`,
				},
			},
		},
	};

	return (
		<ChartWrapper title="Gender Breakdown">
			<Box display="flex" justifyContent="center" mb={2}>
				<ToggleButtonGroup
					value={view}
					exclusive
					onChange={handleViewChange}
					aria-label="view"
				>
					<ToggleButton value="overall" aria-label="overall">
						Overall
					</ToggleButton>
					<ToggleButton value="perPlatform" aria-label="perPlatform">
						Per Platform
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			{view === 'perPlatform' && (
				<Box display="flex" flexDirection="column" alignItems="center" mb={2}>
					{chunk(platforms, chunkSize).map((platformGroup, index) => (
						<Box key={index} display="flex" justifyContent="center" mb={1}>
							<ToggleButtonGroup
								value={selectedPlatform}
								exclusive
								onChange={handlePlatformChange}
								aria-label={`platform-group-${index}`}
							>
								{platformGroup.map((platform) => (
									<ToggleButton
										key={platform}
										value={platform}
										aria-label={platform}
									>
										{platform}
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Box>
					))}
				</Box>
			)}
			<Box style={{ height: 300 }}>
				<Doughnut data={chartData} options={options} />
			</Box>
		</ChartWrapper>
	);
};

GenderBreakdownChart.propTypes = {
	data: PropTypes.shape({
		overall: PropTypes.arrayOf(
			PropTypes.shape({
				gender: PropTypes.string.isRequired,
				percentage: PropTypes.number.isRequired,
			})
		).isRequired,
		perPlatform: PropTypes.objectOf(
			PropTypes.arrayOf(
				PropTypes.shape({
					gender: PropTypes.string.isRequired,
					percentage: PropTypes.number.isRequired,
				})
			)
		).isRequired,
	}).isRequired,
};

export default GenderBreakdownChart;
