import React, { useState } from 'react';
import { Button, Dialog, Typography, Grid, Chip, Box } from '@mui/material';
import LongTermGoals from '../dialogs/step2/LongTermGoals';
import ShortTermGoals from '../dialogs/step2/ShortTermGoals';
import KeyMilestones from '../dialogs/step2/KeyMilestones';
import CareerIdols from '../dialogs/step2/CareerIdols';
import CareerPath from '../dialogs/step2/CareerPath';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TimelineIcon from '@mui/icons-material/Timeline';
import FlagIcon from '@mui/icons-material/Flag';
import { useTheme } from '@mui/material/styles';

const ArtistOnboardingStep2 = ({ artistData, updateArtistData, onNext, onBack }) => {
	const theme = useTheme();
	const [openCareerIdols, setOpenCareerIdols] = useState(false);
	const [openCareerPath, setOpenCareerPath] = useState(false);
	const [openLongTerm, setOpenLongTerm] = useState(false);
	const [openShortTerm, setOpenShortTerm] = useState(false);
	const [openMilestones, setOpenMilestones] = useState(false);

	const [careerIdols, setCareerIdols] = useState(
		artistData?.careerIdols || { idol1: {}, idol2: {}, idol3: {} }
	);
	const [careerPath, setCareerPath] = useState(artistData?.careerPath || {});
	const [longTermGoals, setLongTermGoals] = useState(
		artistData?.careerObjectives?.longTerm || {}
	);
	const [shortTermGoals, setShortTermGoals] = useState(
		artistData?.careerObjectives?.shortTerm || {}
	);
	const [milestones, setMilestones] = useState(
		artistData?.careerObjectives?.milestones || []
	);

	const isCareerIdolsComplete = () => {
		return Object.values(careerIdols).some((idol) => Object.keys(idol).length > 3);
	};

	const isCareerPathComplete = () => {
		return careerPath && careerPath.pathName && careerPath.pathName.trim() !== '';
	};

	// Validation for Long Term Goals
	const isLongTermGoalsComplete = () => {
		return (
			longTermGoals?.longTermGoal1Category &&
			longTermGoals?.longTermGoal1Description &&
			longTermGoals?.longTermGoal2Category &&
			longTermGoals?.longTermGoal2Description &&
			longTermGoals?.longTermGoal3Category &&
			longTermGoals?.longTermGoal3Description
		);
	};

	// Validation for Short Term Goals
	const isShortTermGoalsComplete = () => {
		return (
			shortTermGoals?.shortTermGoal1 &&
			shortTermGoals?.shortTermGoal2 &&
			shortTermGoals?.shortTermGoal3 &&
			shortTermGoals?.shortTermSecondaryGoal1 &&
			shortTermGoals?.shortTermSecondaryGoal2 &&
			shortTermGoals?.shortTermSecondaryGoal3 &&
			//shortTermGoals?.shortTermValue1 &&
			//shortTermGoals?.shortTermValue2 &&
			//shortTermGoals?.shortTermValue3 &&
			shortTermGoals?.shortTermDetail1 &&
			shortTermGoals?.shortTermDetail2 &&
			shortTermGoals?.shortTermDetail3
		);
	};

	// Validation for Milestones
	const isMilestonesComplete = () => {
		return (
			milestones?.milestone1Category &&
			milestones?.milestone2Category &&
			milestones?.milestone3Category &&
			milestones?.milestone1Description &&
			milestones?.milestone2Description &&
			milestones?.milestone3Description &&
			milestones?.milestone1Secondary &&
			milestones?.milestone2Secondary &&
			milestones?.milestone3Secondary &&
			//milestones?.milestone1Value &&
			//milestones?.milestone2Value &&
			//milestones?.milestone3Value &&
			milestones?.milestone1Date &&
			milestones?.milestone2Date &&
			milestones?.milestone3Date
		);
	};

	// Combined Career Objectives Validation
	const isCareerObjectivesComplete = () => {
		return (
			isLongTermGoalsComplete() &&
			isShortTermGoalsComplete() &&
			isMilestonesComplete()
		);
	};

	const isStepComplete = () => {
		return (
			isCareerIdolsComplete() &&
			isCareerPathComplete() &&
			isCareerObjectivesComplete()
		);
	};

	const handleSave = (section, updatedData) => {
		if (section === 'careerIdols') {
			setCareerIdols(updatedData);
		} else if (section === 'careerPath') {
			setCareerPath(updatedData);
		} else if (section === 'longTerm') {
			setLongTermGoals(updatedData);
		} else if (section === 'shortTerm') {
			setShortTermGoals(updatedData);
		} else if (section === 'milestones') {
			setMilestones(updatedData);
		}
		updateArtistData({
			[section]: updatedData,
			careerObjectives: {
				...artistData.careerObjectives,
				...(section === 'longTerm' ? { longTerm: updatedData } : {}),
				...(section === 'shortTerm' ? { shortTerm: updatedData } : {}),
				...(section === 'milestones' ? { milestones: updatedData } : {}),
			},
		});
	};

	const StatusBadge = ({ label, isComplete }) => (
		<Chip
			icon={
				isComplete ? (
					<CheckCircleIcon style={{ color: 'green' }} />
				) : (
					<RadioButtonUncheckedIcon />
				)
			}
			label={label}
			variant="outlined"
			color={isComplete ? 'success' : 'default'}
		/>
	);

	return (
		<Box
			sx={{
				backgroundColor: '#ffffff',
				padding: '2rem',
				borderRadius: '8px',
				boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				maxWidth: '650px',
				margin: '0 auto',
				textAlign: 'center',
			}}
		>
			<Typography
				variant="h2"
				sx={{
					...theme.typography.h3,
					marginBottom: '1.5rem',
					textAlign: 'center',
				}}
			>
				Career Goals & Milestones
			</Typography>

			<Box sx={{ mt: 2 }}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h6" sx={{ ...theme.typography.h4 }}>
							Career Idols
						</Typography>
						<Typography variant="body1" sx={{ ...theme.typography.body1, mb: 1 }}>
							Choose musicians you would like to have a similar career to.
						</Typography>
						<Button
							variant="contained"
							onClick={() => setOpenCareerIdols(true)}
							sx={{ mb: 2 }}
							startIcon={<FavoriteIcon />}
						>
							Search & Select
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h6" sx={{ ...theme.typography.h4 }}>
							Career Path
						</Typography>
						<Typography variant="body1" sx={{ ...theme.typography.body1, mb: 1 }}>
							Choose the career path you want to follow.
						</Typography>
						<Button
							variant="contained"
							onClick={() => setOpenCareerPath(true)}
							sx={{ mb: 2 }}
							startIcon={<TimelineIcon />}
						>
							Select Path
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Typography variant="h4">Career Objectives</Typography>
						<Typography variant="body1" sx={{ ...theme.typography.body1, mb: 1 }}>
							Set the key objectives you are looking to achieve.
						</Typography>
						<Button
							variant="contained"
							onClick={() => setOpenLongTerm(true)}
							sx={{ mb: 2 }}
							startIcon={<FlagIcon />}
						>
							Create Objectives
						</Button>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ mt: 4 }}>
				<Typography variant="h6" sx={{ ...theme.typography.h4 }}>
					Step Completion Status
				</Typography>
				<Grid container spacing={2} sx={{ mt: 1 }}>
					<Grid item xs={12} md={4}>
						<StatusBadge label="Career Idols" isComplete={isCareerIdolsComplete()} />
					</Grid>
					<Grid item xs={12} md={4}>
						<StatusBadge label="Career Path" isComplete={isCareerPathComplete()} />
					</Grid>
					<Grid item xs={12} md={4}>
						<StatusBadge
							label="Career Objectives"
							isComplete={isCareerObjectivesComplete()}
						/>
					</Grid>
				</Grid>
			</Box>

			<Box sx={{ mt: 4 }} textAlign="center">
				<Button
					variant="contained"
					color="secondary"
					onClick={onBack}
					sx={{ mr: 2 }}
				>
					Back
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={onNext}
					disabled={!isStepComplete()}
				>
					Next
				</Button>
			</Box>

			{/* Dialogs for each section */}
			<Dialog
				open={openCareerIdols}
				onClose={() => setOpenCareerIdols(false)}
				maxWidth="md"
				fullWidth
			>
				<CareerIdols
					artistData={careerIdols}
					artistName={artistData?.artist?.stageName}
					onSave={(updatedData) => {
						handleSave('careerIdols', updatedData);
						setOpenCareerIdols(false);
					}}
				/>
			</Dialog>

			<Dialog
				open={openCareerPath}
				onClose={() => setOpenCareerPath(false)}
				maxWidth="md"
				fullWidth
			>
				<CareerPath
					artistData={careerPath}
					onSave={(updatedData) => {
						handleSave('careerPath', updatedData);
						setOpenCareerPath(false);
					}}
				/>
			</Dialog>

			<Dialog
				open={openLongTerm}
				onClose={() => setOpenLongTerm(false)}
				maxWidth="md"
				fullWidth
			>
				<LongTermGoals
					artistData={longTermGoals}
					onSave={(updatedData) => {
						handleSave('longTerm', updatedData);
						setOpenLongTerm(false);
						setOpenShortTerm(true);
					}}
					onPopupNext={() => {
						setOpenLongTerm(false);
						setOpenShortTerm(true);
					}}
					onPopupBack={() => {
						setOpenLongTerm(false);
						setOpenCareerPath(true);
					}}
				/>
			</Dialog>

			<Dialog
				open={openShortTerm}
				onClose={() => setOpenShortTerm(false)}
				maxWidth="md"
				fullWidth
			>
				<ShortTermGoals
					artistData={shortTermGoals}
					onSave={(updatedData) => {
						handleSave('shortTerm', updatedData);
						setOpenShortTerm(false);
						setOpenMilestones(true);
					}}
					onPopupNext={() => {
						setOpenShortTerm(false);
						setOpenMilestones(true);
					}}
					onPopupBack={() => {
						setOpenShortTerm(false);
						setOpenLongTerm(true);
					}}
				/>
			</Dialog>

			<Dialog
				open={openMilestones}
				onClose={() => setOpenMilestones(false)}
				maxWidth="md"
				fullWidth
			>
				<KeyMilestones
					artistData={milestones}
					onSave={(updatedData) => {
						handleSave('milestones', updatedData);
						setOpenMilestones(false);
					}}
					onPopupBack={() => {
						setOpenMilestones(false);
						setOpenShortTerm(true);
					}}
				/>
			</Dialog>
		</Box>
	);
};

export default ArtistOnboardingStep2;
