import React, { useContext } from 'react';
import {
	Dialog,
	DialogContent,
	Card,
	CardActionArea,
	CardMedia,
	CardContent,
	Typography,
	DialogActions,
	Button,
	Box,
	useTheme,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { styled } from '@mui/system';
import ArtistContext from '../../context/ArtistContext';

// Styled Components
const StyledDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiPaper-root': {
		borderRadius: theme.shape.borderRadius,
		backgroundColor: theme.palette.background.paper,
	},
}));

const MediaContainer = styled(Box)(({ theme }) => ({
	height: '300px',
	width: '300px',
	position: 'relative',
	backgroundColor: theme.palette.grey[300], // Background color for placeholder
	borderTopLeftRadius: theme.shape.borderRadius,
	borderTopRightRadius: theme.shape.borderRadius,
	overflow: 'hidden',
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
	height: '100%',
	width: '100%',
	objectFit: 'cover',
}));

const PlaceholderBox = styled(Box)(({ theme }) => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	borderRadius: theme.shape.borderRadius,
	padding: theme.spacing(1, 3),
	textTransform: 'none',
}));

const ArtistModal = ({ onClose }) => {
	const theme = useTheme();
	const { artists, handleArtistSelect } = useContext(ArtistContext);

	return (
		<StyledDialog open={true} onClose={onClose} fullWidth maxWidth="md">
			<Card
				sx={{
					p: 2,
					':hover': {
						transform: 'none',
					},
				}}
			>
				<Typography variant="h4" sx={{ mt: 4, textAlign: 'center' }}>
					Select One of Your Artists
				</Typography>
				<DialogContent>
					<Box
						display="flex"
						flexWrap="wrap"
						justifyContent="center"
						gap={2}
						overflowY={'auto'}
					>
						{artists?.map((artist) => (
							<Box key={artist.id}>
								<Card
									sx={{
										maxWidth: 350,
										borderRadius: 2,
										boxShadow: theme.shadows[5],
										backgroundColor: 'background.paper',
										position: 'relative',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<CardActionArea onClick={() => handleArtistSelect(artist)}>
										<MediaContainer>
											{artist.profilePhoto ? (
												<StyledCardMedia
													component="img"
													image={artist.profilePhoto}
													alt={artist.name || 'New Artist'}
												/>
											) : (
												<PlaceholderBox>
													<PersonIcon
														sx={{
															fontSize: 120,
															maxHeight: '80%',
															maxWidth: '80%',
															color: 'grey.600',
														}}
													/>
												</PlaceholderBox>
											)}
										</MediaContainer>
										<CardContent
											sx={{
												textAlign: 'center',
												flexGrow: 1,
												padding: theme.spacing(2),
											}}
										>
											<Typography
												gutterBottom
												variant="h5"
												component="div"
												color="text.primary"
												noWrap
											>
												{artist.name || 'New Artist'}
											</Typography>
										</CardContent>
									</CardActionArea>
								</Card>
							</Box>
						))}
					</Box>
				</DialogContent>
				<DialogActions
					sx={{ justifyContent: 'center', padding: (theme) => theme.spacing(2) }}
				>
					<StyledButton onClick={onClose} color="primary" variant="contained">
						Close
					</StyledButton>
				</DialogActions>
			</Card>
		</StyledDialog>
	);
};

export default ArtistModal;
