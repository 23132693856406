// src/components/userStart/Login.js
import React, { useState, useContext } from 'react';
import {
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	Typography,
	Box,
	Card,
	Grid,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { storeUserSession } from '../../utils/apis/auth/sessionUtils';
import MAVALogo from '../../assets/MAVALogo.png';
import SessionContext from '../../context/SessionContext';

const Login = ({ onLogin }) => {
	const { setSession } = useContext(SessionContext);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(true);
	const [errorMessage, setErrorMessage] = useState('');
	const [resendSuccess, setResendSuccess] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/api/auth/login`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ email, password, rememberMe }),
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				if (errorData.message === 'Please verify your email to log in.') {
					setErrorMessage('Please verify your email to log in.');
				} else {
					setErrorMessage('Invalid email or password.');
				}
				throw new Error(errorData.message || 'Login failed');
			}

			const data = await response.json();
			storeUserSession(
				data.accessToken,
				data.email,
				data.firstName,
				data.lastName,
				data.companyName,
				data.isFirstLogin,
				data.companyId,
				data.userId,
				rememberMe
			);
			// Use onLogin to update session state
			onLogin(data.isFirstLogin, data.companyId); // Pass companyId
			navigate(data.isFirstLogin ? '/onboarding' : '/home', { replace: true });
		} catch (error) {
			console.error('Error logging in:', error);
		}
	};

	const handleResendVerification = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/api/auth/resend-verification`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ email }),
				}
			);

			if (response.ok) {
				setResendSuccess(
					'Verification email has been resent. Please check your inbox.'
				);
			} else {
				const data = await response.json();
				setErrorMessage(data.message || 'Error resending verification email.');
			}
		} catch (error) {
			setErrorMessage('Error resending verification email.');
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: 'background.login',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 2,
			}}
		>
			<Card
				className="fade-in"
				sx={{
					padding: 4,
					borderRadius: 3,
					width: '100%',
					maxWidth: 450,
					boxShadow: 3,
					textAlign: 'center',
					backgroundColor: 'background.paper',
					'&:hover': {
						transform: 'none',
					},
				}}
			>
				<Grid container direction="column" spacing={1} sx={{ textAlign: 'center' }}>
					<Grid item>
						<img src={MAVALogo} alt="MAVA Logo" style={{ maxWidth: 225 }} />
					</Grid>
					<Grid item>
						<Typography variant="h3" gutterBottom>
							Log in to Get Started
						</Typography>
					</Grid>
					<Grid item container spacing={2}>
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								disabled
								sx={{ textTransform: 'none', borderRadius: 2 }}
							>
								Continue with Google
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								disabled
								sx={{ textTransform: 'none', borderRadius: 2 }}
							>
								Continue with Microsoft
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button
								variant="contained"
								color="secondary"
								fullWidth
								disabled
								sx={{ textTransform: 'none', borderRadius: 2 }}
							>
								Continue with Apple
							</Button>
						</Grid>
					</Grid>
					<Grid item>
						<Typography variant="subtitle1" color="text.secondary">
							— OR —
						</Typography>
					</Grid>
					<Grid item>
						<form onSubmit={handleSubmit}>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<TextField
										label="Email or Username"
										variant="outlined"
										fullWidth
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										required
									/>
								</Grid>
								<Grid item>
									<TextField
										label="Password"
										type="password"
										variant="outlined"
										fullWidth
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										control={
											<Checkbox
												checked={rememberMe}
												onChange={() => setRememberMe(!rememberMe)}
												color="primary"
											/>
										}
										label="Remember Me"
									/>
								</Grid>
								<Grid item>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
										sx={{ textTransform: 'none', borderRadius: 2 }}
									>
										Log In
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
					{errorMessage && !resendSuccess && (
						<Grid item>
							<Typography color="error" variant="subtitle2">
								{errorMessage}
								{errorMessage === 'Please verify your email to log in.' && (
									<Box
										mt={2}
										mb={2}
										sx={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Button
											variant="outlined"
											onClick={handleResendVerification}
											sx={{
												color: 'primary.main',
												textDecoration: 'underline',
												textTransform: 'none',
											}}
										>
											Resend Verification Email
										</Button>
									</Box>
								)}
							</Typography>
						</Grid>
					)}
					{resendSuccess && (
						<Grid item>
							<Typography color="success.main" variant="caption">
								{resendSuccess}
							</Typography>
						</Grid>
					)}
					<Grid item>
						<Typography
							variant="body2"
							sx={{ textAlign: 'center', color: 'text.secondary' }}
						>
							<a
								href="/forgot-password"
								style={{ color: '#28c0fd', textDecoration: 'none' }}
							>
								Forgot your Password?
							</a>
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							variant="body2"
							sx={{ textAlign: 'center', color: 'text.secondary' }}
						>
							Don't have an account?{' '}
							<a href="/signup" style={{ color: '#A763FF', textDecoration: 'none' }}>
								Sign up for MAVA
							</a>
						</Typography>
					</Grid>
				</Grid>
			</Card>
		</Box>
	);
};

export default Login;
