// src/components/charts/GrowthTrendsChart.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ChartWrapper from './ChartWrapper';

const GrowthTrendsChart = ({ data, totalExtraButtonRows }) => {
	const theme = useTheme();
	const [metric, setMetric] = useState('followers');

	// Calculate dynamic height based on totalExtraButtonRows
	let dynamicHeight = 300 + totalExtraButtonRows * 50;

	const handleMetricChange = (event, newMetric) => {
		if (newMetric !== null) {
			setMetric(newMetric);
		}
	};

	const prepareChartData = () => {
		const labels = data.followersOverTime.map((item) => item.week);
		let dataset = {};

		if (metric === 'followers') {
			const followersData = data.followersOverTime.map((item) => item.followers);
			dataset = {
				label: 'Followers',
				data: followersData,
				borderColor: theme.palette.primary.main,
				backgroundColor: theme.palette.primary.light,
				fill: false,
				tension: 0.4,
			};
		} else if (metric === 'listeners') {
			const listenersData = data.listenersOverTime.map((item) => item.listeners);
			dataset = {
				label: 'Listeners',
				data: listenersData,
				borderColor: theme.palette.secondary.main,
				backgroundColor: theme.palette.secondary.light,
				fill: false,
				tension: 0.4,
			};
		}

		return {
			labels,
			datasets: [dataset],
		};
	};

	const chartData = prepareChartData();

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: true,
				labels: {
					color: theme.palette.text.primary,
				},
			},
			tooltip: {
				callbacks: {
					label: (context) => `${context.parsed.y.toLocaleString()}`,
				},
			},
		},
		scales: {
			x: {
				ticks: { color: theme.palette.text.primary },
				grid: { color: theme.palette.divider },
			},
			y: {
				ticks: { color: theme.palette.text.primary },
				grid: { color: theme.palette.divider },
			},
		},
	};

	return (
		<ChartWrapper title="Growth Trends">
			<Box display="flex" justifyContent="center" mb={2}>
				<ToggleButtonGroup
					value={metric}
					exclusive
					onChange={handleMetricChange}
					aria-label="metric"
				>
					<ToggleButton value="followers" aria-label="followers">
						Followers
					</ToggleButton>
					<ToggleButton value="listeners" aria-label="listeners">
						Listeners
					</ToggleButton>
				</ToggleButtonGroup>
			</Box>
			<Box style={{ height: 300 }}>
				<Line data={chartData} options={options} />
			</Box>
		</ChartWrapper>
	);
};

GrowthTrendsChart.propTypes = {
	data: PropTypes.shape({
		followersOverTime: PropTypes.arrayOf(
			PropTypes.shape({
				month: PropTypes.string.isRequired,
				followers: PropTypes.number.isRequired,
			})
		).isRequired,
		listenersOverTime: PropTypes.arrayOf(
			PropTypes.shape({
				month: PropTypes.string.isRequired,
				listeners: PropTypes.number.isRequired,
			})
		).isRequired,
	}).isRequired,
	totalExtraButtonRows: PropTypes.number.isRequired,
};

export default GrowthTrendsChart;
