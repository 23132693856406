// src/components/marketingAssistant/QuestionFlow.js

import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import QuestionComponent from './QuestionComponent'; // Generic component
import ProgressBar from '../../../common/ProgressBar';
import { campaignQuestionsConfig } from '../../../../utils/configs/campaignQuestionsConfig';

const QuestionFlow = ({
	step,
	artistData,
	formData,
	handleNext,
	handleBack,
	generatePrompt,
}) => {
	const currentGoal = formData.marketing_goal;
	const questionFlow = campaignQuestionsConfig[currentGoal] || [];
	const questionData = questionFlow[step - 1];

	// Check if the last question has been reached
	if (step > questionFlow.length) {
		generatePrompt(formData, artistData);
		return null;
	}

	return (
		<Box
			sx={{
				width: '100%',
				maxWidth: 600,
				margin: '0 auto',
				padding: (theme) => theme.spacing(4),
				backgroundColor: 'background.paper',
				borderRadius: 2,
				boxShadow: 3,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<ProgressBar step={step} totalSteps={questionFlow.length} />
			<QuestionComponent
				questionData={questionData}
				handleNext={handleNext}
				handleBack={handleBack}
				formData={formData}
			/>
		</Box>
	);
};

QuestionFlow.propTypes = {
	step: PropTypes.number.isRequired,
	artistData: PropTypes.object.isRequired,
	formData: PropTypes.object.isRequired,
	handleNext: PropTypes.func.isRequired,
	handleBack: PropTypes.func.isRequired,
	generatePrompt: PropTypes.func.isRequired,
};

export default QuestionFlow;
