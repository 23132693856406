// ArtistOnboardingStep5.js

import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, Box } from '@mui/material';
import ArtistProfileRecap from '../subcomponents/ArtistProfileRecap';
import CareerProfileRecap from '../subcomponents/CareerProfileRecap';
import CareerAssessmentRecap from '../subcomponents/CareerAssessmentRecap';
import { useTheme } from '@mui/material/styles';

const ArtistOnboardingStep5 = ({ artistData, onBack, onFinish }) => {
	const theme = useTheme();
	const [totalFollowers, setTotalFollowers] = useState(0);
	const [totalMonthlyListeners, setTotalMonthlyListeners] = useState(0);

	useEffect(() => {
		if (artistData) {
			// Calculate the total social following
			const spotifyFollowers =
				parseInt(artistData.spotify?.selectedProfile?.followers, 10) || 0;
			const youtubeSubscribers =
				parseInt(artistData.youtube?.selectedProfile?.subscribers, 10) || 0;
			const instagramFollowers =
				parseInt(artistData.instagram?.selectedProfile?.followers, 10) || 0;
			const tiktokFollowers =
				parseInt(artistData.tiktok?.selectedProfile?.followers, 10) || 0;

			// Calculate the total followers and format with commas
			const totalFollowers =
				spotifyFollowers + youtubeSubscribers + instagramFollowers + tiktokFollowers;
			setTotalFollowers(totalFollowers);

			// Calculate the average monthly listeners
			const spotifyListeners =
				parseInt(artistData.spotify?.analyticsData?.listeners, 10) || 0;
			const appleMusicAvgDailyListeners =
				parseInt(artistData.appleMusic?.analyticsData?.averageDailyListeners, 10) ||
				0;
			const youtubeUniqueViewers =
				parseInt(artistData.youtube?.analyticsData?.audience?.uniqueViewers, 10) ||
				0;

			// Convert Apple Music average daily listeners to monthly listeners (assuming 30 days in a month)
			const appleMusicMonthlyListeners = appleMusicAvgDailyListeners * 30;

			// Calculate the total monthly listeners and format with commas
			const totalListeners =
				spotifyListeners + appleMusicMonthlyListeners + youtubeUniqueViewers;
			setTotalMonthlyListeners(totalListeners);
		}
	}, [artistData]);

	// Prepare data for Recent Activities
	const recentActivities = [1, 2, 3, 4].map((index) => ({
		category: artistData.recentActivities[`activity${index}Category`] || '',
	}));

	return (
		<Box
			sx={{
				backgroundColor: '#ffffff',
				padding: '2rem',
				borderRadius: '8px',
				boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
				maxWidth: '1200px',
				margin: '0 auto',
			}}
		>
			{/* Summary Header */}
			<Box sx={{ textAlign: 'center', mb: 3 }}>
				<Typography variant="h6" sx={{ ...theme.typography.h3 }}>
					Summary of Your Data
				</Typography>
				<Typography variant="body1" sx={{ ...theme.typography.body1 }}>
					This is an overview of the data provided for your artist.
				</Typography>
			</Box>

			{/* Review Sections */}
			<Grid container spacing={4} sx={{ mb: 3 }}>
				<Grid item xs={12} md={6}>
					<ArtistProfileRecap
						stageName={artistData?.artist?.stageName}
						genre={artistData?.artist?.genre}
						location={artistData?.artist?.location}
						yearsActive={artistData?.artist?.yearsActive}
						bio={artistData?.artist?.bio}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<CareerProfileRecap
						careerStageLabel={artistData?.careerStage?.label}
						careerPath={artistData?.careerPath?.pathName}
						currentMonthlyListeners={totalMonthlyListeners}
						currentTotalFollowers={totalFollowers}
					/>
				</Grid>

				<Grid item xs={12} md={12}>
					<CareerAssessmentRecap
						careerObjectives={artistData?.careerObjectives}
						immediateNeeds={artistData?.immediateNeeds?.selectedNeeds}
						recentActivities={recentActivities}
					/>
				</Grid>
			</Grid>

			{/* Navigation Buttons */}
			<Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 2 }}>
				<Button
					variant="contained"
					color="secondary"
					onClick={onBack}
					sx={{ minWidth: '120px' }}
				>
					Back
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={onFinish}
					sx={{ minWidth: '160px' }}
				>
					Complete Onboarding
				</Button>
			</Box>
		</Box>
	);
};

export default ArtistOnboardingStep5;
