// src/components/homePage/WelcomeMessage.jsx
import React from 'react';
import { Typography, Box } from '@mui/material';

const MarketingHeader = ({ artistName }) => {
	return (
		<Box
			sx={{
				textAlign: 'center',
			}}
		>
			<Typography variant="h2" component="h1" gutterBottom>
				Let's work on marketing strategies for {artistName}!
			</Typography>
			<Typography variant="subtitle1" color="textSecondary">
				Start a new campaign or resume an existing one.
			</Typography>
		</Box>
	);
};

export default MarketingHeader;
