import React, { useState } from 'react';
import { TextField, Button, Typography, Box, Card, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ email }),
				}
			);

			const data = await response.json();

			if (response.ok) {
				setMessage('Password reset link sent to your email.');
			} else {
				setMessage(data.message || 'Error sending reset link');
			}
		} catch (error) {
			setMessage('Error sending reset link');
		}
	};

	return (
		<Box
			sx={{
				backgroundColor: 'background.login',
				height: '100vh',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 2,
			}}
		>
			<Card
				className="fade-in"
				sx={{
					padding: 4,
					borderRadius: 3,
					width: '100%',
					maxWidth: 400,
					boxShadow: 3,
					textAlign: 'center',
					backgroundColor: 'background.paper',
					':hover': {
						transform: 'none',
					},
				}}
			>
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<Typography variant="h4" gutterBottom>
							Forgot Your Password?
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="body1" sx={{ mb: 2 }}>
							Enter your email address below, and we'll send you a link to reset your
							password.
						</Typography>
					</Grid>
					<Grid item>
						<form onSubmit={handleSubmit}>
							<Grid container direction="column" spacing={2}>
								<Grid item>
									<TextField
										label="Email"
										type="email"
										variant="outlined"
										fullWidth
										value={email}
										onChange={(e) => setEmail(e.target.value)}
										required
									/>
								</Grid>
								<Grid item>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										fullWidth
										sx={{ textTransform: 'none', borderRadius: 2 }}
									>
										Send Reset Link
									</Button>
								</Grid>
							</Grid>
						</form>
					</Grid>
					{message && (
						<Grid item>
							<Typography
								color={message.includes('Error') ? 'error' : 'success.main'}
								variant="caption"
							>
								{message}
							</Typography>
						</Grid>
					)}
					<Grid item>
						<Button
							onClick={() => navigate('/login')}
							variant="outlined"
							color="primary"
							fullWidth
							sx={{ textTransform: 'none', borderRadius: 2 }}
						>
							Back to Login
						</Button>
					</Grid>
				</Grid>
			</Card>
		</Box>
	);
};

export default ForgotPassword;
