// src/themes/components.js
const components = {
	MuiCard: {
		styleOverrides: {
			root: ({ theme }) => ({
				backgroundColor:
					theme.palette.mode === 'dark' ? theme.palette.background.paper : '#ffffff',
				border: `1px solid ${theme.palette.divider}`,
				borderRadius: 6,
				boxShadow: theme.shadows[1],
				transition: 'transform 0.2s, box-shadow 0.2s',
				cursor: 'pointer',
				'&:hover': {
					transform: 'translateY(-5px)',
					boxShadow: theme.shadows[4],
				},
			}),
		},
	},
	MuiButton: {
		styleOverrides: {
			root: ({ theme }) => ({
				borderRadius: '20px', // Rounded corners for friendliness
				padding: theme.spacing(1, 2),
				textTransform: 'none',
				fontWeight: 600,
				fontSize: '0.875rem',
				letterSpacing: '0.02857em',
				transition: 'background-color 0.3s, box-shadow 0.2s',
			}),
			containedPrimary: ({ theme }) => ({
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: theme.palette.primary.dark,
					boxShadow: 'none',
				},
				'&:disabled': {
					color: theme.palette.action.disabled,
				},
			}),
			containedSecondary: ({ theme }) => ({
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
				boxShadow: 'none',
				'&:hover': {
					backgroundColor: theme.palette.secondary.dark,
					boxShadow: 'none',
				},
				'&:disabled': {
					color: theme.palette.action.disabled,
				},
			}),
			outlined: ({ theme }) => ({
				borderColor: theme.palette.primary.main,
				color: theme.palette.primary.main,
				'&:hover': {
					borderColor: theme.palette.primary.dark,
					backgroundColor: theme.palette.action.hover,
				},
			}),
			text: ({ theme }) => ({
				color: theme.palette.primary.main,
				'&:hover': {
					backgroundColor: theme.palette.action.hover,
				},
			}),
		},
	},
	MuiTypography: {
		styleOverrides: {
			h6: ({ theme }) => ({
				fontWeight: 'bold',
				fontSize: '1.2em',
				color: theme.palette.primary.main,
				marginBottom: theme.spacing(1.25),
			}),
			body2: ({ theme }) => ({
				fontSize: '0.95em',
				color: theme.palette.text.secondary,
				marginBottom: theme.spacing(1.875),
			}),
		},
	},
	MuiModal: {
		styleOverrides: {
			root: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
		},
	},
	MuiSelect: {
		styleOverrides: {
			root: ({ theme }) => ({
				minWidth: 120,
				'& .MuiSelect-select': {
					padding: theme.spacing(1.5),
				},
			}),
		},
	},
	MuiIconButton: {
		styleOverrides: {
			root: ({ theme }) => ({
				color: theme.palette.primary.main,
				'&:hover': {
					backgroundColor: theme.palette.action.hover,
				},
			}),
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: ({ theme }) => ({
				borderRadius: theme.shape.borderRadius,
				backgroundColor: theme.palette.background.paper,
				transition: theme.transitions.create(['border-color', 'box-shadow']),
				'&:hover': {
					borderColor: theme.palette.primary.main,
				},
				'&.Mui-focused': {
					borderColor: theme.palette.primary.dark,
					boxShadow: `${theme.palette.primary.main} 0 0 0 1px`,
				},
			}),
		},
	},
	// Add more component overrides as needed
};

export default components;
