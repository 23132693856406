import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ManagerOnboarding from './mainPages/ManagerOnboarding';
import ArtistOnboarding from './ArtistOnboarding';
import { updateIsFirstLogin } from '../../utils/apis/auth/sessionUtils';
import SessionContext from '../../context/SessionContext';
import ArtistContext from '../../context/ArtistContext';

const InitialOnboarding = () => {
	const { selectedArtist } = useContext(ArtistContext);
	const { session, setSession } = useContext(SessionContext);
	const [step, setStep] = useState(0);
	const navigate = useNavigate();

	useEffect(() => {
		if (!session.isFirstLogin) {
			navigate('/home');
		}
	}, [session.isFirstLogin, navigate]);

	const handleManagerComplete = () => {
		setStep(1);
	};

	const handleArtistComplete = () => {
		setSession((prev) => ({ ...prev, isFirstLogin: false }));
		updateIsFirstLogin(false);
		navigate('/home');
	};

	return (
		<>
			{step === 0 && <ManagerOnboarding onComplete={handleManagerComplete} />}
			{step === 1 && (
				<ArtistOnboarding
					selectedArtist={selectedArtist}
					onComplete={handleArtistComplete}
				/>
			)}
		</>
	);
};

export default InitialOnboarding;
