// src/context/ColorModeContext.js
import React, { createContext, useMemo, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getPalette from '../themes/palette';
import typography from '../themes/typography';
import spacing from '../themes/spacing';
import components from '../themes/components';
import breakpoints from '../themes/breakpoints';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export const ColorModeContextProvider = ({ children }) => {
	const [mode, setMode] = useState('light');

	const colorMode = useMemo(
		() => ({
			toggleColorMode: () => {
				setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
			},
		}),
		[]
	);

	const theme = useMemo(() => {
		const baseTheme = createTheme({
			palette: getPalette(mode),
			spacing,
			breakpoints,
			components,
			shape: {
				borderRadius: 12,
			},
		});

		baseTheme.typography = typography(baseTheme);

		return baseTheme;
	}, [mode]);

	return (
		<ColorModeContext.Provider value={colorMode}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ColorModeContext.Provider>
	);
};
