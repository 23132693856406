export const questionToPromptMap = {
	adCreativesReady: (value) => {
		if (value === 'No') {
			return '- **Ad Creatives:** Not prepared. Please suggest options.\n';
		}
		return value ? `- **Ad Creative Ideas:** ${value}.\n` : '';
	},
	adPlatforms: (value, details) =>
		Array.isArray(value)
			? `- **Ad Platforms:** ${value
					.map((platform) =>
						details && details[platform]
							? `${platform} (${details[platform]})`
							: platform
					)
					.join(', ')}.\n`
			: value
			? `- **Ad Platforms:** ${value}${
					details && details[value] ? ` (${details[value]})` : ''
			  }.\n`
			: '',
	budget: (value, details) =>
		value
			? `- **Marketing Budget:** ${value}${
					details && details[value] ? ` ($${details[value]})` : ''
			  }.\n`
			: '',
	campaignDuration: (value) => (value ? `- **Campaign Duration:** ${value}.\n` : ''),
	campaignPrimaryGoal: (value, details) =>
		Array.isArray(value)
			? `- **Campaign Primary Goal:** ${value
					.map((goal) =>
						details && details[goal] ? `${goal} (${details[goal]})` : goal
					)
					.join(', ')}.\n`
			: value
			? `- **Campaign Primary Goal:** ${value}${
					details && String(details[value]) ? ` (${details[value]})` : ''
			  }.\n`
			: '',
	campaignSecondaryGoals: (value, details) =>
		Array.isArray(value)
			? `- **Campaign Secondary Goals:** ${value
					.map((outcome) =>
						details && details[outcome]
							? `${outcome} (${details[outcome]})`
							: outcome
					)
					.join(', ')}.\n`
			: value
			? `- **Campaign Secondary Goals:** ${value}.\n`
			: '',
	campaignStartDate: (value, details) =>
		value
			? `- **Campaign Start Date:** ${value}${
					details && details[value] ? ` (${details[value]})` : ''
			  }.\n`
			: '',

	mood: (value, details) =>
		Array.isArray(value)
			? `- **Mood:** ${value
					.map((mood) =>
						details && details[mood] ? `${mood} (${details[mood]})` : mood
					)
					.join(', ')}.\n`
			: value
			? `- **Mood:** ${value}${
					details && String(details[value]) ? ` (${details[value]})` : ''
			  }.\n`
			: '',
	platformFocus: (value, details) =>
		Array.isArray(value)
			? `- **Platform Focus:** ${value
					.map((platform) =>
						details && details[platform]
							? `${platform} (${details[platform]})`
							: platform
					)
					.join(', ')}.\n`
			: value
			? `- **Platform Focus:** ${value}${
					details && details[value] ? ` (${details[value]})` : ''
			  }.\n`
			: '',

	previousStrategies: (value) =>
		value ? `- **Previously Implemented Marketing Strategies:** ${value}.\n` : '',
	projectName: (value) => (value ? `- **Project Title:** "${value}".\n` : ''),
	releaseDate: (value) =>
		value ? `- **Release Date:** Scheduled for release on ${value}.\n` : '',
	releaseGenres: (value, details) =>
		Array.isArray(value)
			? `- **Release Genres:** ${value
					.map((theme) =>
						details && details[theme] ? `${theme} (${details[theme]})` : theme
					)
					.join(', ')}.\n`
			: value
			? `- **Release Genres:** ${value}${
					details && String(details[value]) ? ` (${details[value]})` : ''
			  }.\n`
			: '',
	releaseType: (value, details) =>
		value ? `- **Music Release Type:** ${value}.\n` : '',
};
