// components/modules/chatInterface/subcomponents/messageTypes/VisualizationMessage.jsx

import React from 'react';
import PropTypes from 'prop-types';
import Visualization from './Visualization';
import { Box, Typography } from '@mui/material';

const VisualizationMessage = ({ data, text }) => {
	return (
		<Box sx={{ p: 1 }}>
			{text && (
				<Typography variant="subtitle1" gutterBottom>
					{text}
				</Typography>
			)}
			<Visualization data={data} />
		</Box>
	);
};

VisualizationMessage.propTypes = {
	data: PropTypes.object.isRequired,
};

export default React.memo(VisualizationMessage);
