import React from 'react';
import {
	Button,
	TextField,
	Typography,
	Grid,
	FormControl,
	Select,
	MenuItem,
	IconButton,
	Box,
	Divider,
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { useFormik, FieldArray, FormikProvider, getIn } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';

const TikTokData = ({ artistData: tiktokData, onSave, onCancel }) => {
	const theme = useTheme();
	// Define the available age ranges
	const ageRangeOptions = ['18-24', '25-34', '35-44', '45-54', '55+'];

	// Validation schema using Yup
	const validationSchema = Yup.object().shape({
		overview: Yup.object().shape({
			videoViews: Yup.number()
				.typeError('Video Views must be a valid number')
				.integer('Video Views must be an integer')
				.min(0, 'Video Views cannot be negative')
				.required('Video Views is required'),
			profileViews: Yup.number()
				.typeError('Profile Views must be a valid number')
				.integer('Profile Views must be an integer')
				.min(0, 'Profile Views cannot be negative')
				.required('Profile Views is required'),
			likes: Yup.number()
				.typeError('Likes must be a valid number')
				.integer('Likes must be an integer')
				.min(0, 'Likes cannot be negative')
				.required('Likes is required'),
			comments: Yup.number()
				.typeError('Comments must be a valid number')
				.integer('Comments must be an integer')
				.min(0, 'Comments cannot be negative')
				.required('Comments is required'),
			shares: Yup.number()
				.typeError('Shares must be a valid number')
				.integer('Shares must be an integer')
				.min(0, 'Shares cannot be negative')
				.required('Shares is required'),
			trafficSources: Yup.array()
				.of(
					Yup.object().shape({
						source: Yup.string()
							.typeError('Source must be a string')
							.matches(/^[A-Za-z\s]+$/, 'Source can only contain letters and spaces')
							.required('Source is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(5, 'You can add up to 5 traffic sources only')
				.required('Traffic Sources are required')
				.min(1, 'At least 1 traffic source must be added'),
			searchQueries: Yup.array()
				.of(
					Yup.object().shape({
						query: Yup.string()
							.typeError('Query must be a string')
							.matches(/^[A-Za-z\s]+$/, 'Query can only contain letters and spaces')
							.required('Query is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(5, 'You can add up to 5 search queries only')
				.required('Search Queries are required')
				.min(1, 'At least 1 search query must be added'),
		}),
		viewers: Yup.object().shape({
			totalViewers: Yup.number()
				.typeError('Total Viewers must be a valid number')
				.integer('Total Viewers must be an integer')
				.min(0, 'Total Viewers cannot be negative')
				.required('Total Viewers is required'),
			newViewers: Yup.number()
				.typeError('New Viewers must be a valid number')
				.integer('New Viewers must be an integer')
				.min(0, 'New Viewers cannot be negative')
				.required('New Viewers is required'),
			genderPercentM: Yup.number()
				.typeError('Gender Percent (M) must be a valid number')
				.min(0, 'Gender Percent (M) cannot be negative')
				.max(100, 'Gender Percent (M) cannot exceed 100')
				.required('Gender Percent (M) is required'),
			genderPercentF: Yup.number()
				.typeError('Gender Percent (F) must be a valid number')
				.min(0, 'Gender Percent (F) cannot be negative')
				.max(100, 'Gender Percent (F) cannot exceed 100')
				.required('Gender Percent (F) is required'),
			ageRanges: Yup.array()
				.of(
					Yup.object().shape({
						ageRange: Yup.string()
							.typeError('Age Range must be a string')
							.required('Age Range is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(3, 'You can add up to 3 age ranges only')
				.required('Age Ranges are required')
				.min(3, 'At least 3 age ranges must be added'),
			topLocations: Yup.array()
				.of(
					Yup.object().shape({
						location: Yup.string()
							.typeError('Location must be a string')
							.matches(
								/^[A-Za-z\s\/]+$/,
								'Location can only contain letters and spaces'
							)
							.required('Location is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(5, 'You can add up to 5 top locations only')
				.required('Top Locations are required')
				.min(1, 'At least 1 top location must be added'),
		}),
		followers: Yup.object().shape({
			totalFollowers: Yup.number()
				.typeError('Total Followers must be a valid number')
				.integer('Total Followers must be an integer')
				.min(0, 'Total Followers cannot be negative')
				.required('Total Followers is required'),
			netFollowers: Yup.number()
				.typeError('Net Followers must be a valid number')
				.integer('Net Followers must be an integer')
				.min(0, 'Net Followers cannot be negative')
				.required('Net Followers is required'),
			genderPercentM: Yup.number()
				.typeError('Gender Percent (M) must be a valid number')
				.min(0, 'Gender Percent (M) cannot be negative')
				.max(100, 'Gender Percent (M) cannot exceed 100')
				.required('Gender Percent (M) is required'),
			genderPercentF: Yup.number()
				.typeError('Gender Percent (F) must be a valid number')
				.min(0, 'Gender Percent (F) cannot be negative')
				.max(100, 'Gender Percent (F) cannot exceed 100')
				.required('Gender Percent (F) is required'),
			ageRanges: Yup.array()
				.of(
					Yup.object().shape({
						ageRange: Yup.string()
							.typeError('Age Range must be a string')
							.required('Age Range is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(3, 'You can add up to 3 age ranges only')
				.required('Age Ranges are required')
				.min(3, 'At least 3 age ranges must be added'),
			topLocations: Yup.array()
				.of(
					Yup.object().shape({
						location: Yup.string()
							.typeError('Location must be a string')
							.matches(
								/^[A-Za-z\s\/]+$/,
								'Location can only contain letters and spaces'
							)
							.required('Location is required'),
						percent: Yup.number()
							.typeError('Percent must be a valid number')
							.min(0, 'Percent cannot be negative')
							.max(100, 'Percent cannot exceed 100')
							.required('Percent is required'),
					})
				)
				.max(5, 'You can add up to 5 top locations only')
				.required('Top Locations are required')
				.min(1, 'At least 1 top location must be added'),
		}),
	});

	// Initialize Formik with enableReinitialize
	const formik = useFormik({
		initialValues: {
			overview: {
				videoViews: tiktokData?.overview?.videoViews ?? '',
				profileViews: tiktokData?.overview?.profileViews ?? '',
				likes: tiktokData?.overview?.likes ?? '',
				comments: tiktokData?.overview?.comments ?? '',
				shares: tiktokData?.overview?.shares ?? '',
				trafficSources: Array.isArray(tiktokData?.overview?.trafficSources)
					? tiktokData.overview.trafficSources.map((item) => ({
							source: item.source ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ source: '', percent: '' },
							{ source: '', percent: '' },
							{ source: '', percent: '' },
							{ source: '', percent: '' },
							{ source: '', percent: '' },
					  ],
				searchQueries: Array.isArray(tiktokData?.overview?.searchQueries)
					? tiktokData.overview.searchQueries.map((item) => ({
							query: item.query ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ query: '', percent: '' },
							{ query: '', percent: '' },
							{ query: '', percent: '' },
							{ query: '', percent: '' },
							{ query: '', percent: '' },
					  ],
			},
			viewers: {
				totalViewers: tiktokData?.viewers?.totalViewers ?? '',
				newViewers: tiktokData?.viewers?.newViewers ?? '',
				genderPercentM: tiktokData?.viewers?.genderPercentM ?? '',
				genderPercentF: tiktokData?.viewers?.genderPercentF ?? '',
				ageRanges: Array.isArray(tiktokData?.viewers?.ageRanges)
					? tiktokData.viewers.ageRanges.map((item) => ({
							ageRange: item.ageRange ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
					  ],
				topLocations: Array.isArray(tiktokData?.viewers?.topLocations)
					? tiktokData.viewers.topLocations.map((item) => ({
							location: item.location ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ location: '', percent: '' },
							{ location: '', percent: '' },
							{ location: '', percent: '' },
							{ location: '', percent: '' },
							{ location: '', percent: '' },
					  ],
			},
			followers: {
				totalFollowers: tiktokData?.followers?.totalFollowers ?? '',
				netFollowers: tiktokData?.followers?.netFollowers ?? '',
				genderPercentM: tiktokData?.followers?.genderPercentM ?? '',
				genderPercentF: tiktokData?.followers?.genderPercentF ?? '',
				ageRanges: Array.isArray(tiktokData?.followers?.ageRanges)
					? tiktokData.followers.ageRanges.map((item) => ({
							ageRange: item.ageRange ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
							{ ageRange: '', percent: '' },
					  ],
				topLocations: Array.isArray(tiktokData?.followers?.topLocations)
					? tiktokData.followers.topLocations.map((item) => ({
							location: item.location ?? '',
							percent: item.percent ?? '',
					  }))
					: [
							{ location: '', percent: '' },
							{ location: '', percent: '' },
							{ location: '', percent: '' },
							{ location: '', percent: '' },
							{ location: '', percent: '' },
					  ],
			},
		},
		validationSchema,
		onSubmit: (values) => {
			onSave(values);
		},
		enableReinitialize: true,
		validateOnMount: true,
	});

	// Helper function to render dynamic fields
	const renderDynamicFields = (
		name,
		label,
		arrayHelpers,
		fieldLabel1,
		fieldLabel2
	) => {
		// Determine the correct field name based on fieldLabel1
		let fieldName1;
		if (fieldLabel1 === 'Age Range') {
			fieldName1 = 'ageRange';
		} else if (
			fieldLabel1 === 'City' ||
			fieldLabel1 === 'Country' ||
			fieldLabel1 === 'Location' ||
			fieldLabel1 === 'Query' ||
			fieldLabel1 === 'Source' ||
			fieldLabel1 === 'Song'
		) {
			fieldName1 = fieldLabel1.toLowerCase();
		} else {
			fieldName1 = 'source'; // Fallback to 'source' if needed
		}

		return (
			<>
				<Typography
					variant="subtitle1"
					sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
				>
					{label}
				</Typography>
				{getIn(formik.values, name).map((_, index) => (
					<Box display="flex" alignItems="center" mb={2} key={index}>
						{/* Conditional rendering based on fieldLabel1 */}
						{fieldLabel1 === 'Age Range' ? (
							<FormControl fullWidth sx={{ mr: 2 }}>
								<Select
									label={`${fieldLabel1} ${index + 1}`}
									name={`${name}[${index}].${fieldName1}`}
									value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
										Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
									}
								>
									{ageRangeOptions.map((range) => (
										<MenuItem key={range} value={range}>
											{range}
										</MenuItem>
									))}
								</Select>
								{getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`) && (
										<Typography color="error" variant="caption">
											{getIn(formik.errors, `${name}[${index}].${fieldName1}`)}
										</Typography>
									)}
							</FormControl>
						) : (
							<TextField
								fullWidth
								label={`${fieldLabel1}:`}
								name={`${name}[${index}].${fieldName1}`}
								type="text"
								inputMode="numeric"
								value={getIn(formik.values, `${name}[${index}].${fieldName1}`)}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									Boolean(getIn(formik.errors, `${name}[${index}].${fieldName1}`))
								}
								helperText={
									getIn(formik.touched, `${name}[${index}].${fieldName1}`) &&
									getIn(formik.errors, `${name}[${index}].${fieldName1}`)
								}
								variant="outlined"
								sx={{ mr: 2 }}
							/>
						)}
						<TextField
							fullWidth
							label={`${fieldLabel2}:`}
							name={`${name}[${index}].percent`}
							type="text"
							inputMode="numeric"
							value={getIn(formik.values, `${name}[${index}].percent`)}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={
								getIn(formik.touched, `${name}[${index}].percent`) &&
								Boolean(getIn(formik.errors, `${name}[${index}].percent`))
							}
							helperText={
								getIn(formik.touched, `${name}[${index}].percent`) &&
								getIn(formik.errors, `${name}[${index}].percent`)
							}
							variant="outlined"
							sx={{ mr: 2 }}
							InputProps={{
								endAdornment: <Typography variant="caption">%</Typography>,
								inputProps: { min: 0, max: 100 },
							}}
						/>
						<IconButton
							color="secondary"
							onClick={() => arrayHelpers.remove(index)}
							disabled={getIn(formik.values, name).length === 1}
						>
							<RemoveCircleOutline />
						</IconButton>
					</Box>
				))}
				{getIn(formik.values, name).length <
					(fieldLabel1 === 'Age Range' ? 3 : 5) && (
					<Button
						variant="outlined"
						startIcon={<AddCircleOutline />}
						onClick={() =>
							arrayHelpers.push(
								fieldLabel1 === 'Age Range'
									? { ageRange: '', percent: '' }
									: { [fieldName1]: '', percent: '' } // Dynamically set fieldName1
							)
						}
						sx={{ mb: 2 }}
					>
						Add {fieldLabel1}
					</Button>
				)}
			</>
		);
	};

	return (
		<FormikProvider value={formik}>
			<Box
				sx={{
					width: '100%',
					maxWidth: '750px',
					margin: '0 auto',
					padding: 2,
					backgroundColor: 'background.paper',
					borderRadius: 2,
				}}
			>
				<form onSubmit={formik.handleSubmit}>
					{/* Header Section */}
					<Box textAlign="center" mb={4}>
						<Typography variant="h4" sx={{ mb: 2, mt: 2 }}>
							TikTok Analytics Data
						</Typography>
						<Button
							variant="contained"
							color="primary"
							href="https://www.tiktok.com/tiktokstudio/analytics/overview"
							target="_blank"
							sx={{ ...theme.typography.button, mb: 2 }}
						>
							Open TikTok Analytics
						</Button>
						<Typography variant="subtitle1" sx={{ ...theme.typography.h4, mb: 2 }}>
							Last 28 Days
						</Typography>
						<Divider sx={{ mb: 2 }} />
					</Box>

					{/* Overview Data */}
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Overview Data
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Video Views"
									name="overview.videoViews"
									type="text"
									inputMode="numeric"
									value={formik.values.overview.videoViews}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.overview?.videoViews &&
										Boolean(formik.errors.overview?.videoViews)
									}
									helperText={
										formik.touched.overview?.videoViews &&
										formik.errors.overview?.videoViews
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Profile Views"
									name="overview.profileViews"
									type="text"
									inputMode="numeric"
									value={formik.values.overview.profileViews}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.overview?.profileViews &&
										Boolean(formik.errors.overview?.profileViews)
									}
									helperText={
										formik.touched.overview?.profileViews &&
										formik.errors.overview?.profileViews
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Likes"
									name="overview.likes"
									type="text"
									inputMode="numeric"
									value={formik.values.overview.likes}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.overview?.likes &&
										Boolean(formik.errors.overview?.likes)
									}
									helperText={
										formik.touched.overview?.likes && formik.errors.overview?.likes
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Comments"
									name="overview.comments"
									type="text"
									inputMode="numeric"
									value={formik.values.overview.comments}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.overview?.comments &&
										Boolean(formik.errors.overview?.comments)
									}
									helperText={
										formik.touched.overview?.comments &&
										formik.errors.overview?.comments
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={4}>
								<TextField
									fullWidth
									label="Shares"
									name="overview.shares"
									type="text"
									inputMode="numeric"
									value={formik.values.overview.shares}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.overview?.shares &&
										Boolean(formik.errors.overview?.shares)
									}
									helperText={
										formik.touched.overview?.shares && formik.errors.overview?.shares
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>

							{/* Traffic Sources */}
							<Grid item xs={12}>
								<FieldArray
									name="overview.trafficSources"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'overview.trafficSources',
											'Overview - Top 5 Traffic Sources',
											arrayHelpers,
											'Source',
											'Percent'
										)
									}
								/>
								{formik.touched.overview?.trafficSources &&
									typeof formik.errors.overview?.trafficSources === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.overview?.trafficSources}
										</Typography>
									)}
							</Grid>

							{/* Search Queries */}
							<Grid item xs={12}>
								<FieldArray
									name="overview.searchQueries"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'overview.searchQueries',
											'Overview - Top 5 Search Queries',
											arrayHelpers,
											'Query',
											'Percent'
										)
									}
								/>
								{formik.touched.overview?.searchQueries &&
									typeof formik.errors.overview?.searchQueries === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.overview?.searchQueries}
										</Typography>
									)}
							</Grid>
						</Grid>
					</Box>

					{/* Viewers Data */}
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Viewers Data
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Total Viewers"
									name="viewers.totalViewers"
									type="text"
									inputMode="numeric"
									value={formik.values.viewers.totalViewers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.viewers?.totalViewers &&
										Boolean(formik.errors.viewers?.totalViewers)
									}
									helperText={
										formik.touched.viewers?.totalViewers &&
										formik.errors.viewers?.totalViewers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="New Viewers"
									name="viewers.newViewers"
									type="text"
									inputMode="numeric"
									value={formik.values.viewers.newViewers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.viewers?.newViewers &&
										Boolean(formik.errors.viewers?.newViewers)
									}
									helperText={
										formik.touched.viewers?.newViewers &&
										formik.errors.viewers?.newViewers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (M)"
									name="viewers.genderPercentM"
									type="text"
									inputMode="numeric"
									value={formik.values.viewers.genderPercentM}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.viewers?.genderPercentM &&
										Boolean(formik.errors.viewers?.genderPercentM)
									}
									helperText={
										formik.touched.viewers?.genderPercentM &&
										formik.errors.viewers?.genderPercentM
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (F)"
									name="viewers.genderPercentF"
									type="text"
									inputMode="numeric"
									value={formik.values.viewers.genderPercentF}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.viewers?.genderPercentF &&
										Boolean(formik.errors.viewers?.genderPercentF)
									}
									helperText={
										formik.touched.viewers?.genderPercentF &&
										formik.errors.viewers?.genderPercentF
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<FieldArray
									name="viewers.ageRanges"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'viewers.ageRanges',
											'Viewers - Top 3 Age Ranges',
											arrayHelpers,
											'Age Range',
											'Percent'
										)
									}
								/>
								{formik.touched.viewers?.ageRanges &&
									typeof formik.errors.viewers?.ageRanges === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.viewers?.ageRanges}
										</Typography>
									)}
							</Grid>

							{/* Top Locations */}
							<Grid item xs={12}>
								<FieldArray
									name="viewers.topLocations"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'viewers.topLocations',
											'Viewers - Top 5 Locations',
											arrayHelpers,
											'Location',
											'Percent'
										)
									}
								/>
								{formik.touched.viewers?.topLocations &&
									typeof formik.errors.viewers?.topLocations === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.viewers?.topLocations}
										</Typography>
									)}
							</Grid>
						</Grid>
					</Box>

					{/* Followers Data */}
					<Box mb={4}>
						<Typography
							variant="h6"
							sx={{ ...theme.typography.body1, mt: 2, mb: 2, fontWeight: 600 }}
						>
							Followers Data
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Total Followers"
									name="followers.totalFollowers"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.totalFollowers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.totalFollowers &&
										Boolean(formik.errors.followers?.totalFollowers)
									}
									helperText={
										formik.touched.followers?.totalFollowers &&
										formik.errors.followers?.totalFollowers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Net Followers"
									name="followers.netFollowers"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.netFollowers}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.netFollowers &&
										Boolean(formik.errors.followers?.netFollowers)
									}
									helperText={
										formik.touched.followers?.netFollowers &&
										formik.errors.followers?.netFollowers
									}
									variant="outlined"
									InputProps={{ inputProps: { min: 0 } }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (M)"
									name="followers.genderPercentM"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.genderPercentM}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.genderPercentM &&
										Boolean(formik.errors.followers?.genderPercentM)
									}
									helperText={
										formik.touched.followers?.genderPercentM &&
										formik.errors.followers?.genderPercentM
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									fullWidth
									label="Gender Percent (F)"
									name="followers.genderPercentF"
									type="text"
									inputMode="numeric"
									value={formik.values.followers.genderPercentF}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.followers?.genderPercentF &&
										Boolean(formik.errors.followers?.genderPercentF)
									}
									helperText={
										formik.touched.followers?.genderPercentF &&
										formik.errors.followers?.genderPercentF
									}
									variant="outlined"
									InputProps={{
										endAdornment: <Typography variant="caption">%</Typography>,
										inputProps: { min: 0, max: 100 },
									}}
								/>
							</Grid>
							<Grid item xs={12} sm={12}>
								<FieldArray
									name="followers.ageRanges"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'followers.ageRanges',
											'Followers - Top 3 Age Ranges',
											arrayHelpers,
											'Age Range',
											'Percent'
										)
									}
								/>
								{formik.touched.followers?.ageRanges &&
									typeof formik.errors.followers?.ageRanges === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.followers?.ageRanges}
										</Typography>
									)}
							</Grid>

							{/* Top Locations */}
							<Grid item xs={12}>
								<FieldArray
									name="followers.topLocations"
									render={(arrayHelpers) =>
										renderDynamicFields(
											'followers.topLocations',
											'Followers - Top 5 Locations',
											arrayHelpers,
											'Location',
											'Percent'
										)
									}
								/>
								{formik.touched.followers?.topLocations &&
									typeof formik.errors.followers?.topLocations === 'string' && (
										<Typography color="error" variant="caption">
											{formik.errors.followers?.topLocations}
										</Typography>
									)}
							</Grid>
						</Grid>
					</Box>

					{/* Submit and Cancel Buttons */}
					<Box mt={4} display="flex" justifyContent="center" gap={2}>
						<Button
							variant="outlined"
							color="secondary"
							onClick={onCancel}
							sx={{ ...theme.typography.button }}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!formik.isValid || formik.isSubmitting}
							size="large"
							sx={{ ...theme.typography.button }}
						>
							Save Data
						</Button>
					</Box>
				</form>
			</Box>
		</FormikProvider>
	);
};

export default TikTokData;
