// src/components/modules/chatInterface/subcomponents/navigationTabs/SettingsOption.jsx

import React from 'react';
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Paper,
	Typography,
	Box,
	Tooltip,
	IconButton,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SettingsOption = ({
	label,
	defaultValue,
	options,
	icon,
	tooltip,
	onChange,
}) => {
	const [value, setValue] = React.useState(defaultValue);

	const handleChange = (event) => {
		const newValue = event.target.value;
		setValue(newValue);
		if (onChange) {
			onChange(newValue);
		}
	};

	return (
		<Paper
			sx={{
				p: 2,
				display: 'flex',
				alignItems: 'center',
				borderRadius: 2,
				boxShadow: 2,
			}}
			elevation={2}
		>
			{icon && <Box sx={{ mr: 2 }}>{icon}</Box>}
			<Box sx={{ flexGrow: 1 }}>
				<Box display="flex" alignItems="center">
					<Typography variant="body1" gutterBottom sx={{ fontWeight: 'bold' }}>
						{label}
					</Typography>
					{tooltip && (
						<Tooltip title={tooltip} placement="right">
							<IconButton size="small" sx={{ ml: 1 }}>
								<InfoOutlinedIcon fontSize="small" color="primary" />
							</IconButton>
						</Tooltip>
					)}
				</Box>
				<FormControl fullWidth>
					<InputLabel id={`${label}-label`}>{label}</InputLabel>
					<Select
						labelId={`${label}-label`}
						value={value}
						label={label}
						onChange={handleChange}
						sx={{ borderRadius: 1 }}
					>
						{options.map((option) => (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
		</Paper>
	);
};

export default SettingsOption;
