import React, { useState, useContext, useEffect } from 'react';
import { Box, Toolbar } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import ArtistContext from '../../context/ArtistContext';
import { styled } from '@mui/system';
import { useTheme, useMediaQuery } from '@mui/material';

// Styled Main Content to ensure responsiveness and spacing
const MainContent = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	width: '100%',
	backgroundColor: theme.palette.background.default,
	minHeight: '100vh',
	overflow: 'auto',
	[theme.breakpoints.down('sm')]: {
		padding: theme.spacing(2),
	},
}));

const Layout = ({ children, onLogout, hideSidebar = false }) => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const location = useLocation();
	const { selectedArtist, setShowModal } = useContext(ArtistContext);

	// Determine if the current route is '/onboarding'
	const hideSidebarFlag = location.pathname === '/onboarding' || hideSidebar;

	// Adjust sidebar behavior based on screen size
	const [open, setOpen] = useState(!isSmallScreen);

	useEffect(() => {
		setOpen(!isSmallScreen);
	}, [isSmallScreen]);

	return (
		<Box sx={{ display: 'flex' }}>
			{/* Render Sidebar unless on '/onboarding' route */}
			{!hideSidebarFlag && (
				<Sidebar
					variant={isSmallScreen ? 'temporary' : 'permanent'}
					setOpen={setOpen}
					open={open}
					onClose={() => setOpen(false)}
					selectedArtist={selectedArtist}
					setShowModal={setShowModal}
				/>
			)}

			{/* Render Header with open state */}
			<Header
				open={open}
				setOpen={setOpen}
				onLogout={onLogout}
				sidebarHiding={hideSidebarFlag}
			/>

			{/* Main Content Area */}
			<MainContent>
				{/* Spacer to prevent content from being hidden behind the fixed Header */}
				<Toolbar />
				{children}
			</MainContent>
		</Box>
	);
};

export default Layout;
