import React from 'react';
import {
	Button,
	DialogActions,
	DialogContent,
	Typography,
	Grid,
	Box,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import MilestoneCard from './MilestoneCard';

const KeyMilestones = ({ artistData, onSave, onPopupBack }) => {
	const theme = useTheme();
	const milestoneCategories = [
		'Press',
		'Narrative',
		'Industry Awards',
		'Merch Revenue per Head',
		'Event Room Caps',
		'Brand Deals',
	];

	const secondaryOptions = {
		Press: ['Media Coverage', 'Interviews', 'Magazine Features', 'Online Articles'],
		Narrative: ['Music Genre', 'Public Persona', 'Artistic Style', 'Message'],
		'Industry Awards': ['Grammy', 'Billboard', 'MTV', 'BET'],
		'Merch Revenue per Head': ['T-Shirts', 'Hoodies', 'Accessories', 'Vinyls'],
		'Event Room Caps': ['100-300', '300-500', '500-1000', '1000+'],
		'Brand Deals': [
			'Endorsement',
			'Sponsorship',
			'Licensing Deal',
			'In-Kind Partnerships',
		],
	};

	const formik = useFormik({
		initialValues: {
			milestones: [
				{
					category: artistData.milestone1Category || '',
					secondary: artistData.milestone1Secondary || '',
					description: artistData.milestone1Description || '',
					value: artistData.milestone1Value || '',
					date: artistData.milestone1Date ? dayjs(artistData.milestone1Date) : null,
				},
				{
					category: artistData.milestone2Category || '',
					secondary: artistData.milestone2Secondary || '',
					description: artistData.milestone2Description || '',
					value: artistData.milestone2Value || '',
					date: artistData.milestone2Date ? dayjs(artistData.milestone2Date) : null,
				},
				{
					category: artistData.milestone3Category || '',
					secondary: artistData.milestone3Secondary || '',
					description: artistData.milestone3Description || '',
					value: artistData.milestone3Value || '',
					date: artistData.milestone3Date ? dayjs(artistData.milestone3Date) : null,
				},
			],
		},
		validationSchema: Yup.object({
			milestones: Yup.array().of(
				Yup.object({
					category: Yup.string().required('Milestone category is required'),
					secondary: Yup.string().required('Secondary milestone is required'),
					description: Yup.string()
						.min(50, 'Description must be at least 50 characters long')
						.required('Description is required'),
					value: Yup.number()
						.nullable()
						.notRequired()
						.typeError('Value must be a number'),
					date: Yup.date()
						.nullable()
						.required('Target date is required')
						.typeError('Invalid date'),
				})
			),
		}),
		onSubmit: (values) => {
			const updatedData = {
				milestone1Category: values.milestones[0].category,
				milestone1Secondary: values.milestones[0].secondary,
				milestone1Description: values.milestones[0].description,
				milestone1Date: values.milestones[0].date,
				milestone1Value: values.milestones[0].value,
				milestone2Category: values.milestones[1].category,
				milestone2Secondary: values.milestones[1].secondary,
				milestone2Description: values.milestones[1].description,
				milestone2Date: values.milestones[1].date,
				milestone2Value: values.milestones[1].value,
				milestone3Category: values.milestones[2].category,
				milestone3Secondary: values.milestones[2].secondary,
				milestone3Description: values.milestones[2].description,
				milestone3Date: values.milestones[2].date,
				milestone3Value: values.milestones[2].value,
			};
			onSave(updatedData);
		},
	});

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Box p={3}>
				<DialogContent>
					<Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
						What are your key milestones for the next 1-3 years?
					</Typography>
					<FormikProvider value={formik}>
						<form onSubmit={formik.handleSubmit}>
							<FieldArray
								name="milestones"
								render={() => (
									<Grid container spacing={1}>
										{formik.values.milestones.map((milestone, index) => (
											<Grid item sm={12} md={4} key={index}>
												<MilestoneCard
													index={index}
													milestone={milestone}
													milestoneCategories={milestoneCategories}
													secondaryOptions={secondaryOptions}
												/>
											</Grid>
										))}
									</Grid>
								)}
							/>
							<Grid container sx={{ justifyContent: 'center', mt: 2 }}>
								<Grid item xs={12} sm={12}>
									<Box display="flex" justifyContent="center" alignItems="center">
										<DialogActions>
											<Button
												onClick={onPopupBack}
												color="secondary"
												variant="contained"
												sx={{ ...theme.typography.button }}
											>
												Back
											</Button>
											<Button
												type="submit"
												color="primary"
												variant="contained"
												sx={{ ...theme.typography.button }}
											>
												Save & Close
											</Button>
										</DialogActions>
									</Box>
								</Grid>
							</Grid>
						</form>
					</FormikProvider>
				</DialogContent>
			</Box>
		</LocalizationProvider>
	);
};

export default KeyMilestones;
