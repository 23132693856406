// src/utils/cleanFormData.js

export const cleanFormData = (formData) => {
	const cleanedFormData = { ...formData };

	Object.keys(cleanedFormData).forEach((key) => {
		if (key.endsWith('Details')) {
			if (
				typeof cleanedFormData[key] === 'object' &&
				!Array.isArray(cleanedFormData[key])
			) {
				// Remove keys with empty objects
				if (Object.keys(cleanedFormData[key]).length === 0) {
					delete cleanedFormData[key];
				} else {
					// Remove keys with empty strings, nulls, or undefined
					Object.keys(cleanedFormData[key]).forEach((subKey) => {
						if (
							cleanedFormData[key][subKey] === '' ||
							cleanedFormData[key][subKey] === null ||
							cleanedFormData[key][subKey] === undefined
						) {
							delete cleanedFormData[key][subKey];
						}
					});
					// If after cleanup, the object is empty, delete it
					if (Object.keys(cleanedFormData[key]).length === 0) {
						delete cleanedFormData[key];
					}
				}
			} else if (
				cleanedFormData[key] === '' ||
				cleanedFormData[key] === null ||
				cleanedFormData[key] === undefined
			) {
				delete cleanedFormData[key];
			}
		}
	});

	return cleanedFormData;
};
